(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bL.aD === region.b2.aD)
	{
		return 'on line ' + region.bL.aD;
	}
	return 'on lines ' + region.bL.aD + ' through ' + region.b2.aD;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dT,
		impl.e3,
		impl.eU,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		W: func(record.W),
		bM: record.bM,
		bJ: record.bJ
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		(key !== 'value' || key !== 'checked' || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		value
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		value
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.W;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bM;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bJ) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			var oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			var newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}



// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dT,
		impl.e3,
		impl.eU,
		function(sendToApp, initialModel) {
			var view = impl.e6;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dT,
		impl.e3,
		impl.eU,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bK && impl.bK(sendToApp)
			var view = impl.e6;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.c_);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cJ) && (_VirtualDom_doc.title = title = doc.cJ);
			});
		}
	);
});



// ANIMATION


var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.el;
	var onUrlRequest = impl.em;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bK: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.download)
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cr === next.cr
							&& curr.b7 === next.b7
							&& curr.co.a === next.co.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dT: function(flags)
		{
			return A3(impl.dT, flags, _Browser_getUrl(), key);
		},
		e6: impl.e6,
		e3: impl.e3,
		eU: impl.eU
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dN: 'hidden', az: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dN: 'mozHidden', az: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dN: 'msHidden', az: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dN: 'webkitHidden', az: 'webkitvisibilitychange' }
		: { dN: 'hidden', az: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cz: _Browser_getScene(),
		cL: {
			bo: _Browser_window.pageXOffset,
			fe: _Browser_window.pageYOffset,
			ax: _Browser_doc.documentElement.clientWidth,
			ao: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ax: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ao: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cz: {
				ax: node.scrollWidth,
				ao: node.scrollHeight
			},
			cL: {
				bo: node.scrollLeft,
				fe: node.scrollTop,
				ax: node.clientWidth,
				ao: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cz: _Browser_getScene(),
			cL: {
				bo: x,
				fe: y,
				ax: _Browser_doc.documentElement.clientWidth,
				ao: _Browser_doc.documentElement.clientHeight
			},
			dx: {
				bo: x + rect.left,
				fe: y + rect.top,
				ax: rect.width,
				ao: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.ec) { flags += 'm'; }
	if (options.da) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dz.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dz.b, xhr)); });
		$elm$core$Maybe$isJust(request.eX) && _Http_track(router, xhr, request.eX.a);

		try {
			xhr.open(request.d8, request.e4, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.e4));
		}

		_Http_configureRequest(xhr, request);

		request.c_.a && xhr.setRequestHeader('Content-Type', request.c_.a);
		xhr.send(request.c_.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.dM; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.eV.a || 0;
	xhr.responseType = request.dz.d;
	xhr.withCredentials = request.cP;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		e4: xhr.responseURL,
		eR: xhr.status,
		eS: xhr.statusText,
		dM: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			eH: event.loaded,
			cD: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ez: event.loaded,
			cD: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.d) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.d * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.d);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{i: nodeList, d: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {b4: fragment, b7: host, cl: path, co: port_, cr: protocol, cs: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$Login = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$MenuClosed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Online = 0;
var $author$project$Main$Checkout = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$ChoosePlan = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$Meals = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$OrderConfirmation = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$Receipt = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$Welcome = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Wizard = function (a) {
	return {$: 2, a: a};
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Dog$Dog = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Dog$Breeds$Breed = F2(
	function (id, name) {
		return {a3: id, cg: name};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Dog$Breeds$breedDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Dog$Breeds$Breed)));
var $author$project$Allergy$Beef = 0;
var $author$project$Allergy$Chicken = 1;
var $author$project$Allergy$Duck = 6;
var $author$project$Allergy$Fish = 4;
var $author$project$Allergy$GuineaFowl = 9;
var $author$project$Allergy$Lamb = 3;
var $author$project$Allergy$Pork = 5;
var $author$project$Allergy$Turkey = 2;
var $author$project$Allergy$Venison = 7;
var $author$project$Allergy$WildBoar = 8;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Allergy$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (allergyStr) {
		switch (allergyStr) {
			case 'beef':
				return $elm$json$Json$Decode$succeed(0);
			case 'chicken':
				return $elm$json$Json$Decode$succeed(1);
			case 'turkey':
				return $elm$json$Json$Decode$succeed(2);
			case 'lamb':
				return $elm$json$Json$Decode$succeed(3);
			case 'fish':
				return $elm$json$Json$Decode$succeed(4);
			case 'pork':
				return $elm$json$Json$Decode$succeed(5);
			case 'duck':
				return $elm$json$Json$Decode$succeed(6);
			case 'venison':
				return $elm$json$Json$Decode$succeed(7);
			case 'wild_boar':
				return $elm$json$Json$Decode$succeed(8);
			case 'guinea_fowl':
				return $elm$json$Json$Decode$succeed(9);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Allergy: ' + allergyStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$ActivityLevel$BundleOfEnergy = 3;
var $author$project$Dog$ActivityLevel$FairlyActive = 2;
var $author$project$Dog$ActivityLevel$HyperActive = 4;
var $author$project$Dog$ActivityLevel$Lazy = 0;
var $author$project$Dog$ActivityLevel$Leisurely = 1;
var $author$project$Dog$ActivityLevel$WorkingDog = 5;
var $author$project$Dog$ActivityLevel$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (activityLevelStr) {
		switch (activityLevelStr) {
			case 'Lazy':
				return $elm$json$Json$Decode$succeed(0);
			case 'Leisurely':
				return $elm$json$Json$Decode$succeed(1);
			case 'FairlyActive':
				return $elm$json$Json$Decode$succeed(2);
			case 'BundleOfEnergy':
				return $elm$json$Json$Decode$succeed(3);
			case 'HyperActive':
				return $elm$json$Json$Decode$succeed(4);
			case 'WorkingDog':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Unsupported ActivityLevel: ' + activityLevelStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$BodyCondition$Chubby = 2;
var $author$project$Dog$BodyCondition$JustRight = 1;
var $author$project$Dog$BodyCondition$Skinny = 0;
var $author$project$Dog$BodyCondition$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (bodyConditionStr) {
		switch (bodyConditionStr) {
			case 'Skinny':
				return $elm$json$Json$Decode$succeed(0);
			case 'JustRight':
				return $elm$json$Json$Decode$succeed(1);
			case 'Chubby':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Unsupported BodyCondition: ' + bodyConditionStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$FoodBrands$FoodBrand = F3(
	function (id, name, category) {
		return {aj: category, a3: id, cg: name};
	});
var $author$project$Dog$FoodCategory$Dry = 0;
var $author$project$Dog$FoodCategory$HomeCooked = 3;
var $author$project$Dog$FoodCategory$Raw = 2;
var $author$project$Dog$FoodCategory$Wet = 1;
var $author$project$Dog$FoodCategory$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (foodCategoryStr) {
		switch (foodCategoryStr) {
			case 'Dry':
				return $elm$json$Json$Decode$succeed(0);
			case 'Wet':
				return $elm$json$Json$Decode$succeed(1);
			case 'Raw':
				return $elm$json$Json$Decode$succeed(2);
			case 'HomeCooked':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Unsupported FoodCategory: ' + foodCategoryStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$FoodBrands$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'category',
	$author$project$Dog$FoodCategory$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Dog$FoodBrands$FoodBrand))));
var $author$project$Dog$Fussiness$CanBeFussy = 1;
var $author$project$Dog$Fussiness$EatsAnything = 3;
var $author$project$Dog$Fussiness$GoodEater = 2;
var $author$project$Dog$Fussiness$VeryFussy = 0;
var $author$project$Dog$Fussiness$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (fussinessStr) {
		switch (fussinessStr) {
			case 'VeryFussy':
				return $elm$json$Json$Decode$succeed(0);
			case 'CanBeFussy':
				return $elm$json$Json$Decode$succeed(1);
			case 'GoodEater':
				return $elm$json$Json$Decode$succeed(2);
			case 'EatsAnything':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Fussiness: ' + fussinessStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$HealthIssues$None = {$: 0};
var $author$project$Dog$HealthIssues$Some = function (a) {
	return {$: 1, a: a};
};
var $author$project$Dog$HealthIssues$Cancer = 8;
var $author$project$Dog$HealthIssues$Diabetes = 1;
var $author$project$Dog$HealthIssues$Epilepsy = 12;
var $author$project$Dog$HealthIssues$HeartCondition = 11;
var $author$project$Dog$HealthIssues$IBD = 2;
var $author$project$Dog$HealthIssues$JointProblems = 7;
var $author$project$Dog$HealthIssues$KidneyDisease = 3;
var $author$project$Dog$HealthIssues$LiverDisease = 5;
var $author$project$Dog$HealthIssues$Obesity = 9;
var $author$project$Dog$HealthIssues$Pancreatitis = 0;
var $author$project$Dog$HealthIssues$SensitiveStomach = 10;
var $author$project$Dog$HealthIssues$SkinCoatIssues = 6;
var $author$project$Dog$HealthIssues$StruviteStones = 4;
var $author$project$Dog$HealthIssues$healthIssueDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (healthIssueStr) {
		switch (healthIssueStr) {
			case 'Pancreatitis':
				return $elm$json$Json$Decode$succeed(0);
			case 'Diabetes':
				return $elm$json$Json$Decode$succeed(1);
			case 'IBD':
				return $elm$json$Json$Decode$succeed(2);
			case 'Kidney disease':
				return $elm$json$Json$Decode$succeed(3);
			case 'Struvite stones':
				return $elm$json$Json$Decode$succeed(4);
			case 'Liver disease':
				return $elm$json$Json$Decode$succeed(5);
			case 'Skin/Coat issues':
				return $elm$json$Json$Decode$succeed(6);
			case 'Joint problems':
				return $elm$json$Json$Decode$succeed(7);
			case 'Cancer':
				return $elm$json$Json$Decode$succeed(8);
			case 'Obesity':
				return $elm$json$Json$Decode$succeed(9);
			case 'Sensitive stomach':
				return $elm$json$Json$Decode$succeed(10);
			case 'Heart condition':
				return $elm$json$Json$Decode$succeed(11);
			case 'Epilepsy':
				return $elm$json$Json$Decode$succeed(12);
			default:
				return $elm$json$Json$Decode$fail('Unsupported HealthIssue: ' + healthIssueStr);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Dog$HealthIssues$decoder = function () {
	var contentsDecoder = function (tag) {
		switch (tag) {
			case 'None':
				return $elm$json$Json$Decode$succeed($author$project$Dog$HealthIssues$None);
			case 'Some':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$elm$json$Json$Decode$list($author$project$Dog$HealthIssues$healthIssueDecoder),
					$elm$json$Json$Decode$succeed($author$project$Dog$HealthIssues$Some));
			default:
				return $elm$json$Json$Decode$fail('Unsupported HealthIssues: ' + tag);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		contentsDecoder,
		A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
}();
var $author$project$Dog$Sex$Female = 0;
var $author$project$Dog$Sex$Male = 1;
var $author$project$Dog$Sex$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (sexStr) {
		switch (sexStr) {
			case 'Female':
				return $elm$json$Json$Decode$succeed(0);
			case 'Male':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Sex: ' + sexStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$SnackingHabits$LotsOfSnacks = 2;
var $author$project$Dog$SnackingHabits$NoSnacks = 0;
var $author$project$Dog$SnackingHabits$SomeSnacks = 1;
var $author$project$Dog$SnackingHabits$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (snackingHabitsStr) {
		switch (snackingHabitsStr) {
			case 'NoSnacks':
				return $elm$json$Json$Decode$succeed(0);
			case 'SomeSnacks':
				return $elm$json$Json$Decode$succeed(1);
			case 'LotsOfSnacks':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Unsupported SnackingHabits: ' + snackingHabitsStr);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Dog$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$author$project$Dog$SnackingHabits$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$author$project$Dog$HealthIssues$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$list($author$project$Allergy$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$author$project$Dog$ActivityLevel$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Dog$Dog))))))))))))))));
var $author$project$Session$Session = $elm$core$Basics$identity;
var $author$project$Dog$FoodBrands$FoodBrands = $elm$core$Basics$identity;
var $author$project$Dog$FoodBrands$init = function (brands) {
	return {aO: brands.aO, bx: brands.bx, aq: brands.aq};
};
var $author$project$Session$brandsDecoder = function () {
	var otherDecoder = A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dry',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'wet',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'raw',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed(
					F3(
						function (raw, wet, dry) {
							return {dv: dry, ey: raw, fa: wet};
						})))));
	var onSuccess = F3(
		function (all, other, homecooked) {
			return $author$project$Dog$FoodBrands$init(
				{aO: all, bx: homecooked, aq: other});
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'homecooked',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'other',
			otherDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'all',
				$elm$json$Json$Decode$list($author$project$Dog$FoodBrands$decoder),
				$elm$json$Json$Decode$succeed(onSuccess))));
}();
var $author$project$DeliveryDate$DeliveryDate = F2(
	function (posix, isDeliverable) {
		return {b9: isDeliverable, aG: posix};
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$DeliveryDate$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isDeliverable',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'posix',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$DeliveryDate$DeliveryDate)));
var $author$project$Dog$Breeds$Breeds = $elm$core$Basics$identity;
var $author$project$Dog$Breeds$decoder = function () {
	var onSuccess = F2(
		function (allBreeds, mostPopularBreeds) {
			return {aO: allBreeds, bE: mostPopularBreeds};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'most_popular',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'all',
			$elm$json$Json$Decode$list($author$project$Dog$Breeds$breedDecoder),
			$elm$json$Json$Decode$succeed(onSuccess)));
}();
var $author$project$Jwt$Jwt = $elm$core$Basics$identity;
var $author$project$Jwt$decoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Decode$field, 'jwt', $elm$json$Json$Decode$string));
var $author$project$Plan$Complete = 0;
var $author$project$Plan$ToBeMixed = 1;
var $author$project$Plan$planTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (planTypeStr) {
		switch (planTypeStr) {
			case 'Complete':
				return $elm$json$Json$Decode$succeed(0);
			case 'ToBeMixed':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unexpected planType: ' + planTypeStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Plan$decoder = function () {
	var onSuccess = F6(
		function (id, pouchMass, planType, pricePerPouch, deliveryInterval, numberOfPouches) {
			return {dq: deliveryInterval, a3: id, ej: numberOfPouches, cn: planType, ev: pouchMass, as: pricePerPouch};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'numberOfPouches',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'deliveryInterval',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'pricePerPouch',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'planType',
					$author$project$Plan$planTypeDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'pouchMass',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed(onSuccess)))))));
}();
var $author$project$Recipe$Beef = 0;
var $author$project$Recipe$Chicken = 1;
var $author$project$Recipe$DuckAndChicken = 6;
var $author$project$Recipe$Lamb = 3;
var $author$project$Recipe$Pork = 5;
var $author$project$Recipe$Turkey = 2;
var $author$project$Recipe$WhiteFish = 4;
var $author$project$Recipe$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (recipeStr) {
		switch (recipeStr) {
			case 'Beef':
				return $elm$json$Json$Decode$succeed(0);
			case 'Chicken':
				return $elm$json$Json$Decode$succeed(1);
			case 'Turkey':
				return $elm$json$Json$Decode$succeed(2);
			case 'Lamb':
				return $elm$json$Json$Decode$succeed(3);
			case 'WhiteFish':
				return $elm$json$Json$Decode$succeed(4);
			case 'Pork':
				return $elm$json$Json$Decode$succeed(5);
			case 'DuckAndChicken':
				return $elm$json$Json$Decode$succeed(6);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Recipe: ' + recipeStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$UndeliverableDate$UndeliverableDate = F2(
	function (posix, replacement) {
		return {aG: posix, eB: replacement};
	});
var $author$project$UndeliverableDate$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'replacement',
	A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'posix',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$UndeliverableDate$UndeliverableDate)));
var $author$project$DiscountCode$DiscountCode = $elm$core$Basics$identity;
var $author$project$DiscountCode$Percentage = 0;
var $author$project$DiscountCode$Total = 1;
var $author$project$DiscountCode$basisDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (basisStr) {
		switch (basisStr) {
			case 'Percentage':
				return $elm$json$Json$Decode$succeed(0);
			case 'Total':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Basis: ' + basisStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$DiscountCode$decoder = function () {
	var onSuccess = F5(
		function (id_, code_, basis, value_, n) {
			return {aP: basis, aU: code_, a3: id_, ba: n, av: value_};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'value',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'basis',
				$author$project$DiscountCode$basisDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'code',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed(onSuccess))))));
}();
var $author$project$Session$discountCodesDecoder = function () {
	var onSuccess = F3(
		function (_default, active, all) {
			return {_: active, aO: all, aA: _default};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'all',
		$elm$json$Json$Decode$list($author$project$DiscountCode$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'active',
			$author$project$DiscountCode$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'default',
				$author$project$DiscountCode$decoder,
				$elm$json$Json$Decode$succeed(onSuccess))));
}();
var $author$project$Session$SalesRep = F2(
	function (firstName, lastName) {
		return {b3: firstName, d_: lastName};
	});
var $author$project$Session$salesRepDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastName',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstName',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Session$SalesRep)));
var $author$project$Session$decoder = function () {
	var onSuccess = function (jwt) {
		return function (salesRep_) {
			return function (breeds_) {
				return function (brands_) {
					return function (codes) {
						return function (plans_) {
							return function (deliveryDates_) {
								return function (undeliverableDates_) {
									return function (recipes_) {
										return function (allergies_) {
											return {bp: allergies_, T: brands_, aQ: breeds_, aZ: deliveryDates_, E: codes, a8: jwt, be: plans_, eA: recipes_, aH: salesRep_, bn: undeliverableDates_};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'allergies',
		$elm$json$Json$Decode$list($author$project$Allergy$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'recipes',
			$elm$json$Json$Decode$list($author$project$Recipe$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'undeliverableDates',
				$elm$json$Json$Decode$list($author$project$UndeliverableDate$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'deliveryDates',
					$elm$json$Json$Decode$list($author$project$DeliveryDate$decoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'plans',
						$elm$json$Json$Decode$list($author$project$Plan$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'discountCodes',
							$author$project$Session$discountCodesDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'brands',
								$author$project$Session$brandsDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'breeds',
									$author$project$Dog$Breeds$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'salesRep',
										$author$project$Session$salesRepDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'jwt',
											$author$project$Jwt$decoder,
											$elm$json$Json$Decode$succeed(onSuccess)))))))))));
}();
var $author$project$Page$Meals$init = F3(
	function (env, session, dogs) {
		return {j: dogs, c: env, f: session};
	});
var $author$project$Page$Checkout$Closed = {$: 0};
var $author$project$Page$Checkout$ReadyToSearch = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PetParentDetails$decoder = function () {
	var onSuccess = F3(
		function (name, emailAddress, marketingConsent) {
			return {dy: emailAddress, ap: marketingConsent, cg: name};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'marketingConsent',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'emailAddress',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed(onSuccess))));
}();
var $author$project$Page$Checkout$DogsForPlansPage = F2(
	function (all, enabled) {
		return {aO: all, a$: enabled};
	});
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Json$Helpers$setDecoder = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Set$fromList,
		$elm$json$Json$Decode$list(decoder));
};
var $author$project$Page$Checkout$dogsForPlansPageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'enabled',
	$author$project$Json$Helpers$setDecoder($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'all',
		$elm$json$Json$Decode$list($author$project$Dog$decoder),
		$elm$json$Json$Decode$succeed($author$project$Page$Checkout$DogsForPlansPage)));
var $author$project$Page$Checkout$FieldsInteractedWith = function (firstName) {
	return function (lastName) {
		return function (email) {
			return function (emailConfirmation) {
				return function (firstDeliveryDate) {
					return function (password) {
						return function (postCode) {
							return function (address1) {
								return function (address2) {
									return function (town) {
										return function (phoneNumber) {
											return function (cardNumber) {
												return function (cardExpMonth) {
													return function (cardExpYear) {
														return function (cardCVC) {
															return {bR: address1, bS: address2, t: cardCVC, u: cardExpMonth, v: cardExpYear, w: cardNumber, o: email, y: emailConfirmation, e: firstDeliveryDate, b3: firstName, d_: lastName, A: password, B: phoneNumber, m: postCode, cK: town};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Checkout$fieldsInteractedWithDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cardCVC',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'cardExpYear',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'cardExpMonth',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cardNumber',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'phoneNumber',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'town',
						$elm$json$Json$Decode$bool,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'address2',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'address1',
								$elm$json$Json$Decode$bool,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'postCode',
									$elm$json$Json$Decode$bool,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'password',
										$elm$json$Json$Decode$bool,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'firstDeliveryDate',
											$elm$json$Json$Decode$bool,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'emailConfirmation',
												$elm$json$Json$Decode$bool,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'email',
													$elm$json$Json$Decode$bool,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'lastName',
														$elm$json$Json$Decode$bool,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'firstName',
															$elm$json$Json$Decode$bool,
															$elm$json$Json$Decode$succeed($author$project$Page$Checkout$FieldsInteractedWith))))))))))))))));
var $author$project$Page$Checkout$FormData = function (firstName) {
	return function (lastName) {
		return function (email) {
			return function (emailConfirmation) {
				return function (firstDeliveryDate) {
					return function (password) {
						return function (postCode) {
							return function (address1) {
								return function (address2) {
									return function (town) {
										return function (phoneNumber) {
											return function (cardNumber) {
												return function (cardExpMonth) {
													return function (cardExpYear) {
														return function (cardCVC) {
															return {bR: address1, bS: address2, t: cardCVC, u: cardExpMonth, v: cardExpYear, w: cardNumber, o: email, y: emailConfirmation, e: firstDeliveryDate, b3: firstName, d_: lastName, A: password, B: phoneNumber, m: postCode, cK: town};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Checkout$formDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cardCVC',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'cardExpYear',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'cardExpMonth',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cardNumber',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'phoneNumber',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'town',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'address2',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'address1',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'postCode',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'password',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'firstDeliveryDate',
											A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'emailConfirmation',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'email',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'lastName',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'firstName',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Checkout$FormData))))))))))))))));
var $author$project$Page$Checkout$Address1 = 7;
var $author$project$Page$Checkout$CardCVC = 14;
var $author$project$Page$Checkout$CardExpMonth = 12;
var $author$project$Page$Checkout$CardExpYear = 13;
var $author$project$Page$Checkout$CardNumber = 11;
var $author$project$Page$Checkout$EmailConfirmation = 3;
var $author$project$Page$Checkout$FirstName = 0;
var $author$project$Page$Checkout$LastName = 1;
var $author$project$Page$Checkout$Password = 5;
var $author$project$Page$Checkout$PhoneNumber = 10;
var $author$project$Page$Checkout$PostCode = 6;
var $author$project$Page$Checkout$Town = 9;
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_v0, totalErrors) {
				var getErrors = _v0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3($elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var $author$project$Translations$Checkout$cardNumberError = 'Oops, it looks like this isn\'t a valid card number. Please check your card number and try again.';
var $author$project$Translations$Checkout$cvcError = 'Oops, it looks like this isn\'t a valid CVC. Your CVC has 3 digits and is located at the back of your card.';
var $author$project$Page$Checkout$FirstDeliveryDate = 4;
var $author$project$Translations$Checkout$deliveryDateError = 'Please choose a delivery date';
var $rtfeldman$elm_validate$Validate$ifFalse = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_Nil : _List_fromArray(
				[error]);
		};
		return getErrors;
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Page$Checkout$deliveryDateValidator = function () {
	var isPossibleDate = function (_v0) {
		var firstDeliveryDate = _v0.e;
		return !_Utils_eq(
			firstDeliveryDate,
			$elm$time$Time$millisToPosix(0));
	};
	return A2(
		$rtfeldman$elm_validate$Validate$ifFalse,
		isPossibleDate,
		_Utils_Tuple2(4, $author$project$Translations$Checkout$deliveryDateError));
}();
var $author$project$Page$Checkout$Email = 2;
var $author$project$Translations$Checkout$emailError = 'Please enter your email';
var $author$project$Translations$Checkout$emailInvalidError = 'Please enter a valid email with a single @';
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dR: index, d7: match, ei: number, eT: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{da: true, ec: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $rtfeldman$elm_validate$Validate$ifInvalidEmail = F2(
	function (subjectToEmail, errorFromEmail) {
		var getErrors = function (subject) {
			var email = subjectToEmail(subject);
			return $rtfeldman$elm_validate$Validate$isValidEmail(email) ? _List_Nil : _List_fromArray(
				[
					errorFromEmail(email)
				]);
		};
		return getErrors;
	});
var $author$project$Page$Checkout$emailValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.o;
			},
			_Utils_Tuple2(2, $author$project$Translations$Checkout$emailError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifInvalidEmail,
			function ($) {
				return $.o;
			},
			function (_v0) {
				return _Utils_Tuple2(2, $author$project$Translations$Checkout$emailInvalidError);
			})
		]));
var $author$project$Translations$Checkout$expiryMonthError = 'Please enter your card\'s expiry month';
var $author$project$Translations$Checkout$expiryYearError = 'Please enter your card\'s expiry year';
var $author$project$Translations$Checkout$firstNameError = 'Please enter your first name';
var $author$project$Translations$Checkout$lastNameError = 'Please enter your last name';
var $author$project$Page$Checkout$matchingEmails = function (_v0) {
	var email = _v0.o;
	var emailConfirmation = _v0.y;
	return _Utils_eq(email, emailConfirmation);
};
var $author$project$Translations$Checkout$mobileNumberError = 'Oops, it looks like this isn\'t a valid UK mobile number. Please check your number.';
var $author$project$Translations$Checkout$passwordError = 'Please enter a password (at least 8 characters)';
var $author$project$Translations$Checkout$reEnterEmailError = 'Oops! It looks like these email addresses don\'t match. Please check both addresses.';
var $author$project$Translations$Checkout$streetError = 'Please enter your address';
var $author$project$Translations$Checkout$townCityError = 'Enter your town or city';
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{da: false, ec: false},
		string);
};
var $author$project$Page$Checkout$cvcRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\d{3,4}$'));
var $author$project$Page$Checkout$validCardCVC = function (_v0) {
	var cardCVC = _v0.t;
	return A2($elm$regex$Regex$contains, $author$project$Page$Checkout$cvcRegex, cardCVC);
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$Checkout$validCardExpMonth = function (_v0) {
	var cardExpMonth = _v0.u;
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (x) {
				return (1 <= x) && (x <= 12);
			},
			$elm$core$String$toInt(cardExpMonth)));
};
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Page$Checkout$validCardExpYear = function (_v0) {
	var cardExpYear = _v0.v;
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (x) {
				return x >= 2019;
			},
			$elm$core$String$toInt(cardExpYear)));
};
var $author$project$List$Helpers$catMaybes = function (xs) {
	var ensureJusts = F2(
		function (maybes, acc) {
			ensureJusts:
			while (true) {
				if (!maybes.b) {
					return $elm$core$List$reverse(acc);
				} else {
					if (maybes.a.$ === 1) {
						var _v1 = maybes.a;
						return _List_Nil;
					} else {
						var x = maybes.a.a;
						var rest = maybes.b;
						var $temp$maybes = rest,
							$temp$acc = A2($elm$core$List$cons, x, acc);
						maybes = $temp$maybes;
						acc = $temp$acc;
						continue ensureJusts;
					}
				}
			}
		});
	return A2(ensureJusts, xs, _List_Nil);
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$CreditCard$doubleAndSum = function (x) {
	var addend = function () {
		var _v0 = x < 5;
		if (_v0) {
			return 0;
		} else {
			return -9;
		}
	}();
	return (2 * x) + addend;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $author$project$CreditCard$isEven = function (x) {
	return !A2($elm$core$Basics$modBy, 2, x);
};
var $author$project$CreditCard$mapIfIndex = F3(
	function (cond, f, xs) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, x) {
					return cond(i) ? f(x) : x;
				}),
			xs);
	});
var $author$project$CreditCard$isAmex = function (cardNumber) {
	_v0$2:
	while (true) {
		if ((cardNumber.b && (cardNumber.a === 3)) && cardNumber.b.b) {
			switch (cardNumber.b.a) {
				case 4:
					var _v1 = cardNumber.b;
					return true;
				case 7:
					var _v2 = cardNumber.b;
					return true;
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return false;
};
var $author$project$CreditCard$prefixIfAmex = function (cardNumber) {
	return $author$project$CreditCard$isAmex(cardNumber) ? A2($elm$core$List$cons, 0, cardNumber) : cardNumber;
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$CreditCard$validLength = function (cardNumber) {
	var expectedLength = $author$project$CreditCard$isAmex(cardNumber) ? 15 : 16;
	return _Utils_eq(
		$elm$core$List$length(cardNumber),
		expectedLength);
};
var $author$project$CreditCard$valid = function (cardNumber) {
	var luhnCheck = function (xs) {
		return !A2(
			$elm$core$Basics$modBy,
			10,
			$elm$core$List$sum(
				A3(
					$author$project$CreditCard$mapIfIndex,
					$author$project$CreditCard$isEven,
					$author$project$CreditCard$doubleAndSum,
					$author$project$CreditCard$prefixIfAmex(xs))));
	};
	return $author$project$CreditCard$validLength(cardNumber) && luhnCheck(cardNumber);
};
var $author$project$CreditCard$validate = function (cardNumber) {
	return $author$project$CreditCard$valid(
		$author$project$List$Helpers$catMaybes(
			A2(
				$elm$core$List$map,
				A2($elm$core$Basics$composeR, $elm$core$String$fromChar, $elm$core$String$toInt),
				$elm$core$String$toList(cardNumber))));
};
var $author$project$Page$Checkout$validCardNumber = function (_v0) {
	var cardNumber = _v0.w;
	return $author$project$CreditCard$validate(cardNumber);
};
var $author$project$Page$Checkout$validPassword = function (_v0) {
	var password = _v0.A;
	return $elm$core$String$length(password) >= 8;
};
var $author$project$Page$Checkout$mobileNumberRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^((\\+44(\\s\\(0\\)\\s|\\s0\\s|\\s)?)|0)7\\d{3}(\\s)?\\d{6}$'));
var $author$project$Page$Checkout$validPhoneNumber = function (_v0) {
	var phoneNumber = _v0.B;
	return (phoneNumber === '') || A2($elm$regex$Regex$contains, $author$project$Page$Checkout$mobileNumberRegex, phoneNumber);
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$PostCode$excludedPrefixes = _List_fromArray(
	['ab31', 'ab32', 'ab33', 'ab34', 'ab35', 'ab36', 'ab37', 'ab38', 'ab41', 'ab42', 'ab43', 'ab44', 'ab45', 'ab46', 'ab47', 'ab48', 'ab49', 'ab50', 'ab51', 'ab52', 'ab53', 'ab54', 'ab55', 'ab56', 'ei', 'fk17', 'fk18', 'fk19', 'fk20', 'fk21', 'g83', 'gy1', 'gy2', 'gy3', 'gy4', 'gy5', 'gy6', 'gy7', 'gy8', 'gy9', 'hs1', 'hs2', 'hs3', 'hs4', 'hs5', 'hs6', 'hs7', 'hs8', 'hs9', 'im', 'iv', 'je', 'ka27', 'ka28', 'kw0', 'kw1', 'kw2', 'kw3', 'kw4', 'kw5', 'kw6', 'kw7', 'kw8', 'kw9', 'kw10', 'kw11', 'kw12', 'kw13', 'kw14', 'kw15', 'kw16', 'kw17', 'pa20', 'pa21', 'pa22', 'pa23', 'pa24', 'pa25', 'pa26', 'pa27', 'pa28', 'pa29', 'pa30', 'pa31', 'pa32', 'pa33', 'pa34', 'pa35', 'pa36', 'pa37', 'pa38', 'pa39', 'pa40', 'pa41', 'pa42', 'pa43', 'pa44', 'pa45', 'pa46', 'pa47', 'pa48', 'pa49', 'pa50', 'pa51', 'pa52', 'pa53', 'pa54', 'pa55', 'pa56', 'pa57', 'pa58', 'pa59', 'pa60', 'pa61', 'pa62', 'pa63', 'pa64', 'pa65', 'pa66', 'pa67', 'pa68', 'pa69', 'pa70', 'pa71', 'pa72', 'pa73', 'pa74', 'pa75', 'pa76', 'pa77', 'pa78', 'ph15', 'ph16', 'ph17', 'ph18', 'ph19', 'ph20', 'ph21', 'ph22', 'ph23', 'ph24', 'ph25', 'ph26', 'ph27', 'ph28', 'ph29', 'ph30', 'ph31', 'ph32', 'ph33', 'ph34', 'ph35', 'ph36', 'ph37', 'ph38', 'ph39', 'ph40', 'ph41', 'ph42', 'ph43', 'ph44', 'ph45', 'ph46', 'ph47', 'ph48', 'ph49', 'ph50', 'ph51', 'ph52', 'ph53', 'ph54', 'ph55', 'ph56', 'ph57', 'ph58', 'ph59', 'ph60', 'ph61', 'ph62', 'ph63', 'ph64', 'ph65', 'ph66', 'ph67', 'ph68', 'ph69', 'ph70', 'ph71', 'ph72', 'ph73', 'ph74', 'ph75', 'ph76', 'ph77', 'ph78', 'ph79', 'ph80', 'ph81', 'ph82', 'ph83', 'ph84', 'ph85', 'ph86', 'ph87', 'ph88', 'ph89', 'ph90', 'ph91', 'ph92', 'ph93', 'ph94', 'ph95', 'ph96', 'ph97', 'ph98', 'ph99', 'p030', 'p031', 'p032', 'p033', 'p034', 'p035', 'p036', 'p037', 'p038', 'p039', 'p040', 'p041', 'tr21', 'tr22', 'tr23', 'tr24', 'tr25', 'ze', 'bt']);
var $elm$core$String$toLower = _String_toLower;
var $author$project$PostCode$excluded = function (_v0) {
	var postCode = _v0.m;
	var normalisedPostcode = $elm$core$String$toLower(postCode);
	return A2(
		$elm$core$List$any,
		function (prefix) {
			return A2($elm$core$String$startsWith, prefix, normalisedPostcode);
		},
		$author$project$PostCode$excludedPrefixes);
};
var $author$project$Translations$Checkout$postcodeError = 'Oops, it looks like this isn\'t a valid UK postcode. Please check your postcode.';
var $author$project$Translations$Checkout$postcodeUndeliverableError = 'Sorry, we can\'t deliver to this post code';
var $author$project$PostCode$postCodeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$'));
var $author$project$PostCode$valid = function (_v0) {
	var postCode = _v0.m;
	return A2($elm$regex$Regex$contains, $author$project$PostCode$postCodeRegex, postCode);
};
var $author$project$PostCode$validator = function (fieldTag) {
	return $rtfeldman$elm_validate$Validate$all(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				$author$project$PostCode$valid,
				_Utils_Tuple2(fieldTag, $author$project$Translations$Checkout$postcodeError)),
				A2(
				$rtfeldman$elm_validate$Validate$ifTrue,
				$author$project$PostCode$excluded,
				_Utils_Tuple2(fieldTag, $author$project$Translations$Checkout$postcodeUndeliverableError))
			]));
};
var $author$project$Page$Checkout$formDataValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.b3;
			},
			_Utils_Tuple2(0, $author$project$Translations$Checkout$firstNameError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.d_;
			},
			_Utils_Tuple2(1, $author$project$Translations$Checkout$lastNameError)),
			$author$project$Page$Checkout$emailValidator,
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$matchingEmails,
			_Utils_Tuple2(3, $author$project$Translations$Checkout$reEnterEmailError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validPassword,
			_Utils_Tuple2(5, $author$project$Translations$Checkout$passwordError)),
			$author$project$Page$Checkout$deliveryDateValidator,
			$author$project$PostCode$validator(6),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.bR;
			},
			_Utils_Tuple2(7, $author$project$Translations$Checkout$streetError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.cK;
			},
			_Utils_Tuple2(9, $author$project$Translations$Checkout$townCityError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validPhoneNumber,
			_Utils_Tuple2(10, $author$project$Translations$Checkout$mobileNumberError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validCardNumber,
			_Utils_Tuple2(11, $author$project$Translations$Checkout$cardNumberError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validCardExpMonth,
			_Utils_Tuple2(12, $author$project$Translations$Checkout$expiryMonthError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validCardExpYear,
			_Utils_Tuple2(13, $author$project$Translations$Checkout$expiryYearError)),
			A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			$author$project$Page$Checkout$validCardCVC,
			_Utils_Tuple2(14, $author$project$Translations$Checkout$cvcError))
		]));
var $author$project$Page$Checkout$Address2 = 8;
var $author$project$Page$Checkout$formFieldDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'FirstName':
				return $elm$json$Json$Decode$succeed(0);
			case 'LastName':
				return $elm$json$Json$Decode$succeed(1);
			case 'Email':
				return $elm$json$Json$Decode$succeed(2);
			case 'EmailConfirmation':
				return $elm$json$Json$Decode$succeed(3);
			case 'FirstDeliveryDate':
				return $elm$json$Json$Decode$succeed(4);
			case 'Password':
				return $elm$json$Json$Decode$succeed(5);
			case 'PostCode':
				return $elm$json$Json$Decode$succeed(6);
			case 'Address1':
				return $elm$json$Json$Decode$succeed(7);
			case 'Address2':
				return $elm$json$Json$Decode$succeed(8);
			case 'Town':
				return $elm$json$Json$Decode$succeed(9);
			case 'PhoneNumber':
				return $elm$json$Json$Decode$succeed(10);
			case 'CardNumber':
				return $elm$json$Json$Decode$succeed(11);
			case 'CardExpMonth':
				return $elm$json$Json$Decode$succeed(12);
			case 'CardExpYear':
				return $elm$json$Json$Decode$succeed(13);
			case 'CardCVC':
				return $elm$json$Json$Decode$succeed(14);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Checkout.FormField: ' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Page$Checkout$formSubmissionErrorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'error',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'field',
		$author$project$Page$Checkout$formFieldDecoder,
		$elm$json$Json$Decode$succeed(
			F2(
				function (field, error) {
					return _Utils_Tuple2(field, error);
				}))));
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $author$project$Page$Checkout$modelDecoder = function (env) {
	var onSuccess = function (session) {
		return function (formData) {
			return function (fieldsInteractedWith) {
				return function (formSubmissionErrors) {
					return function (leaveSafeTooltipShown) {
						return function (ongoingPlanTooltipShown) {
							return function (dogs) {
								return function (dogsForPlansPage) {
									return function (petParentDetails) {
										return function (selectedPlan) {
											return function (buySubscriptionResultPending) {
												return {
													I: buySubscriptionResultPending,
													j: dogs,
													aC: dogsForPlansPage,
													c: env,
													U: fieldsInteractedWith,
													a: formData,
													V: formSubmissionErrors,
													ac: leaveSafeTooltipShown,
													G: ongoingPlanTooltipShown,
													l: $author$project$Page$Checkout$Closed,
													P: petParentDetails,
													Q: $author$project$Page$Checkout$ReadyToSearch(formData.m),
													Y: selectedPlan,
													f: session,
													ag: A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$Checkout$formDataValidator, formData)
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'buySubscriptionResultPending',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'selectedPlan',
			$author$project$Plan$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'petParentDetails',
				$author$project$Page$PetParentDetails$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dogsForPlansPage',
					$author$project$Page$Checkout$dogsForPlansPageDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dogs',
						$elm$json$Json$Decode$list($author$project$Dog$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'ongoingPlanTooltipShown',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'leaveSafeTooltipShown',
								$elm$json$Json$Decode$bool,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'formSubmissionErrors',
									$elm$json$Json$Decode$list($author$project$Page$Checkout$formSubmissionErrorDecoder),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fieldsInteractedWith',
										$author$project$Page$Checkout$fieldsInteractedWithDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'formData',
											$author$project$Page$Checkout$formDataDecoder,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'session',
												$author$project$Session$decoder,
												$elm$json$Json$Decode$succeed(onSuccess))))))))))));
};
var $author$project$Page$ChoosePlan$Plans = {$: 0};
var $author$project$Page$ChoosePlan$modelDecoder = function (env) {
	var onSuccess = F3(
		function (session, dogs, enabledDogs) {
			return {J: $author$project$Page$ChoosePlan$Plans, j: dogs, F: enabledDogs, c: env, f: session};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'enabledDogs',
		$author$project$Json$Helpers$setDecoder($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'dogs',
			$elm$json$Json$Decode$list($author$project$Dog$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'session',
				$author$project$Session$decoder,
				$elm$json$Json$Decode$succeed(onSuccess))));
};
var $author$project$Page$Login$Model = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Login$modelDecoder = function (env) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Page$Login$Model(env),
		A2(
			$elm$json$Json$Decode$map,
			function (email) {
				return {o: email, an: $elm$core$Maybe$Nothing, ad: false, A: '', f: $elm$core$Maybe$Nothing};
			},
			A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string)));
};
var $author$project$Page$Meals$Model = F3(
	function (env, session, dogs) {
		return {j: dogs, c: env, f: session};
	});
var $author$project$Page$Meals$modelDecoder = function (env) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dogs',
		$elm$json$Json$Decode$list($author$project$Dog$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'session',
			$author$project$Session$decoder,
			$elm$json$Json$Decode$succeed(
				$author$project$Page$Meals$Model(env))));
};
var $author$project$SignInLink$SignInLink = $elm$core$Basics$identity;
var $author$project$SignInLink$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (urlStr) {
		var _v0 = $elm$url$Url$fromString(urlStr);
		if (!_v0.$) {
			var url = _v0.a;
			return $elm$json$Json$Decode$succeed(url);
		} else {
			return $elm$json$Json$Decode$fail('Could not parse URL: ' + urlStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Page$OrderConfirmation$modelDecoder = function (env) {
	var onSuccess = F6(
		function (session, dogs, firstDeliveryDate, plan, saleMadeOnline, signInLink) {
			return {j: dogs, c: env, e: firstDeliveryDate, r: plan, R: saleMadeOnline, f: session, aI: signInLink};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'signInLink',
		$elm$json$Json$Decode$nullable($author$project$SignInLink$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'saleMadeOnline',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'plan',
				$author$project$Plan$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'firstDeliveryDate',
					A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dogs',
						$elm$json$Json$Decode$list($author$project$Dog$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'session',
							$author$project$Session$decoder,
							$elm$json$Json$Decode$succeed(onSuccess)))))));
};
var $author$project$Page$Receipt$modelDecoder = function (env) {
	var onSuccess = F5(
		function (session, dogs, firstDeliveryDate, plan, signInLink) {
			return {j: dogs, c: env, e: firstDeliveryDate, r: plan, f: session, aI: signInLink};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'signInLink',
		$elm$json$Json$Decode$nullable($author$project$SignInLink$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'plan',
			$author$project$Plan$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'firstDeliveryDate',
				A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dogs',
					$elm$json$Json$Decode$list($author$project$Dog$decoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'session',
						$author$project$Session$decoder,
						$elm$json$Json$Decode$succeed(onSuccess))))));
};
var $author$project$Page$Welcome$Model = F2(
	function (env, session) {
		return {c: env, f: session};
	});
var $author$project$Page$Welcome$modelDecoder = function (env) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Page$Welcome$Model(env),
		A2($elm$json$Json$Decode$field, 'session', $author$project$Session$decoder));
};
var $author$project$Page$Wizard$Closed = {$: 0};
var $author$project$Page$Wizard$AnyAllergies = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Wizard$AnyHealthIssues = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Wizard$HaveBeenDeSexed = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Wizard$HowManyDogs = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wizard$HowOld = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Wizard$WhatActivityLevel = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Wizard$WhatBodyCondition = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Wizard$WhatBreed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wizard$WhatCurrentFoods = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wizard$WhatKindOfEater = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Wizard$WhatMass = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Wizard$WhatSnackingHabits = function (a) {
	return {$: 11, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Page$Wizard$HowManyDogs$Closed = {$: 0};
var $author$project$Page$Wizard$HowManyDogs$emptyDog = {bP: $elm$core$Maybe$Nothing, bp: $elm$core$Maybe$Nothing, bU: $elm$core$Maybe$Nothing, bV: $elm$core$Maybe$Nothing, b_: $elm$core$Maybe$Nothing, b$: $elm$core$Maybe$Nothing, b1: $elm$core$Maybe$Nothing, b5: $elm$core$Maybe$Nothing, bw: $elm$core$Maybe$Nothing, cc: $elm$core$Maybe$Nothing, ce: $elm$core$Maybe$Nothing, cg: $elm$core$Maybe$Nothing, cB: $elm$core$Maybe$Nothing, cE: $elm$core$Maybe$Nothing, cN: $elm$core$Maybe$Nothing};
var $author$project$Page$Wizard$HowManyDogs$init = function (maybeDogs) {
	var dogs = function () {
		if (!maybeDogs.b) {
			return _List_fromArray(
				[$author$project$Page$Wizard$HowManyDogs$emptyDog]);
		} else {
			return maybeDogs;
		}
	}();
	return {j: dogs, l: $author$project$Page$Wizard$HowManyDogs$Closed};
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm_community$list_extra$List$Extra$indexedFoldr = F3(
	function (func, acc, list) {
		var step = F2(
			function (x, _v0) {
				var i = _v0.a;
				var thisAcc = _v0.b;
				return _Utils_Tuple2(
					i - 1,
					A3(func, i, x, thisAcc));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(
				$elm$core$List$length(list) - 1,
				acc),
			list).b;
	});
var $author$project$Page$Wizard$WhatBreed$init = function (dogs) {
	var recordBreedIfPresent = F3(
		function (id, dog, dict) {
			var _v0 = dog.bV;
			if (!_v0.$) {
				var breed = _v0.a;
				return A3($elm$core$Dict$insert, id, breed.cg, dict);
			} else {
				return dict;
			}
		});
	var breedInputValues = A3($elm_community$list_extra$List$Extra$indexedFoldr, recordBreedIfPresent, $elm$core$Dict$empty, dogs);
	return {aa: breedInputValues, j: dogs};
};
var $author$project$Page$Wizard$WhatCurrentFoods$init = function (dogs) {
	var recordBrandIfPresent = F3(
		function (id, dog, dict) {
			var _v0 = dog.b_;
			if (!_v0.$) {
				var currentBrand = _v0.a;
				return A3($elm$core$Dict$insert, id, currentBrand.cg, dict);
			} else {
				return dict;
			}
		});
	var brandInputValues = A3($elm_community$list_extra$List$Extra$indexedFoldr, recordBrandIfPresent, $elm$core$Dict$empty, dogs);
	return {p: brandInputValues, j: dogs};
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Dog$Mass$gramsToKg = function (grams) {
	return grams / 1000;
};
var $author$project$Page$Wizard$WhatMass$init = function (dogs) {
	var recordMass = F3(
		function (index, dog, dict) {
			return function (mass) {
				return A3($elm$core$Dict$insert, index, mass, dict);
			}(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromFloat,
						A2($elm$core$Maybe$map, $author$project$Dog$Mass$gramsToKg, dog.cc))));
		});
	var massInputValues = A3($elm_community$list_extra$List$Extra$indexedFoldr, recordMass, $elm$core$Dict$empty, dogs);
	return {j: dogs, L: massInputValues};
};
var $author$project$Page$Wizard$Steps$NoStepsComplete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$noStepsCompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$elm$json$Json$Decode$nullable($author$project$Dog$Fussiness$decoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$nullable(
													$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder)),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$elm$json$Json$Decode$nullable($author$project$Dog$Breeds$breedDecoder),
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$elm$json$Json$Decode$nullable($author$project$Dog$Sex$decoder),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$NoStepsComplete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step10Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step10CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$list($author$project$Allergy$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$author$project$Dog$ActivityLevel$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step10Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step11Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step11CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$author$project$Dog$HealthIssues$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$list($author$project$Allergy$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$author$project$Dog$ActivityLevel$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step11Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step1Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step1CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$elm$json$Json$Decode$nullable($author$project$Dog$Fussiness$decoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$nullable(
													$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder)),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$elm$json$Json$Decode$nullable($author$project$Dog$Breeds$breedDecoder),
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step1Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step2Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step2CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$elm$json$Json$Decode$nullable($author$project$Dog$Fussiness$decoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$nullable(
													$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder)),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step2Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step3Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step3CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$elm$json$Json$Decode$nullable($author$project$Dog$Fussiness$decoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step3Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step4Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step4CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step4Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step5Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step5CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step5Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step6Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step6CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$elm$json$Json$Decode$nullable($author$project$Dog$BodyCondition$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step6Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step7Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step7CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step7Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step8Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step8CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$elm$json$Json$Decode$nullable($author$project$Dog$ActivityLevel$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step8Complete))))))))))))))));
var $author$project$Page$Wizard$Steps$Step9Complete = function (name) {
	return function (sex) {
		return function (breed) {
			return function (currentlyEating) {
				return function (currentBrand) {
					return function (fussiness) {
						return function (yearsOld) {
							return function (monthsOld) {
								return function (deSexed) {
									return function (bodyCondition) {
										return function (mass) {
											return function (activityLevel) {
												return function (allergies) {
													return function (healthIssues) {
														return function (snackingHabits) {
															return {bP: activityLevel, bp: allergies, bU: bodyCondition, bV: breed, b_: currentBrand, b$: currentlyEating, b1: deSexed, b5: fussiness, bw: healthIssues, cc: mass, ce: monthsOld, cg: name, cB: sex, cE: snackingHabits, cN: yearsOld};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Wizard$Steps$step9CompleteDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'snackingHabits',
	$elm$json$Json$Decode$nullable($author$project$Dog$SnackingHabits$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'healthIssues',
		$elm$json$Json$Decode$nullable($author$project$Dog$HealthIssues$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Allergy$decoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'activityLevel',
				$author$project$Dog$ActivityLevel$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mass',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'bodyCondition',
						$author$project$Dog$BodyCondition$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'deSexed',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'monthsOld',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'yearsOld',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fussiness',
										$author$project$Dog$Fussiness$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'currentBrand',
											$elm$json$Json$Decode$nullable($author$project$Dog$FoodBrands$decoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'currentlyEating',
												$elm$json$Json$Decode$list($author$project$Dog$FoodCategory$decoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'breed',
													$author$project$Dog$Breeds$breedDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'sex',
														$author$project$Dog$Sex$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'name',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Page$Wizard$Steps$Step9Complete))))))))))))))));
var $author$project$Page$Wizard$currentStepDecoder = function () {
	var makeStepDecoder = function (stepStr) {
		switch (stepStr) {
			case 'HowManyDogs':
				var onSuccess = function (dogs) {
					return $author$project$Page$Wizard$HowManyDogs(
						$author$project$Page$Wizard$HowManyDogs$init(dogs));
				};
				return A2(
					$elm$json$Json$Decode$map,
					onSuccess,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$noStepsCompleteDecoder));
			case 'WhatBreed':
				var onSuccess = function (dogs) {
					return $author$project$Page$Wizard$WhatBreed(
						$author$project$Page$Wizard$WhatBreed$init(dogs));
				};
				return A2(
					$elm$json$Json$Decode$map,
					onSuccess,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step1CompleteDecoder));
			case 'WhatCurrentFoods':
				var onSuccess = function (dogs) {
					return $author$project$Page$Wizard$WhatCurrentFoods(
						$author$project$Page$Wizard$WhatCurrentFoods$init(dogs));
				};
				return A2(
					$elm$json$Json$Decode$map,
					onSuccess,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step2CompleteDecoder));
			case 'WhatKindOfEater':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$WhatKindOfEater,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step3CompleteDecoder));
			case 'HowOld':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$HowOld,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step4CompleteDecoder));
			case 'HaveBeenDeSexed':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$HaveBeenDeSexed,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step5CompleteDecoder));
			case 'WhatBodyCondition':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$WhatBodyCondition,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step6CompleteDecoder));
			case 'WhatMass':
				return A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeL, $author$project$Page$Wizard$WhatMass, $author$project$Page$Wizard$WhatMass$init),
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step7CompleteDecoder));
			case 'WhatActivityLevel':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$WhatActivityLevel,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step8CompleteDecoder));
			case 'AnyAllergies':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$AnyAllergies,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step9CompleteDecoder));
			case 'AnyHealthIssues':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$AnyHealthIssues,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step10CompleteDecoder));
			case 'WhatSnackingHabits':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$Wizard$WhatSnackingHabits,
					$elm$json$Json$Decode$list($author$project$Page$Wizard$Steps$step11CompleteDecoder));
			default:
				return $elm$json$Json$Decode$fail('Unsupported Step: ' + stepStr);
		}
	};
	var stepDecoder = function (stepStr) {
		return A2(
			$elm$json$Json$Decode$field,
			'stepData',
			makeStepDecoder(stepStr));
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		stepDecoder,
		A2($elm$json$Json$Decode$field, 'stepName', $elm$json$Json$Decode$string));
}();
var $author$project$Page$Wizard$modelDecoder = function (env) {
	var onSuccess = F3(
		function (session, step, petParentDetails) {
			return {n: step, c: env, l: $author$project$Page$Wizard$Closed, P: petParentDetails, f: session};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'petParentDetails',
		$elm$json$Json$Decode$nullable($author$project$Page$PetParentDetails$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'currentStep',
			$author$project$Page$Wizard$currentStepDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'session',
				$author$project$Session$decoder,
				$elm$json$Json$Decode$succeed(onSuccess))));
};
var $author$project$Main$decodePage = F2(
	function (env, value) {
		var makePageDecoder = function (pageString) {
			switch (pageString) {
				case 'Login':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Login,
						$author$project$Page$Login$modelDecoder(env));
				case 'Welcome':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Welcome,
						$author$project$Page$Welcome$modelDecoder(env));
				case 'Wizard':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Wizard,
						$author$project$Page$Wizard$modelDecoder(env));
				case 'CalculatePlan':
					return A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dogs',
						$elm$json$Json$Decode$list($author$project$Dog$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'session',
							$author$project$Session$decoder,
							$elm$json$Json$Decode$succeed(
								F2(
									function (session, dogs) {
										return $author$project$Main$Meals(
											A3($author$project$Page$Meals$init, env, session, dogs));
									}))));
				case 'Meals':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Meals,
						$author$project$Page$Meals$modelDecoder(env));
				case 'ChoosePlan':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$ChoosePlan,
						$author$project$Page$ChoosePlan$modelDecoder(env));
				case 'Checkout':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Checkout,
						$author$project$Page$Checkout$modelDecoder(env));
				case 'OrderConfirmation':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$OrderConfirmation,
						$author$project$Page$OrderConfirmation$modelDecoder(env));
				case 'Receipt':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Main$Receipt,
						$author$project$Page$Receipt$modelDecoder(env));
				default:
					return $elm$json$Json$Decode$fail('Unbalanced Page Energy');
			}
		};
		var pageDecoder = function (pageString) {
			return A2(
				$elm$json$Json$Decode$field,
				'pageData',
				makePageDecoder(pageString));
		};
		return A2(
			$elm$json$Json$Decode$decodeValue,
			A2(
				$elm$json$Json$Decode$andThen,
				pageDecoder,
				A2($elm$json$Json$Decode$field, 'pageString', $elm$json$Json$Decode$string)),
			value);
	});
var $author$project$Env$Env = $elm$core$Basics$identity;
var $author$project$Env$init = F2(
	function (vars, assetPaths) {
		return {bT: assetPaths, bu: vars};
	});
var $author$project$Page$Login$init = function (env) {
	return A2(
		$author$project$Page$Login$Model,
		env,
		{o: '', an: $elm$core$Maybe$Nothing, ad: false, A: '', f: $elm$core$Maybe$Nothing});
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Main$init = function (flags) {
	var signUpStore = {dn: _List_Nil, dD: _List_Nil, ek: _List_Nil};
	var env = A2($author$project$Env$init, flags.c, flags.bT);
	var initialPage = A2(
		$elm$core$Result$withDefault,
		$author$project$Main$Login(
			$author$project$Page$Login$init(env)),
		A2($author$project$Main$decodePage, env, flags.bI));
	return _Utils_Tuple2(
		{
			b: initialPage,
			c: env,
			bb: 0,
			l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing),
			bi: signUpStore
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Main$StoreDecodingFailed = {$: 2};
var $author$project$Main$UpdateOnlineStatus = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$UpdateStore = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$SignUp$Store = F3(
	function (customers, offlineAcquisitions, failures) {
		return {dn: customers, dD: failures, ek: offlineAcquisitions};
	});
var $author$project$SignUp$SignUp = F5(
	function (id, name, dogNames, firstAttemptedAt, completedAt) {
		return {bY: completedAt, aB: dogNames, a2: firstAttemptedAt, a3: id, cg: name};
	});
var $author$project$SignUp$signUpDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'completedAt',
	A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstAttemptedAt',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'dogNames',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$SignUp$SignUp))))));
var $author$project$SignUp$signUpListDecoder = $elm$json$Json$Decode$list($author$project$SignUp$signUpDecoder);
var $author$project$SignUp$signUpStoreDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'failed',
	$author$project$SignUp$signUpListDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'pending',
		$author$project$SignUp$signUpListDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'complete',
			$author$project$SignUp$signUpListDecoder,
			$elm$json$Json$Decode$succeed($author$project$SignUp$Store))));
var $author$project$SignUp$decodeStoreToMsg = F2(
	function (toMsg, value) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$SignUp$signUpStoreDecoder, value);
		if (!_v0.$) {
			var store = _v0.a;
			return toMsg(
				$elm$core$Maybe$Just(store));
		} else {
			return toMsg($elm$core$Maybe$Nothing);
		}
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$SignUp$updateStore = _Platform_incomingPort('updateStore', $elm$json$Json$Decode$value);
var $author$project$SignUp$onStoreChange = function (toMsg) {
	return $author$project$SignUp$updateStore(
		$author$project$SignUp$decodeStoreToMsg(toMsg));
};
var $author$project$Main$updateOnlineStatus = _Platform_incomingPort('updateOnlineStatus', $elm$json$Json$Decode$bool);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$SignUp$onStoreChange(
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$map($author$project$Main$UpdateStore),
					$elm$core$Maybe$withDefault($author$project$Main$StoreDecodingFailed))),
				$author$project$Main$updateOnlineStatus($author$project$Main$UpdateOnlineStatus)
			]));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Allergy$encode = function (allergy) {
	switch (allergy) {
		case 0:
			return $elm$json$Json$Encode$string('beef');
		case 1:
			return $elm$json$Json$Encode$string('chicken');
		case 2:
			return $elm$json$Json$Encode$string('turkey');
		case 3:
			return $elm$json$Json$Encode$string('lamb');
		case 4:
			return $elm$json$Json$Encode$string('fish');
		case 5:
			return $elm$json$Json$Encode$string('pork');
		case 6:
			return $elm$json$Json$Encode$string('duck');
		case 7:
			return $elm$json$Json$Encode$string('venison');
		case 8:
			return $elm$json$Json$Encode$string('wild_boar');
		default:
			return $elm$json$Json$Encode$string('guinea_fowl');
	}
};
var $author$project$Dog$ActivityLevel$encode = function (activityLevel) {
	switch (activityLevel) {
		case 0:
			return $elm$json$Json$Encode$string('Lazy');
		case 1:
			return $elm$json$Json$Encode$string('Leisurely');
		case 2:
			return $elm$json$Json$Encode$string('FairlyActive');
		case 3:
			return $elm$json$Json$Encode$string('BundleOfEnergy');
		case 4:
			return $elm$json$Json$Encode$string('HyperActive');
		default:
			return $elm$json$Json$Encode$string('WorkingDog');
	}
};
var $author$project$Dog$BodyCondition$encode = function (bodyCondition) {
	switch (bodyCondition) {
		case 0:
			return $elm$json$Json$Encode$string('Skinny');
		case 1:
			return $elm$json$Json$Encode$string('JustRight');
		default:
			return $elm$json$Json$Encode$string('Chubby');
	}
};
var $author$project$Dog$FoodCategory$encode = function (foodCategory) {
	switch (foodCategory) {
		case 0:
			return $elm$json$Json$Encode$string('Dry');
		case 1:
			return $elm$json$Json$Encode$string('Wet');
		case 2:
			return $elm$json$Json$Encode$string('Raw');
		default:
			return $elm$json$Json$Encode$string('HomeCooked');
	}
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Dog$FoodBrands$encode = function (foodBrand) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(foodBrand.a3)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(foodBrand.cg)),
				_Utils_Tuple2(
				'category',
				$author$project$Dog$FoodCategory$encode(foodBrand.aj))
			]));
};
var $author$project$Dog$Fussiness$encode = function (fussiness) {
	switch (fussiness) {
		case 0:
			return $elm$json$Json$Encode$string('VeryFussy');
		case 1:
			return $elm$json$Json$Encode$string('CanBeFussy');
		case 2:
			return $elm$json$Json$Encode$string('GoodEater');
		default:
			return $elm$json$Json$Encode$string('EatsAnything');
	}
};
var $author$project$Dog$HealthIssues$encodeHealthIssue = function (healthIssue) {
	switch (healthIssue) {
		case 0:
			return $elm$json$Json$Encode$string('Pancreatitis');
		case 1:
			return $elm$json$Json$Encode$string('Diabetes');
		case 2:
			return $elm$json$Json$Encode$string('IBD');
		case 3:
			return $elm$json$Json$Encode$string('Kidney disease');
		case 4:
			return $elm$json$Json$Encode$string('Struvite stones');
		case 5:
			return $elm$json$Json$Encode$string('Liver disease');
		case 6:
			return $elm$json$Json$Encode$string('Skin/Coat issues');
		case 7:
			return $elm$json$Json$Encode$string('Joint problems');
		case 8:
			return $elm$json$Json$Encode$string('Cancer');
		case 9:
			return $elm$json$Json$Encode$string('Obesity');
		case 10:
			return $elm$json$Json$Encode$string('Sensitive stomach');
		case 11:
			return $elm$json$Json$Encode$string('Heart condition');
		default:
			return $elm$json$Json$Encode$string('Epilepsy');
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Dog$HealthIssues$encode = function (issues) {
	var _v0 = function () {
		if (!issues.$) {
			return _Utils_Tuple2('None', $elm$json$Json$Encode$null);
		} else {
			var xs = issues.a;
			return _Utils_Tuple2(
				'Some',
				A2($elm$json$Json$Encode$list, $author$project$Dog$HealthIssues$encodeHealthIssue, xs));
		}
	}();
	var tag = _v0.a;
	var contents = _v0.b;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'tag',
				$elm$json$Json$Encode$string(tag)),
				_Utils_Tuple2('contents', contents)
			]));
};
var $author$project$Dog$Sex$encode = function (sex) {
	if (!sex) {
		return $elm$json$Json$Encode$string('Female');
	} else {
		return $elm$json$Json$Encode$string('Male');
	}
};
var $author$project$Dog$SnackingHabits$encode = function (snackingHabits) {
	switch (snackingHabits) {
		case 0:
			return $elm$json$Json$Encode$string('NoSnacks');
		case 1:
			return $elm$json$Json$Encode$string('SomeSnacks');
		default:
			return $elm$json$Json$Encode$string('LotsOfSnacks');
	}
};
var $author$project$Dog$Breeds$encodeBreed = function (breed) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(breed.a3)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(breed.cg))
			]));
};
var $author$project$Json$Helpers$encodeMaybe = F2(
	function (encoder, maybe) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, encoder, maybe));
	});
var $author$project$Dog$encode = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				$author$project$Dog$ActivityLevel$encode(dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2($elm$json$Json$Encode$list, $author$project$Allergy$encode, dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				$author$project$Dog$HealthIssues$encode(dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				$author$project$Dog$SnackingHabits$encode(dog.cE))
			]));
};
var $author$project$Page$PetParentDetails$encode = function (petParentDetails) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(petParentDetails.cg)),
				_Utils_Tuple2(
				'emailAddress',
				$elm$json$Json$Encode$string(petParentDetails.dy)),
				_Utils_Tuple2(
				'marketingConsent',
				$elm$json$Json$Encode$bool(petParentDetails.ap))
			]));
};
var $author$project$Dog$FoodBrands$all = function (_v0) {
	var foodBrands = _v0;
	return foodBrands.aO;
};
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$DeliveryDate$encode = function (deliveryDate) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'posix',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(deliveryDate.aG))),
				_Utils_Tuple2(
				'isDeliverable',
				$elm$json$Json$Encode$bool(deliveryDate.b9))
			]));
};
var $author$project$DiscountCode$encodeBasis = function (basis) {
	if (!basis) {
		return $elm$json$Json$Encode$string('Percentage');
	} else {
		return $elm$json$Json$Encode$string('Total');
	}
};
var $author$project$DiscountCode$encode = function (_v0) {
	var discountCode = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(discountCode.a3)),
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(discountCode.aU)),
				_Utils_Tuple2(
				'basis',
				$author$project$DiscountCode$encodeBasis(discountCode.aP)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$int(discountCode.av)),
				_Utils_Tuple2(
				'n',
				$elm$json$Json$Encode$int(discountCode.ba))
			]));
};
var $author$project$Dog$Breeds$encode = function (_v0) {
	var breeds = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'all',
				A2($elm$json$Json$Encode$list, $author$project$Dog$Breeds$encodeBreed, breeds.aO)),
				_Utils_Tuple2(
				'most_popular',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, breeds.bE))
			]));
};
var $author$project$Jwt$encode = function (_v0) {
	var token = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'jwt',
				$elm$json$Json$Encode$string(token))
			]));
};
var $author$project$Plan$encodePlanType = function (planType) {
	return $elm$json$Json$Encode$string(
		function () {
			if (!planType) {
				return 'Complete';
			} else {
				return 'ToBeMixed';
			}
		}());
};
var $author$project$Plan$encode = function (plan) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(plan.a3)),
				_Utils_Tuple2(
				'planType',
				$author$project$Plan$encodePlanType(plan.cn)),
				_Utils_Tuple2(
				'pricePerPouch',
				$elm$json$Json$Encode$int(plan.as)),
				_Utils_Tuple2(
				'pouchMass',
				$elm$json$Json$Encode$int(plan.ev)),
				_Utils_Tuple2(
				'deliveryInterval',
				$elm$json$Json$Encode$int(plan.dq)),
				_Utils_Tuple2(
				'numberOfPouches',
				$elm$json$Json$Encode$int(plan.ej))
			]));
};
var $author$project$Recipe$encode = function (recipe) {
	switch (recipe) {
		case 0:
			return $elm$json$Json$Encode$string('Beef');
		case 1:
			return $elm$json$Json$Encode$string('Chicken');
		case 2:
			return $elm$json$Json$Encode$string('Turkey');
		case 3:
			return $elm$json$Json$Encode$string('Lamb');
		case 4:
			return $elm$json$Json$Encode$string('WhiteFish');
		case 5:
			return $elm$json$Json$Encode$string('Pork');
		default:
			return $elm$json$Json$Encode$string('DuckAndChicken');
	}
};
var $author$project$UndeliverableDate$encode = function (undeliverableDate) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'posix',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(undeliverableDate.aG))),
				_Utils_Tuple2(
				'replacement',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(undeliverableDate.eB)))
			]));
};
var $author$project$Dog$FoodBrands$homecooked = function (_v0) {
	var brands = _v0;
	return {aj: 3, a3: brands.bx, cg: 'homecooked'};
};
var $author$project$Dog$FoodBrands$other = function (_v0) {
	var foodBrands = _v0;
	return foodBrands.aq;
};
var $author$project$Session$encode = function (_v0) {
	var session = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'jwt',
				$author$project$Jwt$encode(session.a8)),
				_Utils_Tuple2(
				'salesRep',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'firstName',
							$elm$json$Json$Encode$string(session.aH.b3)),
							_Utils_Tuple2(
							'lastName',
							$elm$json$Json$Encode$string(session.aH.d_))
						]))),
				_Utils_Tuple2(
				'breeds',
				$author$project$Dog$Breeds$encode(session.aQ)),
				_Utils_Tuple2(
				'brands',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'all',
							A2(
								$elm$json$Json$Encode$list,
								$author$project$Dog$FoodBrands$encode,
								$author$project$Dog$FoodBrands$all(session.T))),
							_Utils_Tuple2(
							'other',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'raw',
										$elm$json$Json$Encode$int(
											$author$project$Dog$FoodBrands$other(session.T).ey)),
										_Utils_Tuple2(
										'dry',
										$elm$json$Json$Encode$int(
											$author$project$Dog$FoodBrands$other(session.T).dv)),
										_Utils_Tuple2(
										'wet',
										$elm$json$Json$Encode$int(
											$author$project$Dog$FoodBrands$other(session.T).fa))
									]))),
							_Utils_Tuple2(
							'homecooked',
							$elm$json$Json$Encode$int(
								$author$project$Dog$FoodBrands$homecooked(session.T).a3))
						]))),
				_Utils_Tuple2(
				'discountCodes',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'all',
							A2($elm$json$Json$Encode$list, $author$project$DiscountCode$encode, session.E.aO)),
							_Utils_Tuple2(
							'active',
							$author$project$DiscountCode$encode(session.E._)),
							_Utils_Tuple2(
							'default',
							$author$project$DiscountCode$encode(session.E.aA))
						]))),
				_Utils_Tuple2(
				'plans',
				A2($elm$json$Json$Encode$list, $author$project$Plan$encode, session.be)),
				_Utils_Tuple2(
				'deliveryDates',
				A2($elm$json$Json$Encode$list, $author$project$DeliveryDate$encode, session.aZ)),
				_Utils_Tuple2(
				'undeliverableDates',
				A2($elm$json$Json$Encode$list, $author$project$UndeliverableDate$encode, session.bn)),
				_Utils_Tuple2(
				'recipes',
				A2($elm$json$Json$Encode$list, $author$project$Recipe$encode, session.eA)),
				_Utils_Tuple2(
				'allergies',
				A2($elm$json$Json$Encode$list, $author$project$Allergy$encode, session.bp))
			]));
};
var $author$project$Json$Helpers$encodeSet = F2(
	function (encoder, set) {
		return A2(
			$elm$json$Json$Encode$list,
			encoder,
			$elm$core$Set$toList(set));
	});
var $author$project$Page$Checkout$encodeDogsForPlansPage = function (_v0) {
	var all = _v0.aO;
	var enabled = _v0.a$;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'all',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, all)),
				_Utils_Tuple2(
				'enabled',
				A2($author$project$Json$Helpers$encodeSet, $elm$json$Json$Encode$int, enabled))
			]));
};
var $author$project$Page$Checkout$encodeFieldsInteractedWith = function (fields) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$bool(fields.b3)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$bool(fields.d_)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$bool(fields.o)),
				_Utils_Tuple2(
				'emailConfirmation',
				$elm$json$Json$Encode$bool(fields.y)),
				_Utils_Tuple2(
				'firstDeliveryDate',
				$elm$json$Json$Encode$bool(fields.e)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$bool(fields.A)),
				_Utils_Tuple2(
				'postCode',
				$elm$json$Json$Encode$bool(fields.m)),
				_Utils_Tuple2(
				'address1',
				$elm$json$Json$Encode$bool(fields.bR)),
				_Utils_Tuple2(
				'address2',
				$elm$json$Json$Encode$bool(fields.bS)),
				_Utils_Tuple2(
				'town',
				$elm$json$Json$Encode$bool(fields.cK)),
				_Utils_Tuple2(
				'phoneNumber',
				$elm$json$Json$Encode$bool(fields.B)),
				_Utils_Tuple2(
				'cardNumber',
				$elm$json$Json$Encode$bool(fields.w)),
				_Utils_Tuple2(
				'cardExpMonth',
				$elm$json$Json$Encode$bool(fields.u)),
				_Utils_Tuple2(
				'cardExpYear',
				$elm$json$Json$Encode$bool(fields.v)),
				_Utils_Tuple2(
				'cardCVC',
				$elm$json$Json$Encode$bool(fields.t))
			]));
};
var $author$project$Page$Checkout$encodeFormData = function (formData) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(formData.b3)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$string(formData.d_)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(formData.o)),
				_Utils_Tuple2(
				'emailConfirmation',
				$elm$json$Json$Encode$string(formData.y)),
				_Utils_Tuple2(
				'firstDeliveryDate',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(formData.e))),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(formData.A)),
				_Utils_Tuple2(
				'postCode',
				$elm$json$Json$Encode$string(formData.m)),
				_Utils_Tuple2(
				'address1',
				$elm$json$Json$Encode$string(formData.bR)),
				_Utils_Tuple2(
				'address2',
				$elm$json$Json$Encode$string(formData.bS)),
				_Utils_Tuple2(
				'town',
				$elm$json$Json$Encode$string(formData.cK)),
				_Utils_Tuple2(
				'phoneNumber',
				$elm$json$Json$Encode$string(formData.B)),
				_Utils_Tuple2(
				'cardNumber',
				$elm$json$Json$Encode$string(formData.w)),
				_Utils_Tuple2(
				'cardExpMonth',
				$elm$json$Json$Encode$string(formData.u)),
				_Utils_Tuple2(
				'cardExpYear',
				$elm$json$Json$Encode$string(formData.v)),
				_Utils_Tuple2(
				'cardCVC',
				$elm$json$Json$Encode$string(formData.t))
			]));
};
var $author$project$Page$Checkout$encodeFormField = function (field) {
	var tag = function () {
		switch (field) {
			case 0:
				return 'FirstName';
			case 1:
				return 'LastName';
			case 2:
				return 'Email';
			case 3:
				return 'EmailConfirmation';
			case 4:
				return 'FirstDeliveryDate';
			case 5:
				return 'Password';
			case 6:
				return 'PostCode';
			case 7:
				return 'Address1';
			case 8:
				return 'Address2';
			case 9:
				return 'Town';
			case 10:
				return 'PhoneNumber';
			case 11:
				return 'CardNumber';
			case 12:
				return 'CardExpMonth';
			case 13:
				return 'CardExpYear';
			default:
				return 'CardCVC';
		}
	}();
	return $elm$json$Json$Encode$string(tag);
};
var $author$project$Page$Checkout$encodeFormSubmissionErrors = function (errors) {
	var errorEncoder = function (_v0) {
		var field = _v0.a;
		var error = _v0.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'field',
					$author$project$Page$Checkout$encodeFormField(field)),
					_Utils_Tuple2(
					'error',
					$elm$json$Json$Encode$string(error))
				]));
	};
	return A2($elm$json$Json$Encode$list, errorEncoder, errors);
};
var $author$project$Page$Checkout$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'formData',
				$author$project$Page$Checkout$encodeFormData(model.a)),
				_Utils_Tuple2(
				'fieldsInteractedWith',
				$author$project$Page$Checkout$encodeFieldsInteractedWith(model.U)),
				_Utils_Tuple2(
				'formSubmissionErrors',
				$author$project$Page$Checkout$encodeFormSubmissionErrors(model.V)),
				_Utils_Tuple2(
				'leaveSafeTooltipShown',
				$elm$json$Json$Encode$bool(model.ac)),
				_Utils_Tuple2(
				'ongoingPlanTooltipShown',
				$elm$json$Json$Encode$bool(model.G)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, model.j)),
				_Utils_Tuple2(
				'dogsForPlansPage',
				$author$project$Page$Checkout$encodeDogsForPlansPage(model.aC)),
				_Utils_Tuple2(
				'petParentDetails',
				$author$project$Page$PetParentDetails$encode(model.P)),
				_Utils_Tuple2(
				'selectedPlan',
				$author$project$Plan$encode(model.Y)),
				_Utils_Tuple2(
				'buySubscriptionResultPending',
				$elm$json$Json$Encode$bool(model.I))
			]));
};
var $author$project$Page$ChoosePlan$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, model.j)),
				_Utils_Tuple2(
				'enabledDogs',
				A2($author$project$Json$Helpers$encodeSet, $elm$json$Json$Encode$int, model.F))
			]));
};
var $author$project$Page$Login$encodeModel = function (_v0) {
	var model = _v0.b;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(model.o))
			]));
};
var $author$project$Page$Meals$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, model.j))
			]));
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.cr;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.b4,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.cs,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.co,
					_Utils_ap(http, url.b7)),
				url.cl)));
};
var $author$project$SignInLink$encode = function (_v0) {
	var url = _v0;
	return $elm$json$Json$Encode$string(
		$elm$url$Url$toString(url));
};
var $author$project$Page$OrderConfirmation$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, model.j)),
				_Utils_Tuple2(
				'firstDeliveryDate',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(model.e))),
				_Utils_Tuple2(
				'plan',
				$author$project$Plan$encode(model.r)),
				_Utils_Tuple2(
				'saleMadeOnline',
				$elm$json$Json$Encode$bool(model.R)),
				_Utils_Tuple2(
				'signInLink',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$SignInLink$encode, model.aI))
			]));
};
var $author$project$Page$Receipt$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$Dog$encode, model.j)),
				_Utils_Tuple2(
				'firstDeliveryDate',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(model.e))),
				_Utils_Tuple2(
				'plan',
				$author$project$Plan$encode(model.r)),
				_Utils_Tuple2(
				'signInLink',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$SignInLink$encode, model.aI))
			]));
};
var $author$project$Page$Welcome$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f))
			]));
};
var $author$project$Page$Wizard$Steps$encodeNoStepsComplete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$string, dog.cg)),
				_Utils_Tuple2(
				'sex',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Sex$encode, dog.cB)),
				_Utils_Tuple2(
				'breed',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Breeds$encodeBreed, dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Dog$FoodCategory$encode),
					dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Fussiness$encode, dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep10Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				$author$project$Dog$ActivityLevel$encode(dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2($elm$json$Json$Encode$list, $author$project$Allergy$encode, dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep11Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				$author$project$Dog$ActivityLevel$encode(dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2($elm$json$Json$Encode$list, $author$project$Allergy$encode, dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				$author$project$Dog$HealthIssues$encode(dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep1Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Breeds$encodeBreed, dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Dog$FoodCategory$encode),
					dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Fussiness$encode, dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep2Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Dog$FoodCategory$encode),
					dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Fussiness$encode, dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep3Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$Fussiness$encode, dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep4Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep5Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$bool, dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep6Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$BodyCondition$encode, dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep7Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				A2($author$project$Json$Helpers$encodeMaybe, $elm$json$Json$Encode$int, dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep8Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$ActivityLevel$encode, dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$Steps$encodeStep9Complete = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'sex',
				$author$project$Dog$Sex$encode(dog.cB)),
				_Utils_Tuple2(
				'breed',
				$author$project$Dog$Breeds$encodeBreed(dog.bV)),
				_Utils_Tuple2(
				'currentlyEating',
				A2($elm$json$Json$Encode$list, $author$project$Dog$FoodCategory$encode, dog.b$)),
				_Utils_Tuple2(
				'currentBrand',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$FoodBrands$encode, dog.b_)),
				_Utils_Tuple2(
				'fussiness',
				$author$project$Dog$Fussiness$encode(dog.b5)),
				_Utils_Tuple2(
				'yearsOld',
				$elm$json$Json$Encode$int(dog.cN)),
				_Utils_Tuple2(
				'monthsOld',
				$elm$json$Json$Encode$int(dog.ce)),
				_Utils_Tuple2(
				'deSexed',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'bodyCondition',
				$author$project$Dog$BodyCondition$encode(dog.bU)),
				_Utils_Tuple2(
				'mass',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'activityLevel',
				$author$project$Dog$ActivityLevel$encode(dog.bP)),
				_Utils_Tuple2(
				'allergies',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$list($author$project$Allergy$encode),
					dog.bp)),
				_Utils_Tuple2(
				'healthIssues',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$HealthIssues$encode, dog.bw)),
				_Utils_Tuple2(
				'snackingHabits',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Dog$SnackingHabits$encode, dog.cE))
			]));
};
var $author$project$Page$Wizard$HowManyDogs$getDogs = function (_v0) {
	var dogs = _v0.j;
	return dogs;
};
var $author$project$Page$Wizard$WhatBreed$getDogs = function (_v0) {
	var dogs = _v0.j;
	return dogs;
};
var $author$project$Page$Wizard$WhatCurrentFoods$getDogs = function (_v0) {
	var dogs = _v0.j;
	return dogs;
};
var $author$project$Page$Wizard$WhatMass$getDogs = function (_v0) {
	var dogs = _v0.j;
	return dogs;
};
var $author$project$Page$Wizard$encodeCurrentStep = function (step) {
	var _v0 = function () {
		switch (step.$) {
			case 0:
				var subModel = step.a;
				return _Utils_Tuple2(
					'HowManyDogs',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Page$Wizard$Steps$encodeNoStepsComplete,
						$author$project$Page$Wizard$HowManyDogs$getDogs(subModel)));
			case 1:
				var subModel = step.a;
				return _Utils_Tuple2(
					'WhatBreed',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Page$Wizard$Steps$encodeStep1Complete,
						$author$project$Page$Wizard$WhatBreed$getDogs(subModel)));
			case 2:
				var subModel = step.a;
				return _Utils_Tuple2(
					'WhatCurrentFoods',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Page$Wizard$Steps$encodeStep2Complete,
						$author$project$Page$Wizard$WhatCurrentFoods$getDogs(subModel)));
			case 3:
				var dogs = step.a;
				return _Utils_Tuple2(
					'WhatKindOfEater',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep3Complete, dogs));
			case 4:
				var dogs = step.a;
				return _Utils_Tuple2(
					'HowOld',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep4Complete, dogs));
			case 5:
				var dogs = step.a;
				return _Utils_Tuple2(
					'HaveBeenDeSexed',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep5Complete, dogs));
			case 6:
				var dogs = step.a;
				return _Utils_Tuple2(
					'WhatBodyCondition',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep6Complete, dogs));
			case 7:
				var subModel = step.a;
				return _Utils_Tuple2(
					'WhatMass',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Page$Wizard$Steps$encodeStep7Complete,
						$author$project$Page$Wizard$WhatMass$getDogs(subModel)));
			case 8:
				var dogs = step.a;
				return _Utils_Tuple2(
					'WhatActivityLevel',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep8Complete, dogs));
			case 9:
				var dogs = step.a;
				return _Utils_Tuple2(
					'AnyAllergies',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep9Complete, dogs));
			case 10:
				var dogs = step.a;
				return _Utils_Tuple2(
					'AnyHealthIssues',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep10Complete, dogs));
			default:
				var dogs = step.a;
				return _Utils_Tuple2(
					'WhatSnackingHabits',
					A2($elm$json$Json$Encode$list, $author$project$Page$Wizard$Steps$encodeStep11Complete, dogs));
		}
	}();
	var stepName = _v0.a;
	var stepData = _v0.b;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'stepName',
				$elm$json$Json$Encode$string(stepName)),
				_Utils_Tuple2('stepData', stepData)
			]));
};
var $author$project$Page$Wizard$encodeModel = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'session',
				$author$project$Session$encode(model.f)),
				_Utils_Tuple2(
				'currentStep',
				$author$project$Page$Wizard$encodeCurrentStep(model.n)),
				_Utils_Tuple2(
				'petParentDetails',
				A2($author$project$Json$Helpers$encodeMaybe, $author$project$Page$PetParentDetails$encode, model.P))
			]));
};
var $author$project$Main$encodePage = function (page) {
	encodePage:
	while (true) {
		switch (page.$) {
			case 0:
				var model = page.a;
				return _Utils_Tuple2(
					'Login',
					$author$project$Page$Login$encodeModel(model));
			case 1:
				var model = page.a;
				return _Utils_Tuple2(
					'Welcome',
					$author$project$Page$Welcome$encodeModel(model));
			case 2:
				var model = page.a;
				return _Utils_Tuple2(
					'Wizard',
					$author$project$Page$Wizard$encodeModel(model));
			case 3:
				var session = page.a;
				var dogs = page.b;
				var petParentDetails = page.c;
				return _Utils_Tuple2(
					'CalculatePlan',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'session',
								$author$project$Session$encode(session)),
								_Utils_Tuple2(
								'dogs',
								A2($elm$json$Json$Encode$list, $author$project$Dog$encode, dogs)),
								_Utils_Tuple2(
								'petParentDetails',
								A2($author$project$Json$Helpers$encodeMaybe, $author$project$Page$PetParentDetails$encode, petParentDetails))
							])));
			case 4:
				var model = page.a;
				return _Utils_Tuple2(
					'Meals',
					$author$project$Page$Meals$encodeModel(model));
			case 5:
				var model = page.a;
				return _Utils_Tuple2(
					'ChoosePlan',
					$author$project$Page$ChoosePlan$encodeModel(model));
			case 6:
				var model = page.a;
				return _Utils_Tuple2(
					'Checkout',
					$author$project$Page$Checkout$encodeModel(model));
			case 7:
				var model = page.a;
				return _Utils_Tuple2(
					'OrderConfirmation',
					$author$project$Page$OrderConfirmation$encodeModel(model));
			case 8:
				var model = page.a;
				return _Utils_Tuple2(
					'Receipt',
					$author$project$Page$Receipt$encodeModel(model));
			default:
				if (page.a.$ === 9) {
					var _v1 = page.a;
					var session = page.b;
					return _Utils_Tuple2('NotSupported', $elm$json$Json$Encode$null);
				} else {
					var pageToGoBackTo = page.a;
					var session = page.b;
					var $temp$page = pageToGoBackTo;
					page = $temp$page;
					continue encodePage;
				}
		}
	}
};
var $author$project$Main$persistToLocalStorage = _Platform_outgoingPort('persistToLocalStorage', $elm$core$Basics$identity);
var $author$project$Main$persistCurrentPage = function (page) {
	var _v0 = $author$project$Main$encodePage(page);
	var pageString = _v0.a;
	var encodedPageData = _v0.b;
	return $author$project$Main$persistToLocalStorage(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'pageString',
					$elm$json$Json$Encode$string(pageString)),
					_Utils_Tuple2('pageData', encodedPageData)
				])));
};
var $author$project$Monitoring$DeviceBecameOnline = {$: 4};
var $author$project$Monitoring$EnterWizard = 2;
var $author$project$Main$GotCheckoutMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$GotChoosePlanMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$GotMealsMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$GotOrderConfirmationMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$Main$GotReceiptMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$GotWelcomeMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$GotWizardMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$Offline = 1;
var $author$project$Monitoring$ReachWaypoint = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$SalesHistory = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Monitoring$SalesHistoryPage = 9;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Maybe$Helpers$filter = F2(
	function (p, maybeX) {
		return A2(
			$elm$core$Maybe$andThen,
			function (x) {
				return p(x) ? $elm$core$Maybe$Just(x) : $elm$core$Maybe$Nothing;
			},
			maybeX);
	});
var $author$project$Page$Checkout$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$ChoosePlan$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$Meals$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$OrderConfirmation$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$Receipt$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$Welcome$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Page$Wizard$getSession = function (_v0) {
	var session = _v0.f;
	return session;
};
var $author$project$Main$getSession = function (page) {
	switch (page.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$Welcome$getSession(subModel));
		case 2:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$Wizard$getSession(subModel));
		case 3:
			var session = page.a;
			return $elm$core$Maybe$Just(session);
		case 4:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$Meals$getSession(subModel));
		case 5:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$ChoosePlan$getSession(subModel));
		case 6:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$Checkout$getSession(subModel));
		case 7:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$OrderConfirmation$getSession(subModel));
		case 8:
			var subModel = page.a;
			return $elm$core$Maybe$Just(
				$author$project$Page$Receipt$getSession(subModel));
		default:
			var session = page.b;
			return $elm$core$Maybe$Just(session);
	}
};
var $author$project$Session$resetActiveDiscountCode = function (_v0) {
	var session = _v0;
	var codes = session.E;
	var newCodes = _Utils_update(
		codes,
		{_: codes.aA});
	return _Utils_update(
		session,
		{E: newCodes});
};
var $author$project$Page$Wizard$init = F2(
	function (env, session) {
		return {
			n: $author$project$Page$Wizard$HowManyDogs(
				$author$project$Page$Wizard$HowManyDogs$init(_List_Nil)),
			c: env,
			l: $author$project$Page$Wizard$Closed,
			P: $elm$core$Maybe$Nothing,
			f: $author$project$Session$resetActiveDiscountCode(session)
		};
	});
var $author$project$Main$ViewportReset = {$: 3};
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Main$resetViewport = A2(
	$elm$core$Task$perform,
	function (_v0) {
		return $author$project$Main$ViewportReset;
	},
	A2($elm$browser$Browser$Dom$setViewport, 0, 0));
var $author$project$Jwt$authToken = function (_v0) {
	var token = _v0;
	return token;
};
var $author$project$Session$apiToken = function (_v0) {
	var jwt = _v0.a8;
	return $author$project$Jwt$authToken(jwt);
};
var $author$project$Monitoring$deltaFor = function (event) {
	if (event.$ === 1) {
		var n = event.a;
		return n;
	} else {
		return 1;
	}
};
var $author$project$Monitoring$boolToString = function (x) {
	if (x) {
		return 'True';
	} else {
		return 'False';
	}
};
var $author$project$Monitoring$endpointFailureReasonToString = function (event) {
	switch (event) {
		case 0:
			return 'Rails';
		case 1:
			return 'Stripe';
		default:
			return 'Unreachable';
	}
};
var $author$project$Monitoring$endpointEventToString = function (event) {
	switch (event.$) {
		case 0:
			return 'Attempt';
		case 1:
			return 'Success';
		default:
			var endpointFailureReason = event.a;
			return 'Failure-' + $author$project$Monitoring$endpointFailureReasonToString(endpointFailureReason);
	}
};
var $author$project$Monitoring$waypointToString = function (event) {
	switch (event) {
		case 0:
			return 'WelcomePage';
		case 1:
			return 'BackToWelcomePage';
		case 2:
			return 'EnterWizard';
		case 3:
			return 'ReEnterWizard';
		case 4:
			return 'MealsPage';
		case 5:
			return 'PlansPage';
		case 6:
			return 'CheckoutPage';
		case 7:
			return 'OrderConfirmationPage';
		case 8:
			return 'ReceiptPage';
		default:
			return 'SalesHistoryPage';
	}
};
var $author$project$Monitoring$eventToString = function (event) {
	switch (event.$) {
		case 0:
			return 'LoginSuccessful';
		case 1:
			return 'WorkingDogs';
		case 2:
			var flag = event.a;
			return 'ConsentedToMarketing-' + $author$project$Monitoring$boolToString(flag);
		case 3:
			var endpointEvent = event.a;
			return 'SignUp-Online-' + $author$project$Monitoring$endpointEventToString(endpointEvent);
		case 4:
			return 'DeviceBecameOnline';
		default:
			var waypoint = event.a;
			return 'ReachWaypoint-' + $author$project$Monitoring$waypointToString(waypoint);
	}
};
var $author$project$Monitoring$encodeEvent = function (event) {
	return $elm$json$Json$Encode$string(
		$author$project$Monitoring$eventToString(event));
};
var $author$project$Monitoring$encodeEventPayload = function (payload) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'apiToken',
				$elm$json$Json$Encode$string(payload.bq)),
				_Utils_Tuple2(
				'event',
				$author$project$Monitoring$encodeEvent(payload.bv)),
				_Utils_Tuple2(
				'delta',
				$elm$json$Json$Encode$int(payload.br))
			]));
};
var $author$project$Monitoring$monitoringEvent = _Platform_outgoingPort('monitoringEvent', $elm$core$Basics$identity);
var $author$project$Monitoring$send = F2(
	function (session, event) {
		var payload = {
			bq: $author$project$Session$apiToken(session),
			br: $author$project$Monitoring$deltaFor(event),
			bv: event
		};
		return $author$project$Monitoring$monitoringEvent(
			$author$project$Monitoring$encodeEventPayload(payload));
	});
var $author$project$Session$setActiveDiscountCode = F2(
	function (code, _v0) {
		var session = _v0;
		var codes = session.E;
		var newCodes = _Utils_update(
			codes,
			{_: code});
		return _Utils_update(
			session,
			{E: newCodes});
	});
var $author$project$Main$CalculatePlan = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Page$Checkout$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$ChoosePlan$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$Meals$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$OrderConfirmation$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$Receipt$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$Welcome$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Page$Wizard$setSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var $author$project$Main$setSession = F2(
	function (page, session) {
		switch (page.$) {
			case 0:
				return page;
			case 1:
				var subModel = page.a;
				return $author$project$Main$Welcome(
					A2($author$project$Page$Welcome$setSession, session, subModel));
			case 2:
				var subModel = page.a;
				return $author$project$Main$Wizard(
					A2($author$project$Page$Wizard$setSession, session, subModel));
			case 3:
				var dogs = page.b;
				var petParent = page.c;
				return A3($author$project$Main$CalculatePlan, session, dogs, petParent);
			case 4:
				var subModel = page.a;
				return $author$project$Main$Meals(
					A2($author$project$Page$Meals$setSession, session, subModel));
			case 5:
				var subModel = page.a;
				return $author$project$Main$ChoosePlan(
					A2($author$project$Page$ChoosePlan$setSession, session, subModel));
			case 6:
				var subModel = page.a;
				return $author$project$Main$Checkout(
					A2($author$project$Page$Checkout$setSession, session, subModel));
			case 7:
				var subModel = page.a;
				return $author$project$Main$OrderConfirmation(
					A2($author$project$Page$OrderConfirmation$setSession, session, subModel));
			case 8:
				var subModel = page.a;
				return $author$project$Main$Receipt(
					A2($author$project$Page$Receipt$setSession, session, subModel));
			default:
				var pageToGoBackTo = page.a;
				return A2($author$project$Main$SalesHistory, pageToGoBackTo, session);
		}
	});
var $author$project$Main$MenuOpen = {$: 0};
var $author$project$Main$toggleMenu = function (model) {
	var overlays = model.l;
	if (!overlays.$) {
		return _Utils_update(
			model,
			{
				l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
			});
	} else {
		return _Utils_update(
			model,
			{l: $author$project$Main$MenuOpen});
	}
};
var $author$project$Page$Router$BackToSeePlans = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Page$Checkout$CantSearch = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$DatePicker = 0;
var $author$project$Monitoring$EndpointFailure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Monitoring$EndpointSuccess = {$: 1};
var $author$project$Page$Checkout$GotPostCodeSearchResults = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$Checkout$HaveSearchResults = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Checkout$Open = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Router$OrderConfirmation = F6(
	function (a, b, c, d, e, f) {
		return {$: 10, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$Monitoring$Rails = 0;
var $author$project$Page$Checkout$Searching = function (a) {
	return {$: 2, a: a};
};
var $author$project$Monitoring$SignUpResult = function (a) {
	return {$: 3, a: a};
};
var $author$project$Monitoring$Stripe = 1;
var $author$project$Monitoring$Unreachable = 2;
var $author$project$Address$address1 = function (_v0) {
	var address = _v0;
	return address.bR;
};
var $author$project$Address$address2 = function (_v0) {
	var address = _v0;
	return address.bS;
};
var $author$project$Page$Checkout$GotSignUpResult = function (a) {
	return {$: 19, a: a};
};
var $author$project$Session$activeDiscountCode = function (_v0) {
	var session = _v0;
	return session.E._;
};
var $author$project$SignUp$OfflineAcquisition = $elm$core$Basics$identity;
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$SignUp$buildCard = function (customerDetails) {
	return {w: customerDetails.w, aX: customerDetails.t, a0: customerDetails.u, a1: customerDetails.v, cg: customerDetails.b3 + (' ' + customerDetails.d_)};
};
var $author$project$SignUp$NetworkError = function (a) {
	return {$: 0, a: a};
};
var $author$project$SignUp$Stripe = 0;
var $author$project$SignUp$StripeToken = $elm$core$Basics$identity;
var $author$project$SignUp$UnrecoverableError = function (a) {
	return {$: 3, a: a};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$stringBody = _Http_pair;
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$Env$stripePublishableKey = function (_v0) {
	var envVars = _v0.bu;
	return envVars.cF;
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{cP: false, c_: r.c_, dz: r.cx, dM: r.dM, d8: r.d8, eV: r.eV, eX: $elm$core$Maybe$Nothing, e4: r.e4});
};
var $author$project$SignUp$createStripeToken = F3(
	function (env, card, offlineFallback) {
		var secretKey = $author$project$Env$stripePublishableKey(env);
		var headers = _List_fromArray(
			[
				A2($elm$http$Http$header, 'Accept', 'application/json'),
				A2($elm$http$Http$header, 'Authorization', 'Bearer ' + secretKey)
			]);
		var encodedCardDetails = A2(
			$elm$core$String$join,
			'&',
			A2(
				$elm$core$List$map,
				function (_v2) {
					var key = _v2.a;
					var value = _v2.b;
					return 'card[' + (key + (']=' + value));
				},
				_List_fromArray(
					[
						_Utils_Tuple2('number', card.w),
						_Utils_Tuple2('exp_month', card.a0),
						_Utils_Tuple2('exp_year', card.a1),
						_Utils_Tuple2('cvc', card.aX)
					])));
		var decodeStripeToken = function (response) {
			switch (response.$) {
				case 1:
					return $elm$core$Result$Err(
						$author$project$SignUp$NetworkError(offlineFallback));
				case 2:
					return $elm$core$Result$Err(
						$author$project$SignUp$NetworkError(offlineFallback));
				case 4:
					var responseBody = response.b;
					return A2(
						$elm$core$Result$mapError,
						function (_v1) {
							return $author$project$SignUp$UnrecoverableError(0);
						},
						A2(
							$elm$core$Result$map,
							$elm$core$Basics$identity,
							A2(
								$elm$json$Json$Decode$decodeString,
								A2(
									$elm$json$Json$Decode$at,
									_List_fromArray(
										['id']),
									$elm$json$Json$Decode$string),
								responseBody)));
				case 3:
					return $elm$core$Result$Err(
						$author$project$SignUp$UnrecoverableError(0));
				default:
					return $elm$core$Result$Err(
						$author$project$SignUp$UnrecoverableError(0));
			}
		};
		var body = A2($elm$http$Http$stringBody, 'application/x-www-form-urlencoded', encodedCardDetails);
		return $elm$http$Http$task(
			{
				c_: body,
				dM: headers,
				d8: 'POST',
				cx: $elm$http$Http$stringResolver(decodeStripeToken),
				eV: $elm$core$Maybe$Nothing,
				e4: 'https://api.stripe.com/v1/tokens'
			});
	});
var $author$project$SignUp$encodeBodyShape = function (bodyCondition) {
	switch (bodyCondition) {
		case 0:
			return $elm$json$Json$Encode$string('skinny');
		case 1:
			return $elm$json$Json$Encode$string('just_right');
		default:
			return $elm$json$Json$Encode$string('chubby');
	}
};
var $author$project$SignUp$encodeEaterType = function (fussiness) {
	switch (fussiness) {
		case 0:
			return $elm$json$Json$Encode$string('fussy_eater');
		case 1:
			return $elm$json$Json$Encode$string('picky_eater');
		case 2:
			return $elm$json$Json$Encode$string('good_eater');
		default:
			return $elm$json$Json$Encode$string('eats_anything');
	}
};
var $author$project$SignUp$encodeExercise = function (activityLevel) {
	switch (activityLevel) {
		case 0:
			return $elm$json$Json$Encode$string('low');
		case 1:
			return $elm$json$Json$Encode$string('normal');
		case 2:
			return $elm$json$Json$Encode$string('normal');
		case 3:
			return $elm$json$Json$Encode$string('normal');
		case 4:
			return $elm$json$Json$Encode$string('hyper');
		default:
			return $elm$json$Json$Encode$string('hyper');
	}
};
var $author$project$SignUp$encodeFoodType = function (food) {
	switch (food) {
		case 0:
			return $elm$json$Json$Encode$string('dry_food');
		case 1:
			return $elm$json$Json$Encode$string('wet_food');
		case 2:
			return $elm$json$Json$Encode$string('raw_food');
		default:
			return $elm$json$Json$Encode$string('home_food');
	}
};
var $author$project$SignUp$encodeGender = function (sex) {
	if (!sex) {
		return $elm$json$Json$Encode$string('female');
	} else {
		return $elm$json$Json$Encode$string('male');
	}
};
var $author$project$Dog$HealthIssues$toList = function (healthIssues) {
	if (!healthIssues.$) {
		return _List_Nil;
	} else {
		var xs = healthIssues.a;
		return xs;
	}
};
var $author$project$SignUp$encodeHealthConcerns = function (healthIssues) {
	var issues = $author$project$Dog$HealthIssues$toList(healthIssues);
	var issueToString = function (issue) {
		switch (issue) {
			case 0:
				return 'pancreatitis';
			case 1:
				return 'diabetes';
			case 2:
				return 'ibd';
			case 3:
				return 'kidney_disease';
			case 4:
				return 'struvite_stones';
			case 5:
				return 'liver_disease';
			case 6:
				return 'skin_or_coat_issues';
			case 7:
				return 'joint_problems';
			case 8:
				return 'cancer';
			case 9:
				return 'obesity';
			case 10:
				return 'sensitive_stomach';
			case 11:
				return 'heart_condition';
			default:
				return 'epilepsy';
		}
	};
	return A2(
		$elm$json$Json$Encode$list,
		$elm$json$Json$Encode$string,
		A2($elm$core$List$map, issueToString, issues));
};
var $author$project$SignUp$encodeSnackingHabits = function (snackingHabits) {
	switch (snackingHabits) {
		case 0:
			return $elm$json$Json$Encode$string('eats_no_snacks');
		case 1:
			return $elm$json$Json$Encode$string('eats_some_snacks');
		default:
			return $elm$json$Json$Encode$string('eats_lots_of_snacks');
	}
};
var $author$project$SignUp$encodeDog = function (dog) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(dog.cg)),
				_Utils_Tuple2(
				'gender',
				$author$project$SignUp$encodeGender(dog.cB)),
				_Utils_Tuple2(
				'breed_id',
				$elm$json$Json$Encode$int(dog.bV.a3)),
				_Utils_Tuple2(
				'body_shape',
				$author$project$SignUp$encodeBodyShape(dog.bU)),
				_Utils_Tuple2(
				'exercise',
				$author$project$SignUp$encodeExercise(dog.bP)),
				_Utils_Tuple2(
				'eater_type',
				$author$project$SignUp$encodeEaterType(dog.b5)),
				_Utils_Tuple2(
				'age_in_months',
				$elm$json$Json$Encode$int((dog.cN * 12) + dog.ce)),
				_Utils_Tuple2(
				'weight_in_grams',
				$elm$json$Json$Encode$int(dog.cc)),
				_Utils_Tuple2(
				'neutered',
				$elm$json$Json$Encode$bool(dog.b1)),
				_Utils_Tuple2(
				'snacking_habits',
				$author$project$SignUp$encodeSnackingHabits(dog.cE)),
				_Utils_Tuple2(
				'allergies',
				A2($elm$json$Json$Encode$list, $author$project$Allergy$encode, dog.bp)),
				_Utils_Tuple2(
				'health_concerns',
				$author$project$SignUp$encodeHealthConcerns(dog.bw)),
				_Utils_Tuple2(
				'fed_food_id',
				A2(
					$author$project$Json$Helpers$encodeMaybe,
					$elm$json$Json$Encode$int,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.a3;
						},
						dog.b_))),
				_Utils_Tuple2(
				'previous_food_types',
				A2($elm$json$Json$Encode$list, $author$project$SignUp$encodeFoodType, dog.b$))
			]));
};
var $author$project$SignUp$encodeRecipe = function (recipe) {
	switch (recipe) {
		case 0:
			return $elm$json$Json$Encode$string('beef');
		case 1:
			return $elm$json$Json$Encode$string('chicken');
		case 2:
			return $elm$json$Json$Encode$string('turkey');
		case 3:
			return $elm$json$Json$Encode$string('lamb');
		case 4:
			return $elm$json$Json$Encode$string('white_fish');
		case 5:
			return $elm$json$Json$Encode$string('pork');
		default:
			return $elm$json$Json$Encode$string('duck_and_chicken');
	}
};
var $author$project$DiscountCode$id = function (_v0) {
	var discountCode = _v0;
	return discountCode.a3;
};
var $author$project$SignUp$encodeUser = function (_v0) {
	var customerDetails = _v0.dm;
	var dogs = _v0.j;
	var discountCode = _v0.al;
	var marketingConsent = _v0.ap;
	var recipes = _v0.eA;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'first_name',
				$elm$json$Json$Encode$string(customerDetails.b3)),
				_Utils_Tuple2(
				'last_name',
				$elm$json$Json$Encode$string(customerDetails.d_)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(customerDetails.o)),
				_Utils_Tuple2(
				'phone',
				$elm$json$Json$Encode$string(customerDetails.B)),
				_Utils_Tuple2(
				'address',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'address_1',
							$elm$json$Json$Encode$string(customerDetails.bR)),
							_Utils_Tuple2(
							'address_2',
							$elm$json$Json$Encode$string(customerDetails.bS)),
							_Utils_Tuple2(
							'postcode',
							$elm$json$Json$Encode$string(customerDetails.m)),
							_Utils_Tuple2(
							'city',
							$elm$json$Json$Encode$string(customerDetails.cK))
						]))),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(customerDetails.A)),
				_Utils_Tuple2(
				'dogs',
				A2($elm$json$Json$Encode$list, $author$project$SignUp$encodeDog, dogs)),
				_Utils_Tuple2(
				'discount_code_id',
				$elm$json$Json$Encode$int(
					$author$project$DiscountCode$id(discountCode))),
				_Utils_Tuple2(
				'privacy_preferences',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'consented_to_marketing',
							$elm$json$Json$Encode$bool(marketingConsent))
						]))),
				_Utils_Tuple2(
				'selected_recipes',
				A2($elm$json$Json$Encode$list, $author$project$SignUp$encodeRecipe, recipes))
			]));
};
var $author$project$SignUp$encodeSubscriptionBody = F2(
	function (acquisition, _v0) {
		var token = _v0;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'stripe_token',
					$elm$json$Json$Encode$string(token)),
					_Utils_Tuple2(
					'target_first_delivery_date',
					$elm$json$Json$Encode$int(
						$elm$time$Time$posixToMillis(acquisition.dm.e))),
					_Utils_Tuple2(
					'target_plan_id',
					$elm$json$Json$Encode$int(acquisition.r.a3)),
					_Utils_Tuple2(
					'user',
					$author$project$SignUp$encodeUser(acquisition))
				]));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$SignUp$ButternutRailsApi = 1;
var $author$project$SignUp$CardError = {$: 2};
var $author$project$SignUp$Customer = $elm$core$Basics$identity;
var $author$project$SignUp$EmailTaken = {$: 1};
var $author$project$SignUp$onlineSignUpResolver = F3(
	function (acquisition, offlineFallback, response) {
		var errorFromString = function (err) {
			switch (err) {
				case 'card_error':
					return $author$project$SignUp$CardError;
				case 'email_taken':
					return $author$project$SignUp$EmailTaken;
				default:
					return $author$project$SignUp$UnrecoverableError(1);
			}
		};
		var errorDecoder = function (body) {
			return A2(
				$elm$core$Result$withDefault,
				$author$project$SignUp$UnrecoverableError(1),
				A2(
					$elm$core$Result$map,
					errorFromString,
					A2(
						$elm$json$Json$Decode$decodeString,
						A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string),
						body)));
		};
		switch (response.$) {
			case 4:
				var body = response.b;
				var signInLinkDecoder = A2($elm$json$Json$Decode$field, 'sign_in_link', $author$project$SignInLink$decoder);
				var dogNames = A2(
					$elm$core$List$map,
					function ($) {
						return $.cg;
					},
					acquisition.j);
				var _v1 = acquisition.dm;
				var firstName = _v1.b3;
				var lastName = _v1.d_;
				var name = firstName + (' ' + lastName);
				return A2(
					$elm$core$Result$mapError,
					function (_v2) {
						return $author$project$SignUp$UnrecoverableError(1);
					},
					A2(
						$elm$core$Result$map,
						function (signInLink_) {
							return {aB: dogNames, cg: name, aI: signInLink_};
						},
						A2($elm$json$Json$Decode$decodeString, signInLinkDecoder, body)));
			case 3:
				var body = response.b;
				return $elm$core$Result$Err(
					errorDecoder(body));
			case 1:
				return $elm$core$Result$Err(
					$author$project$SignUp$NetworkError(offlineFallback));
			case 2:
				return $elm$core$Result$Err(
					$author$project$SignUp$NetworkError(offlineFallback));
			default:
				return $elm$core$Result$Err(
					$author$project$SignUp$UnrecoverableError(1));
		}
	});
var $author$project$Env$railsURI = function (_v0) {
	var envVars = _v0.bu;
	return envVars.ct;
};
var $author$project$SignUp$createSubscription = F5(
	function (env, session, acquisition, offlineFallback, stripeToken) {
		var url = $author$project$Env$railsURI(env) + '/point-of-sale/v1/checkout/online';
		var headers = _List_fromArray(
			[
				A2(
				$elm$http$Http$header,
				'Authorization',
				'Bearer ' + $author$project$Session$apiToken(session))
			]);
		var body = $elm$http$Http$jsonBody(
			A2($author$project$SignUp$encodeSubscriptionBody, acquisition, stripeToken));
		return $elm$http$Http$task(
			{
				c_: body,
				dM: headers,
				d8: 'POST',
				cx: $elm$http$Http$stringResolver(
					A2($author$project$SignUp$onlineSignUpResolver, acquisition, offlineFallback)),
				eV: $elm$core$Maybe$Nothing,
				e4: url
			});
	});
var $author$project$SignUp$attemptNow = F4(
	function (env, session, acquisition, toMsg) {
		var offlineFallback = acquisition;
		var card = $author$project$SignUp$buildCard(acquisition.dm);
		return A2(
			$elm$core$Task$attempt,
			toMsg,
			A2(
				$elm$core$Task$andThen,
				A4($author$project$SignUp$createSubscription, env, session, acquisition, offlineFallback),
				A3($author$project$SignUp$createStripeToken, env, card, offlineFallback)));
	});
var $rtfeldman$elm_validate$Validate$fromValid = function (_v0) {
	var subject = _v0;
	return subject;
};
var $author$project$Page$Checkout$checkout = F7(
	function (env, session, validData, dogs, plan, marketingConsent, recipes) {
		var formData = $rtfeldman$elm_validate$Validate$fromValid(validData);
		var acquisition = {
			dm: formData,
			al: $author$project$Session$activeDiscountCode(session),
			j: dogs,
			ap: marketingConsent,
			r: plan,
			eA: recipes
		};
		return A4($author$project$SignUp$attemptNow, env, session, acquisition, $author$project$Page$Checkout$GotSignUpResult);
	});
var $author$project$Session$deliveryDates = function (_v0) {
	var session = _v0;
	return session.aZ;
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Page$Checkout$interactedWithEverything = {bR: true, bS: true, t: true, u: true, v: true, w: true, o: true, y: true, e: true, b3: true, d_: true, A: true, B: true, m: true, cK: true};
var $author$project$Page$Router$None = {$: 0};
var $author$project$Page$Router$none = $author$project$Page$Router$None;
var $elm$core$Basics$not = _Basics_not;
var $author$project$SignUp$persistCustomer = _Platform_outgoingPort('persistCustomer', $elm$core$Basics$identity);
var $author$project$SignUp$persist = function (_v0) {
	var customer = _v0;
	var encodedCustomer = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(customer.cg)),
				_Utils_Tuple2(
				'dogNames',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, customer.aB))
			]));
	return $author$project$SignUp$persistCustomer(encodedCustomer);
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Allergy$allergicToRecipe = F2(
	function (allergies, recipe) {
		switch (recipe) {
			case 0:
				return A2($elm$core$List$member, 0, allergies);
			case 1:
				return A2($elm$core$List$member, 1, allergies);
			case 2:
				return A2($elm$core$List$member, 2, allergies);
			case 3:
				return A2($elm$core$List$member, 3, allergies);
			case 4:
				return A2($elm$core$List$member, 4, allergies);
			case 5:
				return A2($elm$core$List$member, 5, allergies);
			default:
				return A2($elm$core$List$member, 6, allergies) || A2($elm$core$List$member, 1, allergies);
		}
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Dog$getAllAllergies = function (dogs) {
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.bp;
		},
		dogs);
};
var $author$project$Recipe$isLowFat = function (recipe) {
	switch (recipe) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		case 3:
			return false;
		case 4:
			return true;
		case 5:
			return false;
		default:
			return false;
	}
};
var $author$project$Dog$HealthIssues$lowFatRecommendedFor = function (issue) {
	switch (issue) {
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 0:
			return true;
		case 1:
			return true;
		case 5:
			return false;
		case 6:
			return false;
		case 7:
			return false;
		case 8:
			return false;
		case 9:
			return true;
		case 10:
			return true;
		case 11:
			return false;
		default:
			return false;
	}
};
var $author$project$Session$recipes = function (_v0) {
	var session = _v0;
	return session.eA;
};
var $author$project$Dog$recipesFor = F2(
	function (session, dogs) {
		var lowFatRecipes = A2(
			$elm$core$List$filter,
			$author$project$Recipe$isLowFat,
			$author$project$Session$recipes(session));
		var allHealthIssues = A2(
			$elm$core$List$concatMap,
			A2(
				$elm$core$Basics$composeL,
				$author$project$Dog$HealthIssues$toList,
				function ($) {
					return $.bw;
				}),
			dogs);
		var edibleRecipes = A2($elm$core$List$any, $author$project$Dog$HealthIssues$lowFatRecommendedFor, allHealthIssues) ? lowFatRecipes : $author$project$Session$recipes(session);
		var allAllergies = $author$project$Dog$getAllAllergies(dogs);
		var allergicTo = function (recipe) {
			return A2($author$project$Allergy$allergicToRecipe, allAllergies, recipe);
		};
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, allergicTo),
			edibleRecipes);
	});
var $author$project$Page$Checkout$recordInteraction = F2(
	function (formField, model) {
		var fieldsInteractedWith = model.U;
		var submissionErrors = A2(
			$elm$core$List$filter,
			function (_v1) {
				var f = _v1.a;
				return !_Utils_eq(f, formField);
			},
			model.V);
		var newFieldsInteractedWith = function () {
			switch (formField) {
				case 0:
					return _Utils_update(
						fieldsInteractedWith,
						{b3: true});
				case 1:
					return _Utils_update(
						fieldsInteractedWith,
						{d_: true});
				case 2:
					return _Utils_update(
						fieldsInteractedWith,
						{o: true});
				case 3:
					return _Utils_update(
						fieldsInteractedWith,
						{y: true});
				case 4:
					return _Utils_update(
						fieldsInteractedWith,
						{e: true});
				case 5:
					return _Utils_update(
						fieldsInteractedWith,
						{A: true});
				case 6:
					return _Utils_update(
						fieldsInteractedWith,
						{m: true});
				case 7:
					return _Utils_update(
						fieldsInteractedWith,
						{bR: true});
				case 8:
					return _Utils_update(
						fieldsInteractedWith,
						{bS: true});
				case 9:
					return _Utils_update(
						fieldsInteractedWith,
						{cK: true});
				case 10:
					return _Utils_update(
						fieldsInteractedWith,
						{B: true});
				case 11:
					return _Utils_update(
						fieldsInteractedWith,
						{w: true});
				case 12:
					return _Utils_update(
						fieldsInteractedWith,
						{u: true});
				case 13:
					return _Utils_update(
						fieldsInteractedWith,
						{v: true});
				default:
					return _Utils_update(
						fieldsInteractedWith,
						{t: true});
			}
		}();
		return _Utils_update(
			model,
			{U: newFieldsInteractedWith, V: submissionErrors});
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$SignUp$attemptLater = _Platform_outgoingPort('attemptLater', $elm$core$Basics$identity);
var $author$project$SignUp$cardEncoder = function (card) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'number',
				$elm$json$Json$Encode$string(card.w)),
				_Utils_Tuple2(
				'expiryYear',
				$elm$json$Json$Encode$string(card.a1)),
				_Utils_Tuple2(
				'expiryMonth',
				$elm$json$Json$Encode$string(card.a0)),
				_Utils_Tuple2(
				'cvc',
				$elm$json$Json$Encode$string(card.aX)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(card.cg))
			]));
};
var $author$project$SignUp$retryLater = function (_v0) {
	var acquisition = _v0;
	return $author$project$SignUp$attemptLater(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'card',
					$author$project$SignUp$cardEncoder(
						$author$project$SignUp$buildCard(acquisition.dm))),
					_Utils_Tuple2(
					'payloadAwaitingStripeToken',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user',
								$author$project$SignUp$encodeUser(acquisition)),
								_Utils_Tuple2(
								'target_first_delivery_date',
								$elm$json$Json$Encode$int(
									$elm$time$Time$posixToMillis(acquisition.dm.e))),
								_Utils_Tuple2(
								'target_plan_id',
								$elm$json$Json$Encode$int(acquisition.r.a3))
							])))
				])));
};
var $author$project$Page$Checkout$errorPriority = function (field) {
	switch (field) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		case 11:
			return 11;
		case 12:
			return 12;
		case 13:
			return 13;
		default:
			return 14;
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Checkout$idForFormField = function (field) {
	switch (field) {
		case 0:
			return 'checkout-input-first-name';
		case 1:
			return 'checkout-input-last-name';
		case 2:
			return 'checkout-input-email';
		case 3:
			return 'checkout-input-email-confirmation';
		case 4:
			return 'checkout-input-first-delivery-date';
		case 5:
			return 'checkout-input-password';
		case 6:
			return 'checkout-input-postcode';
		case 7:
			return 'checkout-input-address1';
		case 8:
			return 'checkout-input-address2';
		case 9:
			return 'checkout-input-town';
		case 10:
			return 'checkout-input-phone-number';
		case 11:
			return 'checkout-input-card-number';
		case 12:
			return 'checkout-input-card-exp-month';
		case 13:
			return 'checkout-input-card-exp-year';
		default:
			return 'checkout-input-card-cvc';
	}
};
var $author$project$Page$Checkout$ScrollComplete = {$: 23};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$Page$Checkout$scrollToId = function (targetId) {
	return A2(
		$elm$core$Task$attempt,
		$elm$core$Basics$always($author$project$Page$Checkout$ScrollComplete),
		A2(
			$elm$core$Task$andThen,
			function (e) {
				return A2($elm$browser$Browser$Dom$setViewport, 0, e.dx.fe - 150);
			},
			$elm$browser$Browser$Dom$getElement(targetId)));
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Page$Checkout$scrollToFirstError = function (errors) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeL, $author$project$Page$Checkout$scrollToId, $author$project$Page$Checkout$idForFormField),
			$elm$core$List$head(
				A2(
					$elm$core$List$sortBy,
					$author$project$Page$Checkout$errorPriority,
					A2($elm$core$List$map, $elm$core$Tuple$first, errors)))));
};
var $author$project$Address$NetworkError = 0;
var $author$project$Address$Address = $elm$core$Basics$identity;
var $author$project$Address$decoder = function () {
	var onSuccess = F3(
		function (town_, address1_, address2_) {
			return {bR: address1_, bS: address2_, cK: town_};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'address2',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'address1',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'town',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed(onSuccess))));
}();
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.eR));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cv: reqs, cH: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.eX;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cv));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.cH)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					cP: r.cP,
					c_: r.c_,
					dz: A2(_Http_mapExpect, func, r.dz),
					dM: r.dM,
					d8: r.d8,
					eV: r.eV,
					eX: r.eX,
					e4: r.e4
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{cP: false, c_: r.c_, dz: r.dz, dM: r.dM, d8: r.d8, eV: r.eV, eX: r.eX, e4: r.e4}));
};
var $author$project$Address$searchByPostCode = F4(
	function (env, session, toMsg, searchTerm) {
		var url = $author$project$Env$railsURI(env) + ('/point-of-sale/v1/postcodes/search/' + searchTerm);
		var translateMsg = function (result) {
			if (!result.$) {
				var addresses = result.a;
				return toMsg(
					$elm$core$Result$Ok(addresses));
			} else {
				return toMsg(
					$elm$core$Result$Err(0));
			}
		};
		var headers = _List_fromArray(
			[
				A2(
				$elm$http$Http$header,
				'Authorization',
				'Bearer ' + $author$project$Session$apiToken(session))
			]);
		return $elm$http$Http$request(
			{
				c_: $elm$http$Http$emptyBody,
				dz: A2(
					$elm$http$Http$expectJson,
					translateMsg,
					$elm$json$Json$Decode$list($author$project$Address$decoder)),
				dM: headers,
				d8: 'GET',
				eV: $elm$core$Maybe$Just(10000),
				eX: $elm$core$Maybe$Nothing,
				e4: url
			});
	});
var $author$project$SignUp$signInLink = function (_v0) {
	var customer = _v0;
	return customer.aI;
};
var $author$project$Page$Checkout$submissionErrorsFor = function (err) {
	switch (err.$) {
		case 1:
			return _List_fromArray(
				[
					_Utils_Tuple2(2, 'Sorry, that email is already being used. Please try a different one.')
				]);
		case 2:
			return _List_fromArray(
				[
					_Utils_Tuple2(11, 'Oops! Something went wrong trying to charge your card')
				]);
		case 3:
			return _List_Nil;
		default:
			return _List_Nil;
	}
};
var $author$project$Page$Checkout$toggleTooltip = F2(
	function (t, tooltips) {
		if (!t) {
			return _Utils_update(
				tooltips,
				{ac: !tooltips.ac});
		} else {
			return _Utils_update(
				tooltips,
				{G: !tooltips.G});
		}
	});
var $author$project$Address$town = function (_v0) {
	var address = _v0;
	return address.cK;
};
var $author$project$Page$Checkout$updateFormData = F2(
	function (model, formData) {
		return _Utils_update(
			model,
			{
				a: formData,
				ag: A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$Checkout$formDataValidator, formData)
			});
	});
var $author$project$Page$Checkout$updatePostCodeSearchTerm = F2(
	function (searchTerm, model) {
		var postCodeInput = function () {
			var _v0 = model.Q;
			switch (_v0.$) {
				case 0:
					return $author$project$Page$Checkout$ReadyToSearch(searchTerm);
				case 1:
					return $author$project$Page$Checkout$CantSearch(searchTerm);
				case 2:
					return $author$project$Page$Checkout$Searching(searchTerm);
				default:
					var results = _v0.b;
					return A2($author$project$Page$Checkout$HaveSearchResults, searchTerm, results);
			}
		}();
		return _Utils_update(
			model,
			{Q: postCodeInput});
	});
var $author$project$Page$Checkout$update = F2(
	function (msg, model) {
		var formData = model.a;
		switch (msg.$) {
			case 0:
				var today = msg.a;
				var firstDeliveryDate = A2(
					$elm$core$Maybe$withDefault,
					$elm$time$Time$millisToPosix(0),
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.aG;
						},
						A2(
							$elm_community$list_extra$List$Extra$find,
							function (date) {
								return date.b9;
							},
							$author$project$Session$deliveryDates(model.f))));
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$recordInteraction,
						4,
						A2(
							$author$project$Page$Checkout$updateFormData,
							model,
							_Utils_update(
								formData,
								{e: firstDeliveryDate}))),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 23:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$Router$none);
			case 1:
				var firstName = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{b3: firstName})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 2:
				var lastName = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{d_: lastName})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 5:
				var email = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{o: email})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 27:
				var petParentDetails = model.P;
				var newPetParentDetails = _Utils_update(
					petParentDetails,
					{ap: !model.P.ap});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: newPetParentDetails}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 6:
				var emailConfirmation = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{y: emailConfirmation})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 7:
				var firstDeliveryDate = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$recordInteraction,
						4,
						A2(
							$author$project$Page$Checkout$updateFormData,
							model,
							_Utils_update(
								formData,
								{e: firstDeliveryDate}))),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 8:
				var password = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{A: password})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 9:
				var postCode = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updatePostCodeSearchTerm,
						postCode,
						A2(
							$author$project$Page$Checkout$updateFormData,
							model,
							_Utils_update(
								formData,
								{m: postCode}))),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 10:
				var address1 = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{bR: address1})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 11:
				var address2 = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{bS: address2})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 12:
				var town = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{cK: town})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 13:
				var phoneNumber = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{B: phoneNumber})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 14:
				var cardNumber = msg.a;
				var newCardNumber = A3($elm$core$String$replace, ' ', '', cardNumber);
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{w: newCardNumber})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 15:
				var month = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{u: month})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 16:
				var year = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{v: year})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 17:
				var cardCVC = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$updateFormData,
						model,
						_Utils_update(
							formData,
							{t: cardCVC})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 21:
				var formField = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Page$Checkout$recordInteraction, formField, model),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 22:
				var t = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Page$Checkout$toggleTooltip, t, model),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 4:
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$recordInteraction,
						4,
						_Utils_update(
							model,
							{l: $author$project$Page$Checkout$Closed})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							l: $author$project$Page$Checkout$Open(0)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 18:
				var validatedFormData = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$Checkout$formDataValidator, model.a);
				var requestInFlight = function () {
					if (!validatedFormData.$) {
						return true;
					} else {
						return false;
					}
				}();
				var recipes = A2($author$project$Dog$recipesFor, model.f, model.j);
				var cmd = function () {
					if (!validatedFormData.$) {
						var validFormData = validatedFormData.a;
						return A7($author$project$Page$Checkout$checkout, model.c, model.f, validFormData, model.j, model.Y, model.P.ap, recipes);
					} else {
						var errors = validatedFormData.a;
						return $author$project$Page$Checkout$scrollToFirstError(errors);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{I: requestInFlight, U: $author$project$Page$Checkout$interactedWithEverything, ag: validatedFormData}),
					cmd,
					$author$project$Page$Router$none);
			case 19:
				if (!msg.a.$) {
					var customer = msg.a.a;
					var signInLink = $author$project$SignUp$signInLink(customer);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{I: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$SignUp$persist(customer),
									A2(
									$author$project$Monitoring$send,
									model.f,
									$author$project$Monitoring$SignUpResult($author$project$Monitoring$EndpointSuccess))
								])),
						A6(
							$author$project$Page$Router$OrderConfirmation,
							model.f,
							model.j,
							model.a.e,
							model.Y,
							true,
							$elm$core$Maybe$Just(signInLink)));
				} else {
					if (!msg.a.a.$) {
						var offlineAcquisition = msg.a.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{I: false}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$SignUp$retryLater(offlineAcquisition),
										A2(
										$author$project$Monitoring$send,
										model.f,
										$author$project$Monitoring$SignUpResult(
											$author$project$Monitoring$EndpointFailure(2)))
									])),
							A6($author$project$Page$Router$OrderConfirmation, model.f, model.j, model.a.e, model.Y, false, $elm$core$Maybe$Nothing));
					} else {
						var err = msg.a.a;
						var errors = $author$project$Page$Checkout$submissionErrorsFor(err);
						var endpointEvent = function () {
							switch (err.$) {
								case 1:
									return 0;
								case 2:
									return 1;
								case 3:
									return 2;
								default:
									return 2;
							}
						}();
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{I: false, V: errors}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$author$project$Monitoring$send,
										model.f,
										$author$project$Monitoring$SignUpResult(
											$author$project$Monitoring$EndpointFailure(endpointEvent))),
										$author$project$Page$Checkout$scrollToFirstError(errors)
									])),
							$author$project$Page$Router$none);
					}
				}
			case 20:
				var _v4 = model.aC;
				var all = _v4.aO;
				var enabled = _v4.a$;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A3($author$project$Page$Router$BackToSeePlans, model.f, all, enabled));
			case 24:
				var searchTerm = msg.a;
				var validatedPostCode = A2(
					$rtfeldman$elm_validate$Validate$validate,
					$author$project$PostCode$validator(6),
					model.a);
				var startSearchIfValid = function (x) {
					if (!validatedPostCode.$) {
						return _Utils_Tuple2(
							_Utils_update(
								x,
								{
									Q: $author$project$Page$Checkout$Searching(searchTerm)
								}),
							A4($author$project$Address$searchByPostCode, model.c, model.f, $author$project$Page$Checkout$GotPostCodeSearchResults, searchTerm));
					} else {
						return _Utils_Tuple2(x, $elm$core$Platform$Cmd$none);
					}
				};
				var _v5 = startSearchIfValid(
					A2($author$project$Page$Checkout$recordInteraction, 6, model));
				var newModel = _v5.a;
				var cmd = _v5.b;
				return _Utils_Tuple3(newModel, cmd, $author$project$Page$Router$none);
			case 25:
				var result = msg.a;
				var postCodeInput = function () {
					if (!result.$) {
						var addresses = result.a;
						return A2($author$project$Page$Checkout$HaveSearchResults, model.a.m, addresses);
					} else {
						return $author$project$Page$Checkout$CantSearch(model.a.m);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Q: postCodeInput}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			default:
				var address = msg.a;
				var newFormData = _Utils_update(
					formData,
					{
						bR: $author$project$Address$address1(address),
						bS: $author$project$Address$address2(address),
						cK: $author$project$Address$town(address)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Checkout$recordInteraction,
						8,
						A2(
							$author$project$Page$Checkout$recordInteraction,
							7,
							A2(
								$author$project$Page$Checkout$updateFormData,
								_Utils_update(
									model,
									{
										Q: $author$project$Page$Checkout$ReadyToSearch(formData.m)
									}),
								newFormData))),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
		}
	});
var $author$project$Page$ChoosePlan$AccountBenefits = {$: 1};
var $author$project$Page$ChoosePlan$BoxContents = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Router$SeeMeals = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Router$StartCheckout = F4(
	function (a, b, c, d) {
		return {$: 9, a: a, b: b, c: c, d: d};
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Page$ChoosePlan$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var id = msg.a;
				var newEnabledDogs = A2($elm$core$Set$member, id, model.F) ? A2($elm$core$Set$remove, id, model.F) : A2($elm$core$Set$insert, id, model.F);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{F: newEnabledDogs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 1:
				var plan = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A4($author$project$Page$Router$StartCheckout, model.f, model.j, model.F, plan));
			case 6:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2($author$project$Page$Router$SeeMeals, model.f, model.j));
			case 3:
				var plan = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							J: A2(
								$author$project$Page$ChoosePlan$BoxContents,
								plan,
								A2($author$project$Dog$recipesFor, model.f, model.j))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{J: $author$project$Page$ChoosePlan$Plans}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{J: $author$project$Page$ChoosePlan$AccountBenefits}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{J: $author$project$Page$ChoosePlan$Plans}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
		}
	});
var $author$project$Page$Router$Login = function (a) {
	return {$: 1, a: a};
};
var $author$project$Monitoring$LoginSuccessful = {$: 0};
var $author$project$Page$Login$LoginResult = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Login$HttpError = 4;
var $author$project$Page$Login$NetworkError = 0;
var $author$project$Page$Login$SessionDecodeError = 3;
var $author$project$Dog$FoodCategory$serverDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (foodCategoryStr) {
		switch (foodCategoryStr) {
			case 'dry':
				return $elm$json$Json$Decode$succeed(0);
			case 'wet':
				return $elm$json$Json$Decode$succeed(1);
			case 'raw':
				return $elm$json$Json$Decode$succeed(2);
			case 'home_cooked':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Unsupported FoodCategory: ' + foodCategoryStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Dog$FoodBrands$serverDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'category',
	$author$project$Dog$FoodCategory$serverDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Dog$FoodBrands$FoodBrand))));
var $author$project$Session$brandsFromServerDecoder = function () {
	var otherDecoder = A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dry',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'wet',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'raw',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed(
					F3(
						function (raw, wet, dry) {
							return {dv: dry, ey: raw, fa: wet};
						})))));
	var onSuccess = F3(
		function (all, other, homecooked) {
			return $author$project$Dog$FoodBrands$init(
				{aO: all, bx: homecooked, aq: other});
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'homecooked',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'other',
			otherDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'all',
				$elm$json$Json$Decode$list($author$project$Dog$FoodBrands$serverDecoder),
				$elm$json$Json$Decode$succeed(onSuccess))));
}();
var $author$project$DiscountCode$serverBasisDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (basisInt) {
		switch (basisInt) {
			case 0:
				return $elm$json$Json$Decode$succeed(0);
			case 1:
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail(
					'Unsupported Basis from server: ' + $elm$core$String$fromInt(basisInt));
		}
	},
	$elm$json$Json$Decode$int);
var $author$project$DiscountCode$serverDecoder = function () {
	var onSuccess = F5(
		function (id_, code_, basis, value_, n) {
			return {aP: basis, aU: code_, a3: id_, ba: n, av: value_};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'value',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'discount_basis',
				$author$project$DiscountCode$serverBasisDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'code',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed(onSuccess))))));
}();
var $author$project$Session$discountCodesFromServerDecoder = function () {
	var onSuccess = F2(
		function (_default, all) {
			return {_: _default, aO: all, aA: _default};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'all',
		$elm$json$Json$Decode$list($author$project$DiscountCode$serverDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'default',
			$author$project$DiscountCode$serverDecoder,
			$elm$json$Json$Decode$succeed(onSuccess)));
}();
var $author$project$Session$salesRepFromServerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'last_name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'first_name',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Session$SalesRep)));
var $author$project$Allergy$serverDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (allergyStr) {
		switch (allergyStr) {
			case 'beef':
				return $elm$json$Json$Decode$succeed(0);
			case 'chicken':
				return $elm$json$Json$Decode$succeed(1);
			case 'turkey':
				return $elm$json$Json$Decode$succeed(2);
			case 'lamb':
				return $elm$json$Json$Decode$succeed(3);
			case 'fish':
				return $elm$json$Json$Decode$succeed(4);
			case 'pork':
				return $elm$json$Json$Decode$succeed(5);
			case 'duck':
				return $elm$json$Json$Decode$succeed(6);
			case 'venison':
				return $elm$json$Json$Decode$succeed(7);
			case 'wild_boar':
				return $elm$json$Json$Decode$succeed(8);
			case 'guinea_fowl':
				return $elm$json$Json$Decode$succeed(9);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Allergy: ' + allergyStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$DeliveryDate$serverDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'is_deliverable',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'posix',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$DeliveryDate$DeliveryDate)));
var $author$project$Plan$serverPlanTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (planTypeStr) {
		switch (planTypeStr) {
			case 'all_bb':
				return $elm$json$Json$Decode$succeed(0);
			case 'mix_bb':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unsupported PlanType from server: ' + planTypeStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Plan$serverDecoder = function () {
	var onSuccess = F6(
		function (id, pouchMass, planType, pricePerPouch, deliveryInterval, numberOfPouches) {
			return {dq: deliveryInterval, a3: id, ej: numberOfPouches, cn: planType, ev: pouchMass, as: pricePerPouch};
		});
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'number_of_pouches',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'duration_in_days',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'price_per_pouch_cents',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'box_kind',
					$author$project$Plan$serverPlanTypeDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'size_in_grams',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed(onSuccess)))))));
}();
var $author$project$Recipe$serverDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (recipeStr) {
		switch (recipeStr) {
			case 'beef':
				return $elm$json$Json$Decode$succeed(0);
			case 'chicken':
				return $elm$json$Json$Decode$succeed(1);
			case 'turkey':
				return $elm$json$Json$Decode$succeed(2);
			case 'lamb':
				return $elm$json$Json$Decode$succeed(3);
			case 'white_fish':
				return $elm$json$Json$Decode$succeed(4);
			case 'pork':
				return $elm$json$Json$Decode$succeed(5);
			case 'duck_and_chicken':
				return $elm$json$Json$Decode$succeed(6);
			default:
				return $elm$json$Json$Decode$fail('Unsupported Recipe: ' + recipeStr);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$UndeliverableDate$serverDecoder = $author$project$UndeliverableDate$decoder;
var $author$project$Session$decodeFromServer = F2(
	function (jwt, json) {
		var onSuccess = F9(
			function (salesRep_, breeds_, brands_, codes, plans_, deliveryDates_, undeliverableDates_, recipes_, allergies_) {
				return {bp: allergies_, T: brands_, aQ: breeds_, aZ: deliveryDates_, E: codes, a8: jwt, be: plans_, eA: recipes_, aH: salesRep_, bn: undeliverableDates_};
			});
		var decoderGivenJwt = A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'allergies',
			$elm$json$Json$Decode$list($author$project$Allergy$serverDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'recipes',
				$elm$json$Json$Decode$list($author$project$Recipe$serverDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'undeliverable_dates',
					$elm$json$Json$Decode$list($author$project$UndeliverableDate$serverDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'delivery_window',
						$elm$json$Json$Decode$list($author$project$DeliveryDate$serverDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'plans',
							$elm$json$Json$Decode$list($author$project$Plan$serverDecoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'discount_codes',
								$author$project$Session$discountCodesFromServerDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'brands',
									$author$project$Session$brandsFromServerDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'breeds',
										$author$project$Dog$Breeds$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'sales_rep',
											$author$project$Session$salesRepFromServerDecoder,
											$elm$json$Json$Decode$succeed(onSuccess))))))))));
		return A2($elm$json$Json$Decode$decodeString, decoderGivenJwt, json);
	});
var $author$project$Page$Login$bootstrap = F2(
	function (env, jwt) {
		var url = $author$project$Env$railsURI(env) + '/point-of-sale/v1/bootstrap';
		var headers = _List_fromArray(
			[
				A2(
				$elm$http$Http$header,
				'Authorization',
				'Bearer ' + $author$project$Jwt$authToken(jwt))
			]);
		var decodeSession = function (response) {
			switch (response.$) {
				case 4:
					var body = response.b;
					return A2(
						$elm$core$Result$mapError,
						function (_v1) {
							return 3;
						},
						A2($author$project$Session$decodeFromServer, jwt, body));
				case 2:
					return $elm$core$Result$Err(0);
				default:
					return $elm$core$Result$Err(4);
			}
		};
		return $elm$http$Http$task(
			{
				c_: $elm$http$Http$emptyBody,
				dM: headers,
				d8: 'GET',
				cx: $elm$http$Http$stringResolver(decodeSession),
				eV: $elm$core$Maybe$Nothing,
				e4: url
			});
	});
var $author$project$Page$Login$JwtDecodeError = 2;
var $author$project$Page$Login$Unauthorized = 1;
var $author$project$Page$Login$createJwt = F3(
	function (env, email, password) {
		var url = $author$project$Env$railsURI(env) + '/point-of-sale/v1/login';
		var encodedAuth = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'auth',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'email',
								$elm$json$Json$Encode$string(email)),
								_Utils_Tuple2(
								'password',
								$elm$json$Json$Encode$string(password))
							])))
				]));
		var decodeJwt = function (response) {
			switch (response.$) {
				case 4:
					var str = response.b;
					return A2(
						$elm$core$Result$mapError,
						function (_v1) {
							return 2;
						},
						A2($elm$json$Json$Decode$decodeString, $author$project$Jwt$decoder, str));
				case 2:
					return $elm$core$Result$Err(0);
				case 3:
					var statusCode = response.a.eR;
					return (statusCode === 404) ? $elm$core$Result$Err(1) : $elm$core$Result$Err(4);
				default:
					return $elm$core$Result$Err(4);
			}
		};
		var body = $elm$http$Http$jsonBody(encodedAuth);
		return $elm$http$Http$task(
			{
				c_: body,
				dM: _List_Nil,
				d8: 'POST',
				cx: $elm$http$Http$stringResolver(decodeJwt),
				eV: $elm$core$Maybe$Nothing,
				e4: url
			});
	});
var $author$project$Page$Login$attemptLogin = F3(
	function (env, email, password) {
		return A2(
			$elm$core$Task$attempt,
			$author$project$Page$Login$LoginResult,
			A2(
				$elm$core$Task$andThen,
				$author$project$Page$Login$bootstrap(env),
				A3($author$project$Page$Login$createJwt, env, email, password)));
	});
var $author$project$Page$Login$offlineAuthToken = _Platform_outgoingPort('offlineAuthToken', $elm$json$Json$Encode$string);
var $author$project$Page$Login$setOfflineAuthToken = function (session) {
	return $author$project$Page$Login$offlineAuthToken(
		$author$project$Session$apiToken(session));
};
var $author$project$Page$Login$update = F2(
	function (msg, _v0) {
		var env = _v0.a;
		var model = _v0.b;
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Login$Model,
						env,
						_Utils_update(
							model,
							{o: email, an: $elm$core$Maybe$Nothing})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 1:
				var password = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Login$Model,
						env,
						_Utils_update(
							model,
							{an: $elm$core$Maybe$Nothing, A: password})),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Router$none);
			case 2:
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Login$Model,
						env,
						_Utils_update(
							model,
							{ad: true})),
					A3($author$project$Page$Login$attemptLogin, env, model.o, model.A),
					$author$project$Page$Router$none);
			default:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple3(
						A2(
							$author$project$Page$Login$Model,
							env,
							_Utils_update(
								model,
								{
									ad: false,
									f: $elm$core$Maybe$Just(session)
								})),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Page$Login$setOfflineAuthToken(session),
									A2($author$project$Monitoring$send, session, $author$project$Monitoring$LoginSuccessful)
								])),
						$author$project$Page$Router$Login(session));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple3(
						A2(
							$author$project$Page$Login$Model,
							env,
							_Utils_update(
								model,
								{
									an: $elm$core$Maybe$Just(error),
									ad: false
								})),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Router$none);
				}
		}
	});
var $author$project$Page$Router$SeePlans = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$Meals$update = F2(
	function (msg, model) {
		if (!msg) {
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				A2($author$project$Page$Router$SeePlans, model.f, model.j));
		} else {
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$Router$none);
		}
	});
var $author$project$Page$Router$ViewReceipt = F5(
	function (a, b, c, d, e) {
		return {$: 11, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Page$OrderConfirmation$update = F2(
	function (msg, model) {
		return A5($author$project$Page$Router$ViewReceipt, model.f, model.j, model.e, model.r, model.aI);
	});
var $author$project$Page$Router$GoHome = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Receipt$update = F2(
	function (msg, model) {
		return $author$project$Page$Router$GoHome(model.f);
	});
var $author$project$Page$Router$StartWizard = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Welcome$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$none,
			$author$project$Page$Router$StartWizard(model.f));
	});
var $author$project$Page$Router$CalculatePlan = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Page$Wizard$Open = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wizard$ViewportReset = {$: 17};
var $author$project$Page$Wizard$resetViewport = A2(
	$elm$core$Task$perform,
	function (_v0) {
		return $author$project$Page$Wizard$ViewportReset;
	},
	A2($elm$browser$Browser$Dom$setViewport, 0, 0));
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var y = xs.a;
			var ys = xs.b;
			return _Utils_eq(x, y) ? ys : A2(
				$elm$core$List$cons,
				y,
				A2($elm_community$list_extra$List$Extra$remove, x, ys));
		}
	});
var $author$project$Page$Wizard$AnyAllergies$toggleAllergy = F2(
	function (allergy, dog) {
		var allergies = A2($elm$core$Maybe$withDefault, _List_Nil, dog.bp);
		var newAllergies = A2($elm$core$List$member, allergy, allergies) ? A2($elm_community$list_extra$List$Extra$remove, allergy, allergies) : _Utils_ap(
			allergies,
			_List_fromArray(
				[allergy]));
		return _Utils_update(
			dog,
			{
				bp: $elm$core$Maybe$Just(newAllergies)
			});
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $author$project$Page$Wizard$AnyAllergies$update = F2(
	function (msg, dogs) {
		if (msg.$ === 1) {
			var id = msg.a;
			return A3(
				$elm_community$list_extra$List$Extra$updateAt,
				id,
				function (dog) {
					return _Utils_update(
						dog,
						{
							bp: $elm$core$Maybe$Just(_List_Nil)
						});
				},
				dogs);
		} else {
			var id = msg.a;
			var allergy = msg.b;
			return A3(
				$elm_community$list_extra$List$Extra$updateAt,
				id,
				$author$project$Page$Wizard$AnyAllergies$toggleAllergy(allergy),
				dogs);
		}
	});
var $author$project$Dog$HealthIssues$none = $author$project$Dog$HealthIssues$None;
var $author$project$Dog$HealthIssues$some = $author$project$Dog$HealthIssues$Some(_List_Nil);
var $author$project$Dog$HealthIssues$toggle = F2(
	function (issue, healthIssues) {
		if (!healthIssues.$) {
			return $author$project$Dog$HealthIssues$Some(
				_List_fromArray(
					[issue]));
		} else {
			var issues = healthIssues.a;
			return A2($elm$core$List$member, issue, issues) ? $author$project$Dog$HealthIssues$Some(
				A2($elm_community$list_extra$List$Extra$remove, issue, issues)) : $author$project$Dog$HealthIssues$Some(
				A2($elm$core$List$cons, issue, issues));
		}
	});
var $author$project$Page$Wizard$AnyHealthIssues$toggleIssue = F2(
	function (issue, maybeIssues) {
		return A2(
			$author$project$Dog$HealthIssues$toggle,
			issue,
			A2($elm$core$Maybe$withDefault, $author$project$Dog$HealthIssues$none, maybeIssues));
	});
var $author$project$Page$Wizard$AnyHealthIssues$update = F2(
	function (msg, dogs) {
		if (!msg.$) {
			var id = msg.a;
			var hasIssues = msg.b;
			var healthIssues = hasIssues ? $author$project$Dog$HealthIssues$some : $author$project$Dog$HealthIssues$none;
			return A3(
				$elm_community$list_extra$List$Extra$updateAt,
				id,
				function (dog) {
					return _Utils_update(
						dog,
						{
							bw: $elm$core$Maybe$Just(healthIssues)
						});
				},
				dogs);
		} else {
			var id = msg.a;
			var issue = msg.b;
			return A3(
				$elm_community$list_extra$List$Extra$updateAt,
				id,
				function (dog) {
					return _Utils_update(
						dog,
						{
							bw: $elm$core$Maybe$Just(
								A2($author$project$Page$Wizard$AnyHealthIssues$toggleIssue, issue, dog.bw))
						});
				},
				dogs);
		}
	});
var $author$project$Page$Wizard$HaveBeenDeSexed$update = F2(
	function (msg, dogs) {
		var id = msg.a;
		var isDeSexed = msg.b;
		return A3(
			$elm_community$list_extra$List$Extra$updateAt,
			id,
			function (dog) {
				return _Utils_update(
					dog,
					{
						b1: $elm$core$Maybe$Just(isDeSexed)
					});
			},
			dogs);
	});
var $author$project$Page$Wizard$HowManyDogs$Open = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$init = function (items) {
	if (!items.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var nonEmptyList = items;
		return A2(
			$elm$core$Maybe$map,
			$elm$core$List$reverse,
			$elm$core$List$tail(
				$elm$core$List$reverse(nonEmptyList)));
	}
};
var $author$project$Page$Wizard$HowManyDogs$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var modal = msg.a;
				return _Utils_update(
					model,
					{
						l: $author$project$Page$Wizard$HowManyDogs$Open(modal)
					});
			case 2:
				return _Utils_update(
					model,
					{l: $author$project$Page$Wizard$HowManyDogs$Closed});
			case 0:
				return _Utils_update(
					model,
					{
						j: _Utils_ap(
							model.j,
							_List_fromArray(
								[$author$project$Page$Wizard$HowManyDogs$emptyDog]))
					});
			case 3:
				var dogs = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm_community$list_extra$List$Extra$init(model.j));
				return _Utils_update(
					model,
					{j: dogs, l: $author$project$Page$Wizard$HowManyDogs$Closed});
			case 4:
				var id = msg.a;
				var name = msg.b;
				var newName = (name === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(name);
				var dogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{cg: newName});
					},
					model.j);
				return _Utils_update(
					model,
					{j: dogs});
			default:
				var id = msg.a;
				var sex = msg.b;
				var dogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{
								cB: $elm$core$Maybe$Just(sex)
							});
					},
					model.j);
				return _Utils_update(
					model,
					{j: dogs});
		}
	});
var $author$project$Page$Wizard$HowOld$parseAndCheckBounds = F3(
	function (lowerBound, upperBound, str) {
		var boundsCheck = function (x) {
			return ((_Utils_cmp(x, lowerBound) > -1) && (_Utils_cmp(x, upperBound) < 1)) ? $elm$core$Maybe$Just(x) : $elm$core$Maybe$Nothing;
		};
		return A2(
			$elm$core$Maybe$andThen,
			boundsCheck,
			$elm$core$String$toInt(str));
	});
var $author$project$Page$Wizard$HowOld$update = F2(
	function (msg, dogs) {
		if (!msg.$) {
			var id = msg.a;
			var yearsOldStr = msg.b;
			if (yearsOldStr === '') {
				return A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{cN: $elm$core$Maybe$Nothing});
					},
					dogs);
			} else {
				var maybeYearsOld = A3($author$project$Page$Wizard$HowOld$parseAndCheckBounds, 0, 100, yearsOldStr);
				if (!maybeYearsOld.$) {
					var yearsOld = maybeYearsOld.a;
					return A3(
						$elm_community$list_extra$List$Extra$updateAt,
						id,
						function (dog) {
							return _Utils_update(
								dog,
								{
									cN: $elm$core$Maybe$Just(yearsOld)
								});
						},
						dogs);
				} else {
					return dogs;
				}
			}
		} else {
			var id = msg.a;
			var monthsOldStr = msg.b;
			if (monthsOldStr === '') {
				return A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{ce: $elm$core$Maybe$Nothing});
					},
					dogs);
			} else {
				var maybeMonthsOld = A3($author$project$Page$Wizard$HowOld$parseAndCheckBounds, 0, 11, monthsOldStr);
				if (!maybeMonthsOld.$) {
					var monthsOld = maybeMonthsOld.a;
					return A3(
						$elm_community$list_extra$List$Extra$updateAt,
						id,
						function (dog) {
							return _Utils_update(
								dog,
								{
									ce: $elm$core$Maybe$Just(monthsOld)
								});
						},
						dogs);
				} else {
					return dogs;
				}
			}
		}
	});
var $author$project$Page$Wizard$WhatActivityLevel$update = F2(
	function (msg, dogs) {
		if (!msg.$) {
			var id = msg.a;
			var toggleWorkingDog = function (dog) {
				return _Utils_eq(
					dog.bP,
					$elm$core$Maybe$Just(5)) ? _Utils_update(
					dog,
					{bP: $elm$core$Maybe$Nothing}) : _Utils_update(
					dog,
					{
						bP: $elm$core$Maybe$Just(5)
					});
			};
			return A3($elm_community$list_extra$List$Extra$updateAt, id, toggleWorkingDog, dogs);
		} else {
			var id = msg.a;
			var activityLevel = msg.b;
			return A3(
				$elm_community$list_extra$List$Extra$updateAt,
				id,
				function (dog) {
					return _Utils_update(
						dog,
						{
							bP: $elm$core$Maybe$Just(activityLevel)
						});
				},
				dogs);
		}
	});
var $author$project$Page$Wizard$WhatBodyCondition$update = F2(
	function (msg, dogs) {
		var id = msg.a;
		var bodyCondition = msg.b;
		return A3(
			$elm_community$list_extra$List$Extra$updateAt,
			id,
			function (dog) {
				return _Utils_update(
					dog,
					{
						bU: $elm$core$Maybe$Just(bodyCondition)
					});
			},
			dogs);
	});
var $author$project$Page$Wizard$WhatBreed$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var id = msg.a;
			var searchTerm = msg.b;
			var unsetBreedIfChanged = function (dog) {
				return _Utils_eq(
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.cg;
						},
						dog.bV),
					$elm$core$Maybe$Just(searchTerm)) ? dog : _Utils_update(
					dog,
					{bV: $elm$core$Maybe$Nothing});
			};
			var breedInputValues = A3($elm$core$Dict$insert, id, searchTerm, model.aa);
			return _Utils_update(
				model,
				{
					aa: breedInputValues,
					j: A3($elm_community$list_extra$List$Extra$updateAt, id, unsetBreedIfChanged, model.j)
				});
		} else {
			var id = msg.a;
			var breed = msg.b;
			return _Utils_update(
				model,
				{
					aa: A3($elm$core$Dict$insert, id, breed.cg, model.aa),
					j: A3(
						$elm_community$list_extra$List$Extra$updateAt,
						id,
						function (dog) {
							return _Utils_update(
								dog,
								{
									bV: $elm$core$Maybe$Just(breed)
								});
						},
						model.j)
				});
		}
	});
var $author$project$Page$Wizard$WhatCurrentFoods$deselectFood = F3(
	function (brands, food, dog) {
		var foods = A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$);
		var newFoods = A2($elm_community$list_extra$List$Extra$remove, food, foods);
		var isMember = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (brand) {
					return A2($elm$core$List$member, brand.aj, newFoods);
				},
				dog.b_));
		var newCurrentBrand = isMember ? dog.b_ : (_Utils_eq(
			newFoods,
			_List_fromArray(
				[3])) ? $elm$core$Maybe$Just(
			$author$project$Dog$FoodBrands$homecooked(brands)) : $elm$core$Maybe$Nothing);
		return _Utils_update(
			dog,
			{
				b_: newCurrentBrand,
				b$: $elm$core$Maybe$Just(newFoods)
			});
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Page$Wizard$WhatCurrentFoods$selectFood = F3(
	function (brands, food, dog) {
		var homecookedBrand = $elm$core$Maybe$Just(
			$author$project$Dog$FoodBrands$homecooked(brands));
		var foods = A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$);
		var newFoods = _Utils_ap(
			foods,
			_List_fromArray(
				[food]));
		var newCurrentBrand = _Utils_eq(
			newFoods,
			_List_fromArray(
				[3])) ? homecookedBrand : (_Utils_eq(dog.b_, homecookedBrand) ? $elm$core$Maybe$Nothing : dog.b_);
		return _Utils_update(
			dog,
			{
				b_: newCurrentBrand,
				b$: $elm$core$Maybe$Just(newFoods)
			});
	});
var $author$project$Page$Wizard$WhatCurrentFoods$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var id = msg.a;
				var searchTerm = msg.b;
				var unsetBrandIfChanged = function (dog) {
					return _Utils_eq(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.cg;
							},
							dog.b_),
						$elm$core$Maybe$Just(searchTerm)) ? dog : _Utils_update(
						dog,
						{b_: $elm$core$Maybe$Nothing});
				};
				var brandInputValues = A3($elm$core$Dict$insert, id, searchTerm, model.p);
				return _Utils_update(
					model,
					{
						p: brandInputValues,
						j: A3($elm_community$list_extra$List$Extra$updateAt, id, unsetBrandIfChanged, model.j)
					});
			case 0:
				var id = msg.a;
				var food = msg.b;
				var brands = msg.c;
				return _Utils_update(
					model,
					{
						j: A3(
							$elm_community$list_extra$List$Extra$updateAt,
							id,
							A2($author$project$Page$Wizard$WhatCurrentFoods$selectFood, brands, food),
							model.j)
					});
			case 1:
				var id = msg.a;
				var food = msg.b;
				var brands = msg.c;
				var newDogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					A2($author$project$Page$Wizard$WhatCurrentFoods$deselectFood, brands, food),
					model.j);
				var maybeBrandBefore = A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.b_;
					},
					A2($elm_community$list_extra$List$Extra$getAt, id, model.j));
				var maybeBrandAfter = A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.b_;
					},
					A2($elm_community$list_extra$List$Extra$getAt, id, newDogs));
				var newBrandInputValues = function () {
					var _v1 = _Utils_Tuple2(maybeBrandBefore, maybeBrandAfter);
					if ((!_v1.a.$) && (_v1.b.$ === 1)) {
						var _v2 = _v1.b;
						return A2($elm$core$Dict$remove, id, model.p);
					} else {
						return model.p;
					}
				}();
				return _Utils_update(
					model,
					{p: newBrandInputValues, j: newDogs});
			case 2:
				var id = msg.a;
				var brand = msg.b;
				return _Utils_update(
					model,
					{
						p: A3($elm$core$Dict$insert, id, brand.cg, model.p),
						j: A3(
							$elm_community$list_extra$List$Extra$updateAt,
							id,
							function (dog) {
								return _Utils_update(
									dog,
									{
										b_: $elm$core$Maybe$Just(brand)
									});
							},
							model.j)
					});
			default:
				var id = msg.a;
				var maybeBrandName = A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.cg;
					},
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.b_;
						},
						A2($elm_community$list_extra$List$Extra$getAt, id, model.j)));
				var brandInputValues = function () {
					if (!maybeBrandName.$) {
						var name = maybeBrandName.a;
						return A3($elm$core$Dict$insert, id, name, model.p);
					} else {
						return A2($elm$core$Dict$remove, id, model.p);
					}
				}();
				return _Utils_update(
					model,
					{p: brandInputValues});
		}
	});
var $author$project$Page$Wizard$WhatKindOfEater$update = F2(
	function (msg, dogs) {
		var id = msg.a;
		var fussiness = msg.b;
		return A3(
			$elm_community$list_extra$List$Extra$updateAt,
			id,
			function (dog) {
				return _Utils_update(
					dog,
					{
						b5: $elm$core$Maybe$Just(fussiness)
					});
			},
			dogs);
	});
var $author$project$Page$Wizard$WhatMass$parseGrams = function (massStr) {
	return A2(
		$elm$core$Maybe$andThen,
		function (grams) {
			return ((grams >= 0) && (grams <= 50000)) ? $elm$core$Maybe$Just(grams) : $elm$core$Maybe$Nothing;
		},
		$elm$core$String$toInt(massStr));
};
var $elm$core$Basics$round = _Basics_round;
var $author$project$Dog$Mass$kgToGrams = function (kgs) {
	return $elm$core$Basics$round(kgs * 1000);
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Page$Wizard$WhatMass$parseKilogramsToGrams = function (massStr) {
	if (massStr === '') {
		return $elm$core$Maybe$Just(0);
	} else {
		return A2(
			$elm$core$Maybe$andThen,
			function (grams) {
				return (grams >= 0) ? $elm$core$Maybe$Just(grams) : $elm$core$Maybe$Nothing;
			},
			A2(
				$elm$core$Maybe$map,
				$author$project$Dog$Mass$kgToGrams,
				$elm$core$String$toFloat(massStr)));
	}
};
var $author$project$Page$Wizard$WhatMass$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var id = msg.a;
				var mass = msg.b;
				var massInKg = $author$project$Dog$Mass$gramsToKg(mass);
				var massInputValues = A3(
					$elm$core$Dict$insert,
					id,
					$elm$core$String$fromFloat(massInKg),
					model.L);
				var dogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{
								cc: $elm$core$Maybe$Just(mass)
							});
					},
					model.j);
				return _Utils_update(
					model,
					{j: dogs, L: massInputValues});
			case 1:
				var id = msg.a;
				var gramStr = msg.b;
				var maybeMass = $author$project$Page$Wizard$WhatMass$parseGrams(gramStr);
				var massForInputValues = A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromFloat,
						A2($elm$core$Maybe$map, $author$project$Dog$Mass$gramsToKg, maybeMass)));
				var massInputValues = A3($elm$core$Dict$insert, id, massForInputValues, model.L);
				var dogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{cc: maybeMass});
					},
					model.j);
				return _Utils_update(
					model,
					{j: dogs, L: massInputValues});
			default:
				var id = msg.a;
				var kgStr = msg.b;
				var maybeMass = $author$project$Page$Wizard$WhatMass$parseKilogramsToGrams(kgStr);
				var massInputValues = A3($elm$core$Dict$insert, id, kgStr, model.L);
				var dogs = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					id,
					function (dog) {
						return _Utils_update(
							dog,
							{cc: maybeMass});
					},
					model.j);
				return _Utils_update(
					model,
					{j: dogs, L: massInputValues});
		}
	});
var $author$project$Page$Wizard$WhatSnackingHabits$update = F2(
	function (msg, dogs) {
		var id = msg.a;
		var snackingHabits = msg.b;
		return A3(
			$elm_community$list_extra$List$Extra$updateAt,
			id,
			function (dog) {
				return _Utils_update(
					dog,
					{
						cE: $elm$core$Maybe$Just(snackingHabits)
					});
			},
			dogs);
	});
var $author$project$Page$Wizard$updateCurrentStep = F3(
	function (toStep, model, subModel) {
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{
					n: toStep(subModel)
				}),
			$elm$core$Platform$Cmd$none,
			$author$project$Page$Router$none);
	});
var $author$project$Page$Wizard$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.n);
		_v0$17:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					var _v1 = _v0.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{l: $author$project$Page$Wizard$Closed}),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Router$none);
				case 2:
					var modal = _v0.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								l: $author$project$Page$Wizard$Open(modal)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Router$none);
				case 3:
					if (!_v0.b.$) {
						var _v2 = _v0.a;
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							$author$project$Page$Router$GoHome(model.f));
					} else {
						break _v0$17;
					}
				case 4:
					if (_v0.b.$ === 11) {
						var dogs = _v0.a.a;
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							A3($author$project$Page$Router$CalculatePlan, model.f, model.P, dogs));
					} else {
						break _v0$17;
					}
				case 0:
					var step = _v0.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{n: step, l: $author$project$Page$Wizard$Closed}),
						$author$project$Page$Wizard$resetViewport,
						$author$project$Page$Router$none);
				case 5:
					if (!_v0.b.$) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$HowManyDogs,
							model,
							A2($author$project$Page$Wizard$HowManyDogs$update, subMsg, subModel));
					} else {
						break _v0$17;
					}
				case 6:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatBreed,
							model,
							A2($author$project$Page$Wizard$WhatBreed$update, subMsg, subModel));
					} else {
						break _v0$17;
					}
				case 7:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatCurrentFoods,
							model,
							A2($author$project$Page$Wizard$WhatCurrentFoods$update, subMsg, subModel));
					} else {
						break _v0$17;
					}
				case 8:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatKindOfEater,
							model,
							A2($author$project$Page$Wizard$WhatKindOfEater$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 9:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$HowOld,
							model,
							A2($author$project$Page$Wizard$HowOld$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 10:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$HaveBeenDeSexed,
							model,
							A2($author$project$Page$Wizard$HaveBeenDeSexed$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 11:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatBodyCondition,
							model,
							A2($author$project$Page$Wizard$WhatBodyCondition$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 12:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatMass,
							model,
							A2($author$project$Page$Wizard$WhatMass$update, subMsg, subModel));
					} else {
						break _v0$17;
					}
				case 13:
					if (_v0.b.$ === 8) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatActivityLevel,
							model,
							A2($author$project$Page$Wizard$WhatActivityLevel$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 14:
					if (_v0.b.$ === 9) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$AnyAllergies,
							model,
							A2($author$project$Page$Wizard$AnyAllergies$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 15:
					if (_v0.b.$ === 10) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$AnyHealthIssues,
							model,
							A2($author$project$Page$Wizard$AnyHealthIssues$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				case 16:
					if (_v0.b.$ === 11) {
						var subMsg = _v0.a.a;
						var dogs = _v0.b.a;
						return A3(
							$author$project$Page$Wizard$updateCurrentStep,
							$author$project$Page$Wizard$WhatSnackingHabits,
							model,
							A2($author$project$Page$Wizard$WhatSnackingHabits$update, subMsg, dogs));
					} else {
						break _v0$17;
					}
				default:
					break _v0$17;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$Router$none);
	});
var $author$project$Monitoring$BackToWelcomePage = 1;
var $author$project$Monitoring$CheckoutPage = 6;
var $author$project$Main$GotoPage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Monitoring$MealsPage = 4;
var $author$project$Monitoring$OrderConfirmationPage = 7;
var $author$project$Monitoring$PlansPage = 5;
var $author$project$Monitoring$ReEnterWizard = 3;
var $author$project$Monitoring$ReceiptPage = 8;
var $author$project$Monitoring$WelcomePage = 0;
var $author$project$Page$ChoosePlan$fromCheckout = F4(
	function (env, session, dogs, enabledDogs) {
		return {J: $author$project$Page$ChoosePlan$Plans, j: dogs, F: enabledDogs, c: env, f: session};
	});
var $author$project$Page$Checkout$GotToday = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Checkout$initialFieldsInteractedWith = {bR: false, bS: false, t: false, u: false, v: false, w: false, o: false, y: false, e: false, b3: false, d_: false, A: false, B: false, m: false, cK: false};
var $author$project$Page$Checkout$initialFormData = F3(
	function (firstName, email, firstDeliveryDate) {
		return {bR: '', bS: '', t: '', u: '', v: '', w: '', o: email, y: '', e: firstDeliveryDate, b3: firstName, d_: '', A: '', B: '', m: '', cK: ''};
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm_community$list_extra$List$Extra$removeIfIndex = function (predicate) {
	return A2(
		$elm_community$list_extra$List$Extra$indexedFoldr,
		F3(
			function (index, item, acc) {
				return predicate(index) ? acc : A2($elm$core$List$cons, item, acc);
			}),
		_List_Nil);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.bL, posixMinutes) < 0) {
					return posixMinutes + era.ch;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Checkout$init = F5(
	function (env, session, allDogs, enabledDogs, selectedPlan) {
		var name = '';
		var email = '';
		var formData = A3(
			$author$project$Page$Checkout$initialFormData,
			name,
			email,
			$elm$time$Time$millisToPosix(0));
		var dogsForPlansPage = {aO: allDogs, a$: enabledDogs};
		var dogs = A2(
			$elm_community$list_extra$List$Extra$removeIfIndex,
			function (i) {
				return !A2($elm$core$Set$member, i, enabledDogs);
			},
			allDogs);
		var deliveryDates = $author$project$Session$deliveryDates(session);
		return _Utils_Tuple2(
			{
				I: false,
				j: dogs,
				aC: dogsForPlansPage,
				c: env,
				U: $author$project$Page$Checkout$initialFieldsInteractedWith,
				a: formData,
				V: _List_Nil,
				ac: false,
				G: false,
				l: $author$project$Page$Checkout$Closed,
				P: {dy: email, ap: false, cg: name},
				Q: $author$project$Page$Checkout$ReadyToSearch(formData.m),
				Y: selectedPlan,
				f: session,
				ag: A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$Checkout$formDataValidator, formData)
			},
			A2(
				$elm$core$Task$perform,
				A2(
					$elm$core$Basics$composeR,
					$elm$time$Time$toWeekday($elm$time$Time$utc),
					$author$project$Page$Checkout$GotToday),
				$elm$time$Time$now));
	});
var $author$project$Page$ChoosePlan$initEnabledDogs = function (dogs) {
	return $elm$core$Set$fromList(
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, _v0) {
					return i;
				}),
			dogs));
};
var $author$project$Page$ChoosePlan$init = F3(
	function (env, session, dogs) {
		return {
			J: $author$project$Page$ChoosePlan$Plans,
			j: dogs,
			F: $author$project$Page$ChoosePlan$initEnabledDogs(dogs),
			c: env,
			f: session
		};
	});
var $author$project$Page$OrderConfirmation$init = F7(
	function (env, session, dogs, firstDeliveryDate, plan, saleMadeOnline, signInLink) {
		return {j: dogs, c: env, e: firstDeliveryDate, r: plan, R: saleMadeOnline, f: session, aI: signInLink};
	});
var $author$project$Page$Receipt$init = F6(
	function (env, session, dogs, firstDeliveryDate, plan, signInLink) {
		return {j: dogs, c: env, e: firstDeliveryDate, r: plan, f: session, aI: signInLink};
	});
var $author$project$Page$Welcome$init = F2(
	function (env, session) {
		return {c: env, f: session};
	});
var $author$project$Page$Wizard$Steps$completeToEleven = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: $elm$core$Maybe$Just(dog.cE),
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$initEnd = F4(
	function (env, session, maybePetParentDetails, dogs) {
		return {
			n: $author$project$Page$Wizard$WhatSnackingHabits(
				A2($elm$core$List$map, $author$project$Page$Wizard$Steps$completeToEleven, dogs)),
			c: env,
			l: $author$project$Page$Wizard$Closed,
			P: maybePetParentDetails,
			f: session
		};
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Main$sendAfter = F2(
	function (delay, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(delay));
	});
var $author$project$Main$handleRouterRequest = F3(
	function (env, navEvent, page) {
		switch (navEvent.$) {
			case 0:
				return _Utils_Tuple2(page, $elm$core$Platform$Cmd$none);
			case 1:
				var session = navEvent.a;
				return _Utils_Tuple2(
					$author$project$Main$Welcome(
						A2($author$project$Page$Welcome$init, env, session)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(0)));
			case 2:
				var session = navEvent.a;
				return _Utils_Tuple2(
					$author$project$Main$Welcome(
						A2($author$project$Page$Welcome$init, env, session)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(1)));
			case 3:
				var session = navEvent.a;
				return _Utils_Tuple2(
					$author$project$Main$Wizard(
						A2($author$project$Page$Wizard$init, env, session)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(2)));
			case 4:
				var session = navEvent.a;
				var maybePetParentDetails = navEvent.b;
				var dogs = navEvent.c;
				return _Utils_Tuple2(
					$author$project$Main$Wizard(
						A4($author$project$Page$Wizard$initEnd, env, session, maybePetParentDetails, dogs)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(3)));
			case 5:
				var session = navEvent.a;
				var petParentDetails = navEvent.b;
				var dogs = navEvent.c;
				var mealsPage = $author$project$Main$Meals(
					A3($author$project$Page$Meals$init, env, session, dogs));
				return _Utils_Tuple2(
					A3($author$project$Main$CalculatePlan, session, dogs, petParentDetails),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$author$project$Monitoring$send,
								session,
								$author$project$Monitoring$ReachWaypoint(4)),
								A2(
								$author$project$Main$sendAfter,
								3000,
								$author$project$Main$GotoPage(mealsPage))
							])));
			case 6:
				var session = navEvent.a;
				var dogs = navEvent.b;
				return _Utils_Tuple2(
					$author$project$Main$Meals(
						A3($author$project$Page$Meals$init, env, session, dogs)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(4)));
			case 7:
				var session = navEvent.a;
				var dogs = navEvent.b;
				return _Utils_Tuple2(
					$author$project$Main$ChoosePlan(
						A3($author$project$Page$ChoosePlan$init, env, session, dogs)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(5)));
			case 8:
				var session = navEvent.a;
				var dogs = navEvent.b;
				var enabledDogs = navEvent.c;
				return _Utils_Tuple2(
					$author$project$Main$ChoosePlan(
						A4($author$project$Page$ChoosePlan$fromCheckout, env, session, dogs, enabledDogs)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(5)));
			case 9:
				var session = navEvent.a;
				var dogs = navEvent.b;
				var enabledDogs = navEvent.c;
				var plan = navEvent.d;
				var _v1 = A5($author$project$Page$Checkout$init, env, session, dogs, enabledDogs, plan);
				var checkoutModel = _v1.a;
				var cmd = _v1.b;
				return _Utils_Tuple2(
					$author$project$Main$Checkout(checkoutModel),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$author$project$Monitoring$send,
								session,
								$author$project$Monitoring$ReachWaypoint(6)),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCheckoutMsg, cmd)
							])));
			case 10:
				var session = navEvent.a;
				var dogs = navEvent.b;
				var firstDeliveryDate = navEvent.c;
				var plan = navEvent.d;
				var saleMadeOnline = navEvent.e;
				var signInLink = navEvent.f;
				return _Utils_Tuple2(
					$author$project$Main$OrderConfirmation(
						A7($author$project$Page$OrderConfirmation$init, env, session, dogs, firstDeliveryDate, plan, saleMadeOnline, signInLink)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(7)));
			default:
				var session = navEvent.a;
				var dogs = navEvent.b;
				var firstDeliveryDate = navEvent.c;
				var plan = navEvent.d;
				var signInLink = navEvent.e;
				return _Utils_Tuple2(
					$author$project$Main$Receipt(
						A6($author$project$Page$Receipt$init, env, session, dogs, firstDeliveryDate, plan, signInLink)),
					A2(
						$author$project$Monitoring$send,
						session,
						$author$project$Monitoring$ReachWaypoint(8)));
		}
	});
var $author$project$Main$updateCurrentPage = F4(
	function (toPage, toMsg, model, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		var navEvent = _v0.c;
		var oldPage = toPage(subModel);
		var _v1 = A3($author$project$Main$handleRouterRequest, model.c, navEvent, oldPage);
		var newPage = _v1.a;
		var routerCmd = _v1.b;
		var scrollCmd = _Utils_eq(newPage, oldPage) ? $elm$core$Platform$Cmd$none : $author$project$Main$resetViewport;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					b: newPage,
					l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, toMsg, subCmd),
						routerCmd,
						scrollCmd
					])));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.b);
		_v0$19:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var signUpStore = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bi: signUpStore}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var isOnline = _v0.a.a;
					var monitoringEvent = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							function (session) {
								return A2($author$project$Monitoring$send, session, $author$project$Monitoring$DeviceBecameOnline);
							},
							A2(
								$author$project$Maybe$Helpers$filter,
								function (_v1) {
									return isOnline;
								},
								$author$project$Main$getSession(model.b))));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bb: isOnline ? 0 : 1
							}),
						monitoringEvent);
				case 4:
					var _v2 = _v0.a;
					return _Utils_Tuple2(
						$author$project$Main$toggleMenu(model),
						$elm$core$Platform$Cmd$none);
				case 5:
					var modal = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								l: $author$project$Main$MenuClosed(
									$elm$core$Maybe$Just(modal))
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var _v3 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
							}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var _v4 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: $author$project$Main$Login(
									$author$project$Page$Login$init(model.c)),
								l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
							}),
						$author$project$Main$resetViewport);
				case 8:
					var session = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: $author$project$Main$Wizard(
									A2($author$project$Page$Wizard$init, model.c, session)),
								l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Monitoring$send,
									session,
									$author$project$Monitoring$ReachWaypoint(2)),
									$author$project$Main$resetViewport
								])));
				case 10:
					var discountCode = _v0.a.a;
					var page = _v0.b;
					var newPage = A2(
						$elm$core$Maybe$withDefault,
						page,
						A2(
							$elm$core$Maybe$map,
							$author$project$Main$setSession(page),
							A2(
								$elm$core$Maybe$map,
								$author$project$Session$setActiveDiscountCode(discountCode),
								$author$project$Main$getSession(page))));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: newPage}),
						$elm$core$Platform$Cmd$none);
				case 11:
					if (_v0.b.$ === 9) {
						var session = _v0.a.a;
						var _v5 = _v0.b;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var session = _v0.a.a;
						var pageToGoBackTo = _v0.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									b: A2($author$project$Main$SalesHistory, pageToGoBackTo, session),
									l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$author$project$Monitoring$send,
										session,
										$author$project$Monitoring$ReachWaypoint(9)),
										$author$project$Main$resetViewport
									])));
					}
				case 9:
					var page = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: page,
								l: $author$project$Main$MenuClosed($elm$core$Maybe$Nothing)
							}),
						$author$project$Main$resetViewport);
				case 12:
					if (!_v0.b.$) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Login,
							$author$project$Main$GotLoginMsg,
							model,
							A2($author$project$Page$Login$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 13:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Welcome,
							$author$project$Main$GotWelcomeMsg,
							model,
							A2($author$project$Page$Welcome$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 14:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Wizard,
							$author$project$Main$GotWizardMsg,
							model,
							A2($author$project$Page$Wizard$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 15:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Meals,
							$author$project$Main$GotMealsMsg,
							model,
							A2($author$project$Page$Meals$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 16:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$ChoosePlan,
							$author$project$Main$GotChoosePlanMsg,
							model,
							A2($author$project$Page$ChoosePlan$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 17:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Checkout,
							$author$project$Main$GotCheckoutMsg,
							model,
							A2($author$project$Page$Checkout$update, subMsg, subModel));
					} else {
						break _v0$19;
					}
				case 18:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$OrderConfirmation,
							$author$project$Main$GotOrderConfirmationMsg,
							model,
							function (navEvent) {
								return _Utils_Tuple3(subModel, $elm$core$Platform$Cmd$none, navEvent);
							}(
								A2($author$project$Page$OrderConfirmation$update, subMsg, subModel)));
					} else {
						break _v0$19;
					}
				case 19:
					if (_v0.b.$ === 8) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateCurrentPage,
							$author$project$Main$Receipt,
							$author$project$Main$GotReceiptMsg,
							model,
							function (navEvent) {
								return _Utils_Tuple3(subModel, $elm$core$Platform$Cmd$none, navEvent);
							}(
								A2($author$project$Page$Receipt$update, subMsg, subModel)));
					} else {
						break _v0$19;
					}
				default:
					break _v0$19;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Main$updateWithPersist = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Main$update, msg, model);
		var newModel = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Main$persistCurrentPage(newModel.b),
						cmd
					])));
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Main$ToggleMenu = {$: 4};
var $author$project$Env$asset = F2(
	function (_v0, accessor) {
		var assetPaths = _v0.bT;
		return accessor(assetPaths);
	});
var $author$project$Page$Checkout$BackClicked = {$: 20};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Buttons$back = F2(
	function (env, config) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn--text'),
					$elm$html$Html$Events$onClick(config.ci)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							A2(
								$author$project$Env$asset,
								env,
								function ($) {
									return $.cR;
								})),
							$elm$html$Html$Attributes$class('icon--left')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(config.bW)
						]))
				]));
	});
var $author$project$Page$Checkout$backButton = F2(
	function (env, buttonText) {
		var config = {bW: buttonText, ci: $author$project$Page$Checkout$BackClicked};
		return A2($author$project$Buttons$back, env, config);
	});
var $author$project$Page$ChoosePlan$BackClicked = {$: 6};
var $author$project$Page$ChoosePlan$backButton = F2(
	function (env, buttonText) {
		var config = {bW: buttonText, ci: $author$project$Page$ChoosePlan$BackClicked};
		return A2($author$project$Buttons$back, env, config);
	});
var $author$project$Page$Meals$BackClicked = 1;
var $author$project$Page$Meals$backButton = F2(
	function (env, buttonText) {
		var config = {bW: buttonText, ci: 1};
		return A2($author$project$Buttons$back, env, config);
	});
var $author$project$Page$Wizard$ExitWizardConfirmationModal = 0;
var $author$project$Page$Wizard$OpenModal = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wizard$viewBackButton = F2(
	function (env, msg) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(msg),
					$elm$html$Html$Attributes$class('btn btn--text')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							A2(
								$author$project$Env$asset,
								env,
								function ($) {
									return $.cR;
								})),
							$elm$html$Html$Attributes$class('icon--left')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Back')
						]))
				]));
	});
var $author$project$Page$Wizard$headerBackButton = function (model) {
	var _v0 = model.n;
	if (!_v0.$) {
		var dogs = _v0.a;
		return A2(
			$author$project$Page$Wizard$viewBackButton,
			model.c,
			$author$project$Page$Wizard$OpenModal(0));
	} else {
		return $elm$html$Html$text('');
	}
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Main$backButton = F2(
	function (env, page) {
		switch (page.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				return $elm$html$Html$text('');
			case 8:
				return $elm$html$Html$text('');
			case 6:
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotCheckoutMsg,
					A2($author$project$Page$Checkout$backButton, env, 'Choose Plan'));
			case 4:
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotMealsMsg,
					A2($author$project$Page$Meals$backButton, env, 'About You'));
			case 5:
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotChoosePlanMsg,
					A2($author$project$Page$ChoosePlan$backButton, env, 'Meals'));
			case 2:
				var subModel = page.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotWizardMsg,
					$author$project$Page$Wizard$headerBackButton(subModel));
			case 7:
				return $elm$html$Html$text('');
			case 9:
				var pageToGoBackTo = page.a;
				var session = page.b;
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Main$GotoPage(pageToGoBackTo)),
							$elm$html$Html$Attributes$class('btn btn--text')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.cR;
										})),
									$elm$html$Html$Attributes$class('icon--left')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Back')
								]))
						]));
			default:
				return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Main$LogOutModal = {$: 0};
var $author$project$Main$OpenModal = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Wizard$GotoStep = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wizard$Steps$eightToSeven = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: $elm$core$Maybe$Just(dog.cc),
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$sevenToSix = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: $elm$core$Maybe$Just(dog.bU),
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$fiveToFour = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: $elm$core$Maybe$Just(dog.ce),
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: $elm$core$Maybe$Just(dog.cN)
	};
};
var $author$project$Page$Wizard$Steps$fourToThree = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: $elm$core$Maybe$Just(dog.b5),
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$threeToTwo = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: $elm$core$Maybe$Just(dog.b$),
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$oneToNone = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: $elm$core$Maybe$Just(dog.cg),
		cB: $elm$core$Maybe$Just(dog.cB),
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$twoToOne = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: $elm$core$Maybe$Just(dog.bV),
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$twoToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$twoToOne, $author$project$Page$Wizard$Steps$oneToNone);
var $author$project$Page$Wizard$Steps$threeToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$threeToTwo, $author$project$Page$Wizard$Steps$twoToNone);
var $author$project$Page$Wizard$Steps$fourToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$fourToThree, $author$project$Page$Wizard$Steps$threeToNone);
var $author$project$Page$Wizard$Steps$fiveToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$fiveToFour, $author$project$Page$Wizard$Steps$fourToNone);
var $author$project$Page$Wizard$Steps$sixToFive = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: $elm$core$Maybe$Just(dog.b1),
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$sixToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$sixToFive, $author$project$Page$Wizard$Steps$fiveToNone);
var $author$project$Page$Wizard$Steps$sevenToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$sevenToSix, $author$project$Page$Wizard$Steps$sixToNone);
var $author$project$Page$Wizard$Steps$eightToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$eightToSeven, $author$project$Page$Wizard$Steps$sevenToNone);
var $author$project$Page$Wizard$Steps$elevenToTen = function (dog) {
	return {
		bP: dog.bP,
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: $elm$core$Maybe$Just(dog.bw),
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$nineToEight = function (dog) {
	return {
		bP: $elm$core$Maybe$Just(dog.bP),
		bp: dog.bp,
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$nineToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$nineToEight, $author$project$Page$Wizard$Steps$eightToNone);
var $author$project$Page$Wizard$Steps$tenToNine = function (dog) {
	return {
		bP: dog.bP,
		bp: $elm$core$Maybe$Just(dog.bp),
		bU: dog.bU,
		bV: dog.bV,
		b_: dog.b_,
		b$: dog.b$,
		b1: dog.b1,
		b5: dog.b5,
		bw: dog.bw,
		cc: dog.cc,
		ce: dog.ce,
		cg: dog.cg,
		cB: dog.cB,
		cE: dog.cE,
		cN: dog.cN
	};
};
var $author$project$Page$Wizard$Steps$tenToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$tenToNine, $author$project$Page$Wizard$Steps$nineToNone);
var $author$project$Page$Wizard$Steps$elevenToNone = A2($elm$core$Basics$composeR, $author$project$Page$Wizard$Steps$elevenToTen, $author$project$Page$Wizard$Steps$tenToNone);
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Page$Wizard$viewEditAddDogsButton = F2(
	function (env, msg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('header__profile__dropdown__menu__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							A2(
								$author$project$Env$asset,
								env,
								function ($) {
									return $.es;
								}))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('no-margin'),
							$elm$html$Html$Events$onClick(msg)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Edit/Add dogs')
						]))
				]));
	});
var $author$project$Page$Wizard$editAddDogsButton = function (model) {
	var mkMsg = F2(
		function (unwrapper, dogs) {
			return $elm$core$Maybe$Just(
				$author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$HowManyDogs(
						$author$project$Page$Wizard$HowManyDogs$init(
							A2($elm$core$List$map, unwrapper, dogs)))));
		});
	var msg = function () {
		var _v0 = model.n;
		switch (_v0.$) {
			case 0:
				var dogs = _v0.a;
				return $elm$core$Maybe$Nothing;
			case 1:
				var subModel = _v0.a;
				return A2(
					mkMsg,
					$author$project$Page$Wizard$Steps$oneToNone,
					$author$project$Page$Wizard$WhatBreed$getDogs(subModel));
			case 2:
				var subModel = _v0.a;
				return A2(
					mkMsg,
					$author$project$Page$Wizard$Steps$twoToNone,
					$author$project$Page$Wizard$WhatCurrentFoods$getDogs(subModel));
			case 3:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$threeToNone, dogs);
			case 4:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$fourToNone, dogs);
			case 5:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$fiveToNone, dogs);
			case 6:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$sixToNone, dogs);
			case 7:
				var subModel = _v0.a;
				return A2(
					mkMsg,
					$author$project$Page$Wizard$Steps$sevenToNone,
					$author$project$Page$Wizard$WhatMass$getDogs(subModel));
			case 8:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$eightToNone, dogs);
			case 9:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$nineToNone, dogs);
			case 10:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$tenToNone, dogs);
			default:
				var dogs = _v0.a;
				return A2(mkMsg, $author$project$Page$Wizard$Steps$elevenToNone, dogs);
		}
	}();
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2(
			$elm$core$Maybe$map,
			$author$project$Page$Wizard$viewEditAddDogsButton(model.c),
			msg));
};
var $author$project$Main$addEditDogsButton = F2(
	function (env, page) {
		if (page.$ === 2) {
			var subModel = page.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Main$GotWizardMsg,
				$author$project$Page$Wizard$editAddDogsButton(subModel));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Main$SetActiveDiscountCode = function (a) {
	return {$: 10, a: a};
};
var $author$project$DiscountCode$code = function (_v0) {
	var discountCode = _v0;
	return discountCode.aU;
};
var $author$project$Session$discountCodes = function (_v0) {
	var session = _v0;
	return session.E.aO;
};
var $author$project$Main$discountCodeButtons = F2(
	function (env, session) {
		var allCodes = $author$project$Session$discountCodes(session);
		var activeCode = $author$project$Session$activeDiscountCode(session);
		var checkmarkFor = function (discountCode) {
			return _Utils_eq(discountCode, activeCode) ? A2(
				$author$project$Env$asset,
				env,
				function ($) {
					return $.dd;
				}) : A2(
				$author$project$Env$asset,
				env,
				function ($) {
					return $.dc;
				});
		};
		var mkButton = function (discountCode) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header__profile__dropdown__menu__item'),
						$elm$html$Html$Events$onClick(
						$author$project$Main$SetActiveDiscountCode(discountCode))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								checkmarkFor(discountCode))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('no-margin')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$DiscountCode$code(discountCode))
							]))
					]));
		};
		return A2($elm$core$List$map, mkButton, allCodes);
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Main$StartOverModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$startOverButton = F2(
	function (env, page) {
		switch (page.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				return $elm$html$Html$text('');
			default:
				var _v1 = $author$project$Main$getSession(page);
				if (_v1.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var session = _v1.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Main$OpenModal(
									$author$project$Main$StartOverModal(session))),
								$elm$html$Html$Attributes$class('header__profile__dropdown__menu__item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.eC;
											}))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('no-margin')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Start Over')
									]))
							]));
				}
		}
	});
var $author$project$Main$ShowSalesHistory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$todaysSalesButton = F2(
	function (env, page) {
		switch (page.$) {
			case 0:
				return $elm$html$Html$text('');
			case 9:
				return $elm$html$Html$text('');
			default:
				var _v1 = $author$project$Main$getSession(page);
				if (_v1.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var session = _v1.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Main$ShowSalesHistory(session)),
								$elm$html$Html$Attributes$class('header__profile__dropdown__menu__item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.eE;
											}))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('no-margin')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Today\'s sales')
									]))
							]));
				}
		}
	});
var $author$project$Main$dropDownContents = function (model) {
	var isOpen = _Utils_eq(model.l, $author$project$Main$MenuOpen);
	var discountCodes = function () {
		var _v0 = $author$project$Main$getSession(model.b);
		if (_v0.$ === 1) {
			return _List_Nil;
		} else {
			var session = _v0.a;
			return A2($author$project$Main$discountCodeButtons, model.c, session);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('header__profile__dropdown__menu'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('header__profile__dropdown__menu--open', isOpen)
					]))
			]),
		_Utils_ap(
			discountCodes,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('divider-item')
						]),
					_List_Nil),
					A2($author$project$Main$addEditDogsButton, model.c, model.b),
					A2($author$project$Main$todaysSalesButton, model.c, model.b),
					A2($author$project$Main$startOverButton, model.c, model.b),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Main$OpenModal($author$project$Main$LogOutModal)),
							$elm$html$Html$Attributes$class('header__profile__dropdown__menu__item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										model.c,
										function ($) {
											return $.d4;
										}))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Log out')
								]))
						]))
				])));
};
var $elm$core$String$fromList = _String_fromList;
var $author$project$Session$salesRep = function (_v0) {
	var session = _v0;
	return session.aH;
};
var $author$project$List$Helpers$sequenceMaybes = function (xs) {
	var traverseJusts = F2(
		function (maybes, acc) {
			traverseJusts:
			while (true) {
				if (!maybes.b) {
					return $elm$core$Maybe$Just(
						$elm$core$List$reverse(acc));
				} else {
					if (maybes.a.$ === 1) {
						var _v1 = maybes.a;
						return $elm$core$Maybe$Nothing;
					} else {
						var x = maybes.a.a;
						var rest = maybes.b;
						var $temp$maybes = rest,
							$temp$acc = A2($elm$core$List$cons, x, acc);
						maybes = $temp$maybes;
						acc = $temp$acc;
						continue traverseJusts;
					}
				}
			}
		});
	return A2(traverseJusts, xs, _List_Nil);
};
var $author$project$Translations$Welcome$pageTitle = 'Home';
var $author$project$Translations$Wizard$questionTitle = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount === 1) ? 'Tell us about your dog' : 'Tell us about your dogs';
};
var $author$project$Page$Wizard$titleFor = function (_v0) {
	var currentStep = _v0.n;
	var dogCount = function () {
		switch (currentStep.$) {
			case 0:
				var model = currentStep.a;
				return $elm$core$List$length(
					$author$project$Page$Wizard$HowManyDogs$getDogs(model));
			case 1:
				var model = currentStep.a;
				return $elm$core$List$length(
					$author$project$Page$Wizard$WhatBreed$getDogs(model));
			case 2:
				var model = currentStep.a;
				return $elm$core$List$length(
					$author$project$Page$Wizard$WhatCurrentFoods$getDogs(model));
			case 3:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 4:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 5:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 6:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 7:
				var subModel = currentStep.a;
				return $elm$core$List$length(
					$author$project$Page$Wizard$WhatMass$getDogs(subModel));
			case 8:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 9:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			case 10:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
			default:
				var dogs = currentStep.a;
				return $elm$core$List$length(dogs);
		}
	}();
	return $author$project$Translations$Wizard$questionTitle(
		{bs: dogCount});
};
var $author$project$Main$titleFor = function (currentPage) {
	switch (currentPage.$) {
		case 0:
			return 'Login';
		case 1:
			return $author$project$Translations$Welcome$pageTitle;
		case 2:
			var subModel = currentPage.a;
			return $author$project$Page$Wizard$titleFor(subModel);
		case 4:
			return 'Meals';
		case 5:
			return 'Choose Plan';
		case 6:
			return 'Checkout';
		case 7:
			return '';
		case 8:
			return 'Your Order';
		case 9:
			var page = currentPage.a;
			return 'Summary';
		default:
			return '';
	}
};
var $author$project$Main$header = function (model) {
	var networkIndicator = function () {
		var _v1 = model.bb;
		if (!_v1) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('network-status network-status--online')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Connected')
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('network-status network-status--offline')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Offline')
					]));
		}
	}();
	var maybeSalesRep = A2(
		$elm$core$Maybe$map,
		$author$project$Session$salesRep,
		$author$project$Main$getSession(model.b));
	var name = A2(
		$elm$core$Maybe$withDefault,
		'Brand Ambassador',
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.b3;
			},
			maybeSalesRep));
	var initials = A2(
		$elm$core$Maybe$withDefault,
		'BA',
		A2(
			$elm$core$Maybe$andThen,
			function (_v0) {
				var firstName = _v0.b3;
				var lastName = _v0.d_;
				return A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromList,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map($elm$core$Tuple$first),
						$author$project$List$Helpers$sequenceMaybes(
							A2(
								$elm$core$List$map,
								$elm$core$String$uncons,
								_List_fromArray(
									[firstName, lastName])))));
			},
			maybeSalesRep));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header')
					]),
				_List_fromArray(
					[
						networkIndicator,
						A2($author$project$Main$backButton, model.c, model.b),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header__title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Main$titleFor(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header__profile')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('header__profile__icon')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(initials),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.cU;
													}))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('header__profile__dropdown')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$Main$ToggleMenu)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(name),
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('header__profile__dropdown__icon'),
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2(
																'header__profile__dropdown__icon--open',
																_Utils_eq(model.l, $author$project$Main$MenuOpen))
															])),
														$elm$html$Html$Attributes$src(
														A2(
															$author$project$Env$asset,
															model.c,
															function ($) {
																return $.cQ;
															}))
													]),
												_List_Nil)
											]))
									]))
							]))
					])),
				$author$project$Main$dropDownContents(model)
			]));
};
var $author$project$Main$CloseModal = {$: 6};
var $author$project$Main$LogOut = {$: 7};
var $author$project$Main$StartOver = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$Modals$popOver = F2(
	function (env, config) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal--popup__wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal--popup__content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--popup__content__close-button'),
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.dj;
										})),
									$elm$html$Html$Events$onClick(config.cj)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--popup__content__upper')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex--column')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$strong,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(config.cJ)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(config.cG)
												]))
										])),
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.e9;
												}))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--popup__content__lower')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(config.ck),
											$elm$html$Html$Attributes$class('btn btn--danger')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(config.bZ)
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(config.cj),
											$elm$html$Html$Attributes$class('btn btn--white')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('cancel')
										]))
								]))
						]))
				]));
	});
var $author$project$Main$showModal = F2(
	function (env, modal) {
		var config = function () {
			if (!modal.$) {
				return {bZ: 'log out', cj: $author$project$Main$CloseModal, ck: $author$project$Main$LogOut, cG: 'You will lose all of your progress if you do this.', cJ: 'Are you sure you want to log out?'};
			} else {
				var session = modal.a;
				return {
					bZ: 'start over',
					cj: $author$project$Main$CloseModal,
					ck: $author$project$Main$StartOver(session),
					cG: 'You will lose all of your progress if you do this.',
					cJ: 'Are you sure you want to start over?'
				};
			}
		}();
		return A2($author$project$Modals$popOver, env, config);
	});
var $author$project$Main$modals = F2(
	function (env, overlays) {
		if (!overlays.$) {
			return $elm$html$Html$text('');
		} else {
			if (overlays.a.$ === 1) {
				var _v1 = overlays.a;
				return $elm$html$Html$text('');
			} else {
				var modal = overlays.a.a;
				return A2($author$project$Main$showModal, env, modal);
			}
		}
	});
var $author$project$Main$layoutAfterLogin = F3(
	function (model, toMsg, page) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app')
				]),
			_List_fromArray(
				[
					$author$project$Main$header(model),
					A2($elm$html$Html$map, toMsg, page),
					A2($author$project$Main$modals, model.c, model.l)
				]));
	});
var $author$project$String$Helpers$combineNouns = function (nouns) {
	if (!nouns.b) {
		return '';
	} else {
		if (!nouns.b.b) {
			var noun = nouns.a;
			return noun;
		} else {
			if (!nouns.b.b.b) {
				var noun = nouns.a;
				var _v1 = nouns.b;
				var noun2 = _v1.a;
				return noun + (' and ' + noun2);
			} else {
				var noun = nouns.a;
				var rest = nouns.b;
				return noun + (', ' + $author$project$String$Helpers$combineNouns(rest));
			}
		}
	}
};
var $author$project$Page$CalculatePlan$getDogNames = function (dogs) {
	var names = $author$project$String$Helpers$combineNouns(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs));
	var modifierClass = ($elm$core$List$length(dogs) > 1) ? 'multiple-dogs' : 'single-dog';
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(modifierClass)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(names)
			]));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Page$CalculatePlan$viewAnimatedSparks = function (env) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('dog-animation__sparks__stars')
			]),
		A2(
			$elm$core$List$repeat,
			4,
			A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.c6;
							}))
					]),
				_List_Nil)));
};
var $author$project$Page$CalculatePlan$view = F2(
	function (env, dogs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('calculating-plan content content--center text-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dog-animation')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.c4;
										}))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dog-animation__tail')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.c7;
												}))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dog-animation__dog')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.c3;
												}))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dog-animation__sparks')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.c5;
												}))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('dog-animation__sparks__stars')
										]),
									_List_fromArray(
										[
											$author$project$Page$CalculatePlan$viewAnimatedSparks(env)
										]))
								]))
						])),
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('calculating-plan__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('We\'re crunching the numbers')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('calculating-plan__subtitle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('We\'ll know how much food'),
							$author$project$Page$CalculatePlan$getDogNames(dogs),
							$elm$html$Html$text('needs in\n two wags of a dog\'s tail!')
						]))
				]));
	});
var $author$project$Page$Checkout$Address1Changed = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Checkout$Address2Changed = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Checkout$CardCVCChanged = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Checkout$CardExpMonthChanged = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Checkout$CardExpYearChanged = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Checkout$CardNumberChanged = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Checkout$EmailChanged = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Checkout$EmailConfirmationChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Checkout$FieldInteractedWith = function (a) {
	return {$: 21, a: a};
};
var $author$project$Page$Checkout$FirstNameChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$LastNameChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Checkout$LeaveSafeTooltip = 0;
var $author$project$Page$Checkout$OpenDeliveryDateModal = {$: 3};
var $author$project$Page$Checkout$PasswordChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Checkout$PhoneNumberChanged = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Checkout$ToggleTooltip = function (a) {
	return {$: 22, a: a};
};
var $author$project$Page$Checkout$TownChanged = function (a) {
	return {$: 12, a: a};
};
var $author$project$Translations$Checkout$accountDetailsTitle = 'Your account details';
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Translations$Checkout$buildingInformationPlaceholder = 'Flat, apartment, suite, building etc';
var $author$project$Page$Checkout$Checkout = {$: 18};
var $author$project$Translations$Checkout$buySubscriptionButton = 'Buy subscription';
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Page$Checkout$loadingSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('spinner--ball-fall')
		]),
	A2(
		$elm$core$List$repeat,
		3,
		A2($elm$html$Html$div, _List_Nil, _List_Nil)));
var $author$project$Page$Checkout$buySubscriptionButton = F2(
	function (result, isLoading) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(isLoading),
					$elm$html$Html$Attributes$class('btn btn--blue'),
					$elm$html$Html$Events$onClick($author$project$Page$Checkout$Checkout)
				]),
			_List_fromArray(
				[
					isLoading ? $author$project$Page$Checkout$loadingSpinner : $elm$html$Html$text($author$project$Translations$Checkout$buySubscriptionButton)
				]));
	});
var $author$project$String$Helpers$chunksOf = F2(
	function (n, str) {
		return (_Utils_cmp(
			$elm$core$String$length(str),
			n) > 0) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$left, n, str),
			A2(
				$author$project$String$Helpers$chunksOf,
				n,
				A2($elm$core$String$dropLeft, n, str))) : _List_fromArray(
			[str]);
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Localisation$cardNumber = function (str) {
	return $elm$core$String$concat(
		A2(
			$elm$core$List$intersperse,
			' ',
			A2($author$project$String$Helpers$chunksOf, 4, str)));
};
var $author$project$Translations$Checkout$cardNumberPlaceholder = 'Card number';
var $author$project$Translations$Checkout$commitmentDetail = 'No commmitment, push or stop your deliveries at any time.';
var $author$project$Translations$Checkout$cvcPlaceholder = 'CVC';
var $author$project$Translations$Checkout$datePickerPlaceholder = 'Delivery Date';
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		b0: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cd: month,
		cM: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).b0;
	});
var $author$project$DeliveryDate$toEnglishMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cd;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cM;
	});
var $author$project$DeliveryDate$weekdayToEnglish = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $author$project$DeliveryDate$formatPosix = function (posix) {
	var year = $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, posix));
	var monthOfYear = $author$project$DeliveryDate$toEnglishMonth(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, posix));
	var dayOfWeek = $author$project$DeliveryDate$weekdayToEnglish(
		A2($elm$time$Time$toWeekday, $elm$time$Time$utc, posix));
	var dayOfMonth = $elm$core$String$fromInt(
		A2($elm$time$Time$toDay, $elm$time$Time$utc, posix));
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[dayOfWeek, dayOfMonth, monthOfYear, year]));
};
var $author$project$Translations$Checkout$datePickerValue = function (_v0) {
	var deliveryDate = _v0.K;
	return _Utils_eq(
		$elm$time$Time$millisToPosix(0),
		deliveryDate) ? '' : $author$project$DeliveryDate$formatPosix(deliveryDate);
};
var $author$project$Translations$Checkout$deliveryAddressTitle = 'Delivery address';
var $author$project$Translations$Checkout$deliveryDayTitle = 'Delivery day';
var $author$project$Translations$Checkout$emailPlaceholder = 'Enter email eg. dogsaregreat@hotmail.com';
var $author$project$Translations$Checkout$firstNamePlaceholer = 'First name';
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Translations$Checkout$ifImNotInTooltipContent = 'If you\'re not in, your courier will text you for instructions on where to leave your box. Frozen and insulated, it\'ll be safe until you get home.';
var $author$project$Translations$Checkout$ifImNotInTooltipTitle = 'What if I\'m not in?';
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Translations$Checkout$lastNamePlaceholder = 'Last name';
var $author$project$Translations$Checkout$mobileNumberDetail = 'Your courier will text you on the day with your delivery time slot';
var $author$project$Translations$Checkout$mobileNumberPlaceholder = 'Mobile number';
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $author$project$Page$Checkout$OngoingPlanTooltip = 1;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $author$project$DiscountCode$valueFor = F2(
	function (_v0, price) {
		var discountCode = _v0;
		var _v1 = discountCode.aP;
		if (_v1 === 1) {
			return A2($elm$core$Basics$min, price, discountCode.av);
		} else {
			return ((price * discountCode.av) / 100) | 0;
		}
	});
var $author$project$DiscountCode$apply = F2(
	function (discountCode, price) {
		return A2(
			$elm$core$Basics$max,
			0,
			price - A2($author$project$DiscountCode$valueFor, discountCode, price));
	});
var $author$project$Plan$boxPriceAfterTrial = function (plan) {
	return plan.as * plan.ej;
};
var $author$project$Translations$Checkout$delivery = 'Delivery';
var $author$project$Plan$deliveryIntervalInWeeks = function (plan) {
	return (plan.dq / 7) | 0;
};
var $author$project$Translations$Checkout$firstOrderDiscount = 'First order discount';
var $author$project$Translations$Checkout$firstOrderTotal = 'First order total';
var $author$project$Translations$Checkout$free = 'Free';
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$DiscountCode$numberOfBoxes = function (_v0) {
	var discountCode = _v0;
	return discountCode.ba;
};
var $author$project$Localisation$percentage = function (n) {
	return $elm$core$String$fromInt(n) + '%';
};
var $author$project$DiscountCode$value = function (_v0) {
	var discountCode = _v0;
	return discountCode.av;
};
var $author$project$Page$Checkout$multipleBoxDiscountReminder = function (discountCode) {
	if ($author$project$DiscountCode$numberOfBoxes(discountCode) === 2) {
		var discountReminderText = 'Your ' + ($author$project$Localisation$percentage(
			$author$project$DiscountCode$value(discountCode)) + ' discount will be applied to your second box too!');
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(discountReminderText)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Translations$Checkout$ongoingPlanDetail = 'After your trial, we\'ll deliver and bill your regular order every';
var $author$project$Translations$Checkout$ongoingPlanTitle = 'Your Ongoing Plan';
var $author$project$Translations$Checkout$orderSummaryTitle = 'Order summary';
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Translations$possessiveName = function (name) {
	return A2($elm$core$String$endsWith, 's', name) ? (name + '\'') : (name + '\'s');
};
var $author$project$Translations$possessivePronoun = function (sexes) {
	_v0$2:
	while (true) {
		if (sexes.b) {
			if (!sexes.a) {
				if (!sexes.b.b) {
					var _v1 = sexes.a;
					return 'her';
				} else {
					break _v0$2;
				}
			} else {
				if (!sexes.b.b) {
					var _v2 = sexes.a;
					return 'his';
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return 'their';
};
var $author$project$Plan$pouchesPerDay = function (plan) {
	return (plan.ej / plan.dq) | 0;
};
var $author$project$Plan$gramsPerDay = function (plan) {
	return $author$project$Plan$pouchesPerDay(plan) * plan.ev;
};
var $author$project$Plan$trialDurationInDays = function (plan) {
	return (($author$project$Plan$gramsPerDay(plan) >= 800) || (plan.dq < 14)) ? 7 : 14;
};
var $author$project$Plan$pouchesInTrial = function (plan) {
	return $author$project$Plan$pouchesPerDay(plan) * $author$project$Plan$trialDurationInDays(plan);
};
var $author$project$Localisation$doubleDigitNumber = function (n) {
	var asStr = $elm$core$String$fromInt(n);
	return (n < 10) ? ('0' + asStr) : asStr;
};
var $author$project$Localisation$price = function (total) {
	var pounds = (total / 100) | 0;
	var pence = total % 100;
	var paddedPence = (pence < 10) ? ('0' + $elm$core$String$fromInt(pence)) : $elm$core$String$fromInt(pence);
	return '£' + ($elm$core$String$fromInt(pounds) + ('.' + $author$project$Localisation$doubleDigitNumber(pence)));
};
var $author$project$Page$Checkout$tooltip = F3(
	function (config, extraAttributes, contentIfShown) {
		var content = config.a7 ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tooltip__content')
				]),
			contentIfShown) : $elm$html$Html$text('');
		var children = _List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tooltip__icon'),
						$elm$html$Html$Attributes$src(
						A2(
							$author$project$Env$asset,
							config.c,
							function ($) {
								return $.eW;
							}))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tooltip__trigger-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(config.bm)
					])),
				content
			]);
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tooltip'),
					$elm$html$Html$Events$onClick(config.bl)
				]),
			extraAttributes);
		return A2($elm$html$Html$div, attributes, children);
	});
var $author$project$Plan$trialBoxPrice = function (plan) {
	return plan.as * $author$project$Plan$pouchesInTrial(plan);
};
var $author$project$Plan$trialDurationInWeeks = function (plan) {
	return ($author$project$Plan$trialDurationInDays(plan) / 7) | 0;
};
var $author$project$Translations$Checkout$trialTitle = function (_v0) {
	var planType = _v0.cn;
	if (planType === 1) {
		return 'Mixed Plan Trial';
	} else {
		return 'Whole Plan Trial';
	}
};
var $author$project$Translations$Checkout$weeklyPrice = 'Weekly price';
var $author$project$Page$Checkout$orderSummary = F2(
	function (env, config) {
		var trialWeeklyPrice = $author$project$Localisation$price(
			($author$project$Plan$trialBoxPrice(config.r) / $author$project$Plan$trialDurationInWeeks(config.r)) | 0);
		var trialDurationInWeeks = $author$project$Plan$trialDurationInWeeks(config.r);
		var trialDurationStr = (trialDurationInWeeks > 1) ? ($elm$core$String$fromInt(trialDurationInWeeks) + ' weeks') : '1 week';
		var trialDurationExplainer = trialDurationStr + ' worth of fresh food';
		var trialBoxTitle = $author$project$Translations$Checkout$trialTitle(
			{cn: config.r.cn});
		var ongoingPlanDurationInWeeks = $author$project$Plan$deliveryIntervalInWeeks(config.r);
		var ongoingPlanDurationInWeeksStr = (ongoingPlanDurationInWeeks > 1) ? ($elm$core$String$fromInt(ongoingPlanDurationInWeeks) + ' weeks') : ($elm$core$String$fromInt(ongoingPlanDurationInWeeks) + ' week');
		var ongoingSubsciptionWeekyPrice = ($author$project$Plan$boxPriceAfterTrial(config.r) / ongoingPlanDurationInWeeks) | 0;
		var ongoingSubscriptionSummary = $elm$core$String$fromInt(ongoingPlanDurationInWeeks) + (' weeks at ' + ($author$project$Localisation$price(ongoingSubsciptionWeekyPrice) + ' per week'));
		var ongoingSubscriptionTooltipTriggerText = 'Why every ' + ($elm$core$String$fromInt(ongoingPlanDurationInWeeks) + ' weeks?');
		var firstOrderTotal = $author$project$Localisation$price(
			A2(
				$author$project$DiscountCode$apply,
				config.al,
				$author$project$Plan$trialBoxPrice(config.r)));
		var firstBoxDiscount = $author$project$Localisation$price(
			A2(
				$author$project$DiscountCode$valueFor,
				config.al,
				$author$project$Plan$trialBoxPrice(config.r)));
		var dogOrDogsSize = function () {
			var _v0 = config.j;
			if (_v0.b && (!_v0.b.b)) {
				var dog = _v0.a;
				return $author$project$Translations$possessiveName(dog.cg) + ' size';
			} else {
				return 'your dogs\' sizes';
			}
		}();
		var ongoingSubscriptionTooltipContents = 'Your order will last ' + (ongoingPlanDurationInWeeksStr + (' based on ' + (dogOrDogsSize + (' and eating habits. You can customise ' + ($author$project$Translations$possessivePronoun(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.cB;
				},
				config.j)) + ' plan after checkout.')))));
		var dogNames = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			config.j);
		var trialBoxContents = 'Delivered in ' + ($elm$core$String$fromInt(
			$author$project$Plan$pouchesInTrial(config.r)) + (' x ' + ($elm$core$String$fromInt(config.r.ev) + ('g pouches for ' + $author$project$String$Helpers$combineNouns(dogNames)))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('order-summary')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Checkout$orderSummaryTitle)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('order-summary__plan')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.dk;
										}))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-summary__plan__detail')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(trialBoxTitle)
										])),
									A2(
									$elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(trialDurationExplainer)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('no-margin--top')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(trialBoxContents)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('order-summary__breakdown')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-summary__breakdown__total')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$Checkout$weeklyPrice)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(trialWeeklyPrice)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-summary__breakdown__total')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$Checkout$delivery)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount text-uppercase')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$Checkout$free)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-summary__breakdown__total')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount text-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$Checkout$firstOrderDiscount)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount text-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('- ' + firstBoxDiscount)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-summary__breakdown__total')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount text-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$Checkout$firstOrderTotal)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('order-summary__breakdown__total__amount text-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(firstOrderTotal)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('order-summary__ongoing-plan')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Checkout$ongoingPlanTitle)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Checkout$ongoingPlanDetail)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-bold no-margin--top')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(ongoingSubscriptionSummary)
								])),
							$author$project$Page$Checkout$multipleBoxDiscountReminder(config.al)
						])),
					A3(
					$author$project$Page$Checkout$tooltip,
					{
						c: env,
						a7: config.G,
						bl: $author$project$Page$Checkout$ToggleTooltip(1),
						bm: ongoingSubscriptionTooltipTriggerText
					},
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(ongoingSubscriptionTooltipContents)
						]))
				]));
	});
var $author$project$Translations$Checkout$passwordDetail = 'Minimum 8 characters, keep it safe like your dog\'s treats';
var $author$project$Translations$Checkout$passwordPlaceholder = 'Choose password';
var $author$project$Translations$Checkout$paymentDetailsTitle = 'Your payment details';
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Translations$Checkout$reEnterEmailPlaceholder = 'Re-enter email';
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$small = _VirtualDom_node('small');
var $author$project$Translations$Checkout$streetPlaceholder = 'Street and number';
var $author$project$Translations$Checkout$termsAndConditions = 'By clicking Buy Subscription, you agree to our terms and conditions and terms of use ';
var $author$project$Translations$Checkout$townCityPlaceholder = 'Town / city';
var $author$project$Translations$Checkout$trustpilotDetail = 'Rated 9.8/10 based on 1,249 reviews';
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Translations$Checkout$datePickerDetail = 'Your regular deliveries will come on a ';
var $author$project$Translations$Checkout$datePickerDetailDay = function (_v0) {
	var deliveryDate = _v0.K;
	return $author$project$DeliveryDate$weekdayToEnglish(
		A2($elm$time$Time$toWeekday, $elm$time$Time$utc, deliveryDate));
};
var $author$project$Page$Checkout$viewDatePickerDetailIfDateSelect = function (date) {
	return _Utils_eq(
		$elm$time$Time$millisToPosix(0),
		date) ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('input-row__label')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text($author$project$Translations$Checkout$datePickerDetail),
				A2(
				$elm$html$Html$strong,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Translations$Checkout$datePickerDetailDay(
							{K: date}))
					]))
			]));
};
var $author$project$Page$Checkout$interactedWith = F2(
	function (fieldsInteractedWith, formField) {
		var accessor = function () {
			switch (formField) {
				case 0:
					return function ($) {
						return $.b3;
					};
				case 1:
					return function ($) {
						return $.d_;
					};
				case 2:
					return function ($) {
						return $.o;
					};
				case 3:
					return function ($) {
						return $.y;
					};
				case 4:
					return function ($) {
						return $.e;
					};
				case 5:
					return function ($) {
						return $.A;
					};
				case 6:
					return function ($) {
						return $.m;
					};
				case 7:
					return function ($) {
						return $.bR;
					};
				case 8:
					return function ($) {
						return $.bS;
					};
				case 9:
					return function ($) {
						return $.cK;
					};
				case 10:
					return function ($) {
						return $.B;
					};
				case 11:
					return function ($) {
						return $.w;
					};
				case 12:
					return function ($) {
						return $.u;
					};
				case 13:
					return function ($) {
						return $.v;
					};
				default:
					return function ($) {
						return $.t;
					};
			}
		}();
		return accessor(fieldsInteractedWith);
	});
var $author$project$Page$Checkout$viewErrorIfPresent = F2(
	function (_v0, formField) {
		var formSubmissionErrors = _v0.V;
		var fieldsInteractedWith = _v0.U;
		var validatedFormData = _v0.ag;
		if (A2($author$project$Page$Checkout$interactedWith, fieldsInteractedWith, formField)) {
			var validationErrors = function () {
				if (validatedFormData.$ === 1) {
					var errors = validatedFormData.a;
					return errors;
				} else {
					return _List_Nil;
				}
			}();
			var applicableErrors = A2(
				$elm$core$List$filter,
				function (_v3) {
					var x = _v3.a;
					return _Utils_eq(x, formField);
				},
				_Utils_ap(formSubmissionErrors, validationErrors));
			if (!applicableErrors.b) {
				return $elm$html$Html$text('');
			} else {
				var _v2 = applicableErrors.a;
				var error = _v2.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-row__label--error')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(error)
						]));
			}
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Page$Checkout$ToggleMarketingConsent = {$: 27};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Page$Checkout$viewLeadCaptureConsent = function (haveConsent) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('lead-capture__consent')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Attributes$checked(haveConsent),
						$elm$html$Html$Events$onClick($author$project$Page$Checkout$ToggleMarketingConsent)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('no-margin')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('no-margin--top')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('I\'m happy to receive direct marketing emails from Butternut Box. '),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('https://butternutbox.com/privacy-policy.pdf'),
										$elm$html$Html$Attributes$target('_blank')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('See Privacy Policy')
									]))
							]))
					]))
			]));
};
var $author$project$Page$Checkout$CloseDeliveryDateModal = {$: 4};
var $author$project$Page$Checkout$FirstDeliveryDateChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Checkout$datePickerCell = F2(
	function (env, config) {
		var viewDeliveryVanIfSelected = config.bA ? A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('date-picker__cell__item__delivery-icon'),
					$elm$html$Html$Attributes$src(
					A2(
						$author$project$Env$asset,
						env,
						function ($) {
							return $.ds;
						}))
				]),
			_List_Nil) : $elm$html$Html$text('');
		var padWithZero = function (str) {
			return _Utils_ap(
				($elm$core$String$length(str) === 1) ? '0' : '',
				str);
		};
		var dayOfMonthStr = padWithZero(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, $elm$time$Time$utc, config.K.aG)));
		var classes = _List_fromArray(
			[
				_Utils_Tuple2('date-picker__body__date--deliverable--selected', config.K.b9 && config.bA),
				_Utils_Tuple2('date-picker__body__date--deliverable', config.K.b9 && (!config.bA)),
				_Utils_Tuple2('date-picker__body__date--undeliverable', !config.K.b9)
			]);
		var baseClass = 'date-picker__cell__item date-picker__body__date';
		return config.K.b9 ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('date-picker__cell'),
					$elm$html$Html$Events$onClick(config.ci)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(baseClass),
							$elm$html$Html$Attributes$classList(classes)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(dayOfMonthStr),
							viewDeliveryVanIfSelected
						]))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('date-picker__cell')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(baseClass),
							$elm$html$Html$Attributes$classList(classes)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(dayOfMonthStr),
							viewDeliveryVanIfSelected
						]))
				]));
	});
var $author$project$DeliveryDate$Spanning = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$DeliveryDate$Within = function (a) {
	return {$: 0, a: a};
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $elm_community$list_extra$List$Extra$uncons = function (list) {
	if (!list.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var first = list.a;
		var rest = list.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(first, rest));
	}
};
var $author$project$DeliveryDate$getMonthRangeFor = function (dates) {
	return A2(
		$elm$core$Maybe$map,
		function (_v1) {
			var start = _v1.a;
			var end = _v1.b;
			return _Utils_eq(start, end) ? $author$project$DeliveryDate$Within(start) : A2($author$project$DeliveryDate$Spanning, start, end);
		},
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				var head = _v0.a;
				var tail = _v0.b;
				return _Utils_Tuple2(
					head,
					A2(
						$elm$core$Maybe$withDefault,
						head,
						$elm_community$list_extra$List$Extra$last(tail)));
			},
			$elm_community$list_extra$List$Extra$uncons(
				A2(
					$elm$core$List$map,
					$elm$time$Time$toMonth($elm$time$Time$utc),
					A2(
						$elm$core$List$map,
						function ($) {
							return $.aG;
						},
						A2(
							$elm$core$List$sortBy,
							function (date) {
								return $elm$time$Time$posixToMillis(date.aG);
							},
							dates))))));
};
var $author$project$DeliveryDate$stringFromMonthRange = function (monthRange) {
	if (!monthRange.$) {
		var month = monthRange.a;
		return $author$project$DeliveryDate$toEnglishMonth(month);
	} else {
		var start = monthRange.a;
		var end = monthRange.b;
		return $author$project$DeliveryDate$toEnglishMonth(start) + ('/' + $author$project$DeliveryDate$toEnglishMonth(end));
	}
};
var $author$project$Page$Checkout$datePicker = F2(
	function (env, config) {
		var monthRangeText = A2(
			$elm$core$Maybe$withDefault,
			'Months',
			A2(
				$elm$core$Maybe$map,
				$author$project$DeliveryDate$stringFromMonthRange,
				$author$project$DeliveryDate$getMonthRangeFor(config.aY)));
		var mkDatePickerCell = function (deliveryDate) {
			return A2(
				$author$project$Page$Checkout$datePickerCell,
				env,
				{
					K: deliveryDate,
					bA: _Utils_eq(config.aT, deliveryDate.aG),
					ci: config.bH(deliveryDate.aG)
				});
		};
		var activeDayOfWeek = $author$project$DeliveryDate$weekdayToEnglish(
			A2($elm$time$Time$toWeekday, $elm$time$Time$utc, config.aT));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('date-picker__wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('date-picker__modal')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal__close-button')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.dj;
												})),
											$elm$html$Html$Events$onClick(config.cj)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-center')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(monthRangeText)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('date-picker')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('date-picker__header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('M')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('T')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('W')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('T')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('F')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('S')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('date-picker__cell')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('S')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('date-picker__body')
										]),
									A2($elm$core$List$map, mkDatePickerCell, config.aY)),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center explainer-text')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('explainer-text__delivery-day')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(activeDayOfWeek)
												])),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(' will be your regular delivery day. You can always change this in your account.')
												]))
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn--blue'),
											$elm$html$Html$Events$onClick(config.cj)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Got it!')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Checkout$viewOverlays = function (model) {
	var _v0 = model.l;
	if (!_v0.$) {
		return $elm$html$Html$text('');
	} else {
		var _v1 = _v0.a;
		var config = {
			aT: model.a.e,
			aY: $author$project$Session$deliveryDates(model.f),
			bH: $author$project$Page$Checkout$FirstDeliveryDateChanged,
			cj: $author$project$Page$Checkout$CloseDeliveryDateModal
		};
		return A2($author$project$Page$Checkout$datePicker, model.c, config);
	}
};
var $author$project$Page$Checkout$PostCodeChanged = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Checkout$SetAddress = function (a) {
	return {$: 26, a: a};
};
var $author$project$Page$Checkout$StartPostCodeSearch = function (a) {
	return {$: 24, a: a};
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Translations$Checkout$postcodePlaceholder = 'Postcode';
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Page$Checkout$viewPostCodeInput = function (postCodeInput) {
	switch (postCodeInput.$) {
		case 0:
			var searchTerm = postCodeInput.a;
			var isDisabled = $elm$core$String$isEmpty(searchTerm);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('postcode-search')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput($author$project$Page$Checkout$PostCodeChanged),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$Checkout$FieldInteractedWith(6)),
								$elm$html$Html$Attributes$id(
								$author$project$Page$Checkout$idForFormField(6)),
								$elm$html$Html$Attributes$value(searchTerm),
								$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$postcodePlaceholder),
								$elm$html$Html$Attributes$class('input-row__input')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Page$Checkout$StartPostCodeSearch(searchTerm)),
								$elm$html$Html$Attributes$disabled(isDisabled),
								$elm$html$Html$Attributes$class('btn btn--blue')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('search')
							]))
					]));
		case 2:
			var searchTerm = postCodeInput.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('postcode-search')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$id(
								$author$project$Page$Checkout$idForFormField(6)),
								$elm$html$Html$Attributes$value(searchTerm),
								$elm$html$Html$Attributes$class('input-row__input'),
								$elm$html$Html$Attributes$readonly(true)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('btn btn--blue')
							]),
						_List_fromArray(
							[$author$project$Page$Checkout$loadingSpinner]))
					]));
		case 1:
			var searchTerm = postCodeInput.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('postcode-search')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput($author$project$Page$Checkout$PostCodeChanged),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$Checkout$FieldInteractedWith(6)),
								$elm$html$Html$Attributes$id(
								$author$project$Page$Checkout$idForFormField(6)),
								$elm$html$Html$Attributes$value(searchTerm),
								$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$postcodePlaceholder),
								$elm$html$Html$Attributes$class('input-row__input')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('btn btn--blue')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('search')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('input-row__label--error')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Oops! Looks like you have no internet. Try searching again later or enter your address manually.')
							]))
					]));
		default:
			var searchTerm = postCodeInput.a;
			var addresses = postCodeInput.b;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('postcode-search')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput($author$project$Page$Checkout$PostCodeChanged),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$Checkout$FieldInteractedWith(6)),
								$elm$html$Html$Attributes$id(
								$author$project$Page$Checkout$idForFormField(6)),
								$elm$html$Html$Attributes$value(searchTerm),
								$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$postcodePlaceholder),
								$elm$html$Html$Attributes$class('input-row__input')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Page$Checkout$StartPostCodeSearch(searchTerm)),
								$elm$html$Html$Attributes$class('btn btn--blue')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('search')
							])),
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('postcode-search__results')
							]),
						A2(
							$elm$core$List$map,
							function (address) {
								return A2(
									$elm$html$Html$li,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('postcode-search__results__item'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Checkout$SetAddress(address))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2(
												$elm$core$String$join,
												', ',
												_List_fromArray(
													[
														$author$project$Address$address1(address),
														$author$project$Address$address2(address)
													])))
										]));
							},
							addresses))
					]));
	}
};
var $author$project$Page$Checkout$view = function (model) {
	var formattedDate = $author$project$DeliveryDate$formatPosix(model.a.e);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('app')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('content content--no-footer')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid checkout')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Page$Checkout$orderSummary,
										model.c,
										{
											al: $author$project$Session$activeDiscountCode(model.f),
											j: model.j,
											G: model.G,
											r: model.Y
										})
									]))
							])),
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid account-details')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h2,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Checkout$accountDetailsTitle)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row input-row--two-col')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row--with-label')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Events$onInput($author$project$Page$Checkout$FirstNameChanged),
																$elm$html$Html$Events$onBlur(
																$author$project$Page$Checkout$FieldInteractedWith(0)),
																$elm$html$Html$Attributes$id(
																$author$project$Page$Checkout$idForFormField(0)),
																$elm$html$Html$Attributes$value(model.a.b3),
																$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$firstNamePlaceholer),
																$elm$html$Html$Attributes$class('input-row__input')
															]),
														_List_Nil),
														A2($author$project$Page$Checkout$viewErrorIfPresent, model, 0)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row--with-label')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Events$onInput($author$project$Page$Checkout$LastNameChanged),
																$elm$html$Html$Events$onBlur(
																$author$project$Page$Checkout$FieldInteractedWith(1)),
																$elm$html$Html$Attributes$id(
																$author$project$Page$Checkout$idForFormField(1)),
																$elm$html$Html$Attributes$value(model.a.d_),
																$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$lastNamePlaceholder),
																$elm$html$Html$Attributes$class('input-row__input')
															]),
														_List_Nil),
														A2($author$project$Page$Checkout$viewErrorIfPresent, model, 1)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('email'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$EmailChanged),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(2)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(2)),
														$elm$html$Html$Attributes$value(model.a.o),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$emailPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 2)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('email'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$EmailConfirmationChanged),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(3)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(3)),
														$elm$html$Html$Attributes$value(model.a.y),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$reEnterEmailPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 3)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('password'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$PasswordChanged),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(5)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(5)),
														$elm$html$Html$Attributes$autocomplete(false),
														$elm$html$Html$Attributes$value(model.a.A),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$passwordPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 5),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row__label')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$passwordDetail)
													])),
												$author$project$Page$Checkout$viewLeadCaptureConsent(model.P.ap)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid account-details')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h2,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Checkout$deliveryAddressTitle)
											])),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												$author$project$Page$Checkout$viewPostCodeInput(model.Q),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 6)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$Address1Changed),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(7)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(7)),
														$elm$html$Html$Attributes$value(model.a.bR),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$streetPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 7)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$Address2Changed),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(8)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(8)),
														$elm$html$Html$Attributes$value(model.a.bS),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$buildingInformationPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$TownChanged),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(9)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(9)),
														$elm$html$Html$Attributes$value(model.a.cK),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$townCityPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 9)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A3(
												$author$project$Page$Checkout$tooltip,
												{
													c: model.c,
													a7: model.ac,
													bl: $author$project$Page$Checkout$ToggleTooltip(0),
													bm: $author$project$Translations$Checkout$ifImNotInTooltipTitle
												},
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$ifImNotInTooltipContent)
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid account-details')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h2,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Checkout$deliveryDayTitle)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Attributes$readonly(true),
														$elm$html$Html$Events$onClick($author$project$Page$Checkout$OpenDeliveryDateModal),
														$elm$html$Html$Attributes$value(
														$author$project$Translations$Checkout$datePickerValue(
															{K: model.a.e})),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$datePickerPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input date-picker__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 4),
												$author$project$Page$Checkout$viewDatePickerDetailIfDateSelect(model.a.e)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-row--with-label')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('tel'),
														$elm$html$Html$Events$onInput($author$project$Page$Checkout$PhoneNumberChanged),
														$elm$html$Html$Events$onBlur(
														$author$project$Page$Checkout$FieldInteractedWith(10)),
														$elm$html$Html$Attributes$id(
														$author$project$Page$Checkout$idForFormField(10)),
														$elm$html$Html$Attributes$value(model.a.B),
														$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$mobileNumberPlaceholder),
														$elm$html$Html$Attributes$class('input-row__input')
													]),
												_List_Nil),
												A2($author$project$Page$Checkout$viewErrorIfPresent, model, 10),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row__label')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$mobileNumberDetail)
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid payment-details')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('payment-details col-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('payment-details__panel')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h2,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-center')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$paymentDetailsTitle)
													])),
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(
														A2(
															$author$project$Env$asset,
															model.c,
															function ($) {
																return $.ew;
															}))
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row--with-label')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('tel'),
																$elm$html$Html$Events$onInput($author$project$Page$Checkout$CardNumberChanged),
																$elm$html$Html$Events$onBlur(
																$author$project$Page$Checkout$FieldInteractedWith(11)),
																$elm$html$Html$Attributes$id(
																$author$project$Page$Checkout$idForFormField(11)),
																$elm$html$Html$Attributes$value(
																$author$project$Localisation$cardNumber(model.a.w)),
																$elm$html$Html$Attributes$class('input-row__input'),
																$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$cardNumberPlaceholder)
															]),
														_List_Nil),
														A2($author$project$Page$Checkout$viewErrorIfPresent, model, 11)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row input-row--two-col no-margin--top')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-row--with-label select-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('select-row__item')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$select,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$Events$on,
																				'change',
																				A2($elm$json$Json$Decode$map, $author$project$Page$Checkout$CardExpMonthChanged, $elm$html$Html$Events$targetValue)),
																				$elm$html$Html$Attributes$value(model.a.u)
																			]),
																		A2(
																			$elm$core$List$map,
																			function (month) {
																				return A2(
																					$elm$html$Html$option,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							$elm$core$String$fromInt(month))
																						]));
																			},
																			A2($elm$core$List$range, 1, 12)))
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('select-row__item')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$select,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$Events$on,
																				'change',
																				A2($elm$json$Json$Decode$map, $author$project$Page$Checkout$CardExpYearChanged, $elm$html$Html$Events$targetValue)),
																				$elm$html$Html$Attributes$value(model.a.v)
																			]),
																		A2(
																			$elm$core$List$map,
																			function (year) {
																				return A2(
																					$elm$html$Html$option,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							$elm$core$String$fromInt(year))
																						]));
																			},
																			A2($elm$core$List$range, 2019, 2030)))
																	])),
																A2($author$project$Page$Checkout$viewErrorIfPresent, model, 12),
																A2($author$project$Page$Checkout$viewErrorIfPresent, model, 13)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-row--with-label')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$type_('number'),
																		$elm$html$Html$Events$onInput($author$project$Page$Checkout$CardCVCChanged),
																		$elm$html$Html$Events$onBlur(
																		$author$project$Page$Checkout$FieldInteractedWith(14)),
																		$elm$html$Html$Attributes$id(
																		$author$project$Page$Checkout$idForFormField(14)),
																		$elm$html$Html$Attributes$value(model.a.t),
																		$elm$html$Html$Attributes$class('input-row__input'),
																		$elm$html$Html$Attributes$placeholder($author$project$Translations$Checkout$cvcPlaceholder)
																	]),
																_List_Nil),
																A2($author$project$Page$Checkout$viewErrorIfPresent, model, 14)
															]))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-center')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$commitmentDetail)
													])),
												A2($author$project$Page$Checkout$buySubscriptionButton, model.ag, model.I),
												A2(
												$elm$html$Html$small,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-center')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Checkout$termsAndConditions)
													]))
											])),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.eZ;
													}))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-center text-white')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Checkout$trustpilotDetail)
											]))
									]))
							]))
					])),
				$author$project$Page$Checkout$viewOverlays(model)
			]));
};
var $author$project$Page$ChoosePlan$CloseAccountBenefitsModal = {$: 4};
var $author$project$Translations$ChoosePlan$betterDigestionDetail = 'We gently cook our meals for easier digestion and fewer upset tummies.';
var $author$project$Translations$ChoosePlan$betterDigestionTitle = 'Better digestion';
var $author$project$Translations$ChoosePlan$convenientDeliveryDetail = 'Free and regular deliveries to your door.';
var $author$project$Translations$ChoosePlan$convenientDeliveryTitle = 'Convenient Deliveries';
var $author$project$Translations$ChoosePlan$flexiblePlanDetail = 'Change delivery dates and choose your recipes.';
var $author$project$Translations$ChoosePlan$flexiblePlanTitle = 'Flexible Plan';
var $author$project$Translations$ChoosePlan$healthBenefitsDetail = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount > 1) ? 'Feed freshly prepared food for healthier and happier pups.' : 'Feed freshly prepared food for a happier and healthier pup.';
};
var $author$project$Translations$ChoosePlan$healthBenefitsTitle = 'Butternut health benefits';
var $author$project$Translations$ChoosePlan$healthyCoatsDetail = 'We use cold-pressed linseed oil and milled flax for a natural shine.';
var $author$project$Translations$ChoosePlan$healthyCoatsTitle = 'Healthy, shiny coats';
var $author$project$Translations$ChoosePlan$moreEnergyDetail = 'With healthy carbs that release slow energy (like sweet potato).';
var $author$project$Translations$ChoosePlan$moreEnergyTitle = 'More energy';
var $author$project$Translations$ChoosePlan$nurseHelplineDetail = 'For all worries, big and small.';
var $author$project$Translations$ChoosePlan$nurseHelplineTitle = '24hr Vet Nurse helpline';
var $author$project$Translations$ChoosePlan$yourAccount = 'Your account';
var $author$project$Translations$ChoosePlan$yourAccountDetail = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount > 1) ? 'Life should be easy, for you and your dogs.' : 'Life should be easy, for you and your dog.';
};
var $author$project$Page$ChoosePlan$accountBenefitsModal = F2(
	function (env, dogs) {
		var dogCount = $elm$core$List$length(dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal--fullscreen')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Page$ChoosePlan$CloseAccountBenefitsModal),
							$elm$html$Html$Attributes$class('modal--fullscreen__close-button')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.dj;
										}))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal--fullscreen--top no-padding your-account ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--top__title text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$ChoosePlan$yourAccount)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--top__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$ChoosePlan$yourAccountDetail(
										{bs: dogCount}))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('grid no-padding--bottom')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.e5;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$nurseHelplineTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$nurseHelplineDetail)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.dr;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$convenientDeliveryTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$convenientDeliveryDetail)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.dG;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$flexiblePlanTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$flexiblePlanDetail)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal--fullscreen--bottom no-padding bb-health-benefits')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--bottom__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$ChoosePlan$healthBenefitsTitle)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--bottom__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$ChoosePlan$healthBenefitsDetail(
										{bs: dogCount}))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('grid no-padding--bottom')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.cY;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$betterDigestionTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$betterDigestionDetail)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.eJ;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$healthyCoatsTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$healthyCoatsDetail)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-4 text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.eb;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--bottom')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$moreEnergyTitle)
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$Translations$ChoosePlan$moreEnergyDetail)
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$ChoosePlan$CloseBoxContentsModal = {$: 5};
var $author$project$Page$ChoosePlan$StartCheckout = function (a) {
	return {$: 1, a: a};
};
var $author$project$Translations$ChoosePlan$allButternutPlanTitle = 'All Butternut Plan';
var $author$project$Translations$ChoosePlan$mixedPlanTitle = 'Mixed Plan ';
var $author$project$Translations$Recipes$recipeToString = function (recipe) {
	switch (recipe) {
		case 0:
			return 'Beef';
		case 1:
			return 'Chicken';
		case 2:
			return 'Turkey';
		case 3:
			return 'Lamb';
		case 4:
			return 'White Fish';
		case 5:
			return 'Pork';
		default:
			return 'Duck & Chicken';
	}
};
var $author$project$Page$ChoosePlan$boxContentsModal = F3(
	function (env, plan, recipes) {
		var trialBoxPouches = 'You\'ll get ' + ($elm$core$String$fromInt(
			$author$project$Plan$pouchesInTrial(plan)) + (' x ' + ($elm$core$String$fromInt(plan.ev) + 'g pouches.')));
		var trialBoxLength = $author$project$Plan$trialDurationInWeeks(plan);
		var weeksCopy = (trialBoxLength > 1) ? 'weeks\'' : 'week\'s';
		var trialBoxSubtitle = $elm$core$String$fromInt(trialBoxLength) + (' ' + (weeksCopy + ' worth of fresh food, delivered frozen in an insulated box.'));
		var ongoingBoxesSubtitle = $elm$core$String$fromInt(
			$author$project$Plan$deliveryIntervalInWeeks(plan)) + ' weeks worth of fresh food delivered frozen in an insulated box.';
		var ongoingBoxesPouches = 'You\'ll get ' + ($elm$core$String$fromInt(plan.ej) + (' x ' + ($elm$core$String$fromInt(plan.ev) + ('g pouches every ' + ($elm$core$String$fromInt(
			$author$project$Plan$deliveryIntervalInWeeks(plan)) + ' weeks.')))));
		var joinedRecipes = $author$project$String$Helpers$combineNouns(
			A2($elm$core$List$map, $author$project$Translations$Recipes$recipeToString, recipes));
		var ongoingBoxesRecipeExplanation = ($elm$core$List$length(recipes) > 1) ? ('Choose a mix of ' + (joinedRecipes + ' recipes based on what they love.')) : 'Continue to enjoy the perfect amount of Butternut.';
		var trialBoxRecipeExplanation = ($elm$core$List$length(recipes) > 1) ? ('A mix of ' + (joinedRecipes + ' recipes based on what they love.')) : ('Try out our ' + (joinedRecipes + ' recipe and see what they think.'));
		var callToAction = function () {
			var _v0 = plan.cn;
			if (!_v0) {
				return 'Start my ' + $author$project$Translations$ChoosePlan$allButternutPlanTitle;
			} else {
				return 'Start my ' + $author$project$Translations$ChoosePlan$mixedPlanTitle;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal--fullscreen')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Page$ChoosePlan$CloseBoxContentsModal),
							$elm$html$Html$Attributes$class('modal--fullscreen__close-button')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.dj;
										}))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal--fullscreen--top')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--top__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Your trial box')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--top__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(trialBoxSubtitle)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trial-box')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('box-img'),
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.eY;
												}))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-1')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(trialBoxPouches)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(trialBoxRecipeExplanation)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-3')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Welcome pack, including transitioning guide.')
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal--fullscreen--bottom')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--bottom__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Your ongoing boxes')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal--fullscreen--bottom__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(ongoingBoxesSubtitle)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ongoing-box')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('box-img'),
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.en;
												}))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-1')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(ongoingBoxesPouches)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(ongoingBoxesRecipeExplanation)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('point-3')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.de;
														}))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('A waggy tail that’s off the scale at meal times.')
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sprinkle--top-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Page$ChoosePlan$StartCheckout(plan)),
											$elm$html$Html$Attributes$class('btn btn--large btn--blue')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(callToAction),
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.eQ;
														}))
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$Page$ChoosePlan$OpenAccountBenefitsModal = {$: 2};
var $author$project$TrustPilot$currentRating = 'Excellent';
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm_community$list_extra$List$Extra$minimumBy = F2(
	function (f, ls) {
		var minBy = F2(
			function (x, _v1) {
				var y = _v1.a;
				var fy = _v1.b;
				var fx = f(x);
				return (_Utils_cmp(fx, fy) < 0) ? _Utils_Tuple2(x, fx) : _Utils_Tuple2(y, fy);
			});
		if (ls.b) {
			if (!ls.b.b) {
				var l_ = ls.a;
				return $elm$core$Maybe$Just(l_);
			} else {
				var l_ = ls.a;
				var ls_ = ls.b;
				return $elm$core$Maybe$Just(
					A3(
						$elm$core$List$foldl,
						minBy,
						_Utils_Tuple2(
							l_,
							f(l_)),
						ls_).a);
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Maybe$Helpers$orElse = F2(
	function (y, x) {
		return _Utils_eq(x, $elm$core$Maybe$Nothing) ? y : x;
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Plan$findPlan = F3(
	function (planType, targetGrams, plans) {
		var absoluteDistance = function (plan) {
			return $elm$core$Basics$abs(
				targetGrams - $author$project$Plan$gramsPerDay(plan));
		};
		var search = $elm_community$list_extra$List$Extra$minimumBy(absoluteDistance);
		var _v0 = A2(
			$elm$core$List$partition,
			function (plan) {
				return _Utils_cmp(
					$author$project$Plan$gramsPerDay(plan),
					targetGrams) < 0;
			},
			plans);
		var smaller = _v0.a;
		var larger = _v0.b;
		if (!planType) {
			return search(plans);
		} else {
			return A2(
				$author$project$Maybe$Helpers$orElse,
				search(smaller),
				search(larger));
		}
	});
var $author$project$Plan$searchParameters = function (grams) {
	return (grams < 163) ? _List_fromArray(
		[
			_Utils_Tuple2(grams, 0)
		]) : ((grams > 1200) ? _List_fromArray(
		[
			_Utils_Tuple2(grams, 1),
			_Utils_Tuple2((grams / 2) | 0, 1)
		]) : _List_fromArray(
		[
			_Utils_Tuple2(grams, 0),
			_Utils_Tuple2((grams / 2) | 0, 1)
		]));
};
var $author$project$Plan$kcalsPerGram = 1.4;
var $author$project$Plan$toGrams = function (calories) {
	return $elm$core$Basics$round(calories / $author$project$Plan$kcalsPerGram);
};
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$Plan$find = F2(
	function (allPlans, calories) {
		var targetGrams = $author$project$Plan$toGrams(calories);
		var findClosest = function (_v0) {
			var grams = _v0.a;
			var planType = _v0.b;
			return A3(
				$author$project$Plan$findPlan,
				planType,
				grams,
				A2(
					$elm$core$List$sortBy,
					$author$project$Plan$gramsPerDay,
					A2(
						$elm$core$List$filter,
						function (plan) {
							return _Utils_eq(plan.cn, planType);
						},
						allPlans)));
		};
		var addPercentageOfCalories = function (plan) {
			return _Utils_Tuple2(
				plan,
				((100 * $author$project$Plan$gramsPerDay(plan)) / targetGrams) | 0);
		};
		return A2(
			$elm$core$List$map,
			addPercentageOfCalories,
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.a3;
				},
				$author$project$List$Helpers$catMaybes(
					A2(
						$elm$core$List$map,
						findClosest,
						$author$project$Plan$searchParameters(targetGrams)))));
	});
var $author$project$Translations$ChoosePlan$footerButton = 'See benefits';
var $author$project$Translations$ChoosePlan$footerDetail = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount > 1) ? 'Even more reasons why you and your dogs will love Butternut' : 'Even more reasons why you and your dog will love Butternut';
};
var $author$project$Translations$ChoosePlan$footerTitle = 'There\'s more!';
var $author$project$TrustPilot$forPlansPage = _List_fromArray(
	[
		{ai: 'Janet McKenna', ak: 'This is excellent quality food my 15yr old toy poodle loves it - it helped save his life after a serious bout of pancreatitis over a year ago - we are just trying our others on it and they iove it too ! Friendly helpful people plus excellent service from ordering to delivery - wish it had been around when these were puppies but better late than never !', cJ: 'This is excellent quality food my 15yr…'},
		{ai: 'Sarah Barnes', ak: 'After YEARS of trying every kind of gourmet dog food and my dog turning her nose up at everything I have finally found a food she loves and what\'s more it is really good for her. This is without doubt the best dog food and it comes conveniently packaged and frozen. Superb!', cJ: 'Fussy dog heaven!'}
	]);
var $elm$core$Basics$pow = _Basics_pow;
var $author$project$FeedingGuidelines$adultKcalsPerDay = F3(
	function (bodyCondition, activityLevel, mass) {
		var massExponent = 3 / 4;
		var kCalsPerDayPerKg = function () {
			var _v0 = _Utils_Tuple2(bodyCondition, activityLevel);
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return 121.0;
				case 2:
					var _v2 = _v0.a;
					return 86.0;
				default:
					switch (_v0.b) {
						case 0:
							var _v3 = _v0.a;
							var _v4 = _v0.b;
							return 95.0;
						case 1:
							var _v5 = _v0.a;
							var _v6 = _v0.b;
							return 102.0;
						case 2:
							var _v7 = _v0.a;
							var _v8 = _v0.b;
							return 102.0;
						case 3:
							var _v9 = _v0.a;
							var _v10 = _v0.b;
							return 102.0;
						case 4:
							var _v11 = _v0.a;
							var _v12 = _v0.b;
							return 110.0;
						default:
							var _v13 = _v0.a;
							var _v14 = _v0.b;
							return 110.0;
					}
			}
		}();
		return (mass >= 100) ? $elm$core$Maybe$Nothing : A3(
			$elm$core$Basics$composeL,
			$elm$core$Maybe$Just,
			$elm$core$Basics$round,
			A2($elm$core$Basics$pow, mass, massExponent) * kCalsPerDayPerKg);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{i: nodeList, d: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $author$project$FeedingGuidelines$Puppy$lookUpTable = $elm$core$Array$fromList(
	A2(
		$elm$core$List$map,
		$elm$core$Array$fromList,
		_List_fromArray(
			[
				_List_Nil,
				_List_fromArray(
				[145, 145, 145, 145, 141, 137, 121, 128, 126, 124, 111, 103, 97, 93, 87, 84, 79, 76, 75]),
				_List_fromArray(
				[243, 243, 243, 243, 238, 231, 203, 215, 212, 209, 186, 173, 163, 156, 146, 141, 132, 128, 126]),
				_List_fromArray(
				[365, 365, 365, 365, 322, 313, 290, 291, 288, 283, 252, 234, 221, 212, 197, 192, 180, 174, 171]),
				_List_fromArray(
				[453, 453, 453, 453, 410, 388, 360, 361, 357, 352, 313, 290, 275, 263, 245, 238, 223, 215, 212]),
				_List_fromArray(
				[535, 535, 535, 535, 485, 459, 426, 426, 422, 416, 370, 343, 325, 311, 290, 281, 263, 255, 251]),
				_List_fromArray(
				[630, 630, 630, 630, 556, 540, 514, 489, 484, 477, 424, 393, 372, 356, 332, 322, 302, 292, 287]),
				_List_fromArray(
				[707, 707, 707, 707, 624, 606, 577, 564, 543, 535, 477, 442, 418, 400, 373, 362, 339, 328, 323]),
				_List_fromArray(
				[781, 781, 781, 781, 708, 670, 638, 623, 601, 592, 542, 516, 475, 455, 424, 411, 385, 373, 365]),
				_List_fromArray(
				[875, 875, 875, 875, 773, 732, 697, 681, 656, 646, 592, 564, 519, 497, 463, 449, 421, 407, 399]),
				_List_fromArray(
				[947, 947, 947, 947, 837, 792, 755, 737, 710, 699, 640, 610, 562, 538, 501, 486, 456, 440, 431]),
				_List_fromArray(
				[1017, 1017, 1017, 1017, 899, 873, 832, 792, 763, 751, 688, 656, 604, 577, 538, 522, 489, 473, 463]),
				_List_fromArray(
				[1086, 1086, 1086, 1086, 960, 932, 888, 845, 814, 802, 734, 700, 644, 616, 575, 557, 522, 505, 495]),
				_List_fromArray(
				[1182, 1182, 1182, 1182, 1046, 989, 943, 922, 888, 851, 780, 743, 684, 655, 610, 592, 555, 536, 525]),
				_List_fromArray(
				[1249, 1249, 1249, 1249, 1105, 1046, 997, 974, 939, 900, 824, 786, 723, 692, 645, 625, 586, 567, 555]),
				_List_fromArray(
				[1316, 1316, 1316, 1316, 1164, 1101, 1050, 1026, 989, 948, 893, 851, 783, 749, 699, 677, 635, 614, 601]),
				_List_fromArray(
				[1381, 1381, 1381, 1381, 1222, 1156, 1102, 1077, 1038, 995, 937, 893, 822, 787, 733, 711, 667, 644, 631]),
				_List_fromArray(
				[1445, 1445, 1445, 1445, 1279, 1210, 1153, 1127, 1086, 1041, 980, 934, 860, 823, 767, 744, 698, 674, 660]),
				_List_fromArray(
				[1509, 1509, 1509, 1509, 1335, 1263, 1204, 1177, 1134, 1087, 1023, 975, 898, 859, 801, 777, 728, 704, 689]),
				_List_fromArray(
				[1571, 1571, 1571, 1571, 1426, 1349, 1286, 1225, 1181, 1132, 1066, 1016, 935, 895, 834, 809, 758, 733, 718]),
				_List_fromArray(
				[1633, 1633, 1633, 1633, 1482, 1402, 1337, 1273, 1227, 1176, 1108, 1055, 972, 930, 867, 841, 788, 762, 746]),
				_List_fromArray(
				[1693, 1693, 1693, 1693, 1537, 1454, 1387, 1321, 1273, 1220, 1149, 1095, 1008, 965, 899, 872, 817, 790, 774]),
				_List_fromArray(
				[1754, 1754, 1754, 1754, 1592, 1506, 1436, 1368, 1318, 1263, 1190, 1134, 1044, 999, 931, 903, 846, 818, 801]),
				_List_fromArray(
				[-1, -1, -1, -1, 1646, 1557, 1485, 1453, 1400, 1342, 1230, 1172, 1079, 1033, 963, 934, 875, 846, 828]),
				_List_fromArray(
				[-1, -1, -1, -1, 1699, 1607, 1533, 1500, 1445, 1386, 1270, 1210, 1114, 1066, 994, 964, 904, 873, 855]),
				_List_fromArray(
				[-1, -1, -1, -1, 1752, 1657, 1580, 1546, 1490, 1429, 1309, 1248, 1149, 1099, 1025, 994, 932, 901, 882]),
				_List_fromArray(
				[-1, -1, -1, -1, 1804, 1707, 1628, 1592, 1535, 1471, 1386, 1321, 1217, 1164, 1085, 1052, 987, 954, 934]),
				_List_fromArray(
				[-1, -1, -1, -1, 1856, 1756, 1674, 1638, 1579, 1513, 1426, 1359, 1252, 1198, 1116, 1083, 1015, 981, 961]),
				_List_fromArray(
				[-1, -1, -1, -1, 1907, 1851, 1766, 1683, 1622, 1555, 1466, 1396, 1286, 1231, 1147, 1113, 1043, 1008, 987]),
				_List_fromArray(
				[-1, -1, -1, -1, 1958, 1900, 1813, 1728, 1666, 1597, 1505, 1434, 1321, 1264, 1178, 1142, 1071, 1035, 1014]),
				_List_fromArray(
				[-1, -1, -1, -1, 2008, 1949, 1860, 1773, 1709, 1638, 1543, 1470, 1393, 1333, 1242, 1205, 1129, 1092, 1069]),
				_List_fromArray(
				[-1, -1, -1, -1, 2058, 1998, 1906, 1817, 1751, 1679, 1582, 1507, 1428, 1366, 1273, 1235, 1157, 1119, 1096]),
				_List_fromArray(
				[-1, -1, -1, -1, 2108, 2046, 1952, 1861, 1793, 1719, 1620, 1543, 1462, 1399, 1304, 1264, 1185, 1146, 1122]),
				_List_fromArray(
				[-1, -1, -1, -1, 2157, 2094, 1997, 1904, 1835, 1759, 1658, 1579, 1496, 1431, 1334, 1294, 1213, 1173, 1148]),
				_List_fromArray(
				[-1, -1, -1, -1, 2206, 2141, 2043, 1947, 1877, 1799, 1695, 1615, 1530, 1464, 1365, 1323, 1240, 1199, 1174]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2188, 2087, 2044, 1970, 1889, 1733, 1651, 1564, 1496, 1394, 1352, 1268, 1225, 1200]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2235, 2132, 2088, 2012, 1929, 1770, 1686, 1597, 1528, 1424, 1381, 1295, 1252, 1226]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2281, 2176, 2131, 2054, 1969, 1806, 1721, 1630, 1560, 1454, 1410, 1322, 1278, 1251]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2328, 2220, 2174, 2096, 2009, 1843, 1756, 1663, 1591, 1483, 1438, 1348, 1303, 1276]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2373, 2264, 2217, 2137, 2049, 1879, 1790, 1696, 1622, 1512, 1467, 1375, 1329, 1302]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2419, 2307, 2259, 2178, 2088, 1969, 1875, 1728, 1653, 1541, 1495, 1401, 1355, 1327]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2464, 2350, 2302, 2218, 2127, 2005, 1910, 1761, 1684, 1570, 1523, 1427, 1380, 1351]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2509, 2393, 2344, 2259, 2166, 2042, 1945, 1793, 1715, 1599, 1550, 1453, 1405, 1376]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2554, 2436, 2385, 2299, 2204, 2078, 1980, 1825, 1746, 1627, 1578, 1479, 1430, 1400]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2598, 2478, 2427, 2339, 2243, 2115, 2014, 1856, 1776, 1656, 1605, 1505, 1455, 1425]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2642, 2520, 2468, 2379, 2281, 2150, 2049, 1888, 1806, 1684, 1633, 1531, 1480, 1449]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2686, 2562, 2509, 2418, 2319, 2186, 2083, 1919, 1836, 1712, 1660, 1556, 1504, 1473]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2730, 2604, 2550, 2458, 2356, 2222, 2116, 1950, 1866, 1740, 1687, 1581, 1529, 1497]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2773, 2645, 2590, 2497, 2394, 2257, 2150, 1981, 1896, 1767, 1714, 1607, 1553, 1521]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2816, 2687, 2631, 2536, 2431, 2292, 2184, 2012, 1925, 1795, 1740, 1632, 1577, 1545]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2859, 2728, 2671, 2574, 2468, 2327, 2217, 2043, 1955, 1822, 1767, 1657, 1601, 1568]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2902, 2769, 2711, 2613, 2505, 2362, 2250, 2074, 1984, 1849, 1793, 1681, 1625, 1592]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2945, 2809, 2751, 2651, 2542, 2397, 2283, 2104, 2013, 1877, 1820, 1706, 1649, 1615]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 2987, 2850, 2790, 2689, 2579, 2431, 2316, 2134, 2042, 1904, 1846, 1731, 1673, 1638]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 3029, 2890, 2830, 2727, 2615, 2466, 2349, 2164, 2071, 1930, 1872, 1755, 1696, 1661]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, 3071, 2930, 2869, 2765, 2651, 2500, 2381, 2194, 2100, 1957, 1898, 1779, 1720, 1684]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 2908, 2803, 2687, 2534, 2414, 2224, 2128, 1984, 1924, 1803, 1743, 1707]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 2947, 2840, 2723, 2568, 2446, 2254, 2157, 2010, 1949, 1828, 1767, 1730]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 2985, 2878, 2759, 2601, 2478, 2284, 2185, 2037, 1975, 1852, 1790, 1753]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3024, 2915, 2795, 2635, 2510, 2313, 2213, 2063, 2001, 1875, 1813, 1775]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3062, 2952, 2830, 2668, 2542, 2342, 2241, 2089, 2026, 1899, 1836, 1798]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3100, 2989, 2865, 2702, 2574, 2372, 2269, 2115, 2051, 1923, 1859, 1820]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3139, 3025, 2900, 2735, 2605, 2401, 2297, 2141, 2076, 1947, 1882, 1843]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3176, 3062, 2935, 2768, 2637, 2430, 2325, 2167, 2101, 1970, 1904, 1865]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3214, 3098, 2970, 2801, 2668, 2459, 2352, 2193, 2126, 1993, 1927, 1887]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3252, 3134, 3005, 2833, 2699, 2487, 2380, 2218, 2151, 2017, 1950, 1909]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3289, 3170, 3040, 2866, 2730, 2516, 2407, 2244, 2176, 2040, 1972, 1931]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3326, 3206, 3074, 2899, 2761, 2545, 2435, 2269, 2201, 2063, 1994, 1953]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3364, 3242, 3109, 2931, 2792, 2573, 2462, 2295, 2225, 2086, 2017, 1975]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3401, 3278, 3143, 2963, 2823, 2601, 2489, 2320, 2250, 2109, 2039, 1997]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, 3438, 3313, 3177, 2995, 2853, 2630, 2516, 2345, 2274, 2132, 2061, 2018]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3027, 2884, 2658, 2543, 2370, 2299, 2155, 2083, 2040]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3059, 2914, 2686, 2570, 2395, 2323, 2178, 2105, 2061]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3091, 2945, 2714, 2596, 2420, 2347, 2200, 2127, 2083]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3123, 2975, 2741, 2623, 2445, 2371, 2223, 2149, 2104]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3154, 3005, 2769, 2649, 2470, 2395, 2245, 2170, 2126]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3186, 3035, 2797, 2676, 2494, 2419, 2268, 2192, 2147]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3217, 3065, 2824, 2702, 2519, 2443, 2290, 2214, 2168]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3249, 3095, 2852, 2729, 2544, 2466, 2312, 2235, 2189]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3280, 3124, 2879, 2755, 2568, 2490, 2335, 2257, 2210]),
				_List_fromArray(
				[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3311, 3154, 2907, 2781, 2592, 2514, 2357, 2278, 2231])
			])));
var $author$project$FeedingGuidelines$Puppy$kCalsPerDay = F2(
	function (monthsOld, mass) {
		return A2(
			$elm$core$Maybe$andThen,
			function (kCals) {
				return (kCals < 0) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(kCals);
			},
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$Array$get(monthsOld),
				A2($elm$core$Array$get, mass, $author$project$FeedingGuidelines$Puppy$lookUpTable)));
	});
var $author$project$FeedingGuidelines$toKilograms = function (g) {
	return $elm$core$Basics$round(g / 1000);
};
var $author$project$FeedingGuidelines$kCalsPerDay = F6(
	function (monthsOld, yearsOld, bodyCondition, activityLevel, snackingHabits, mass) {
		var scalingFactor = function () {
			switch (snackingHabits) {
				case 0:
					return 0.85;
				case 1:
					return 0.75;
				default:
					return 0.65;
			}
		}();
		var ageInMonths = (yearsOld * 12) + monthsOld;
		var isPuppy = ageInMonths <= 18;
		var toKcals = isPuppy ? $author$project$FeedingGuidelines$Puppy$kCalsPerDay(ageInMonths) : A2($author$project$FeedingGuidelines$adultKcalsPerDay, bodyCondition, activityLevel);
		return (mass >= 100000) ? $elm$core$Maybe$Nothing : A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$round,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$mul(scalingFactor),
				A2(
					$elm$core$Maybe$map,
					$elm$core$Basics$toFloat,
					toKcals(
						$author$project$FeedingGuidelines$toKilograms(mass)))));
	});
var $author$project$Translations$ChoosePlan$planTitle = 'Let\'s feed some dogs!';
var $author$project$Session$plans = function (_v0) {
	var session = _v0;
	return session.be;
};
var $author$project$Translations$ChoosePlan$allButternutCalorieDetail = 'Full daily meals, no need to top up with additional food.';
var $author$project$Translations$ChoosePlan$mixedPlanCalorieDetail = function (_v0) {
	var percentageProvided = _v0.et;
	var remainingCaloriesStr = $elm$core$String$fromInt(100 - percentageProvided);
	return 'Smaller meals, make up the remaining ' + (remainingCaloriesStr + '% with other food');
};
var $author$project$Translations$ChoosePlan$seeAllButternutBoxButton = 'See your All Butternut Box';
var $author$project$Translations$ChoosePlan$seeMixedPlanBoxButton = 'See your Mixed Plan Box';
var $author$project$Translations$ChoosePlan$startAllButternutPlanButton = 'Start All Butternut Plan';
var $author$project$Translations$ChoosePlan$startMixedPlanButton = 'Start Mixed Plan';
var $author$project$Page$ChoosePlan$OpenBoxContentsModal = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Translations$ChoosePlan$caloriePercentage = function (_v0) {
	var calories = _v0.c9;
	var caloriesToString = $elm$core$String$fromInt(calories);
	return caloriesToString + '% of your dogs calories';
};
var $author$project$Page$ChoosePlan$viewPlan = F2(
	function (env, config) {
		var trialBoxPricePerDay = function (price) {
			return (price / $author$project$Plan$trialDurationInDays(config.r)) | 0;
		}(
			A2(
				$author$project$DiscountCode$apply,
				config.al,
				$author$project$Plan$trialBoxPrice(config.r)));
		var tryBoxPriceExplainer = 'Try for ' + ($author$project$Localisation$price(trialBoxPricePerDay) + ' per day');
		var planInfoStr = $elm$core$String$fromInt(
			$author$project$Plan$pouchesInTrial(config.r)) + (' x ' + ($elm$core$String$fromInt(config.r.ev) + 'g pouches'));
		var dogNamesStr = 'For ' + config.aB;
		var boxAfterTrialPricePerDay = ($author$project$Plan$boxPriceAfterTrial(config.r) / config.r.dq) | 0;
		var boxAfterTrialPriceExplainer = ($author$project$DiscountCode$numberOfBoxes(config.al) > 1) ? ($author$project$Localisation$price(boxAfterTrialPricePerDay) + (' after first ' + ($elm$core$String$fromInt(
			$author$project$DiscountCode$numberOfBoxes(config.al)) + ' boxes'))) : ($author$project$Localisation$price(boxAfterTrialPricePerDay) + (' after ' + ($elm$core$String$fromInt(
			$author$project$Plan$trialDurationInWeeks(config.r)) + ' week trial')));
		var bestValueBanner = config.a6 ? A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('choose-plans__plan__banner'),
					$elm$html$Html$Attributes$src(
					A2(
						$author$project$Env$asset,
						env,
						function ($) {
							return $.cV;
						}))
				]),
			_List_Nil) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('choose-plans__plan')
				]),
			_List_fromArray(
				[
					bestValueBanner,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('choose-plans__plan__detail')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(config.a4)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plans__plan__detail__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(config.bd)
								])),
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('For ' + config.aB)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plans__plan__detail__list')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.dd;
												}))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('choose-plans__plan__detail__list__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--top')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Translations$ChoosePlan$caloriePercentage(
														{c9: config.aR}))
												])),
											A2(
											$elm$html$Html$small,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(config.aS)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plans__plan__detail__list')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.dd;
												}))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('choose-plans__plan__detail__list__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('no-margin--top')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(planInfoStr)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('choose-plans__plan__price')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plans__plan__price__amount')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(tryBoxPriceExplainer),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$small,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(boxAfterTrialPriceExplainer)
										]))
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(config.aV),
									$elm$html$Html$Attributes$class('btn btn--blue')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(config.aW)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Page$ChoosePlan$OpenBoxContentsModal(config.r)),
									$elm$html$Html$Attributes$class('btn btn--white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(config.bh)
								]))
						]))
				]));
	});
var $author$project$Page$ChoosePlan$showPlanCard = F4(
	function (env, discountCode, dogs, _v0) {
		var plan = _v0.a;
		var percentageProvided = _v0.b;
		var percentageMultipleOfFive = ((percentageProvided % 5) > 2) ? ((((percentageProvided / 5) | 0) * 5) + 5) : (((percentageProvided / 5) | 0) * 5);
		var dogNamesStr = $author$project$String$Helpers$combineNouns(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.cg;
				},
				dogs));
		var config = function () {
			var _v1 = plan.cn;
			if (!_v1) {
				return {
					aR: 100,
					aS: $author$project$Translations$ChoosePlan$allButternutCalorieDetail,
					aV: $author$project$Page$ChoosePlan$StartCheckout(plan),
					aW: $author$project$Translations$ChoosePlan$startAllButternutPlanButton,
					al: discountCode,
					aB: dogNamesStr,
					a4: A2(
						$author$project$Env$asset,
						env,
						function ($) {
							return $.fc;
						}),
					a6: true,
					r: plan,
					bd: $author$project$Translations$ChoosePlan$allButternutPlanTitle,
					bh: $author$project$Translations$ChoosePlan$seeAllButternutBoxButton
				};
			} else {
				return {
					aR: percentageMultipleOfFive,
					aS: $author$project$Translations$ChoosePlan$mixedPlanCalorieDetail(
						{et: percentageMultipleOfFive}),
					aV: $author$project$Page$ChoosePlan$StartCheckout(plan),
					aW: $author$project$Translations$ChoosePlan$startMixedPlanButton,
					al: discountCode,
					aB: dogNamesStr,
					a4: A2(
						$author$project$Env$asset,
						env,
						function ($) {
							return $.ea;
						}),
					a6: false,
					r: plan,
					bd: $author$project$Translations$ChoosePlan$mixedPlanTitle,
					bh: $author$project$Translations$ChoosePlan$seeMixedPlanBoxButton
				};
			}
		}();
		return A2($author$project$Page$ChoosePlan$viewPlan, env, config);
	});
var $author$project$Translations$ChoosePlan$trialDetail = 'Pick your plan and start with a trial box. After your trial, we\'ll send you a box on a regular schedule so you never run out of food again.';
var $author$project$Page$ChoosePlan$ToggleDogInclusion = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ChoosePlan$includeInPlanToggle = function (config) {
	var onChange = function (msg) {
		return A2(
			$elm$html$Html$Events$on,
			'change',
			$elm$json$Json$Decode$succeed(msg));
	};
	return A2(
		$elm$html$Html$label,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Attributes$checked(config.by),
						$elm$html$Html$Attributes$disabled(config.bz),
						onChange(config.bG)
					]),
				_List_Nil),
				$elm$html$Html$text(config.bt)
			]));
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Page$ChoosePlan$viewIncludeInPlanToggles = F2(
	function (dogs, enabledDogs) {
		var isChecked = function (id) {
			return A2($elm$core$Set$member, id, enabledDogs);
		};
		var isDisabled = function (id) {
			return isChecked(id) && ($elm$core$Set$size(enabledDogs) === 1);
		};
		var planToggle = F2(
			function (id, dog) {
				return $author$project$Page$ChoosePlan$includeInPlanToggle(
					{
						bt: dog.cg,
						by: isChecked(id),
						bz: isDisabled(id),
						bG: $author$project$Page$ChoosePlan$ToggleDogInclusion(id)
					});
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('choose-plan__hero__dog-toggle')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Choose plan for:')
						])),
				A2($elm$core$List$indexedMap, planToggle, dogs)));
	});
var $author$project$TrustPilot$viewReview = F2(
	function (env, review) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('trustpilot__review')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__review__rating')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('trustpilot__review__rating__stars')
										]),
									A2(
										$elm$core$List$repeat,
										5,
										A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.e1;
														}))
												]),
											_List_Nil)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__review__rating__timestamp')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('4 days ago')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__review__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(review.cJ)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__review__content')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(review.ak)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__review__author')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(review.ai)
								]))
						]))
				]));
	});
var $author$project$Page$ChoosePlan$plansView = F4(
	function (env, session, dogs, enabledDogIndices) {
		var enabledDogs = A2(
			$elm_community$list_extra$List$Extra$removeIfIndex,
			function (i) {
				return !A2($elm$core$Set$member, i, enabledDogIndices);
			},
			dogs);
		var discountCode = $author$project$Session$activeDiscountCode(session);
		var caloriesFor = function (dog) {
			return A6($author$project$FeedingGuidelines$kCalsPerDay, dog.ce, dog.cN, dog.bU, dog.bP, dog.cE, dog.cc);
		};
		var maybeAggregateCalorieRecommendation = A2(
			$elm$core$Maybe$map,
			$elm$core$List$sum,
			$author$project$List$Helpers$sequenceMaybes(
				A2($elm$core$List$map, caloriesFor, enabledDogs)));
		var plans = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$author$project$Plan$find(
					$author$project$Session$plans(session)),
				maybeAggregateCalorieRecommendation));
		var allDogsCount = $elm$core$List$length(dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('grid content content--no-footer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('choose-plan__hero')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plan__hero__title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$ChoosePlan$planTitle)
										]))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('choose-plan__hero__sub-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$ChoosePlan$trialDetail)
								])),
							A2($author$project$Page$ChoosePlan$viewIncludeInPlanToggles, dogs, enabledDogIndices)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('choose-plans grid--plans')
						]),
					A2(
						$elm$core$List$map,
						A3($author$project$Page$ChoosePlan$showPlanCard, env, discountCode, enabledDogs),
						plans)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('trustpilot--blue grid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('trustpilot__title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Rated '),
											A2(
											$elm$html$Html$strong,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text($author$project$TrustPilot$currentRating)
												])),
											$elm$html$Html$text(' on')
										])),
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											A2(
												$author$project$Env$asset,
												env,
												function ($) {
													return $.e_;
												}))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex--row')
								]),
							A2(
								$elm$core$List$map,
								$author$project$TrustPilot$viewReview(env),
								$author$project$TrustPilot$forPlansPage))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('choose-plan__footer')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$ChoosePlan$footerTitle)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white no-margin--top')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$ChoosePlan$footerDetail(
										{bs: allDogsCount}))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sprinkle--top-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Page$ChoosePlan$OpenAccountBenefitsModal),
											$elm$html$Html$Attributes$class('btn btn--pink')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text($author$project$Translations$ChoosePlan$footerButton),
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													A2(
														$author$project$Env$asset,
														env,
														function ($) {
															return $.eQ;
														}))
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$Page$ChoosePlan$view = function (model) {
	var _v0 = model.J;
	switch (_v0.$) {
		case 0:
			return A4($author$project$Page$ChoosePlan$plansView, model.c, model.f, model.j, model.F);
		case 1:
			return A2($author$project$Page$ChoosePlan$accountBenefitsModal, model.c, model.j);
		default:
			var plan = _v0.a;
			var recipes = _v0.b;
			return A3($author$project$Page$ChoosePlan$boxContentsModal, model.c, plan, recipes);
	}
};
var $author$project$Page$Login$AttemptLogin = {$: 2};
var $author$project$Page$Login$UpdateEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$UpdatePassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Translations$Login$emailPlaceholder = 'Email';
var $author$project$Translations$Login$forgotPassword = 'Forgot your password?';
var $author$project$Page$Login$loadingSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('spinner--ball-fall')
		]),
	A2(
		$elm$core$List$repeat,
		3,
		A2($elm$html$Html$div, _List_Nil, _List_Nil)));
var $author$project$Translations$Login$loginButton = 'log in';
var $author$project$Page$Login$loginIsDisabled = function (_v0) {
	var email = _v0.o;
	var password = _v0.A;
	var loginResultPending = _v0.ad;
	return loginResultPending || ($elm$core$String$isEmpty(email) || $elm$core$String$isEmpty(password));
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Page$Login$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $author$project$Page$Login$optionalLoginError = function (maybeError) {
	if (maybeError.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var error = maybeError.a;
		var message = function () {
			switch (error) {
				case 1:
					return 'Oops! Looks like those details are incorrect';
				case 0:
					return 'Oops! Looks like you\'re not connected to the internet';
				default:
					return 'Oops! Something went wrong trying to log in.';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input-row__label--error')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(message)
				]));
	}
};
var $author$project$Translations$Login$passwordPlaceholder = 'Password';
var $author$project$Translations$Login$subTitle = 'Log in and get your game face on';
var $author$project$Translations$Login$title = 'Welcome Back!';
var $author$project$Page$Login$view = function (_v0) {
	var env = _v0.a;
	var model = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('app')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('login')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('content content--center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.c2;
											})),
										$elm$html$Html$Attributes$class('login__logo')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('login__panel')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Login$title)
											])),
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('no-margin--top')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Login$subTitle)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('login__panel__fields')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row--with-label')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder($author$project$Translations$Login$emailPlaceholder),
																$elm$html$Html$Attributes$type_('email'),
																$elm$html$Html$Events$onInput($author$project$Page$Login$UpdateEmail),
																$elm$html$Html$Attributes$value(model.o)
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-row--with-label')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$autocomplete(false),
																$elm$html$Html$Attributes$placeholder($author$project$Translations$Login$passwordPlaceholder),
																$elm$html$Html$Attributes$type_('password'),
																$elm$html$Html$Events$onInput($author$project$Page$Login$UpdatePassword),
																$author$project$Page$Login$onEnter($author$project$Page$Login$AttemptLogin),
																$elm$html$Html$Attributes$value(model.A)
															]),
														_List_Nil),
														$author$project$Page$Login$optionalLoginError(model.an),
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$href('https://sales.butternutbox.com/direct_sales_representatives/password/new'),
																$elm$html$Html$Attributes$target('_blank')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text($author$project$Translations$Login$forgotPassword)
															]))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('button'),
														$elm$html$Html$Attributes$class('btn btn--blue'),
														$elm$html$Html$Attributes$disabled(
														$author$project$Page$Login$loginIsDisabled(model)),
														$elm$html$Html$Events$onClick($author$project$Page$Login$AttemptLogin)
													]),
												_List_fromArray(
													[
														model.ad ? $author$project$Page$Login$loadingSpinner : $elm$html$Html$text($author$project$Translations$Login$loginButton)
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Meals$SeeMyPlanClicked = 0;
var $author$project$Translations$Meals$deliveredToYourDoorDetail = 'Frozen in an insulated box. No need to wait in!';
var $author$project$Translations$Meals$deliveredToYourDoorTitle = 'Delivered to your door';
var $author$project$Translations$subjectPronoun = function (sexes) {
	_v0$2:
	while (true) {
		if (sexes.b) {
			if (!sexes.a) {
				if (!sexes.b.b) {
					var _v1 = sexes.a;
					return 'she';
				} else {
					break _v0$2;
				}
			} else {
				if (!sexes.b.b) {
					var _v2 = sexes.a;
					return 'he';
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return 'they';
};
var $author$project$Translations$Meals$foodTheyWillLove = function (_v0) {
	var sexes = _v0.cC;
	var recipes = _v0.eA;
	var pluralRecipes = ($elm$core$List$length(recipes) > 1) ? 'recipes ' : 'recipe ';
	return 'Their tail-waggingly tasty ' + (pluralRecipes + ('that ' + ($author$project$Translations$subjectPronoun(sexes) + '\'ll love!')));
};
var $author$project$TrustPilot$forMealsPage = _List_fromArray(
	[
		{ai: 'kay', ak: 'My dog has suffered IBD for years, as soon as she started on Butternut Box chicken and vegetables her symptoms disappeared. The box of food arrives on date specified, well packed and frozen. It is easy to change order date or alter the size of portions. The staff are very helpful and friendly. I am so glad that I found Butternut Box for my gorgeous girlie', cJ: 'My dog\'s IBD symptoms went as soon as she started Butternut Box'},
		{ai: 'EverDrake', ak: 'The best dog food. My dog loves this food, she waits every morning and evening for it; she even wakes me up sometimes because she is so excited. I love it so much and you can tell it is not processed as you can see the little carrot cubes and peas. The customer service team is excellent; I had a problem and they answered on the same day and were extremely helpful. I truly recommend this.', cJ: 'The best dog food'}
	]);
var $author$project$Translations$Meals$freshAndNatural = 'Fresh and natural';
var $author$project$Translations$Meals$freshlyPreppedDetail = 'With no artificial colourings, flavourings or preservatives.';
var $author$project$Translations$Meals$freshlyPreppedTitle = 'Freshly prepped';
var $author$project$Translations$Meals$gentlyCookedDetail = 'Cooked at a low temperature to keep all the natural goodness.';
var $author$project$Translations$Meals$gentlyCookedTitle = 'Gently Cooked';
var $author$project$Translations$Meals$mealOverviewTitle = 'Numbers crunched, let\'s talk munch!';
var $author$project$Translations$Meals$noAddedGrains = 'No added grains';
var $author$project$Translations$Meals$noneOfTheNasties = 'None of the nasties';
var $author$project$Translations$Meals$perfectFor = 'Perfect for';
var $author$project$Translations$Recipes$perfectFor = function (recipe) {
	switch (recipe) {
		case 0:
			return 'fussy eaters';
		case 1:
			return 'sensitive tummies';
		case 2:
			return 'sensitive tummies';
		case 3:
			return 'fussy eaters';
		case 4:
			return 'sensitive tummies';
		case 5:
			return 'fussy eaters';
		default:
			return 'fussy eaters';
	}
};
var $author$project$Translations$Recipes$recipeToOnBrandName = function (recipe) {
	switch (recipe) {
		case 0:
			return 'Beef It Up';
		case 1:
			return 'Chow Down Chicken';
		case 2:
			return 'Gobble Gobble Turkey';
		case 3:
			return 'Wham Bam Lamb';
		case 4:
			return 'Swish Fish Dish';
		case 5:
			return 'Pork This Way';
		default:
			return 'Duo of Duck & Chicken';
	}
};
var $author$project$Page$Meals$recipeOverview = F2(
	function (env, meal) {
		var config = function () {
			switch (meal) {
				case 0:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.cW;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(0),
						O: $author$project$Translations$Recipes$perfectFor(0)
					};
				case 1:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.df;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(1),
						O: $author$project$Translations$Recipes$perfectFor(1)
					};
				case 2:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.e2;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(2),
						O: $author$project$Translations$Recipes$perfectFor(2)
					};
				case 3:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.dZ;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(3),
						O: $author$project$Translations$Recipes$perfectFor(3)
					};
				case 4:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.fb;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(4),
						O: $author$project$Translations$Recipes$perfectFor(4)
					};
				case 5:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.eu;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(5),
						O: $author$project$Translations$Recipes$perfectFor(5)
					};
				default:
					return {
						b8: A2(
							$author$project$Env$asset,
							env,
							function ($) {
								return $.dw;
							}),
						N: $author$project$Translations$Recipes$recipeToOnBrandName(6),
						O: $author$project$Translations$Recipes$perfectFor(6)
					};
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('meals__section__meal')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(config.b8)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('meals__section__meal__name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(config.N)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('no-margin')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Meals$perfectFor),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(config.O)
								]))
						]))
				]));
	});
var $author$project$Translations$objectPronoun = function (sexes) {
	_v0$2:
	while (true) {
		if (sexes.b) {
			if (!sexes.a) {
				if (!sexes.b.b) {
					var _v1 = sexes.a;
					return 'her';
				} else {
					break _v0$2;
				}
			} else {
				if (!sexes.b.b) {
					var _v2 = sexes.a;
					return 'him';
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return 'them';
};
var $author$project$Translations$Meals$recommendedRecipes = function (_v0) {
	var sexes = _v0.cC;
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'We\'ve customised ' + ($author$project$Translations$possessiveName(name) + (' plan to give ' + ($author$project$Translations$objectPronoun(sexes) + (' all the nutrients ' + ($author$project$Translations$subjectPronoun(sexes) + ' needs to thrive.')))));
	} else {
		return 'We\'ve customised their plan to give them all the nutrients they need to thrive.';
	}
};
var $author$project$Translations$Meals$seePlanButton = 'See my plan';
var $author$project$Translations$Meals$caloriesPerDay = function (_v0) {
	var calories = _v0.c9;
	return $elm$core$String$fromInt(calories) + ' calories per day';
};
var $author$project$Translations$Meals$caloriesPerDayError = function (_v0) {
	var dogName = _v0.bt;
	return 'Something went wrong working out calories for ' + dogName;
};
var $author$project$Translations$Meals$dogNeeds = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' needs';
};
var $author$project$Page$Meals$viewCalorieRequirement = F2(
	function (modifierClass, dog) {
		var calorieRecommendation = A6($author$project$FeedingGuidelines$kCalsPerDay, dog.ce, dog.cN, dog.bU, dog.bP, dog.cE, dog.cc);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('meals__hero__calorie-table' + (modifierClass + '__dog'))
				]),
			function () {
				if (!calorieRecommendation.$) {
					var calories = calorieRecommendation.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Meals$dogNeeds(
										{bt: dog.cg}))
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white no-margin')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Meals$caloriesPerDay(
										{c9: calories}))
								]))
						]);
				} else {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Oops!')
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white no-margin')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Meals$caloriesPerDayError(
										{bt: dog.cg}))
								]))
						]);
				}
			}());
	});
var $author$project$Page$Meals$viewCalorieTable = function (dogs) {
	var modifierClass = ($elm$core$List$length(dogs) > 1) ? '--multiple-dogs' : '--single-dog';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('meals__hero__calorie-table')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('meals__hero__calorie-table' + modifierClass)
					]),
				A2(
					$elm$core$List$map,
					$author$project$Page$Meals$viewCalorieRequirement(modifierClass),
					dogs))
			]));
};
var $author$project$Page$Meals$view = function (model) {
	var sexes = A2(
		$elm$core$List$map,
		function ($) {
			return $.cB;
		},
		model.j);
	var recipes = A2($author$project$Dog$recipesFor, model.f, model.j);
	var name = A2(
		$elm$core$List$map,
		function ($) {
			return $.cg;
		},
		model.j);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('grid content content--no-footer')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('meals__hero')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('meals__hero__title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text($author$project$Translations$Meals$mealOverviewTitle)
							])),
						$author$project$Page$Meals$viewCalorieTable(model.j),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('meals__hero__sub-title text-white')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$Meals$recommendedRecipes(
									{ed: name, cC: sexes}))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('sprinkle--top-right')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(0),
										$elm$html$Html$Attributes$class('btn btn--success')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$seePlanButton),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.eQ;
													}))
											]),
										_List_Nil)
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('meals__section')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('meals__section__title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$Meals$foodTheyWillLove(
									{eA: recipes, cC: sexes}))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('meals__section__meals-wrapper')
							]),
						A2(
							$elm$core$List$map,
							$author$project$Page$Meals$recipeOverview(model.c),
							recipes)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('meals__section__benefit-list')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('meals__section__benefit-list__item')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.ef;
													}))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Meals$noneOfTheNasties)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('meals__section__benefit-list__item')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.dH;
													}))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Meals$freshAndNatural)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('meals__section__benefit-list__item')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.ee;
													}))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Meals$noAddedGrains)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('sprinkle--top-right')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(0),
										$elm$html$Html$Attributes$class('btn btn--success btn--large')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$seePlanButton),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												A2(
													$author$project$Env$asset,
													model.c,
													function ($) {
														return $.eQ;
													}))
											]),
										_List_Nil)
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('trustpilot grid')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('trustpilot__title')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-blue')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Rated '),
										A2(
										$elm$html$Html$strong,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-blue')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$TrustPilot$currentRating)
											])),
										$elm$html$Html$text(' on')
									])),
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											model.c,
											function ($) {
												return $.e$;
											}))
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex--row')
							]),
						A2(
							$elm$core$List$map,
							$author$project$TrustPilot$viewReview(model.c),
							$author$project$TrustPilot$forMealsPage))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('meals__footer grid')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-4 text-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											model.c,
											function ($) {
												return $.dp;
											}))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$deliveredToYourDoorTitle)
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$deliveredToYourDoorDetail)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-4 text-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											model.c,
											function ($) {
												return $.dJ;
											}))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$gentlyCookedTitle)
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$gentlyCookedDetail)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-4 text-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											model.c,
											function ($) {
												return $.dI;
											}))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$freshlyPreppedTitle)
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Meals$freshlyPreppedDetail)
									]))
							]))
					]))
			]));
};
var $author$project$Page$OrderConfirmation$ShowReceipt = 0;
var $author$project$Translations$OrderConfirmation$button = 'See order summary';
var $author$project$Translations$OrderConfirmation$detail = function (_v0) {
	var saleMadeOnline = _v0.R;
	return saleMadeOnline ? 'Your order has been successful. We\'ve sent you a confirmation email.' : 'We\'re processing your payment. You\'ll receive an email confirming your order soon.';
};
var $author$project$Translations$OrderConfirmation$title = function (_v0) {
	var saleMadeOnline = _v0.R;
	return saleMadeOnline ? 'Paw yeah!' : 'Great!';
};
var $author$project$Page$OrderConfirmation$view = function (model) {
	var illustration = model.R ? A2(
		$author$project$Env$asset,
		model.c,
		function ($) {
			return $.eq;
		}) : A2(
		$author$project$Env$asset,
		model.c,
		function ($) {
			return $.ep;
		});
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content content--center text-center order--success')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('order--success__illustration'),
						$elm$html$Html$Attributes$src(illustration)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('order--success__summary')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-green')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$OrderConfirmation$title(
									{R: model.R}))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$OrderConfirmation$detail(
									{R: model.R})),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('btn btn--blue'),
										$elm$html$Html$Events$onClick(0)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$OrderConfirmation$button)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Receipt$BackToHomeClicked = 0;
var $author$project$Time$Helpers$map = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$time$Time$posixToMillis,
		A2($elm$core$Basics$composeR, f, $elm$time$Time$millisToPosix));
};
var $author$project$Time$Helpers$addDays = F2(
	function (days, posix) {
		var seconds_per_minute = 60;
		var minutes_per_hour = 60;
		var milliseconds_per_second = 1000;
		var hours_per_day = 24;
		var delta = (((milliseconds_per_second * seconds_per_minute) * minutes_per_hour) * hours_per_day) * days;
		return A2(
			$author$project$Time$Helpers$map,
			$elm$core$Basics$add(delta),
			posix);
	});
var $author$project$DeliveryDate$deliverableDate = F2(
	function (undeliverableDates, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			posix,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.eB;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.aG;
						},
						$elm$core$Basics$eq(posix)),
					undeliverableDates)));
	});
var $author$project$Translations$Receipt$formatDiscountCode = function (discountCode) {
	var value = $elm$core$String$fromInt(
		$author$project$DiscountCode$value(discountCode));
	return value + '%';
};
var $author$project$Translations$Receipt$firstOrderHeading = function (discountCode) {
	return 'First order total (' + ($author$project$Translations$Receipt$formatDiscountCode(discountCode) + ' off)');
};
var $author$project$Translations$Receipt$ongoingPlanHeading = function (dogs) {
	var numberOfDogs = $elm$core$List$length(dogs);
	var dogsNames = $author$project$String$Helpers$combineNouns(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs));
	var dogOrDogs = (numberOfDogs > 1) ? 'dogs' : 'dog';
	return 'For ' + ($elm$core$String$fromInt(numberOfDogs) + (' ' + (dogOrDogs + (': ' + dogsNames))));
};
var $author$project$Translations$Receipt$secondOrderHeading = function (discountCode) {
	return 'Second order total (' + ($author$project$Translations$Receipt$formatDiscountCode(discountCode) + ' off)');
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $author$project$SignInLink$toString = function (_v0) {
	var url = _v0;
	return $elm$url$Url$toString(url);
};
var $author$project$Page$Receipt$showAccountButton = function (signInLink) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('btn btn--blue'),
				$elm$html$Html$Attributes$href(
				$author$project$SignInLink$toString(signInLink)),
				$elm$html$Html$Attributes$target('_blank'),
				$elm$html$Html$Attributes$rel('noopener noreferrer')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('show me my account')
			]));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Session$undeliverableDates = function (_v0) {
	var session = _v0;
	return session.bn;
};
var $author$project$Page$Receipt$view = function (model) {
	var secondOrderGrossPrice = $author$project$Plan$boxPriceAfterTrial(model.r);
	var secondDeliveryDate = $author$project$DeliveryDate$formatPosix(
		A2(
			$author$project$DeliveryDate$deliverableDate,
			$author$project$Session$undeliverableDates(model.f),
			A2(
				$author$project$Time$Helpers$addDays,
				$author$project$Plan$trialDurationInDays(model.r),
				model.e)));
	var roundToNearestFive = function (x) {
		return ((x % 5) > 2) ? ((((x / 5) | 0) * 5) + 5) : (((x / 5) | 0) * 5);
	};
	var planType = function () {
		var _v2 = model.r.cn;
		if (!_v2) {
			return 'All Butternut Plan';
		} else {
			return 'Mixed';
		}
	}();
	var ongoingPlanBoxPrice = $author$project$Plan$boxPriceAfterTrial(model.r);
	var ongoingPlanPerBoxPrice = $author$project$Localisation$price(ongoingPlanBoxPrice);
	var ongoingPlanPerDayPrice = $author$project$Localisation$price((ongoingPlanBoxPrice / model.r.dq) | 0);
	var ongoingPlanPerWeekPrice = $author$project$Localisation$price(
		(ongoingPlanBoxPrice / $author$project$Plan$deliveryIntervalInWeeks(model.r)) | 0);
	var firstOrderGrossPrice = $author$project$Plan$trialBoxPrice(model.r);
	var firstDeliveryDate = $author$project$DeliveryDate$formatPosix(model.e);
	var discountCode = $author$project$Session$activeDiscountCode(model.f);
	var firstOrderDiscount = $author$project$Localisation$price(
		A2($author$project$DiscountCode$valueFor, discountCode, firstOrderGrossPrice));
	var firstOrderPerDayPrice = $author$project$Localisation$price(
		A2(
			$author$project$DiscountCode$apply,
			discountCode,
			(ongoingPlanBoxPrice / $author$project$Plan$trialDurationInDays(model.r)) | 0));
	var firstOrderDailySummary = firstOrderPerDayPrice + ' per day';
	var firstOrderTotal = $author$project$Localisation$price(
		A2($author$project$DiscountCode$apply, discountCode, firstOrderGrossPrice));
	var firstOrderSummary = firstOrderTotal + (' (' + (firstOrderDiscount + ' discount)'));
	var secondOrderDiscount = $author$project$Localisation$price(
		A2($author$project$DiscountCode$valueFor, discountCode, secondOrderGrossPrice));
	var secondOrderPerDayPrice = $author$project$Localisation$price(
		A2($author$project$DiscountCode$apply, discountCode, (ongoingPlanBoxPrice / model.r.dq) | 0));
	var secondOrderDailySummary = secondOrderPerDayPrice + ' per day';
	var secondOrderTotal = $author$project$Localisation$price(
		A2($author$project$DiscountCode$apply, discountCode, secondOrderGrossPrice));
	var secondOrderSummary = secondOrderTotal + (' (' + (secondOrderDiscount + ' discount)'));
	var deliveryIntervalInWeeks = $author$project$Plan$deliveryIntervalInWeeks(model.r);
	var deliveryIntervalInWeeksStr = (deliveryIntervalInWeeks > 1) ? ($elm$core$String$fromInt(deliveryIntervalInWeeks) + ' weeks') : '1 week';
	var caloriePercentage = function () {
		var _v1 = model.r.cn;
		if (!_v1) {
			return $elm$core$Maybe$Just(100);
		} else {
			var mkCaloriesFor = function (dog) {
				return A6($author$project$FeedingGuidelines$kCalsPerDay, dog.ce, dog.cN, dog.bU, dog.bP, dog.cE, dog.cc);
			};
			return A2(
				$elm$core$Maybe$map,
				roundToNearestFive,
				A2(
					$elm$core$Maybe$map,
					function (grams) {
						return ((100 * $author$project$Plan$gramsPerDay(model.r)) / grams) | 0;
					},
					A2(
						$elm$core$Maybe$map,
						$author$project$Plan$toGrams,
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$sum,
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, mkCaloriesFor, model.j))))));
		}
	}();
	var viewCaloriePercentage = function () {
		if (!caloriePercentage.$) {
			var percent = caloriePercentage.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('order-summary__section__detail')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Calorie percentage')
							])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Localisation$percentage(percent))
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('content order-summary')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('grid')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-center order-summary__heading')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Welcome to the Butternut pack!')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('order-summary__section')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__heading')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(
														A2(
															$author$project$Env$asset,
															model.c,
															function ($) {
																return $.dk;
															}))
													]),
												_List_Nil),
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('First Order Summary')
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('First delivery')
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(firstDeliveryDate)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$Translations$Receipt$firstOrderHeading(discountCode))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(firstOrderSummary)
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(firstOrderDailySummary)
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('order-summary__section')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__heading')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(
														A2(
															$author$project$Env$asset,
															model.c,
															function ($) {
																return $.dk;
															}))
													]),
												_List_Nil),
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Second Order Summary')
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Second delivery')
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(secondDeliveryDate)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$Translations$Receipt$secondOrderHeading(discountCode))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(secondOrderSummary)
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(secondOrderDailySummary)
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('order-summary__section')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__heading')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Ongoing Plan')
													]))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Translations$Receipt$ongoingPlanHeading(model.j))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Plan type')
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(planType)
													]))
											])),
										viewCaloriePercentage,
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Price')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__items')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$table,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$tr,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(' PER DAY')
																					]))
																			])),
																		A2(
																		$elm$html$Html$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(' PER WEEK')
																					]))
																			])),
																		A2(
																		$elm$html$Html$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(' PER BOX')
																					]))
																			]))
																	])),
																A2(
																$elm$html$Html$tr,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$td,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(ongoingPlanPerDayPrice)
																					]))
																			])),
																		A2(
																		$elm$html$Html$td,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(ongoingPlanPerWeekPrice)
																					]))
																			])),
																		A2(
																		$elm$html$Html$td,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('no-margin')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(ongoingPlanPerBoxPrice)
																					]))
																			]))
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__detail')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order-summary__section__detail__heading')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Delivered every')
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('order__summary__section__detail__items no-margin--top')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(deliveryIntervalInWeeksStr)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('order-summary__section__ctas')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Events$onClick(0),
														$elm$html$Html$Attributes$class('btn btn--white text-center')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('back to home')
													])),
												A2(
												$elm$core$Maybe$withDefault,
												$elm$html$Html$text(''),
												A2($elm$core$Maybe$map, $author$project$Page$Receipt$showAccountButton, model.aI))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$SalesHistory$monthNumber = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $author$project$Page$SalesHistory$formatSaleTime = function (saleTime) {
	var zone = $elm$time$Time$utc;
	var year = A2($elm$time$Time$toYear, zone, saleTime);
	var month = $author$project$Page$SalesHistory$monthNumber(
		A2($elm$time$Time$toMonth, zone, saleTime));
	var minute = A2($elm$time$Time$toMinute, zone, saleTime);
	var hour = A2($elm$time$Time$toHour, zone, saleTime);
	var day = A2($elm$time$Time$toDay, zone, saleTime);
	var amOrPm = (hour >= 12) ? 'PM' : 'AM';
	return $author$project$Localisation$doubleDigitNumber(day) + ('.' + ($author$project$Localisation$doubleDigitNumber(month) + ('.' + ($elm$core$String$fromInt(year) + (' - ' + ($author$project$Localisation$doubleDigitNumber(hour) + (':' + ($author$project$Localisation$doubleDigitNumber(minute) + amOrPm))))))));
};
var $author$project$Page$SalesHistory$completedSale = function (signUp) {
	var firstAttemptIfDifferent = _Utils_eq(signUp.bY, signUp.a2) ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sale__time')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Page$SalesHistory$formatSaleTime(signUp.a2))
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sales-history__sale--successful')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(signUp.cg)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__pet-names')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$String$Helpers$combineNouns(signUp.aB))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__time')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$SalesHistory$formatSaleTime(signUp.bY))
					])),
				firstAttemptIfDifferent
			]));
};
var $author$project$Page$SalesHistory$completedSales = function (sales) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, $author$project$Page$SalesHistory$completedSale, sales));
};
var $author$project$Page$SalesHistory$failedSale = function (signUp) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sales-history__sale--failed')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(signUp.cg)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__pet-names')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$String$Helpers$combineNouns(signUp.aB))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__time')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$SalesHistory$formatSaleTime(signUp.a2))
					]))
			]));
};
var $author$project$Page$SalesHistory$failedSales = function (sales) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, $author$project$Page$SalesHistory$failedSale, sales));
};
var $author$project$Page$SalesHistory$pendingSale = function (signUp) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sales-history__sale--pending')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(signUp.cg)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__pet-names')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$String$Helpers$combineNouns(signUp.aB))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sale__time')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$SalesHistory$formatSaleTime(signUp.a2))
					]))
			]));
};
var $author$project$Page$SalesHistory$pendingSales = function (sales) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, $author$project$Page$SalesHistory$pendingSale, sales));
};
var $author$project$Page$SalesHistory$view = F2(
	function (env, store) {
		var pendingSalesCount = $elm$core$List$length(store.ek);
		var customerCount = $elm$core$List$length(store.dn);
		var totalSales = customerCount + pendingSalesCount;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content sales-history')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Today\'s sales')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__sales-counter--total')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Total number of sales')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__sales-amount')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(totalSales))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__divider')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__sales-counter--successful')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Successful sales')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__sales-amount')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(customerCount))
								]))
						])),
					$author$project$Page$SalesHistory$completedSales(store.dn),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__divider')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sales-history__sales-counter--pending')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__title ')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Pending sales')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Please connect the iPad to Wifi to complete your offline sales. Have you logged out and in today?')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sales-counter__sales-amount')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(pendingSalesCount))
								]))
						])),
					$author$project$Page$SalesHistory$pendingSales(store.ek),
					$author$project$Page$SalesHistory$failedSales(store.dD)
				]));
	});
var $author$project$Page$Welcome$GetStarted = 0;
var $author$project$Translations$Welcome$logoutReminderExplanation = 'You have to log out and log back in to ensure that all sales are processed and that all of the information in the app is correct!';
var $author$project$Translations$Welcome$logoutReminderTitle = 'Have you logged out and logged back in this morning?';
var $author$project$Translations$Welcome$startButton = 'get started';
var $author$project$Translations$Welcome$subTitle = 'Are you ready to get started?';
var $author$project$Translations$Welcome$title = function (_v0) {
	var name = _v0.cg;
	return 'Hi ' + (name + '!');
};
var $author$project$Page$Welcome$view = function (model) {
	var name = $author$project$Session$salesRep(model.f).b3;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('welcome')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('content content--center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('welcome__panel')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Translations$Welcome$title(
													{cg: name}))
											])),
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('no-margin--top')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Welcome$subTitle)
											])),
										A2(
										$elm$html$Html$h2,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Welcome$logoutReminderTitle)
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text($author$project$Translations$Welcome$logoutReminderExplanation)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('sprinkle--top-right')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn--blue'),
														$elm$html$Html$Events$onClick(0)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text($author$project$Translations$Welcome$startButton),
														A2(
														$elm$html$Html$img,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$src(
																A2(
																	$author$project$Env$asset,
																	model.c,
																	function ($) {
																		return $.eQ;
																	}))
															]),
														_List_Nil)
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Wizard$CompleteWizard = function (a) {
	return {$: 4, a: a};
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$FeedingGuidelines$canMakeRecommendationFor = function (_v0) {
	var yearsOld = _v0.cN;
	var monthsOld = _v0.ce;
	var mass = _v0.cc;
	return !_Utils_eq(
		A6($author$project$FeedingGuidelines$kCalsPerDay, monthsOld, yearsOld, 1, 2, 0, mass),
		$elm$core$Maybe$Nothing);
};
var $author$project$Dog$collectivelyFullyAllergic = F2(
	function (session, dogs) {
		return A3(
			$elm$core$List$foldl,
			$elm$core$Basics$and,
			true,
			A2(
				$elm$core$List$map,
				$author$project$Allergy$allergicToRecipe(
					$author$project$Dog$getAllAllergies(dogs)),
				$author$project$Session$recipes(session)));
	});
var $author$project$Page$Wizard$Steps$eightToNine = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (activityLevel) {
			return {bP: activityLevel, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.bP);
};
var $author$project$Page$Wizard$Steps$elevenToComplete = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (snackingHabits) {
			return {bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: snackingHabits, cN: dog.cN};
		},
		dog.cE);
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Dog$everyoneCanEatButternut = F2(
	function (session, dogs) {
		return !$elm$core$List$isEmpty(
			A2($author$project$Dog$recipesFor, session, dogs));
	});
var $author$project$Page$Wizard$Steps$fiveToSix = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (deSexed) {
			return {bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: deSexed, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.b1);
};
var $author$project$Page$Wizard$Steps$fourToFive = function (dog) {
	var years = A2($elm$core$Maybe$withDefault, 0, dog.cN);
	var months = A2($elm$core$Maybe$withDefault, 0, dog.ce);
	return ((years > 0) || (months > 0)) ? $elm$core$Maybe$Just(
		{bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: months, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: years}) : $elm$core$Maybe$Nothing;
};
var $author$project$Page$Wizard$Steps$nineToTen = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (allergies) {
			return {bP: dog.bP, bp: allergies, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.bp);
};
var $author$project$Page$Wizard$Steps$noneToOne = function (dog) {
	var _v0 = _Utils_Tuple2(dog.cg, dog.cB);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var name = _v0.a.a;
		var sex = _v0.b.a;
		return $elm$core$Maybe$Just(
			{bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: name, cB: sex, cE: dog.cE, cN: dog.cN});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Wizard$Steps$oneToTwo = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (breed) {
			return {bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: breed, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.bV);
};
var $author$project$Page$Wizard$Steps$sevenToEight = function (dog) {
	var _v0 = dog.cc;
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		if (!_v0.a) {
			return $elm$core$Maybe$Nothing;
		} else {
			var mass = _v0.a;
			return $elm$core$Maybe$Just(
				{bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: mass, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN});
		}
	}
};
var $author$project$Page$Wizard$Steps$sixToSeven = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (bodyCondition) {
			return {bP: dog.bP, bp: dog.bp, bU: bodyCondition, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.bU);
};
var $author$project$Page$Wizard$Steps$tenToEleven = function (dog) {
	var _v0 = dog.bw;
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		if ((_v0.a.$ === 1) && (!_v0.a.a.b)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var healthIssues = _v0.a;
			return $elm$core$Maybe$Just(
				{bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: dog.b5, bw: healthIssues, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN});
		}
	}
};
var $author$project$Page$Wizard$Steps$threeToFour = function (dog) {
	return A2(
		$elm$core$Maybe$map,
		function (fussiness) {
			return {bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: dog.b$, b1: dog.b1, b5: fussiness, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN};
		},
		dog.b5);
};
var $author$project$Page$Wizard$Steps$twoToThree = function (dog) {
	var _v0 = dog.b$;
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		if (!_v0.a.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var currentlyEating = _v0.a;
			return $elm$core$Maybe$Just(
				{bP: dog.bP, bp: dog.bp, bU: dog.bU, bV: dog.bV, b_: dog.b_, b$: currentlyEating, b1: dog.b1, b5: dog.b5, bw: dog.bw, cc: dog.cc, ce: dog.ce, cg: dog.cg, cB: dog.cB, cE: dog.cE, cN: dog.cN});
		}
	}
};
var $author$project$Page$Wizard$viewContinueButton = F4(
	function (env, toNextStep, toMsg, currentStep) {
		if ($elm$core$List$isEmpty(currentStep)) {
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$disabled(true),
						$elm$html$Html$Attributes$class('btn btn--success')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('continue')
							])),
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.cS;
									})),
								$elm$html$Html$Attributes$class('icon--right')
							]),
						_List_Nil)
					]));
		} else {
			var _v0 = toNextStep(currentStep);
			if (_v0.$ === 1) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$disabled(true),
							$elm$html$Html$Attributes$class('btn btn--success')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('continue')
								])),
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.cS;
										})),
									$elm$html$Html$Attributes$class('icon--right')
								]),
							_List_Nil)
						]));
			} else {
				var data = _v0.a;
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							toMsg(data)),
							$elm$html$Html$Attributes$class('btn btn--success')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('continue')
								])),
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.cS;
										})),
									$elm$html$Html$Attributes$class('icon--right')
								]),
							_List_Nil)
						]));
			}
		}
	});
var $author$project$Page$Wizard$continueButton = F3(
	function (env, session, step) {
		switch (step.$) {
			case 0:
				var subModel = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatBreed,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Wizard$WhatBreed$init,
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$noneToOne, dogs_))));
				};
				var dogs = $author$project$Page$Wizard$HowManyDogs$getDogs(subModel);
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 1:
				var subModel = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatCurrentFoods,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Wizard$WhatCurrentFoods$init,
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$oneToTwo, dogs_))));
				};
				var dogs = $author$project$Page$Wizard$WhatBreed$getDogs(subModel);
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 2:
				var subModel = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatKindOfEater,
						$author$project$List$Helpers$sequenceMaybes(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$twoToThree, dogs_)));
				};
				var dogs = $author$project$Page$Wizard$WhatCurrentFoods$getDogs(subModel);
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 3:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$HowOld,
						$author$project$List$Helpers$sequenceMaybes(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$threeToFour, dogs_)));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 4:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$HaveBeenDeSexed,
						$author$project$List$Helpers$sequenceMaybes(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$fourToFive, dogs_)));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 5:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatBodyCondition,
						$author$project$List$Helpers$sequenceMaybes(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$fiveToSix, dogs_)));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 6:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatMass,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Wizard$WhatMass$init,
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$sixToSeven, dogs_))));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 7:
				var subModel = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatActivityLevel,
						A2(
							$author$project$Maybe$Helpers$filter,
							$elm$core$List$all($author$project$FeedingGuidelines$canMakeRecommendationFor),
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$sevenToEight, dogs_))));
				};
				var dogs = $author$project$Page$Wizard$WhatMass$getDogs(subModel);
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 8:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$AnyAllergies,
						$author$project$List$Helpers$sequenceMaybes(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$eightToNine, dogs_)));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 9:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$AnyHealthIssues,
						A2(
							$author$project$Maybe$Helpers$filter,
							A2(
								$elm$core$Basics$composeL,
								$elm$core$Basics$not,
								$author$project$Dog$collectivelyFullyAllergic(session)),
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$nineToTen, dogs_))));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			case 10:
				var dogs = step.a;
				var toNextStep = function (dogs_) {
					return A2(
						$elm$core$Maybe$map,
						$author$project$Page$Wizard$WhatSnackingHabits,
						A2(
							$author$project$Maybe$Helpers$filter,
							$author$project$Dog$everyoneCanEatButternut(session),
							$author$project$List$Helpers$sequenceMaybes(
								A2($elm$core$List$map, $author$project$Page$Wizard$Steps$tenToEleven, dogs_))));
				};
				return A4($author$project$Page$Wizard$viewContinueButton, env, toNextStep, $author$project$Page$Wizard$GotoStep, dogs);
			default:
				var dogs = step.a;
				var nextStepDogs = $author$project$List$Helpers$sequenceMaybes(
					A2($elm$core$List$map, $author$project$Page$Wizard$Steps$elevenToComplete, dogs));
				if (!nextStepDogs.$) {
					var completedDogs = nextStepDogs.a;
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Page$Wizard$CompleteWizard(completedDogs)),
								$elm$html$Html$Attributes$class('btn btn--success')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('complete')
									])),
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.cS;
											})),
										$elm$html$Html$Attributes$class('icon--right')
									]),
								_List_Nil)
							]));
				} else {
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('btn btn--success')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('complete')
									])),
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.cS;
											})),
										$elm$html$Html$Attributes$class('icon--right')
									]),
								_List_Nil)
							]));
				}
		}
	});
var $author$project$Page$Wizard$stepBackButton = F2(
	function (env, step) {
		switch (step.$) {
			case 0:
				var dogs = step.a;
				return $elm$html$Html$text('');
			case 1:
				var subModel = step.a;
				var dogs = $author$project$Page$Wizard$WhatBreed$getDogs(subModel);
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$HowManyDogs(
						$author$project$Page$Wizard$HowManyDogs$init(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$oneToNone, dogs))));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 2:
				var subModel = step.a;
				var dogs = $author$project$Page$Wizard$WhatCurrentFoods$getDogs(subModel);
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatBreed(
						$author$project$Page$Wizard$WhatBreed$init(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$twoToOne, dogs))));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 3:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatCurrentFoods(
						$author$project$Page$Wizard$WhatCurrentFoods$init(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$threeToTwo, dogs))));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 4:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatKindOfEater(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$fourToThree, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 5:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$HowOld(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$fiveToFour, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 6:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$HaveBeenDeSexed(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$sixToFive, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 7:
				var subModel = step.a;
				var dogs = $author$project$Page$Wizard$WhatMass$getDogs(subModel);
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatBodyCondition(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$sevenToSix, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 8:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatMass(
						$author$project$Page$Wizard$WhatMass$init(
							A2($elm$core$List$map, $author$project$Page$Wizard$Steps$eightToSeven, dogs))));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 9:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$WhatActivityLevel(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$nineToEight, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			case 10:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$AnyAllergies(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$tenToNine, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
			default:
				var dogs = step.a;
				var msg = $author$project$Page$Wizard$GotoStep(
					$author$project$Page$Wizard$AnyHealthIssues(
						A2($elm$core$List$map, $author$project$Page$Wizard$Steps$elevenToTen, dogs)));
				return A2($author$project$Page$Wizard$viewBackButton, env, msg);
		}
	});
var $author$project$Page$Wizard$CloseOverlays = {$: 1};
var $author$project$Page$Wizard$ExitWizard = {$: 3};
var $author$project$Page$Wizard$showModal = F2(
	function (env, modal) {
		var config = {bZ: 'go back', cj: $author$project$Page$Wizard$CloseOverlays, ck: $author$project$Page$Wizard$ExitWizard, cG: 'You will lose all of your progress if you do this.', cJ: 'Are you sure you want to go back?'};
		return A2($author$project$Modals$popOver, env, config);
	});
var $author$project$Page$Wizard$viewOverlays = F2(
	function (env, overlays) {
		if (!overlays.$) {
			return $elm$html$Html$text('');
		} else {
			var modal = overlays.a;
			return A2($author$project$Page$Wizard$showModal, env, modal);
		}
	});
var $author$project$Page$Wizard$GotAnyAllergiesMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Wizard$GotAnyHealthIssuesMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Wizard$GotHaveBeenDeSexedMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Wizard$GotHowManyDogsMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Wizard$GotHowOldMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Wizard$GotWhatActivityLevelMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Wizard$GotWhatBodyConditionMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Wizard$GotWhatBreedMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Wizard$GotWhatCurrentFoodsMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Wizard$GotWhatKindOfEaterMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Wizard$GotWhatMassMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Wizard$GotWhatSnackingHabitsMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Session$brands = function (_v0) {
	var session = _v0;
	return session.T;
};
var $author$project$Session$breeds = function (_v0) {
	var session = _v0;
	return session.aQ;
};
var $author$project$Translations$Wizard$AnyAllergies$allergicToAllMealsWarningBody = 'Your dogs are allergic to all our meats.';
var $author$project$Translations$Wizard$AnyAllergies$dogIsAllergicToEverythingWarningTitle = 'Oh no...';
var $author$project$Translations$Wizard$AnyAllergies$questionSubtitle = 'Our meals have no added grain, soy, sugar or salt.';
var $author$project$Translations$Wizard$AnyAllergies$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'Does ' + (name + ' have any allergies?');
	} else {
		return 'Do they have any allergies?';
	}
};
var $author$project$Maybe$Helpers$toBool = F2(
	function (p, maybeX) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Maybe$map, p, maybeX));
	});
var $author$project$Page$Wizard$AnyAllergies$ClearAllergies = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wizard$AnyAllergies$ToggleAllergy = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Session$allergies = function (_v0) {
	var session = _v0;
	return session.bp;
};
var $author$project$Translations$Allergies$allergyToString = function (allergy) {
	switch (allergy) {
		case 0:
			return 'Beef';
		case 1:
			return 'Chicken';
		case 2:
			return 'Turkey';
		case 3:
			return 'Lamb';
		case 4:
			return 'Fish';
		case 5:
			return 'Pork';
		case 6:
			return 'Duck';
		case 7:
			return 'Venison';
		case 8:
			return 'Wild Boar';
		default:
			return 'Guinea Fowl';
	}
};
var $author$project$Buttons$checkBox = F2(
	function (config, item) {
		var selected = A2($elm$core$List$member, item, config.bO);
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn  btn--pill'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn--blue', selected),
							_Utils_Tuple2('btn--light-blue', !selected)
						])),
					$elm$html$Html$Events$onClick(
					config.bj(item))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					config.bk(item))
				]));
	});
var $author$project$Translations$Wizard$AnyAllergies$dogIsAllergicToEverythingWarningBody = function (_v0) {
	var name = _v0.cg;
	var sex = _v0.cB;
	return 'It looks like we can\'t feed ' + (name + (' as ' + ($author$project$Translations$subjectPronoun(
		_List_fromArray(
			[sex])) + ' is allergic to all our meats.')));
};
var $author$project$Translations$Wizard$AnyAllergies$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' is allergic to...';
};
var $author$project$Page$Wizard$AnyAllergies$viewAllergiesInputs = F3(
	function (session, id, dog) {
		var recipes = $author$project$Session$recipes(session);
		var noAllergies = _Utils_eq(
			dog.bp,
			$elm$core$Maybe$Just(_List_Nil));
		var dogIsAlergicToEverything = A2(
			$author$project$Maybe$Helpers$toBool,
			function (allAllergies) {
				return A3(
					$elm$core$List$foldl,
					$elm$core$Basics$and,
					true,
					A2(
						$elm$core$List$map,
						$author$project$Allergy$allergicToRecipe(allAllergies),
						recipes));
			},
			dog.bp);
		var fullyAllergicWarning = dogIsAlergicToEverything ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert alert--warning')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Wizard$AnyAllergies$dogIsAllergicToEverythingWarningTitle)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$Wizard$AnyAllergies$dogIsAllergicToEverythingWarningBody(
								{cg: dog.cg, cB: dog.cB}))
						]))
				])) : $elm$html$Html$text('');
		var dividerText = $author$project$Translations$Wizard$AnyAllergies$questionDivider(
			{bt: dog.cg});
		var checkBoxConfig = {
			bO: A2($elm$core$Maybe$withDefault, _List_Nil, dog.bp),
			bj: $author$project$Page$Wizard$AnyAllergies$ToggleAllergy(id),
			bk: $author$project$Translations$Allergies$allergyToString
		};
		var allergies = $author$project$Session$allergies(session);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(dividerText)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn--group')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn  btn--pill'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn--blue', noAllergies),
											_Utils_Tuple2('btn--light-blue', !noAllergies)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Wizard$AnyAllergies$ClearAllergies(id))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Nothing')
								])),
						A2(
							$elm$core$List$map,
							$author$project$Buttons$checkBox(checkBoxConfig),
							allergies))),
					fullyAllergicWarning
				]));
	});
var $author$project$Page$Wizard$AnyAllergies$view = F3(
	function (env, session, dogs) {
		var recipes = $author$project$Session$recipes(session);
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		var collectivelyAllergicToEverything = A2(
			$author$project$Maybe$Helpers$toBool,
			function (allAllergies) {
				return A3(
					$elm$core$List$foldl,
					$elm$core$Basics$and,
					true,
					A2(
						$elm$core$List$map,
						$author$project$Allergy$allergicToRecipe(allAllergies),
						recipes));
			},
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$concat,
				$author$project$List$Helpers$sequenceMaybes(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.bp;
						},
						dogs))));
		var collectiveAllergyWarning = (($elm$core$List$length(dogs) > 1) && collectivelyAllergicToEverything) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert alert--warning')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Wizard$AnyAllergies$dogIsAllergicToEverythingWarningTitle)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Wizard$AnyAllergies$allergicToAllMealsWarningBody)
						]))
				])) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('question__header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.ex;
											})),
										$elm$html$Html$Attributes$class('question__header__image')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('no-margin--bottom')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Translations$Wizard$AnyAllergies$questionTitle(
											{ed: names}))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Wizard$AnyAllergies$questionSubtitle)
									]))
							]))
					]),
				_Utils_ap(
					A2(
						$elm$core$List$indexedMap,
						$author$project$Page$Wizard$AnyAllergies$viewAllergiesInputs(session),
						dogs),
					_List_fromArray(
						[collectiveAllergyWarning]))));
	});
var $author$project$Dog$HealthIssues$hasIssues = function (healthIssues) {
	if (!healthIssues.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Page$Wizard$AnyHealthIssues$ToggleHealthIssue = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Dog$HealthIssues$all = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
var $author$project$Dog$HealthIssues$canRecommendButternutFor = function (issue) {
	switch (issue) {
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 0:
			return true;
		case 1:
			return true;
		case 5:
			return true;
		case 6:
			return true;
		case 7:
			return true;
		case 8:
			return true;
		case 9:
			return true;
		case 10:
			return true;
		case 11:
			return true;
		default:
			return true;
	}
};
var $author$project$Translations$Wizard$AnyHealthIssues$healthIssueToString = function (issue) {
	switch (issue) {
		case 1:
			return 'Diabetes';
		case 0:
			return 'Pancreatitis';
		case 2:
			return 'IBD';
		case 3:
			return 'Kidney disease';
		case 4:
			return 'Struvite stones';
		case 5:
			return 'Liver disease';
		case 6:
			return 'Skin/Coat issues';
		case 7:
			return 'Joint problems';
		case 8:
			return 'Cancer';
		case 9:
			return 'Obesity';
		case 10:
			return 'Sensitive stomach';
		case 11:
			return 'Heart condition';
		default:
			return 'Epilepsy';
	}
};
var $author$project$Page$Wizard$AnyHealthIssues$healthIssueCheckbox = F2(
	function (config, issue) {
		var selected = A2($elm$core$List$member, issue, config.bO);
		var isRecommended = $author$project$Dog$HealthIssues$canRecommendButternutFor(issue);
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn--pill'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn--light-blue', !selected),
							_Utils_Tuple2('btn--blue', selected && isRecommended),
							_Utils_Tuple2('btn--red', selected && (!isRecommended))
						])),
					$elm$html$Html$Events$onClick(
					config.bj(issue))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Translations$Wizard$AnyHealthIssues$healthIssueToString(issue))
				]));
	});
var $author$project$Translations$Wizard$AnyHealthIssues$recommendationFor = F2(
	function (_v0, recipes) {
		var issue = _v0.dU;
		var name = _v0.cg;
		var sex = _v0.cB;
		var lowFatMeals = $author$project$String$Helpers$combineNouns(
			A2(
				$elm$core$List$map,
				$author$project$Translations$Recipes$recipeToString,
				A2($elm$core$List$filter, $author$project$Recipe$isLowFat, recipes)));
		switch (issue) {
			case 0:
				return 'Even with Pancreatitis, ' + (name + (' can still enjoy Butternut, but we will send you only our lower fat (but still super tasty) ' + (lowFatMeals + ' dishes.')));
			case 1:
				return 'Even with Diabetes, ' + (name + (' can enjoy our Butternut meals. We especially recommend our ' + (lowFatMeals + ' recipes as they are low in fat and completely doglicious.')));
			case 2:
				return 'IBD and sensitive stomachs differ. If ' + (name + ('  has been diagnosed with IBD by a specialist vet, then we don’t recommend Butternut. \n Dr Ciara’s recommendation: \"' + (name + ' likely needs a hydrolysed diet. Talk to your vet about what will help best.\"')));
			case 3:
				return 'Unfortunately, we don\'t recommend Butternut if your dog has kidney disease as our phosphorous content is not restricted.';
			case 4:
				return 'Unfortunately, we don\'t recommend Butternut whilst your dog has Struvite stones. \n Dr Ciara\'s recommendation: \"Talk to your vet about supplementing with DL-Methionine until the stones are gone.\"';
			case 5:
				return 'If ' + (name + ' refuses a vet diet, Butternut is a good alternative as it contains easily digestible proteins, is high in fiber, low in sodium and rich in antioxidants, zinc and Vitamin K.');
			case 6:
				return 'Butternut is great for dogs with skin or coat issues. We use ground flax seed in our recipes as a good source of omega-3 fatty acids.';
			case 7:
				return 'Butternut is great for dogs with joint problems. We use ground flax seed in our recipes as a good source of omega-3 fatty acids.';
			case 8:
				return 'Even with Cancer, ' + (name + ' can still enjoy Butternut due to our fresh ingredients, low sugar content and no added artificial sweeteners or preservatives.');
			case 9:
				return 'Obese dogs are defined as being 15% over their ideal weight. We recommend you contact your local vet to ensure there are no underlying health concerns. Our ' + (lowFatMeals + ' meals contain less than 5% fat, whilst still being super tasty!');
			case 10:
				return 'Even with a sensitive stomach, ' + (name + (' can still enjoy Butternut, but we will only send you our lower fat dishes. ' + (lowFatMeals + ' are low in fat and high in flavour! You can always add other recipes back into your plan by logging in to your account.')));
			case 11:
				return 'Even with a heart condition, ' + (name + (' can enjoy Butternut. All of our meals are great for dogs in need of a low-sodium diet. We especially recommend our Chow Down Chicken and Gobble Gobble Turkey meals if ' + (name + ' needs a diet that is low in fat.')));
			default:
				return 'Even with epilepsy, ' + (name + (' can still enjoy Butternut. Our meals provide a consistent, high-quality diet which, alongside ' + (name + ('\'s prescribed medication, can help to manage ' + ($author$project$Translations$possessivePronoun(
					_List_fromArray(
						[sex])) + ' seizures.')))));
		}
	});
var $author$project$Page$Wizard$AnyHealthIssues$healthIssueRecommendation = F3(
	function (dog, recipes, issue) {
		var isRecommended = $author$project$Dog$HealthIssues$canRecommendButternutFor(issue);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('alert--success', isRecommended),
							_Utils_Tuple2('alert--warning', !isRecommended)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2(
						$author$project$Translations$Wizard$AnyHealthIssues$recommendationFor,
						{dU: issue, cg: dog.cg, cB: dog.cB},
						recipes))
				]));
	});
var $author$project$Page$Wizard$AnyHealthIssues$healthIssuesCheckboxes = F4(
	function (id, dog, recipes, issues) {
		var config = {
			bO: issues,
			bj: $author$project$Page$Wizard$AnyHealthIssues$ToggleHealthIssue(id)
		};
		var checkboxes = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn--group health-issues__issue-list')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Page$Wizard$AnyHealthIssues$healthIssueCheckbox(config),
				$author$project$Dog$HealthIssues$all));
		var badIssuesFirst = A2(
			$elm$core$List$sortBy,
			function (issue) {
				return $author$project$Dog$HealthIssues$canRecommendButternutFor(issue) ? 1 : 0;
			},
			issues);
		var recommendations = A2(
			$elm$core$List$map,
			A2($author$project$Page$Wizard$AnyHealthIssues$healthIssueRecommendation, dog, recipes),
			badIssuesFirst);
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2($elm$core$List$cons, checkboxes, recommendations));
	});
var $author$project$Page$Wizard$AnyHealthIssues$ToggleHealthIssues = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Buttons$radio = F2(
	function (config, item) {
		var selected = _Utils_eq(
			config.bN,
			$elm$core$Maybe$Just(item));
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn--blue', selected),
							_Utils_Tuple2('btn--white', !selected)
						])),
					$elm$html$Html$Events$onClick(
					config.bj(item))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					config.bk(item))
				]));
	});
var $author$project$Page$Wizard$AnyHealthIssues$healthIssuesToggle = F2(
	function (id, maybeIssues) {
		var toString = function (bool) {
			return bool ? 'Yes' : 'No';
		};
		var activeItem = A2($elm$core$Maybe$map, $author$project$Dog$HealthIssues$hasIssues, maybeIssues);
		var config = {
			bN: activeItem,
			bj: $author$project$Page$Wizard$AnyHealthIssues$ToggleHealthIssues(id),
			bk: toString
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn--group')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Buttons$radio(config),
				_List_fromArray(
					[true, false])));
	});
var $author$project$Translations$Wizard$AnyHealthIssues$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName;
};
var $author$project$Page$Wizard$AnyHealthIssues$healthIssuesInputs = F4(
	function (env, recipes, id, dog) {
		var issues = $author$project$Dog$HealthIssues$toList(
			A2($elm$core$Maybe$withDefault, $author$project$Dog$HealthIssues$none, dog.bw));
		var hasIssues = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Maybe$map, $author$project$Dog$HealthIssues$hasIssues, dog.bw));
		var inputs = hasIssues ? _List_fromArray(
			[
				A2($author$project$Page$Wizard$AnyHealthIssues$healthIssuesToggle, id, dog.bw),
				A4($author$project$Page$Wizard$AnyHealthIssues$healthIssuesCheckboxes, id, dog, recipes, issues)
			]) : _List_fromArray(
			[
				A2($author$project$Page$Wizard$AnyHealthIssues$healthIssuesToggle, id, dog.bw)
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$AnyHealthIssues$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('health-issues')
						]),
					inputs)
				]));
	});
var $author$project$Translations$Wizard$AnyHealthIssues$noEdibleRecipesHeader = 'Oh no...';
var $author$project$Translations$Wizard$AnyHealthIssues$noEdibleRecipesWarning = function (dogs) {
	var sexes = A2(
		$elm$core$List$map,
		function ($) {
			return $.cB;
		},
		dogs);
	var dogOrDogsPossessive = ($elm$core$List$length(dogs) > 1) ? 'dogs\'' : 'dog\'s';
	return 'Your ' + (dogOrDogsPossessive + (' health issues, combined with ' + ($author$project$Translations$possessivePronoun(sexes) + (' allergies mean ' + ($author$project$Translations$subjectPronoun(sexes) + ' can\'t eat any of our meats.')))));
};
var $author$project$Translations$Wizard$AnyHealthIssues$questionSubtitle = 'While our meals are perfect for a range of conditions, there are some conditions we can’t yet cater for.';
var $author$project$Translations$Wizard$AnyHealthIssues$questionTitle = 'Any health issues?';
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm_community$list_extra$List$Extra$zip = $elm$core$List$map2($elm$core$Tuple$pair);
var $author$project$Page$Wizard$AnyHealthIssues$view = F3(
	function (env, session, dogs) {
		var allIssues = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$author$project$List$Helpers$sequenceMaybes(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.bw;
					},
					dogs)));
		var allAllergies = A2(
			$elm$core$List$map,
			function ($) {
				return $.bp;
			},
			dogs);
		var recipeLookupRecords = A2(
			$elm$core$List$map,
			function (_v0) {
				var issues = _v0.a;
				var allergies = _v0.b;
				return {bp: allergies, bw: issues};
			},
			A2($elm_community$list_extra$List$Extra$zip, allIssues, allAllergies));
		var noEdibleRecipes = !$elm$core$List$length(
			A2($author$project$Dog$recipesFor, session, recipeLookupRecords));
		var noEdibleRecipesWarning = noEdibleRecipes ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert alert--warning')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Wizard$AnyHealthIssues$noEdibleRecipesHeader)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$Wizard$AnyHealthIssues$noEdibleRecipesWarning(dogs))
						]))
				])) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('question__header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.ex;
											})),
										$elm$html$Html$Attributes$class('question__header__image')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('no-margin--bottom')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Wizard$AnyHealthIssues$questionTitle)
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text($author$project$Translations$Wizard$AnyHealthIssues$questionSubtitle)
									]))
							]))
					]),
				_Utils_ap(
					A2(
						$elm$core$List$indexedMap,
						A2(
							$author$project$Page$Wizard$AnyHealthIssues$healthIssuesInputs,
							env,
							$author$project$Session$recipes(session)),
						dogs),
					_List_fromArray(
						[noEdibleRecipesWarning]))));
	});
var $author$project$Page$Wizard$HaveBeenDeSexed$SetDeSexed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Translations$Wizard$HaveBeenDeSexed$isSpayedOrneutured = function (x) {
	return x ? 'Yes' : 'No';
};
var $author$project$Translations$Wizard$HaveBeenDeSexed$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName;
};
var $author$project$Page$Wizard$HaveBeenDeSexed$deSexedInputs = F2(
	function (id, dog) {
		var config = {
			bN: dog.b1,
			bj: $author$project$Page$Wizard$HaveBeenDeSexed$SetDeSexed(id),
			bk: $author$project$Translations$Wizard$HaveBeenDeSexed$isSpayedOrneutured
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$HaveBeenDeSexed$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn--group')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Buttons$radio(config),
						_List_fromArray(
							[true, false])))
				]));
	});
var $author$project$Translations$Wizard$HaveBeenDeSexed$questionTitle = function (_v0) {
	var sexesAndNames = _v0.eI;
	_v1$2:
	while (true) {
		if (sexesAndNames.b) {
			if (sexesAndNames.a.a === 1) {
				if (!sexesAndNames.b.b) {
					var _v2 = sexesAndNames.a;
					var _v3 = _v2.a;
					var name = _v2.b;
					return 'Is ' + (name + ' Neutered?');
				} else {
					break _v1$2;
				}
			} else {
				if (!sexesAndNames.b.b) {
					var _v4 = sexesAndNames.a;
					var _v5 = _v4.a;
					var name = _v4.b;
					return 'Is ' + (name + ' Spayed?');
				} else {
					break _v1$2;
				}
			}
		} else {
			break _v1$2;
		}
	}
	var sexes = A2($elm$core$List$map, $elm$core$Tuple$first, sexesAndNames);
	return A2(
		$elm$core$List$all,
		$elm$core$Basics$eq(0),
		sexes) ? 'Are they Spayed?' : (A2(
		$elm$core$List$all,
		$elm$core$Basics$eq(1),
		sexes) ? 'Are they Neutered?' : 'Are they Spayed or Neutered?');
};
var $author$project$Page$Wizard$HaveBeenDeSexed$view = F2(
	function (env, dogs) {
		var sexesAndNames = A2(
			$elm$core$List$map,
			function (dog) {
				return _Utils_Tuple2(dog.cB, dog.cg);
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$HaveBeenDeSexed$questionTitle(
										{eI: sexesAndNames}))
								]))
						])),
				A2($elm$core$List$indexedMap, $author$project$Page$Wizard$HaveBeenDeSexed$deSexedInputs, dogs)));
	});
var $author$project$Translations$Wizard$HowManyDogs$questionSubtitle = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount > 1) ? 'Tell us a bit about your dogs' : 'Tell us a bit about your dog';
};
var $author$project$Translations$Wizard$HowManyDogs$questionTitle = 'First things first,\n how many dogs do you have?';
var $author$project$Page$Wizard$HowManyDogs$AddDog = {$: 0};
var $author$project$Page$Wizard$HowManyDogs$OpenModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wizard$HowManyDogs$RemoveDog = {$: 3};
var $author$project$Page$Wizard$HowManyDogs$RemoveDogConfirmationModal = 0;
var $elm_community$list_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elm_community$list_extra$List$Extra$cycleHelp = F3(
	function (acc, n, list) {
		cycleHelp:
		while (true) {
			if (n > 0) {
				var $temp$acc = A2($elm_community$list_extra$List$Extra$reverseAppend, list, acc),
					$temp$n = n - 1,
					$temp$list = list;
				acc = $temp$acc;
				n = $temp$n;
				list = $temp$list;
				continue cycleHelp;
			} else {
				return acc;
			}
		}
	});
var $elm_community$list_extra$List$Extra$cycle = F2(
	function (len, list) {
		var cycleLength = $elm$core$List$length(list);
		return ((!cycleLength) || _Utils_eq(cycleLength, len)) ? list : ((_Utils_cmp(cycleLength, len) < 0) ? $elm$core$List$reverse(
			A2(
				$elm_community$list_extra$List$Extra$reverseAppend,
				A2($elm$core$List$take, len % cycleLength, list),
				A3($elm_community$list_extra$List$Extra$cycleHelp, _List_Nil, (len / cycleLength) | 0, list))) : A2($elm$core$List$take, len, list));
	});
var $author$project$Page$Wizard$HowManyDogs$viewCounter = F2(
	function (env, dogs) {
		var onDecrement = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Wizard$HowManyDogs$RemoveDog,
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return $author$project$Page$Wizard$HowManyDogs$OpenModal(0);
				},
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.bV;
					},
					$elm_community$list_extra$List$Extra$last(dogs))));
		var dogCount = $elm$core$List$length(dogs);
		var dogIllustrations = A2(
			$elm_community$list_extra$List$Extra$cycle,
			dogCount,
			_List_fromArray(
				[
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.dK;
					}),
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.c$;
					}),
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.cX;
					}),
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.dg;
					}),
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.cZ;
					}),
					A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.e7;
					})
				]));
		var countStr = $elm$core$String$fromInt(dogCount);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dog-incrementer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dog-incrementer__counter')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(onDecrement)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dog-incrementer__counter__amount')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(countStr)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Wizard$HowManyDogs$AddDog)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dog-incrementer__illustration')
						]),
					A2(
						$elm$core$List$map,
						function (illustrationSrc) {
							return A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(illustrationSrc)
									]),
								_List_Nil);
						},
						dogIllustrations))
				]));
	});
var $author$project$Translations$Wizard$HowManyDogs$askForNames = function (_v0) {
	var dogCount = _v0.bs;
	return (dogCount > 1) ? 'What are your dogs called?' : 'What is your dog called?';
};
var $author$project$Page$Wizard$HowManyDogs$AddName = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Wizard$HowManyDogs$AddSex = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Dog$Sex$all = _List_fromArray(
	[0, 1]);
var $author$project$Translations$Wizard$HowManyDogs$namePlaceholder = function (_v0) {
	var dogNumber = _v0.dt;
	return 'Dog ' + $elm$core$String$fromInt(dogNumber);
};
var $author$project$Dog$Sex$toString = function (sex) {
	if (!sex) {
		return 'Girl';
	} else {
		return 'Boy';
	}
};
var $author$project$Page$Wizard$HowManyDogs$viewNameAndSexInput = F2(
	function (id, dog) {
		var dogNumber = id + 1;
		var config = {
			bN: dog.cB,
			bj: $author$project$Page$Wizard$HowManyDogs$AddSex(id),
			bk: $author$project$Dog$Sex$toString
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($elm$core$Maybe$withDefault, '', dog.cg)),
							$elm$html$Html$Events$onInput(
							$author$project$Page$Wizard$HowManyDogs$AddName(id)),
							$elm$html$Html$Attributes$placeholder(
							$author$project$Translations$Wizard$HowManyDogs$namePlaceholder(
								{dt: dogNumber}))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn--group')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Buttons$radio(config),
						$author$project$Dog$Sex$all))
				]));
	});
var $author$project$Page$Wizard$HowManyDogs$viewNameAndSexInputs = function (dogs) {
	var dogCount = $elm$core$List$length(dogs);
	return (!dogCount) ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Translations$Wizard$HowManyDogs$askForNames(
							{bs: dogCount}))
					])),
			A2($elm$core$List$indexedMap, $author$project$Page$Wizard$HowManyDogs$viewNameAndSexInput, dogs)));
};
var $author$project$Page$Wizard$HowManyDogs$CloseOverlays = {$: 2};
var $author$project$Page$Wizard$HowManyDogs$showModal = F2(
	function (env, modal) {
		var config = {bZ: 'Remove', cj: $author$project$Page$Wizard$HowManyDogs$CloseOverlays, ck: $author$project$Page$Wizard$HowManyDogs$RemoveDog, cG: 'You will lose any of your progress for this dog if you do this.', cJ: 'Are you sure you remove this dog?'};
		return A2($author$project$Modals$popOver, env, config);
	});
var $author$project$Page$Wizard$HowManyDogs$viewOverlays = F2(
	function (env, overlays) {
		if (!overlays.$) {
			return $elm$html$Html$text('');
		} else {
			var modal = overlays.a;
			return A2($author$project$Page$Wizard$HowManyDogs$showModal, env, modal);
		}
	});
var $author$project$Page$Wizard$HowManyDogs$view = F2(
	function (env, model) {
		var dogCount = $elm$core$List$length(model.j);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Wizard$HowManyDogs$questionTitle)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$HowManyDogs$questionSubtitle(
										{bs: dogCount}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__item')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Wizard$HowManyDogs$viewCounter, env, model.j)
						])),
					$author$project$Page$Wizard$HowManyDogs$viewNameAndSexInputs(model.j),
					A2($author$project$Page$Wizard$HowManyDogs$viewOverlays, env, model.l)
				]));
	});
var $author$project$Page$Wizard$HowOld$SetMonthsOld = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$Wizard$HowOld$SetYearsOld = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Translations$Wizard$HowOld$months = 'months';
var $author$project$Translations$Wizard$HowOld$placeholderText = '0';
var $author$project$Translations$Wizard$HowOld$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' is...';
};
var $author$project$Translations$Wizard$HowOld$years = 'years and';
var $author$project$Page$Wizard$HowOld$ageInputs = F2(
	function (id, dog) {
		var yearsStr = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $elm$core$String$fromInt, dog.cN));
		var monthsStr = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $elm$core$String$fromInt, dog.ce));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$HowOld$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex--row flex--justify-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$class('age-input'),
									$elm$html$Html$Attributes$placeholder($author$project$Translations$Wizard$HowOld$placeholderText),
									$elm$html$Html$Attributes$value(yearsStr),
									$elm$html$Html$Events$onInput(
									$author$project$Page$Wizard$HowOld$SetYearsOld(id))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Wizard$HowOld$years)
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$class('age-input'),
									$elm$html$Html$Attributes$placeholder($author$project$Translations$Wizard$HowOld$placeholderText),
									$elm$html$Html$Attributes$value(monthsStr),
									$elm$html$Html$Events$onInput(
									$author$project$Page$Wizard$HowOld$SetMonthsOld(id))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Wizard$HowOld$months)
								]))
						]))
				]));
	});
var $author$project$Translations$Wizard$HowOld$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'How old is ' + (name + '?');
	} else {
		return 'How old are they?';
	}
};
var $author$project$Page$Wizard$HowOld$view = F2(
	function (env, dogs) {
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$HowOld$questionTitle(
										{ed: names}))
								]))
						])),
				A2($elm$core$List$indexedMap, $author$project$Page$Wizard$HowOld$ageInputs, dogs)));
	});
var $author$project$Page$Wizard$WhatActivityLevel$SetActivityLevel = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Translations$Wizard$WhatActivityLevel$activityLevel = function (x) {
	switch (x) {
		case 0:
			return 'A bit lazy';
		case 1:
			return 'Somewhat active';
		case 2:
			return 'Active';
		case 3:
			return 'Very active';
		case 4:
			return 'Full of energy';
		default:
			return '';
	}
};
var $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail = function (x) {
	switch (x) {
		case 0:
			return 'Under 30min\n walks per day';
		case 1:
			return '0.5 - 1hr\n walks per day';
		case 2:
			return '1-2hr\n walks per day';
		case 3:
			return '2-3hr\n walks per day';
		case 4:
			return 'More than 3hr\n walks per day';
		default:
			return '';
	}
};
var $author$project$Buttons$radioWithIcon = function (config) {
	var imageSrc = config.bA ? config.cA : config.b8;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(config.cf),
				$elm$html$Html$Attributes$class(config.bB),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(config.bB + '--selected', config.bA)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageSrc)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(config.cJ)
					])),
				A2(
				$elm$html$Html$small,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(config.cG)
					]))
			]));
};
var $author$project$Page$Wizard$WhatActivityLevel$activityLevelRadioButtons = F3(
	function (env, id, activityLevel) {
		var toMsg = $author$project$Page$Wizard$WhatActivityLevel$SetActivityLevel(id);
		var itemClass = 'activity-level__example-row__item';
		var isSelected = function (x) {
			return _Utils_eq(
				activityLevel,
				$elm$core$Maybe$Just(x));
		};
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('activity-level__example-row')
						]),
					_List_fromArray(
						[
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d$;
									}),
								bA: isSelected(0),
								bB: itemClass,
								cf: toMsg(0),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d0;
									}),
								cG: $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail(0),
								cJ: $author$project$Translations$Wizard$WhatActivityLevel$activityLevel(0)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d1;
									}),
								bA: isSelected(1),
								bB: itemClass,
								cf: toMsg(1),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d2;
									}),
								cG: $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail(1),
								cJ: $author$project$Translations$Wizard$WhatActivityLevel$activityLevel(1)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.dE;
									}),
								bA: isSelected(2),
								bB: itemClass,
								cf: toMsg(2),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.dF;
									}),
								cG: $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail(2),
								cJ: $author$project$Translations$Wizard$WhatActivityLevel$activityLevel(2)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.c0;
									}),
								bA: isSelected(3),
								bB: itemClass,
								cf: toMsg(3),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.c1;
									}),
								cG: $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail(3),
								cJ: $author$project$Translations$Wizard$WhatActivityLevel$activityLevel(3)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.dO;
									}),
								bA: isSelected(4),
								bB: itemClass,
								cf: toMsg(4),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.dP;
									}),
								cG: $author$project$Translations$Wizard$WhatActivityLevel$activityLevelDetail(4),
								cJ: $author$project$Translations$Wizard$WhatActivityLevel$activityLevel(4)
							})
						]))
				]));
	});
var $author$project$Translations$Wizard$WhatActivityLevel$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' is...';
};
var $author$project$Page$Wizard$WhatActivityLevel$ToggleWorkingDog = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wizard$WhatActivityLevel$onChange = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$Wizard$WhatActivityLevel$workingDogToggle = F2(
	function (id, activityLevel) {
		var isChecked = _Utils_eq(
			activityLevel,
			$elm$core$Maybe$Just(5));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('activity-level__working-dog-toggle')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(isChecked),
							$author$project$Page$Wizard$WhatActivityLevel$onChange(
							$author$project$Page$Wizard$WhatActivityLevel$ToggleWorkingDog(id))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('a working dog')
						]))
				]));
	});
var $author$project$Page$Wizard$WhatActivityLevel$activityLevelInputs = F3(
	function (env, id, dog) {
		var inputs = _Utils_eq(
			dog.bP,
			$elm$core$Maybe$Just(5)) ? _List_fromArray(
			[
				A2($author$project$Page$Wizard$WhatActivityLevel$workingDogToggle, id, dog.bP)
			]) : _List_fromArray(
			[
				A2($author$project$Page$Wizard$WhatActivityLevel$workingDogToggle, id, dog.bP),
				A3($author$project$Page$Wizard$WhatActivityLevel$activityLevelRadioButtons, env, id, dog.bP)
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatActivityLevel$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('activity-level')
						]),
					inputs)
				]));
	});
var $author$project$Translations$Wizard$WhatActivityLevel$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'How active is ' + (name + '?');
	} else {
		return 'How active are they?';
	}
};
var $author$project$Page$Wizard$WhatActivityLevel$view = F2(
	function (env, dogs) {
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatActivityLevel$questionTitle(
										{ed: names}))
								]))
						])),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$Wizard$WhatActivityLevel$activityLevelInputs(env),
					dogs)));
	});
var $author$project$Page$Wizard$WhatBodyCondition$SetBodyCondition = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Translations$Wizard$WhatBodyCondition$bodyCondition = function (x) {
	switch (x) {
		case 0:
			return 'A little skinny';
		case 1:
			return 'Just right';
		default:
			return 'A bit chubby';
	}
};
var $author$project$Translations$Wizard$WhatBodyCondition$bodyConditionExplainer = function (x) {
	switch (x) {
		case 0:
			return 'Narrow waistline and you can clearly see their ribs.';
		case 1:
			return 'Visible waistline with some fat cover but their ribs are easy to feel.';
		default:
			return 'Waistline is hard to see and their ribs are hard to feel.';
	}
};
var $author$project$Page$Wizard$WhatBodyCondition$bodyConditionExplanation = function (bodyCondition) {
	var arrowPosition = function () {
		switch (bodyCondition) {
			case 0:
				return 'first';
			case 1:
				return 'second';
			default:
				return 'third';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('body-type__description body-type__description--' + arrowPosition)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Translations$Wizard$WhatBodyCondition$bodyConditionExplainer(bodyCondition))
			]));
};
var $author$project$Translations$Wizard$WhatBodyCondition$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' is...';
};
var $author$project$Page$Wizard$WhatBodyCondition$bodyConditionInputs = F3(
	function (env, id, dog) {
		var toMsg = function (bodyCondition) {
			return A2($author$project$Page$Wizard$WhatBodyCondition$SetBodyCondition, id, bodyCondition);
		};
		var itemClass = 'body-type__example-row__item';
		var isSelected = function (bodyCondition) {
			return _Utils_eq(
				dog.bU,
				$elm$core$Maybe$Just(bodyCondition));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatBodyCondition$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('body-type')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('body-type__example-row')
								]),
							_List_fromArray(
								[
									$author$project$Buttons$radioWithIcon(
									{
										b8: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.eK;
											}),
										bA: isSelected(0),
										bB: itemClass,
										cf: toMsg(0),
										cA: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.eL;
											}),
										cG: '',
										cJ: $author$project$Translations$Wizard$WhatBodyCondition$bodyCondition(0)
									}),
									$author$project$Buttons$radioWithIcon(
									{
										b8: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.dV;
											}),
										bA: isSelected(1),
										bB: itemClass,
										cf: toMsg(1),
										cA: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.dW;
											}),
										cG: '',
										cJ: $author$project$Translations$Wizard$WhatBodyCondition$bodyCondition(1)
									}),
									$author$project$Buttons$radioWithIcon(
									{
										b8: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.dh;
											}),
										bA: isSelected(2),
										bB: itemClass,
										cf: toMsg(2),
										cA: A2(
											$author$project$Env$asset,
											env,
											function ($) {
												return $.di;
											}),
										cG: '',
										cJ: $author$project$Translations$Wizard$WhatBodyCondition$bodyCondition(2)
									})
								]))
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$html$Html$text(''),
					A2($elm$core$Maybe$map, $author$project$Page$Wizard$WhatBodyCondition$bodyConditionExplanation, dog.bU))
				]));
	});
var $author$project$Translations$Wizard$WhatBodyCondition$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'What does ' + ($author$project$Translations$possessiveName(name) + ' body look like?');
	} else {
		return 'What do their bodies look like?';
	}
};
var $author$project$Page$Wizard$WhatBodyCondition$view = F2(
	function (env, dogs) {
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatBodyCondition$questionTitle(
										{ed: names}))
								]))
						])),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$Wizard$WhatBodyCondition$bodyConditionInputs(env),
					dogs)));
	});
var $author$project$Translations$Wizard$WhatBreed$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'What breed is ' + (name + '?');
	} else {
		return 'What breeds are they?';
	}
};
var $author$project$Page$Wizard$WhatBreed$SearchForBreed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Wizard$WhatBreed$SelectBreed = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Dog$Breeds$all = function (_v0) {
	var breeds = _v0;
	return breeds.aO;
};
var $author$project$Translations$Wizard$WhatBreed$breedPlaceholder = 'Start typing your dog\'s breed';
var $tripokey$elm_fuzzy$Fuzzy$AddPenalty = function (a) {
	return {$: 0, a: a};
};
var $tripokey$elm_fuzzy$Fuzzy$addPenalty = function (penalty) {
	return $tripokey$elm_fuzzy$Fuzzy$AddPenalty(penalty);
};
var $tripokey$elm_fuzzy$Fuzzy$Match = F4(
	function (score, offset, length, keys) {
		return {dX: keys, d3: length, ch: offset, eG: score};
	});
var $tripokey$elm_fuzzy$Fuzzy$Result = F2(
	function (score, matches) {
		return {bC: matches, eG: score};
	});
var $tripokey$elm_fuzzy$Fuzzy$ConfigModel = F4(
	function (addPenalty, movePenalty, removePenalty, insertPenalty) {
		return {aN: addPenalty, a5: insertPenalty, a9: movePenalty, bf: removePenalty};
	});
var $tripokey$elm_fuzzy$Fuzzy$defaultConfig = A4($tripokey$elm_fuzzy$Fuzzy$ConfigModel, 10, 1000, 10000, 1);
var $tripokey$elm_fuzzy$Fuzzy$dissect = F2(
	function (separators, strings) {
		dissect:
		while (true) {
			if (!separators.b) {
				return strings;
			} else {
				var head = separators.a;
				var tail = separators.b;
				var dissectEntry = function (entry) {
					var separatorLength = $elm$core$String$length(head);
					var slice = F2(
						function (index, _v1) {
							var prevIndex = _v1.a;
							var sum = _v1.b;
							var separatorSlice = _List_fromArray(
								[
									A3($elm$core$String$slice, index, index + separatorLength, entry)
								]);
							var precedingSlice = _Utils_eq(prevIndex, index) ? _List_Nil : _List_fromArray(
								[
									A3($elm$core$String$slice, prevIndex, index, entry)
								]);
							return _Utils_Tuple2(
								index + separatorLength,
								_Utils_ap(
									sum,
									_Utils_ap(precedingSlice, separatorSlice)));
						});
					var indexes = A2($elm$core$String$indexes, head, entry);
					var result = A3(
						$elm$core$List$foldl,
						slice,
						_Utils_Tuple2(0, _List_Nil),
						indexes);
					var lastIndex = result.a;
					var first = result.b;
					var entryLength = $elm$core$String$length(entry);
					var last = _Utils_eq(lastIndex, entryLength) ? _List_Nil : _List_fromArray(
						[
							A3($elm$core$String$slice, lastIndex, entryLength, entry)
						]);
					return _Utils_ap(first, last);
				};
				var dissected = A3(
					$elm$core$List$foldl,
					F2(
						function (e, s) {
							return _Utils_ap(
								s,
								dissectEntry(e));
						}),
					_List_Nil,
					strings);
				var $temp$separators = tail,
					$temp$strings = dissected;
				separators = $temp$separators;
				strings = $temp$strings;
				continue dissect;
			}
		}
	});
var $elm$core$String$foldl = _String_foldl;
var $tripokey$elm_fuzzy$Fuzzy$initialModel = _List_Nil;
var $tripokey$elm_fuzzy$Fuzzy$quickSort = function (entries) {
	if (!entries.b) {
		return _Utils_Tuple2(0, _List_Nil);
	} else {
		var head = entries.a;
		var tail = entries.b;
		var partition = A2(
			$elm$core$List$partition,
			function (e) {
				return _Utils_cmp(e, head) < 0;
			},
			tail);
		var smaller = $tripokey$elm_fuzzy$Fuzzy$quickSort(partition.a);
		var penalty = $elm$core$List$isEmpty(smaller.b) ? 0 : 1;
		var larger = $tripokey$elm_fuzzy$Fuzzy$quickSort(partition.b);
		return _Utils_Tuple2(
			(smaller.a + penalty) + larger.a,
			_Utils_ap(
				smaller.b,
				_Utils_ap(
					_List_fromArray(
						[head]),
					larger.b)));
	}
};
var $tripokey$elm_fuzzy$Fuzzy$distance = F3(
	function (config, needle, hay) {
		var accumulateInsertPenalty = F2(
			function (elem, result) {
				if (!result.a.$) {
					var prev = result.a.a;
					var score = result.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(elem),
						((elem - 1) - prev) + score);
				} else {
					var _v2 = result.a;
					var score = result.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(elem),
						score);
				}
			});
		var accumulate = F2(
			function (c, indexList) {
				var indexes = A2(
					$elm$core$String$indexes,
					$elm$core$String$fromChar(c),
					hay);
				var hayIndex = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (e) {
							return !A2($elm$core$List$member, e, indexList);
						},
						indexes));
				if (!hayIndex.$) {
					var v = hayIndex.a;
					return _Utils_ap(
						indexList,
						_List_fromArray(
							[v]));
				} else {
					return indexList;
				}
			});
		var accumulated = A3($elm$core$String$foldl, accumulate, $tripokey$elm_fuzzy$Fuzzy$initialModel, needle);
		var hPenalty = ($elm$core$String$length(hay) - $elm$core$List$length(accumulated)) * config.aN;
		var nPenalty = ($elm$core$String$length(needle) - $elm$core$List$length(accumulated)) * config.bf;
		var sorted = $tripokey$elm_fuzzy$Fuzzy$quickSort(accumulated);
		var iPenalty = A3(
			$elm$core$List$foldl,
			accumulateInsertPenalty,
			_Utils_Tuple2($elm$core$Maybe$Nothing, 0),
			sorted.b).b * config.a5;
		var mPenalty = sorted.a * config.a9;
		return A4(
			$tripokey$elm_fuzzy$Fuzzy$Match,
			((mPenalty + hPenalty) + nPenalty) + iPenalty,
			0,
			$elm$core$String$length(hay),
			sorted.b);
	});
var $tripokey$elm_fuzzy$Fuzzy$padHays = F2(
	function (ns, hs) {
		return _Utils_ap(
			hs,
			A2(
				$elm$core$List$repeat,
				ns - $elm$core$List$length(hs),
				''));
	});
var $tripokey$elm_fuzzy$Fuzzy$reduceLeft = F3(
	function (ns, c, hs) {
		return _Utils_Tuple2(
			A3(
				$elm$core$List$foldl,
				F2(
					function (e, sum) {
						return $elm$core$String$length(e) + sum;
					}),
				0,
				A2($elm$core$List$take, c, hs)),
			A2($elm$core$List$drop, c, hs));
	});
var $tripokey$elm_fuzzy$Fuzzy$reduceRight = F3(
	function (ns, c, hs) {
		return A2(
			$elm$core$List$take,
			$elm$core$List$length(hs) - ((ns - c) - 1),
			hs);
	});
var $tripokey$elm_fuzzy$Fuzzy$match = F4(
	function (configs, separators, needle, hay) {
		var reduceHays = F3(
			function (ns, c, hs) {
				return A3(
					$tripokey$elm_fuzzy$Fuzzy$reduceLeft,
					ns,
					c,
					A3(
						$tripokey$elm_fuzzy$Fuzzy$reduceRight,
						ns,
						c,
						A2($tripokey$elm_fuzzy$Fuzzy$padHays, ns, hs)));
			});
		var needles = A2(
			$tripokey$elm_fuzzy$Fuzzy$dissect,
			separators,
			_List_fromArray(
				[needle]));
		var initialResult = A2($tripokey$elm_fuzzy$Fuzzy$Result, 0, _List_Nil);
		var hays = A2(
			$tripokey$elm_fuzzy$Fuzzy$dissect,
			separators,
			_List_fromArray(
				[hay]));
		var accumulateConfig = F2(
			function (c, sum) {
				switch (c.$) {
					case 0:
						var val = c.a;
						return _Utils_update(
							sum,
							{aN: val});
					case 1:
						var val = c.a;
						return _Utils_update(
							sum,
							{bf: val});
					case 2:
						var val = c.a;
						return _Utils_update(
							sum,
							{a9: val});
					default:
						var val = c.a;
						return _Utils_update(
							sum,
							{a5: val});
				}
			});
		var config = A3($elm$core$List$foldl, accumulateConfig, $tripokey$elm_fuzzy$Fuzzy$defaultConfig, configs);
		var minScore = F2(
			function (n, _v2) {
				var offset = _v2.a;
				var hs = _v2.b;
				var initialPenalty = ((($elm$core$String$length(n) * config.bf) + ($elm$core$String$length(n) * config.a9)) + ($elm$core$String$length(hay) * config.aN)) + (($elm$core$String$length(hay) * $elm$core$String$length(n)) * config.a5);
				var initialMatch = A4($tripokey$elm_fuzzy$Fuzzy$Match, initialPenalty, offset, 0, _List_Nil);
				var accumulateMatch = F2(
					function (e, _v1) {
						var prev = _v1.a;
						var prevOffset = _v1.b;
						var newOffset = prevOffset + $elm$core$String$length(e);
						var eDistance = A3($tripokey$elm_fuzzy$Fuzzy$distance, config, n, e);
						var newMatch = (_Utils_cmp(eDistance.eG, prev.eG) < 0) ? _Utils_update(
							eDistance,
							{ch: prevOffset}) : prev;
						return _Utils_Tuple2(newMatch, newOffset);
					});
				return A3(
					$elm$core$List$foldl,
					accumulateMatch,
					_Utils_Tuple2(initialMatch, offset),
					hs).a;
			});
		var accumulateResult = F2(
			function (n, _v0) {
				var prev = _v0.a;
				var num = _v0.b;
				var matchResult = A2(
					minScore,
					n,
					A3(
						reduceHays,
						$elm$core$List$length(needles),
						num,
						hays));
				var newResult = _Utils_update(
					prev,
					{
						bC: _Utils_ap(
							prev.bC,
							_List_fromArray(
								[matchResult])),
						eG: matchResult.eG + prev.eG
					});
				return _Utils_Tuple2(newResult, num + 1);
			});
		return A3(
			$elm$core$List$foldl,
			accumulateResult,
			_Utils_Tuple2(initialResult, 0),
			needles).a;
	});
var $author$project$Page$Wizard$WhatBreed$fuzzyCompare = F2(
	function (searchTerm, candidate) {
		var separators = _List_Nil;
		var processCasing = $elm$core$String$toLower;
		var normalizedSearchTerm = processCasing(searchTerm);
		var normalizedCanditate = processCasing(candidate);
		var config = _List_fromArray(
			[
				$tripokey$elm_fuzzy$Fuzzy$addPenalty(1)
			]);
		return A4($tripokey$elm_fuzzy$Fuzzy$match, config, separators, normalizedSearchTerm, normalizedCanditate).eG;
	});
var $author$project$Dog$Breeds$mostPopular = function (_v0) {
	var breeds = _v0;
	return A2(
		$elm$core$List$filter,
		function (breed) {
			return A2($elm$core$List$member, breed.a3, breeds.bE);
		},
		breeds.aO);
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Translations$Wizard$WhatBreed$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' is a...';
};
var $author$project$Page$Wizard$WhatBreed$viewWhatBreedInput = F4(
	function (breeds, maybeSearchTerm, id, dog) {
		var viewSearchResult = function (breed) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('breed-selector__item'),
						$elm$html$Html$Events$onClick(
						A2($author$project$Page$Wizard$WhatBreed$SelectBreed, id, breed))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(breed.cg)
					]));
		};
		var searchTerm = A2($elm$core$Maybe$withDefault, '', maybeSearchTerm);
		var scoredBreeds = A2(
			$elm$core$List$sortBy,
			$elm$core$Tuple$first,
			A2(
				$elm$core$List$map,
				function (breed) {
					return _Utils_Tuple2(
						A2($author$project$Page$Wizard$WhatBreed$fuzzyCompare, searchTerm, breed.cg),
						breed);
				},
				$author$project$Dog$Breeds$all(breeds)));
		var searchResults = A2($elm$core$List$map, $elm$core$Tuple$second, scoredBreeds);
		var viewSearchResults = A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('breed-selector')
				]),
			function () {
				if (maybeSearchTerm.$ === 1) {
					return _List_Nil;
				} else {
					if (maybeSearchTerm.a === '') {
						return A2(
							$elm$core$List$map,
							viewSearchResult,
							A2(
								$elm$core$List$take,
								5,
								$author$project$Dog$Breeds$mostPopular(breeds)));
					} else {
						var x = maybeSearchTerm.a;
						return _Utils_eq(
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.cg;
								},
								dog.bV),
							$elm$core$Maybe$Just(x)) ? _List_Nil : A2($elm$core$List$map, viewSearchResult, searchResults);
					}
				}
			}());
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatBreed$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Events$onInput(
							$author$project$Page$Wizard$WhatBreed$SearchForBreed(id)),
							$elm$html$Html$Events$onFocus(
							A2($author$project$Page$Wizard$WhatBreed$SearchForBreed, id, searchTerm)),
							$elm$html$Html$Attributes$value(searchTerm),
							$elm$html$Html$Attributes$placeholder($author$project$Translations$Wizard$WhatBreed$breedPlaceholder)
						]),
					_List_Nil),
					viewSearchResults
				]));
	});
var $author$project$Page$Wizard$WhatBreed$view = F3(
	function (env, breeds, model) {
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			model.j);
		var mkInput = F2(
			function (id, dog) {
				return A4(
					$author$project$Page$Wizard$WhatBreed$viewWhatBreedInput,
					breeds,
					A2($elm$core$Dict$get, id, model.aa),
					id,
					dog);
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatBreed$questionTitle(
										{ed: names}))
								]))
						])),
				A2($elm$core$List$indexedMap, mkInput, model.j)));
	});
var $author$project$Translations$Wizard$WhatCurrentFoods$questionSubtitle = 'Choose all that you feed';
var $author$project$Translations$Wizard$WhatCurrentFoods$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'What does ' + (name + ' currently eat?');
	} else {
		return 'What are they currently eating?';
	}
};
var $author$project$Page$Wizard$WhatCurrentFoods$CloseTypeahead = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Wizard$WhatCurrentFoods$SearchForBrand = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Wizard$WhatCurrentFoods$SelectBrand = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $fapian$elm_html_aria$Html$Attributes$Aria$ariaControls = $elm$html$Html$Attributes$attribute('aria-controls');
var $fapian$elm_html_aria$Html$Attributes$Aria$ariaExpanded = $elm$html$Html$Attributes$attribute('aria-expanded');
var $fapian$elm_html_aria$Html$Attributes$Aria$ariaHasPopup = $elm$html$Html$Attributes$attribute('aria-haspopup');
var $fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel = $elm$html$Html$Attributes$attribute('aria-label');
var $author$project$Html$Helpers$enter = 13;
var $author$project$Html$Helpers$keyCodeDecoder = F2(
	function (codes, msg) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (code) {
				return A2($elm$core$List$member, code, codes) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('un-recognised key-code');
			},
			$elm$html$Html$Events$keyCode);
	});
var $author$project$Html$Helpers$space = 32;
var $author$project$Html$Helpers$onKeyboardSelection = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2(
			$author$project$Html$Helpers$keyCodeDecoder,
			_List_fromArray(
				[$author$project$Html$Helpers$enter, $author$project$Html$Helpers$space]),
			msg));
};
var $author$project$Dog$FoodBrands$otherDry = function (_v0) {
	var brands = _v0;
	return {aj: 0, a3: brands.aq.dv, cg: 'Other food brand - Dry'};
};
var $author$project$Dog$FoodBrands$otherRaw = function (_v0) {
	var brands = _v0;
	return {aj: 2, a3: brands.aq.ey, cg: 'Other food brand - Raw'};
};
var $author$project$Dog$FoodBrands$otherWet = function (_v0) {
	var brands = _v0;
	return {aj: 1, a3: brands.aq.fa, cg: 'Other food brand - Wet'};
};
var $author$project$Dog$FoodBrands$others = function (_v0) {
	var brands = _v0;
	return _List_fromArray(
		[
			$author$project$Dog$FoodBrands$otherWet(brands),
			$author$project$Dog$FoodBrands$otherRaw(brands),
			$author$project$Dog$FoodBrands$otherDry(brands)
		]);
};
var $author$project$Translations$Wizard$WhatCurrentFoods$placeholder = function (dogName) {
	return $author$project$Translations$possessiveName(dogName) + ' current food';
};
var $fapian$elm_html_aria$Html$Attributes$Aria$role = $elm$html$Html$Attributes$attribute('role');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Bool$Helpers$toString = function (x) {
	if (x) {
		return 'true';
	} else {
		return 'false';
	}
};
var $author$project$Page$Wizard$WhatCurrentFoods$typeaheadId = function (dogId) {
	return 'food-brand-typeahead-' + $elm$core$String$fromInt(dogId);
};
var $author$project$Page$Wizard$WhatCurrentFoods$viewSearchResult = F2(
	function (selectBrand, brand) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('typeahead-selector__item'),
					$elm$html$Html$Events$onClick(
					selectBrand(brand)),
					$author$project$Html$Helpers$onKeyboardSelection(
					selectBrand(brand)),
					$elm$html$Html$Attributes$tabindex(0),
					$fapian$elm_html_aria$Html$Attributes$Aria$role('button')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(brand.cg)
				]));
	});
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$isJust = function (maybeThing) {
	if (!maybeThing.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$String$filter = _String_filter;
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root = function (string) {
	return A2(
		$elm$core$String$filter,
		function (c) {
			return $elm$core$Char$isLower(c) || $elm$core$Char$isDigit(c);
		},
		$elm$core$String$toLower(string));
};
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$searchHelper = F2(
	function (letter, restOfWord) {
		if (!restOfWord.$) {
			var string = restOfWord.a;
			var _v1 = A2(
				$elm$core$String$indexes,
				$elm$core$String$fromChar(letter),
				string);
			if (!_v1.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var found = _v1.a;
				return $elm$core$Maybe$Just(
					A2($elm$core$String$dropLeft, found + 1, string));
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match = F2(
	function (needle, haystack) {
		return $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$isJust(
			A3(
				$elm$core$String$foldl,
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$searchHelper,
				$elm$core$Maybe$Just(
					$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root(haystack)),
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root(needle)));
	});
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$filter = F3(
	function (map, needle, records) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeL,
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match(needle),
				map),
			records);
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Page$Wizard$WhatCurrentFoods$viewSearchResults = F4(
	function (foods, brands, id, searchTerm) {
		var normalize = function (str) {
			return $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root(str);
		};
		var normalizedSearchTerm = normalize(searchTerm);
		var longestMatch = F2(
			function (a, b) {
				var bMatches = A2(
					$elm$core$String$startsWith,
					normalizedSearchTerm,
					normalize(b.cg));
				var aMatches = A2(
					$elm$core$String$startsWith,
					normalizedSearchTerm,
					normalize(a.cg));
				return (aMatches && (!bMatches)) ? 0 : ((bMatches && (!aMatches)) ? 2 : 1);
			});
		var isMember = function (selectedCategory) {
			return A2($elm$core$List$member, selectedCategory, foods);
		};
		var searchResults = A2(
			$elm$core$List$sortWith,
			longestMatch,
			A3(
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$filter,
				A2(
					$elm$core$Basics$composeL,
					normalize,
					function ($) {
						return $.cg;
					}),
				normalizedSearchTerm,
				A2(
					$elm$core$List$filter,
					function (brand) {
						return isMember(brand.aj);
					},
					$author$project$Dog$FoodBrands$all(brands))));
		return A2(
			$elm$core$List$map,
			$author$project$Page$Wizard$WhatCurrentFoods$viewSearchResult(
				$author$project$Page$Wizard$WhatCurrentFoods$SelectBrand(id)),
			searchResults);
	});
var $author$project$Page$Wizard$WhatCurrentFoods$foodBrandTypeahead = F6(
	function (brands, id, dog, maybeSearchTerm, selectedBrands, foodsSelected) {
		var showSearchResults = (!_Utils_eq(maybeSearchTerm, $elm$core$Maybe$Nothing)) && (!_Utils_eq(
			maybeSearchTerm,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.cg;
				},
				dog.b_)));
		var showPreviouslySelected = _Utils_eq(
			maybeSearchTerm,
			$elm$core$Maybe$Just(''));
		var showingResults = showPreviouslySelected || showSearchResults;
		var searchTerm = A2($elm$core$Maybe$withDefault, '', maybeSearchTerm);
		var listBoxId = 'typeahead-list-box' + $elm$core$String$fromInt(id);
		var isMember = function (brand) {
			return A2($elm$core$List$member, brand.aj, foodsSelected);
		};
		var otherBrands = A2(
			$elm$core$List$filter,
			isMember,
			$author$project$Dog$FoodBrands$others(brands));
		var otherOption = A2(
			$elm$core$List$map,
			function (other) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('typeahead-selector__item typeahead-selector__item--other'),
							$elm$html$Html$Events$onClick(
							A2($author$project$Page$Wizard$WhatCurrentFoods$SelectBrand, id, other)),
							$author$project$Html$Helpers$onKeyboardSelection(
							A2($author$project$Page$Wizard$WhatCurrentFoods$SelectBrand, id, other)),
							$elm$html$Html$Attributes$tabindex(0),
							$fapian$elm_html_aria$Html$Attributes$Aria$role('button')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(other.cg)
						]));
			},
			otherBrands);
		var foods = A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$);
		var selectableOptions = function () {
			var children = showPreviouslySelected ? _List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('typeahead-selector')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Wizard$WhatCurrentFoods$viewSearchResult(
							$author$project$Page$Wizard$WhatCurrentFoods$SelectBrand(id)),
						selectedBrands))
				]) : (showSearchResults ? _List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('typeahead-selector'),
							$fapian$elm_html_aria$Html$Attributes$Aria$role('listbox'),
							$elm$html$Html$Attributes$id(listBoxId)
						]),
					A4($author$project$Page$Wizard$WhatCurrentFoods$viewSearchResults, foods, brands, id, searchTerm)),
					A2($elm$html$Html$div, _List_Nil, otherOption)
				]) : _List_Nil);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('typeahead-selector-wrapper')
					]),
				children);
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('brand-question-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('brand-question')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Which brand does ' + (dog.cg + ' mostly eat these days?'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$fapian$elm_html_aria$Html$Attributes$Aria$role('combobox'),
							A2($elm$html$Html$Attributes$attribute, 'aria-owns', listBoxId),
							$elm$html$Html$Attributes$id(
							$author$project$Page$Wizard$WhatCurrentFoods$typeaheadId(id)),
							$fapian$elm_html_aria$Html$Attributes$Aria$ariaHasPopup('listbox'),
							$fapian$elm_html_aria$Html$Attributes$Aria$ariaExpanded(
							$author$project$Bool$Helpers$toString(showingResults)),
							$elm$html$Html$Attributes$class('typeahead-combobox'),
							A2(
							$elm$html$Html$Events$on,
							'focusout',
							$elm$json$Json$Decode$succeed(
								$author$project$Page$Wizard$WhatCurrentFoods$CloseTypeahead(id))),
							A2(
							$elm$html$Html$Events$on,
							'focusin',
							$elm$json$Json$Decode$succeed(
								A2($author$project$Page$Wizard$WhatCurrentFoods$SearchForBrand, id, searchTerm)))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$class('typeahead-input'),
									$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Search for your dog\'s current food branc'),
									$fapian$elm_html_aria$Html$Attributes$Aria$ariaControls('typeahead-listbox'),
									A2($elm$html$Html$Attributes$attribute, 'aria-autocomplete', listBoxId),
									$elm$html$Html$Events$onInput(
									$author$project$Page$Wizard$WhatCurrentFoods$SearchForBrand(id)),
									$elm$html$Html$Events$onFocus(
									A2($author$project$Page$Wizard$WhatCurrentFoods$SearchForBrand, id, searchTerm)),
									$elm$html$Html$Attributes$value(searchTerm),
									$elm$html$Html$Attributes$placeholder(
									$author$project$Translations$Wizard$WhatCurrentFoods$placeholder(dog.cg))
								]),
							_List_Nil),
							selectableOptions,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('brand-info-box')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(' We like to get to know our Butternutters before we take them out to dinner.')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Wizard$WhatCurrentFoods$DeselectFoodCategory = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$Wizard$WhatCurrentFoods$SelectFoodCategory = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Dog$FoodCategory$all = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Dog$FoodCategory$toString = function (foodCategory) {
	switch (foodCategory) {
		case 0:
			return 'Dry';
		case 1:
			return 'Wet';
		case 2:
			return 'Raw';
		default:
			return 'Home-cooked';
	}
};
var $author$project$Page$Wizard$WhatCurrentFoods$foodTypeInput = F3(
	function (id, dog, brands) {
		var categoryAlreadySelected = function (item) {
			return A2(
				$elm$core$List$member,
				item,
				A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$));
		};
		var selectMessage = function (item) {
			return categoryAlreadySelected(item) ? A3($author$project$Page$Wizard$WhatCurrentFoods$DeselectFoodCategory, id, item, brands) : A3($author$project$Page$Wizard$WhatCurrentFoods$SelectFoodCategory, id, item, brands);
		};
		var checkBoxConfig = {
			bO: A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$),
			bj: selectMessage,
			bk: $author$project$Dog$FoodCategory$toString
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn--group')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Buttons$checkBox(checkBoxConfig),
				$author$project$Dog$FoodCategory$all));
	});
var $author$project$Translations$Wizard$WhatCurrentFoods$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + ' eats...';
};
var $author$project$Page$Wizard$WhatCurrentFoods$viewFoodInputs = F5(
	function (foodBrands, maybeSearchTerm, id, dog, selectedBrands) {
		var uniqueSelected = A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			function (selectedBrand) {
				return selectedBrand.cg;
			},
			selectedBrands);
		var hasfoodTypeInput = function () {
			var _v0 = dog.b$;
			if (!_v0.$) {
				if (!_v0.a.b) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		}();
		var foods = A2($elm$core$Maybe$withDefault, _List_Nil, dog.b$);
		var inCategorySelected = A2(
			$elm$core$List$filter,
			function (brand) {
				return A2($elm$core$List$member, brand.aj, foods);
			},
			uniqueSelected);
		var eatsHomeCooked = _Utils_eq(
			dog.b$,
			$elm$core$Maybe$Just(
				_List_fromArray(
					[3])));
		var inputs = (hasfoodTypeInput && (!eatsHomeCooked)) ? _List_fromArray(
			[
				A3($author$project$Page$Wizard$WhatCurrentFoods$foodTypeInput, id, dog, foodBrands),
				A6($author$project$Page$Wizard$WhatCurrentFoods$foodBrandTypeahead, foodBrands, id, dog, maybeSearchTerm, inCategorySelected, foods)
			]) : _List_fromArray(
			[
				A3($author$project$Page$Wizard$WhatCurrentFoods$foodTypeInput, id, dog, foodBrands)
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sub-question')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatCurrentFoods$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2($elm$html$Html$div, _List_Nil, inputs)
				]));
	});
var $author$project$Page$Wizard$WhatCurrentFoods$view = F3(
	function (env, brands, model) {
		var selectedBrands = $author$project$List$Helpers$catMaybes(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.b_;
				},
				model.j));
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			model.j);
		var mkInput = F2(
			function (id, dog) {
				return A5(
					$author$project$Page$Wizard$WhatCurrentFoods$viewFoodInputs,
					brands,
					A2($elm$core$Dict$get, id, model.p),
					id,
					dog,
					selectedBrands);
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatCurrentFoods$questionTitle(
										{ed: names}))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Translations$Wizard$WhatCurrentFoods$questionSubtitle)
								]))
						])),
				A2($elm$core$List$indexedMap, mkInput, model.j)));
	});
var $author$project$Translations$Wizard$WhatKindOfEater$questionSubtitle = function (_v0) {
	var sexes = _v0.cC;
	return 'So we can pick meals ' + ($author$project$Translations$subjectPronoun(sexes) + ' will enjoy!');
};
var $author$project$Translations$Wizard$WhatKindOfEater$presentTenseDo = function (dogCount) {
	return (dogCount === 1) ? 'does' : 'do';
};
var $author$project$Translations$Wizard$WhatKindOfEater$questionTitle = function (_v0) {
	var sexes = _v0.cC;
	return 'How ' + ($author$project$Translations$Wizard$WhatKindOfEater$presentTenseDo(
		$elm$core$List$length(sexes)) + (' ' + ($author$project$Translations$subjectPronoun(sexes) + (' eat ' + ($author$project$Translations$possessivePronoun(sexes) + ' food?')))));
};
var $author$project$Page$Wizard$WhatKindOfEater$SetFussiness = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Dog$Fussiness$all = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Translations$Wizard$WhatKindOfEater$fussiness = function (x) {
	switch (x) {
		case 1:
			return 'Can be fussy';
		case 3:
			return 'Eats anything';
		case 2:
			return 'Is a good eater';
		default:
			return 'Is very fussy';
	}
};
var $author$project$Translations$Wizard$WhatKindOfEater$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName;
};
var $author$project$Page$Wizard$WhatKindOfEater$viewFussinessButtons = F2(
	function (id, dog) {
		var config = {
			bN: dog.b5,
			bj: $author$project$Page$Wizard$WhatKindOfEater$SetFussiness(id),
			bk: $author$project$Translations$Wizard$WhatKindOfEater$fussiness
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatKindOfEater$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn--group')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Buttons$radio(config),
						$author$project$Dog$Fussiness$all))
				]));
	});
var $author$project$Page$Wizard$WhatKindOfEater$view = F2(
	function (env, dogs) {
		var sexes = A2(
			$elm$core$List$map,
			function ($) {
				return $.cB;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatKindOfEater$questionTitle(
										{cC: sexes}))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatKindOfEater$questionSubtitle(
										{cC: sexes}))
								]))
						])),
				A2($elm$core$List$indexedMap, $author$project$Page$Wizard$WhatKindOfEater$viewFussinessButtons, dogs)));
	});
var $author$project$Translations$Wizard$WhatMass$bigDogErrorBody = function (_v0) {
	var name = _v0.cg;
	return 'Our bigger dog customers need a bit of extra love. Get in touch with our friendly Customer Love team and they’ll help you create a personalised plan that works for you and ' + (name + '.');
};
var $author$project$Translations$Wizard$WhatMass$bigDogErrorHeading = 'Looks like you have a big dog!';
var $author$project$Page$Wizard$WhatMass$SetMassFromSlider = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Page$Wizard$WhatMass$massSlider = F2(
	function (id, massInGrams) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('range-slider__slider')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('0kg')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('range'),
							$elm$html$Html$Attributes$step('1000'),
							$elm$html$Html$Attributes$min('0'),
							$elm$html$Html$Attributes$max('50000'),
							$elm$html$Html$Events$onInput(
							$author$project$Page$Wizard$WhatMass$SetMassFromSlider(id)),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(massInGrams))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('50kg +')
						]))
				]));
	});
var $author$project$Page$Wizard$WhatMass$SetMassFromTextField = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Wizard$WhatMass$massTextField = F2(
	function (id, massInKg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('range-slider__output')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('range-slider__output__wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$placeholder('00.00'),
									$elm$html$Html$Attributes$value(massInKg),
									$elm$html$Html$Events$onInput(
									$author$project$Page$Wizard$WhatMass$SetMassFromTextField(id))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('kg')
								]))
						]))
				]));
	});
var $author$project$Page$Wizard$WhatMass$SetMass = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Wizard$WhatMass$massWaypoints = F3(
	function (env, id, massInGrams) {
		var withinBounds = F2(
			function (target, actual) {
				var epsilon = 3500;
				var difference = actual - target;
				return ((target === 45000) && (_Utils_cmp(actual, target) > -1)) ? true : ((difference < 0) ? (_Utils_cmp(
					$elm$core$Basics$abs(difference),
					epsilon) < 1) : (_Utils_cmp(difference, epsilon - 1) < 1));
			});
		var waypoints = _List_fromArray(
			[
				_Utils_Tuple2(
				3000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.eM;
					})),
				_Utils_Tuple2(
				10000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.dl;
					})),
				_Utils_Tuple2(
				17000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.eP;
					})),
				_Utils_Tuple2(
				24000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.$7;
					})),
				_Utils_Tuple2(
				31000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.dY;
					})),
				_Utils_Tuple2(
				38000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.eF;
					})),
				_Utils_Tuple2(
				45000,
				A2(
					$author$project$Env$asset,
					env,
					function ($) {
						return $.dL;
					}))
			]);
		var selected = F2(
			function (actualMass, mass) {
				return A2(withinBounds, mass, actualMass);
			});
		var waypoint = F3(
			function (i, currentMass, _v0) {
				var grams = _v0.a;
				var asset = _v0.b;
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$Page$Wizard$WhatMass$SetMass, i, grams)),
							$elm$html$Html$Attributes$src(asset),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'size-selected',
									A2(selected, currentMass, grams))
								]))
						]),
					_List_Nil);
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('range-slider__dog-sizes')
				]),
			A2(
				$elm$core$List$map,
				A2(waypoint, id, massInGrams),
				waypoints));
	});
var $author$project$Page$Wizard$WhatMass$massInputs = F4(
	function (env, massInputValues, id, dog) {
		var massInKg = A2(
			$elm$core$Maybe$withDefault,
			'0',
			A2($elm$core$Dict$get, id, massInputValues));
		var massInGrams = A2($elm$core$Maybe$withDefault, 0, dog.cc);
		var dogIsTooBigToFeed = A2(
			$author$project$Maybe$Helpers$toBool,
			function (mass) {
				return !$author$project$FeedingGuidelines$canMakeRecommendationFor(
					{cc: mass, ce: dog.ce, cN: dog.cN});
			},
			dog.cc);
		var dogTooBigWarning = dogIsTooBigToFeed ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert alert--warning')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Translations$Wizard$WhatMass$bigDogErrorHeading)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$Wizard$WhatMass$bigDogErrorBody(
								{cg: dog.cg}))
						]))
				])) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(dog.cg + ' weighs about...')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('range-slider')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Wizard$WhatMass$massTextField, id, massInKg),
							A3($author$project$Page$Wizard$WhatMass$massWaypoints, env, id, massInGrams),
							A2($author$project$Page$Wizard$WhatMass$massSlider, id, massInGrams)
						])),
					dogTooBigWarning
				]));
	});
var $author$project$Translations$Wizard$WhatMass$questionSubtitle = function (_v0) {
	var sexes = _v0.cC;
	var presentTenseNeed = ($elm$core$List$length(sexes) > 1) ? ' need ' : ' needs ';
	return 'This helps us get the amount of food ' + ($author$project$Translations$subjectPronoun(sexes) + (presentTenseNeed + ('just right. If you\'re not sure, use the slider below to give us ' + ($author$project$Translations$possessivePronoun(sexes) + ' approximate weight.'))));
};
var $author$project$Translations$Wizard$WhatMass$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'How much does ' + (name + ' weigh?');
	} else {
		return 'How much do they currently weigh?';
	}
};
var $author$project$Page$Wizard$WhatMass$view = F2(
	function (env, model) {
		var dogs = model.j;
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		var sexes = A2(
			$elm$core$List$map,
			function ($) {
				return $.cB;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatMass$questionTitle(
										{ed: names}))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatMass$questionSubtitle(
										{cC: sexes}))
								]))
						])),
				A2(
					$elm$core$List$indexedMap,
					A2($author$project$Page$Wizard$WhatMass$massInputs, env, model.L),
					dogs)));
	});
var $author$project$Translations$Wizard$WhatSnackingHabits$questionTitle = function (_v0) {
	var names = _v0.ed;
	if (names.b && (!names.b.b)) {
		var name = names.a;
		return 'Does ' + (name + ' get any treats or snacks?');
	} else {
		return 'Do they get any treats or snacks?';
	}
};
var $author$project$Page$Wizard$WhatSnackingHabits$SetSnackingHabits = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Translations$Wizard$WhatSnackingHabits$questionDivider = function (_v0) {
	var dogName = _v0.bt;
	return dogName + '...';
};
var $author$project$Translations$Wizard$WhatSnackingHabits$snackDescription = function (x) {
	switch (x) {
		case 0:
			return '';
		case 1:
			return '1-3 times a day';
		default:
			return '4+ times a day';
	}
};
var $author$project$Translations$Wizard$WhatSnackingHabits$snackTitle = function (x) {
	switch (x) {
		case 0:
			return 'Doesn\'t eat any';
		case 1:
			return 'Has some';
		default:
			return 'Has lots';
	}
};
var $author$project$Page$Wizard$WhatSnackingHabits$snackingHabitsInputs = F3(
	function (env, id, dog) {
		var toMsg = $author$project$Page$Wizard$WhatSnackingHabits$SetSnackingHabits(id);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question__item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question-divider')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatSnackingHabits$questionDivider(
										{bt: dog.cg}))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex')
						]),
					_List_fromArray(
						[
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.eg;
									}),
								bA: _Utils_eq(
									dog.cE,
									$elm$core$Maybe$Just(0)),
								bB: 'snacks__item',
								cf: A2($author$project$Page$Wizard$WhatSnackingHabits$SetSnackingHabits, id, 0),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.eh;
									}),
								cG: $author$project$Translations$Wizard$WhatSnackingHabits$snackDescription(0),
								cJ: $author$project$Translations$Wizard$WhatSnackingHabits$snackTitle(0)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.eN;
									}),
								bA: _Utils_eq(
									dog.cE,
									$elm$core$Maybe$Just(1)),
								bB: 'snacks__item',
								cf: A2($author$project$Page$Wizard$WhatSnackingHabits$SetSnackingHabits, id, 1),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.eO;
									}),
								cG: $author$project$Translations$Wizard$WhatSnackingHabits$snackDescription(1),
								cJ: $author$project$Translations$Wizard$WhatSnackingHabits$snackTitle(1)
							}),
							$author$project$Buttons$radioWithIcon(
							{
								b8: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d5;
									}),
								bA: _Utils_eq(
									dog.cE,
									$elm$core$Maybe$Just(2)),
								bB: 'snacks__item',
								cf: A2($author$project$Page$Wizard$WhatSnackingHabits$SetSnackingHabits, id, 2),
								cA: A2(
									$author$project$Env$asset,
									env,
									function ($) {
										return $.d6;
									}),
								cG: $author$project$Translations$Wizard$WhatSnackingHabits$snackDescription(2),
								cJ: $author$project$Translations$Wizard$WhatSnackingHabits$snackTitle(2)
							})
						]))
				]));
	});
var $author$project$Page$Wizard$WhatSnackingHabits$view = F2(
	function (env, dogs) {
		var names = A2(
			$elm$core$List$map,
			function ($) {
				return $.cg;
			},
			dogs);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('question')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('question__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									A2(
										$author$project$Env$asset,
										env,
										function ($) {
											return $.ex;
										})),
									$elm$html$Html$Attributes$class('question__header__image')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('no-margin--bottom')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$Wizard$WhatSnackingHabits$questionTitle(
										{ed: names}))
								]))
						])),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$Wizard$WhatSnackingHabits$snackingHabitsInputs(env),
					dogs)));
	});
var $author$project$Page$Wizard$viewStep = F3(
	function (env, session, step) {
		switch (step.$) {
			case 0:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotHowManyDogsMsg,
					A2($author$project$Page$Wizard$HowManyDogs$view, env, dogs));
			case 1:
				var subModel = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatBreedMsg,
					A3(
						$author$project$Page$Wizard$WhatBreed$view,
						env,
						$author$project$Session$breeds(session),
						subModel));
			case 2:
				var subModel = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatCurrentFoodsMsg,
					A3(
						$author$project$Page$Wizard$WhatCurrentFoods$view,
						env,
						$author$project$Session$brands(session),
						subModel));
			case 3:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatKindOfEaterMsg,
					A2($author$project$Page$Wizard$WhatKindOfEater$view, env, dogs));
			case 4:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotHowOldMsg,
					A2($author$project$Page$Wizard$HowOld$view, env, dogs));
			case 5:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotHaveBeenDeSexedMsg,
					A2($author$project$Page$Wizard$HaveBeenDeSexed$view, env, dogs));
			case 6:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatBodyConditionMsg,
					A2($author$project$Page$Wizard$WhatBodyCondition$view, env, dogs));
			case 7:
				var subModel = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatMassMsg,
					A2($author$project$Page$Wizard$WhatMass$view, env, subModel));
			case 8:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatActivityLevelMsg,
					A2($author$project$Page$Wizard$WhatActivityLevel$view, env, dogs));
			case 9:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotAnyAllergiesMsg,
					A3($author$project$Page$Wizard$AnyAllergies$view, env, session, dogs));
			case 10:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotAnyHealthIssuesMsg,
					A3($author$project$Page$Wizard$AnyHealthIssues$view, env, session, dogs));
			default:
				var dogs = step.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Wizard$GotWhatSnackingHabitsMsg,
					A2($author$project$Page$Wizard$WhatSnackingHabits$view, env, dogs));
		}
	});
var $author$project$Page$Wizard$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('grid content')
			]),
		_List_fromArray(
			[
				A3($author$project$Page$Wizard$viewStep, model.c, model.f, model.n),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('footer footer--fixed')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$Wizard$stepBackButton, model.c, model.n),
						A3($author$project$Page$Wizard$continueButton, model.c, model.f, model.n)
					])),
				A2($author$project$Page$Wizard$viewOverlays, model.c, model.l)
			]));
};
var $author$project$Main$view = function (model) {
	var _v0 = model.b;
	switch (_v0.$) {
		case 0:
			var subModel = _v0.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$view(subModel));
		case 1:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotWelcomeMsg,
				$author$project$Page$Welcome$view(subModel));
		case 2:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotWizardMsg,
				$author$project$Page$Wizard$view(subModel));
		case 3:
			var session = _v0.a;
			var dogs = _v0.b;
			var parent = _v0.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('app')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$CalculatePlan$view, model.c, dogs)
					]));
		case 4:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotMealsMsg,
				$author$project$Page$Meals$view(subModel));
		case 5:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotChoosePlanMsg,
				$author$project$Page$ChoosePlan$view(subModel));
		case 6:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotCheckoutMsg,
				$author$project$Page$Checkout$view(subModel));
		case 7:
			var subModel = _v0.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Main$GotOrderConfirmationMsg,
				$author$project$Page$OrderConfirmation$view(subModel));
		case 8:
			var subModel = _v0.a;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$author$project$Main$GotReceiptMsg,
				$author$project$Page$Receipt$view(subModel));
		default:
			var page = _v0.a;
			var session = _v0.b;
			return A3(
				$author$project$Main$layoutAfterLogin,
				model,
				$elm$core$Basics$identity,
				A2($author$project$Page$SalesHistory$view, model.c, model.bi));
	}
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{dT: $author$project$Main$init, eU: $author$project$Main$subscriptions, e3: $author$project$Main$updateWithPersist, e6: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (persistedPage) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (env) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (assetPaths) {
							return $elm$json$Json$Decode$succeed(
								{bT: assetPaths, c: env, bI: persistedPage});
						},
						A2(
							$elm$json$Json$Decode$field,
							'assetPaths',
							A2(
								$elm$json$Json$Decode$andThen,
								function (wholePlan) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (whiteFish) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (warningDog) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (walkingDog) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (walkingDachshund) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (vetLine) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (turkey) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (trustpilotStar) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (trustpilotRating) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (trustpilotLogoBlack) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (trustpilotLogo) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (trustPilotRating) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (trialBox) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (toolTipQuestion) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (sprinkle) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (springerSpaniel) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (someTreatsSelected) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (someTreats) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (smallChihuahua) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (skinnySelected) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (skinny) {
																																																	return A2(
																																																		$elm$json$Json$Decode$andThen,
																																																		function (shinyCoats) {
																																																			return A2(
																																																				$elm$json$Json$Decode$andThen,
																																																				function (schaeferhund) {
																																																					return A2(
																																																						$elm$json$Json$Decode$andThen,
																																																						function (salesHistory) {
																																																							return A2(
																																																								$elm$json$Json$Decode$andThen,
																																																								function (restart) {
																																																									return A2(
																																																										$elm$json$Json$Decode$andThen,
																																																										function (questionHeader) {
																																																											return A2(
																																																												$elm$json$Json$Decode$andThen,
																																																												function (poweredByStripe) {
																																																													return A2(
																																																														$elm$json$Json$Decode$andThen,
																																																														function (pork) {
																																																															return A2(
																																																																$elm$json$Json$Decode$andThen,
																																																																function (pencil) {
																																																																	return A2(
																																																																		$elm$json$Json$Decode$andThen,
																																																																		function (paw) {
																																																																			return A2(
																																																																				$elm$json$Json$Decode$andThen,
																																																																				function (orderSuccess) {
																																																																					return A2(
																																																																						$elm$json$Json$Decode$andThen,
																																																																						function (orderPending) {
																																																																							return A2(
																																																																								$elm$json$Json$Decode$andThen,
																																																																								function (ongoingBox) {
																																																																									return A2(
																																																																										$elm$json$Json$Decode$andThen,
																																																																										function (noTreatsSelected) {
																																																																											return A2(
																																																																												$elm$json$Json$Decode$andThen,
																																																																												function (noTreats) {
																																																																													return A2(
																																																																														$elm$json$Json$Decode$andThen,
																																																																														function (noPreservatives) {
																																																																															return A2(
																																																																																$elm$json$Json$Decode$andThen,
																																																																																function (noGrains) {
																																																																																	return A2(
																																																																																		$elm$json$Json$Decode$andThen,
																																																																																		function (moreEnergy) {
																																																																																			return A2(
																																																																																				$elm$json$Json$Decode$andThen,
																																																																																				function (mixPlan) {
																																																																																					return A2(
																																																																																						$elm$json$Json$Decode$andThen,
																																																																																						function (lotsOfTreatsSelected) {
																																																																																							return A2(
																																																																																								$elm$json$Json$Decode$andThen,
																																																																																								function (lotsOfTreats) {
																																																																																									return A2(
																																																																																										$elm$json$Json$Decode$andThen,
																																																																																										function (logOut) {
																																																																																											return A2(
																																																																																												$elm$json$Json$Decode$andThen,
																																																																																												function (leisurelySelected) {
																																																																																													return A2(
																																																																																														$elm$json$Json$Decode$andThen,
																																																																																														function (leisurely) {
																																																																																															return A2(
																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																function (lazyBonesSelected) {
																																																																																																	return A2(
																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																		function (lazyBones) {
																																																																																																			return A2(
																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																				function (lamb) {
																																																																																																					return A2(
																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																						function (labrador) {
																																																																																																							return A2(
																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																								function (justRightSelected) {
																																																																																																									return A2(
																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																										function (justRight) {
																																																																																																											return A2(
																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																												function (information) {
																																																																																																													return A2(
																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																														function (hyperactiveSelected) {
																																																																																																															return A2(
																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																function (hyperactive) {
																																																																																																																	return A2(
																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																		function (greatDane) {
																																																																																																																			return A2(
																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																				function (goldenRetriever) {
																																																																																																																					return A2(
																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																						function (gentlyCooked) {
																																																																																																																							return A2(
																																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																																								function (freshIngredients) {
																																																																																																																									return A2(
																																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																																										function (fresh) {
																																																																																																																											return A2(
																																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																																												function (flexiblePlan) {
																																																																																																																													return A2(
																																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																																														function (fairlyActiveSelected) {
																																																																																																																															return A2(
																																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																																function (fairlyActive) {
																																																																																																																																	return A2(
																																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																																		function (duckAndChicken) {
																																																																																																																																			return A2(
																																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																																				function (draggableThumb) {
																																																																																																																																					return A2(
																																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																																						function (deliveryVanWhite) {
																																																																																																																																							return A2(
																																																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																																																								function (deliveryVan) {
																																																																																																																																									return A2(
																																																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																																																										function (deliveredToYourDoor) {
																																																																																																																																											return A2(
																																																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																																																												function (dalmatian) {
																																																																																																																																													return A2(
																																																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																																																														function (cockapoo) {
																																																																																																																																															return A2(
																																																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																																																function (closedBox) {
																																																																																																																																																	return A2(
																																																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																																																		function (closeIcon) {
																																																																																																																																																			return A2(
																																																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																																																				function (chubbySelected) {
																																																																																																																																																					return A2(
																																																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																																																						function (chubby) {
																																																																																																																																																							return A2(
																																																																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																																																																								function (chihuahua) {
																																																																																																																																																									return A2(
																																																																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																																																																										function (chicken) {
																																																																																																																																																											return A2(
																																																																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																																																																												function (checkmarkYellow) {
																																																																																																																																																													return A2(
																																																																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																																																																														function (checkmarkActive) {
																																																																																																																																																															return A2(
																																																																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																																																																function (checkmark) {
																																																																																																																																																																	return A2(
																																																																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																																																																		function (calculatePlanLine) {
																																																																																																																																																																			return A2(
																																																																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																																																																				function (calculatePlanDogTail) {
																																																																																																																																																																					return A2(
																																																																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																																																																						function (calculatePlanDogStar) {
																																																																																																																																																																							return A2(
																																																																																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																																																																																								function (calculatePlanDogSparks) {
																																																																																																																																																																									return A2(
																																																																																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																																																																																										function (calculatePlanDogBlob) {
																																																																																																																																																																											return A2(
																																																																																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																																																																																												function (calculatePlanDog) {
																																																																																																																																																																													return A2(
																																																																																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																																																																																														function (butternutLogo) {
																																																																																																																																																																															return A2(
																																																																																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																																																																																function (bundleOfEnergySelected) {
																																																																																																																																																																																	return A2(
																																																																																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																																																																																		function (bundleOfEnergy) {
																																																																																																																																																																																			return A2(
																																																																																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																																																																																				function (bulldog) {
																																																																																																																																																																																					return A2(
																																																																																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																																																																																						function (bloodhound) {
																																																																																																																																																																																							return A2(
																																																																																																																																																																																								$elm$json$Json$Decode$andThen,
																																																																																																																																																																																								function (betterDigestion) {
																																																																																																																																																																																									return A2(
																																																																																																																																																																																										$elm$json$Json$Decode$andThen,
																																																																																																																																																																																										function (bernhardiner) {
																																																																																																																																																																																											return A2(
																																																																																																																																																																																												$elm$json$Json$Decode$andThen,
																																																																																																																																																																																												function (beef) {
																																																																																																																																																																																													return A2(
																																																																																																																																																																																														$elm$json$Json$Decode$andThen,
																																																																																																																																																																																														function (bannerBestValue) {
																																																																																																																																																																																															return A2(
																																																																																																																																																																																																$elm$json$Json$Decode$andThen,
																																																																																																																																																																																																function (baHat) {
																																																																																																																																																																																																	return A2(
																																																																																																																																																																																																		$elm$json$Json$Decode$andThen,
																																																																																																																																																																																																		function (angleRight) {
																																																																																																																																																																																																			return A2(
																																																																																																																																																																																																				$elm$json$Json$Decode$andThen,
																																																																																																																																																																																																				function (angleLeft) {
																																																																																																																																																																																																					return A2(
																																																																																																																																																																																																						$elm$json$Json$Decode$andThen,
																																																																																																																																																																																																						function (angleDown) {
																																																																																																																																																																																																							return $elm$json$Json$Decode$succeed(
																																																																																																																																																																																																								{cQ: angleDown, cR: angleLeft, cS: angleRight, cU: baHat, cV: bannerBestValue, cW: beef, cX: bernhardiner, cY: betterDigestion, cZ: bloodhound, c$: bulldog, c0: bundleOfEnergy, c1: bundleOfEnergySelected, c2: butternutLogo, c3: calculatePlanDog, c4: calculatePlanDogBlob, c5: calculatePlanDogSparks, c6: calculatePlanDogStar, c7: calculatePlanDogTail, c8: calculatePlanLine, dc: checkmark, dd: checkmarkActive, de: checkmarkYellow, df: chicken, dg: chihuahua, dh: chubby, di: chubbySelected, dj: closeIcon, dk: closedBox, dl: cockapoo, $7: dalmatian, dp: deliveredToYourDoor, dr: deliveryVan, ds: deliveryVanWhite, du: draggableThumb, dw: duckAndChicken, dE: fairlyActive, dF: fairlyActiveSelected, dG: flexiblePlan, dH: fresh, dI: freshIngredients, dJ: gentlyCooked, dK: goldenRetriever, dL: greatDane, dO: hyperactive, dP: hyperactiveSelected, dS: information, dV: justRight, dW: justRightSelected, dY: labrador, dZ: lamb, d$: lazyBones, d0: lazyBonesSelected, d1: leisurely, d2: leisurelySelected, d4: logOut, d5: lotsOfTreats, d6: lotsOfTreatsSelected, ea: mixPlan, eb: moreEnergy, ee: noGrains, ef: noPreservatives, eg: noTreats, eh: noTreatsSelected, en: ongoingBox, ep: orderPending, eq: orderSuccess, er: paw, es: pencil, eu: pork, ew: poweredByStripe, ex: questionHeader, eC: restart, eE: salesHistory, eF: schaeferhund, eJ: shinyCoats, eK: skinny, eL: skinnySelected, eM: smallChihuahua, eN: someTreats, eO: someTreatsSelected, eP: springerSpaniel, eQ: sprinkle, eW: toolTipQuestion, eY: trialBox, eZ: trustPilotRating, e_: trustpilotLogo, e$: trustpilotLogoBlack, e0: trustpilotRating, e1: trustpilotStar, e2: turkey, e5: vetLine, e7: walkingDachshund, e8: walkingDog, e9: warningDog, fb: whiteFish, fc: wholePlan});
																																																																																																																																																																																																						},
																																																																																																																																																																																																						A2($elm$json$Json$Decode$field, 'angleDown', $elm$json$Json$Decode$string));
																																																																																																																																																																																																				},
																																																																																																																																																																																																				A2($elm$json$Json$Decode$field, 'angleLeft', $elm$json$Json$Decode$string));
																																																																																																																																																																																																		},
																																																																																																																																																																																																		A2($elm$json$Json$Decode$field, 'angleRight', $elm$json$Json$Decode$string));
																																																																																																																																																																																																},
																																																																																																																																																																																																A2($elm$json$Json$Decode$field, 'baHat', $elm$json$Json$Decode$string));
																																																																																																																																																																																														},
																																																																																																																																																																																														A2($elm$json$Json$Decode$field, 'bannerBestValue', $elm$json$Json$Decode$string));
																																																																																																																																																																																												},
																																																																																																																																																																																												A2($elm$json$Json$Decode$field, 'beef', $elm$json$Json$Decode$string));
																																																																																																																																																																																										},
																																																																																																																																																																																										A2($elm$json$Json$Decode$field, 'bernhardiner', $elm$json$Json$Decode$string));
																																																																																																																																																																																								},
																																																																																																																																																																																								A2($elm$json$Json$Decode$field, 'betterDigestion', $elm$json$Json$Decode$string));
																																																																																																																																																																																						},
																																																																																																																																																																																						A2($elm$json$Json$Decode$field, 'bloodhound', $elm$json$Json$Decode$string));
																																																																																																																																																																																				},
																																																																																																																																																																																				A2($elm$json$Json$Decode$field, 'bulldog', $elm$json$Json$Decode$string));
																																																																																																																																																																																		},
																																																																																																																																																																																		A2($elm$json$Json$Decode$field, 'bundleOfEnergy', $elm$json$Json$Decode$string));
																																																																																																																																																																																},
																																																																																																																																																																																A2($elm$json$Json$Decode$field, 'bundleOfEnergySelected', $elm$json$Json$Decode$string));
																																																																																																																																																																														},
																																																																																																																																																																														A2($elm$json$Json$Decode$field, 'butternutLogo', $elm$json$Json$Decode$string));
																																																																																																																																																																												},
																																																																																																																																																																												A2($elm$json$Json$Decode$field, 'calculatePlanDog', $elm$json$Json$Decode$string));
																																																																																																																																																																										},
																																																																																																																																																																										A2($elm$json$Json$Decode$field, 'calculatePlanDogBlob', $elm$json$Json$Decode$string));
																																																																																																																																																																								},
																																																																																																																																																																								A2($elm$json$Json$Decode$field, 'calculatePlanDogSparks', $elm$json$Json$Decode$string));
																																																																																																																																																																						},
																																																																																																																																																																						A2($elm$json$Json$Decode$field, 'calculatePlanDogStar', $elm$json$Json$Decode$string));
																																																																																																																																																																				},
																																																																																																																																																																				A2($elm$json$Json$Decode$field, 'calculatePlanDogTail', $elm$json$Json$Decode$string));
																																																																																																																																																																		},
																																																																																																																																																																		A2($elm$json$Json$Decode$field, 'calculatePlanLine', $elm$json$Json$Decode$string));
																																																																																																																																																																},
																																																																																																																																																																A2($elm$json$Json$Decode$field, 'checkmark', $elm$json$Json$Decode$string));
																																																																																																																																																														},
																																																																																																																																																														A2($elm$json$Json$Decode$field, 'checkmarkActive', $elm$json$Json$Decode$string));
																																																																																																																																																												},
																																																																																																																																																												A2($elm$json$Json$Decode$field, 'checkmarkYellow', $elm$json$Json$Decode$string));
																																																																																																																																																										},
																																																																																																																																																										A2($elm$json$Json$Decode$field, 'chicken', $elm$json$Json$Decode$string));
																																																																																																																																																								},
																																																																																																																																																								A2($elm$json$Json$Decode$field, 'chihuahua', $elm$json$Json$Decode$string));
																																																																																																																																																						},
																																																																																																																																																						A2($elm$json$Json$Decode$field, 'chubby', $elm$json$Json$Decode$string));
																																																																																																																																																				},
																																																																																																																																																				A2($elm$json$Json$Decode$field, 'chubbySelected', $elm$json$Json$Decode$string));
																																																																																																																																																		},
																																																																																																																																																		A2($elm$json$Json$Decode$field, 'closeIcon', $elm$json$Json$Decode$string));
																																																																																																																																																},
																																																																																																																																																A2($elm$json$Json$Decode$field, 'closedBox', $elm$json$Json$Decode$string));
																																																																																																																																														},
																																																																																																																																														A2($elm$json$Json$Decode$field, 'cockapoo', $elm$json$Json$Decode$string));
																																																																																																																																												},
																																																																																																																																												A2($elm$json$Json$Decode$field, 'dalmatian', $elm$json$Json$Decode$string));
																																																																																																																																										},
																																																																																																																																										A2($elm$json$Json$Decode$field, 'deliveredToYourDoor', $elm$json$Json$Decode$string));
																																																																																																																																								},
																																																																																																																																								A2($elm$json$Json$Decode$field, 'deliveryVan', $elm$json$Json$Decode$string));
																																																																																																																																						},
																																																																																																																																						A2($elm$json$Json$Decode$field, 'deliveryVanWhite', $elm$json$Json$Decode$string));
																																																																																																																																				},
																																																																																																																																				A2($elm$json$Json$Decode$field, 'draggableThumb', $elm$json$Json$Decode$string));
																																																																																																																																		},
																																																																																																																																		A2($elm$json$Json$Decode$field, 'duckAndChicken', $elm$json$Json$Decode$string));
																																																																																																																																},
																																																																																																																																A2($elm$json$Json$Decode$field, 'fairlyActive', $elm$json$Json$Decode$string));
																																																																																																																														},
																																																																																																																														A2($elm$json$Json$Decode$field, 'fairlyActiveSelected', $elm$json$Json$Decode$string));
																																																																																																																												},
																																																																																																																												A2($elm$json$Json$Decode$field, 'flexiblePlan', $elm$json$Json$Decode$string));
																																																																																																																										},
																																																																																																																										A2($elm$json$Json$Decode$field, 'fresh', $elm$json$Json$Decode$string));
																																																																																																																								},
																																																																																																																								A2($elm$json$Json$Decode$field, 'freshIngredients', $elm$json$Json$Decode$string));
																																																																																																																						},
																																																																																																																						A2($elm$json$Json$Decode$field, 'gentlyCooked', $elm$json$Json$Decode$string));
																																																																																																																				},
																																																																																																																				A2($elm$json$Json$Decode$field, 'goldenRetriever', $elm$json$Json$Decode$string));
																																																																																																																		},
																																																																																																																		A2($elm$json$Json$Decode$field, 'greatDane', $elm$json$Json$Decode$string));
																																																																																																																},
																																																																																																																A2($elm$json$Json$Decode$field, 'hyperactive', $elm$json$Json$Decode$string));
																																																																																																														},
																																																																																																														A2($elm$json$Json$Decode$field, 'hyperactiveSelected', $elm$json$Json$Decode$string));
																																																																																																												},
																																																																																																												A2($elm$json$Json$Decode$field, 'information', $elm$json$Json$Decode$string));
																																																																																																										},
																																																																																																										A2($elm$json$Json$Decode$field, 'justRight', $elm$json$Json$Decode$string));
																																																																																																								},
																																																																																																								A2($elm$json$Json$Decode$field, 'justRightSelected', $elm$json$Json$Decode$string));
																																																																																																						},
																																																																																																						A2($elm$json$Json$Decode$field, 'labrador', $elm$json$Json$Decode$string));
																																																																																																				},
																																																																																																				A2($elm$json$Json$Decode$field, 'lamb', $elm$json$Json$Decode$string));
																																																																																																		},
																																																																																																		A2($elm$json$Json$Decode$field, 'lazyBones', $elm$json$Json$Decode$string));
																																																																																																},
																																																																																																A2($elm$json$Json$Decode$field, 'lazyBonesSelected', $elm$json$Json$Decode$string));
																																																																																														},
																																																																																														A2($elm$json$Json$Decode$field, 'leisurely', $elm$json$Json$Decode$string));
																																																																																												},
																																																																																												A2($elm$json$Json$Decode$field, 'leisurelySelected', $elm$json$Json$Decode$string));
																																																																																										},
																																																																																										A2($elm$json$Json$Decode$field, 'logOut', $elm$json$Json$Decode$string));
																																																																																								},
																																																																																								A2($elm$json$Json$Decode$field, 'lotsOfTreats', $elm$json$Json$Decode$string));
																																																																																						},
																																																																																						A2($elm$json$Json$Decode$field, 'lotsOfTreatsSelected', $elm$json$Json$Decode$string));
																																																																																				},
																																																																																				A2($elm$json$Json$Decode$field, 'mixPlan', $elm$json$Json$Decode$string));
																																																																																		},
																																																																																		A2($elm$json$Json$Decode$field, 'moreEnergy', $elm$json$Json$Decode$string));
																																																																																},
																																																																																A2($elm$json$Json$Decode$field, 'noGrains', $elm$json$Json$Decode$string));
																																																																														},
																																																																														A2($elm$json$Json$Decode$field, 'noPreservatives', $elm$json$Json$Decode$string));
																																																																												},
																																																																												A2($elm$json$Json$Decode$field, 'noTreats', $elm$json$Json$Decode$string));
																																																																										},
																																																																										A2($elm$json$Json$Decode$field, 'noTreatsSelected', $elm$json$Json$Decode$string));
																																																																								},
																																																																								A2($elm$json$Json$Decode$field, 'ongoingBox', $elm$json$Json$Decode$string));
																																																																						},
																																																																						A2($elm$json$Json$Decode$field, 'orderPending', $elm$json$Json$Decode$string));
																																																																				},
																																																																				A2($elm$json$Json$Decode$field, 'orderSuccess', $elm$json$Json$Decode$string));
																																																																		},
																																																																		A2($elm$json$Json$Decode$field, 'paw', $elm$json$Json$Decode$string));
																																																																},
																																																																A2($elm$json$Json$Decode$field, 'pencil', $elm$json$Json$Decode$string));
																																																														},
																																																														A2($elm$json$Json$Decode$field, 'pork', $elm$json$Json$Decode$string));
																																																												},
																																																												A2($elm$json$Json$Decode$field, 'poweredByStripe', $elm$json$Json$Decode$string));
																																																										},
																																																										A2($elm$json$Json$Decode$field, 'questionHeader', $elm$json$Json$Decode$string));
																																																								},
																																																								A2($elm$json$Json$Decode$field, 'restart', $elm$json$Json$Decode$string));
																																																						},
																																																						A2($elm$json$Json$Decode$field, 'salesHistory', $elm$json$Json$Decode$string));
																																																				},
																																																				A2($elm$json$Json$Decode$field, 'schaeferhund', $elm$json$Json$Decode$string));
																																																		},
																																																		A2($elm$json$Json$Decode$field, 'shinyCoats', $elm$json$Json$Decode$string));
																																																},
																																																A2($elm$json$Json$Decode$field, 'skinny', $elm$json$Json$Decode$string));
																																														},
																																														A2($elm$json$Json$Decode$field, 'skinnySelected', $elm$json$Json$Decode$string));
																																												},
																																												A2($elm$json$Json$Decode$field, 'smallChihuahua', $elm$json$Json$Decode$string));
																																										},
																																										A2($elm$json$Json$Decode$field, 'someTreats', $elm$json$Json$Decode$string));
																																								},
																																								A2($elm$json$Json$Decode$field, 'someTreatsSelected', $elm$json$Json$Decode$string));
																																						},
																																						A2($elm$json$Json$Decode$field, 'springerSpaniel', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'sprinkle', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'toolTipQuestion', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'trialBox', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'trustPilotRating', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'trustpilotLogo', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'trustpilotLogoBlack', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'trustpilotRating', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'trustpilotStar', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'turkey', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'vetLine', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'walkingDachshund', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'walkingDog', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'warningDog', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'whiteFish', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'wholePlan', $elm$json$Json$Decode$string))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'env',
					A2(
						$elm$json$Json$Decode$andThen,
						function (stripePublishableKey) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (railsURI) {
									return $elm$json$Json$Decode$succeed(
										{ct: railsURI, cF: stripePublishableKey});
								},
								A2($elm$json$Json$Decode$field, 'railsURI', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'stripePublishableKey', $elm$json$Json$Decode$string))));
		},
		A2($elm$json$Json$Decode$field, 'persistedPage', $elm$json$Json$Decode$value)))(0)}});}(this));