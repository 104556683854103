import './sass/main.sass'

import env from './env'

import assetPaths from './assetPaths'

import { Elm } from './elm/Main.elm'
import registerServiceWorker from './registerServiceWorker'

import * as signUps from './signUps'
import * as monitoring from './monitoring'

import * as Sentry from '@sentry/browser'

const LOCAL_STORAGE_PAGE_KEY = 'point-of-sale-page-persistence-v0'
const LOCAL_STORAGE_PAGE_EXPIRATION_KEY = `${LOCAL_STORAGE_PAGE_KEY}-expires`

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: env.sentryDsn,
    release: env.version
  })
}

registerServiceWorker()

function getPersistedPage () {
  try {
    const expirationTimestring = window.localStorage.getItem(LOCAL_STORAGE_PAGE_EXPIRATION_KEY)
    const expirationDate = new Date(Number(expirationTimestring))
    const now = new Date()

    // if you give anything other than a valid Unix timestamp (either a number or
    // a string, JS doesn't mind) // to the Date constructor, you'll get an
    // `Invalid Date {}` object, whose `getDate` method returns NaN
    const stale = isNaN(expirationDate.getDate()) || now > expirationDate
    if (stale) {
      window.localStorage.clear()
      return null
    }

    const persistedPage = window.localStorage.getItem(LOCAL_STORAGE_PAGE_KEY)
    return (persistedPage) ? JSON.parse(persistedPage) : null
  } catch (_) {
    window.localStorage.clear()
    return null
  }
}

function persistPage (page) {
  const key = LOCAL_STORAGE_PAGE_KEY
  const value = JSON.stringify(page)
  window.localStorage.setItem(key, value)

  const expirationDate = new Date()
  expirationDate.setHours(23, 59, 59, 999) // 23:59 tonight
  const expirationTimestring = JSON.stringify(Number(expirationDate))
  window.localStorage.setItem(
    LOCAL_STORAGE_PAGE_EXPIRATION_KEY,
    expirationTimestring
  )
}

function sendTodaysSales (port, store) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const startOfDay = today.getTime()

  port.send({
    complete: store.complete.filter(sale => sale.firstAttemptedAt >= startOfDay),
    pending: store.pending,
    failed: store.failed.filter(sale => sale.firstAttemptedAt >= startOfDay)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const persistedPage = getPersistedPage()
  const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: { assetPaths, env, persistedPage }
  })

  window.addEventListener('offline', () => {
    app.ports.updateOnlineStatus.send(false)
  }, false)

  window.addEventListener('online', () => {
    app.ports.updateOnlineStatus.send(true)
  }, false)

  app.ports.updateOnlineStatus.send(navigator.onLine)

  signUps.getAll().then(store => {
    sendTodaysSales(app.ports.updateStore, store)
  })

  app.ports.persistCustomer.subscribe(async customer => {
    const firstAttemptedAt = (new Date()).getTime()
    const completedAt = firstAttemptedAt
    const toSave = {
      ...customer,
      firstAttemptedAt,
      completedAt
    }
    const store = await signUps.persistSuccessfulSignUp(toSave)
    sendTodaysSales(app.ports.updateStore, store)
  })

  app.ports.attemptLater.subscribe(async customerDetails => {
    const firstAttemptedAt = (new Date()).getTime()
    const toTryLater = {
      ...customerDetails,
      firstAttemptedAt
    }
    const store = await signUps.attemptLater(toTryLater)
    sendTodaysSales(app.ports.updateStore, store)
  })

  app.ports.persistToLocalStorage.subscribe(persistPage)

  app.ports.offlineAuthToken.subscribe(signUps.setAuthToken)

  app.ports.monitoringEvent.subscribe(monitoring.send)

  window.setInterval(async () => {
    await signUps.retryAll()
    const store = await signUps.getAll()
    sendTodaysSales(app.ports.updateStore, store)
  }, 60 * 1000)
})
