import butternutLogo from '././images/butternut_logo.svg'
import closedBox from './images/icons/closed-box.svg'
import toolTipQuestion from './images/icons/tooltip-question.svg'
import poweredByStripe from './images/powered-by-stripe.svg'
import trustPilotRating from './images/trustpilot-rating.svg'
import sprinkle from './images/sprinkle.svg'
import checkmarkActive from './images/icons/checkmark--active.svg'
import checkmark from './images/icons/checkmark.svg'
import information from './images/icons/information.svg'
import restart from './images/icons/restart.svg'
import logOut from './images/icons/log-out.svg'
import angleDown from './images/icons/angle-down.svg'
import angleLeft from './images/icons/angle-left.svg'
import angleRight from './images/icons/angle-right.svg'
import chicken from './images/meals/chicken.png'
import beef from './images/meals/beef.png'
import turkey from './images/meals/turkey.png'
import lamb from './images/meals/lamb.png'
import whiteFish from './images/meals/white_fish.png'
import pork from './images/meals/pork.png'
import duckAndChicken from './images/meals/duckAndChicken.png'
import fresh from './images/icons/fresh.svg'
import noGrains from './images/icons/no-grains.svg'
import noPreservatives from './images/icons/no-preservatives.svg'
import trustpilotLogoBlack from './images/trustpilot/trustpilot-logo--black.svg'
import trustpilotLogo from './images/trustpilot/trustpilot-logo.svg'
import trustpilotRating from './images/trustpilot/trustpilot-rating.svg'
import trustpilotStar from './images/trustpilot/trustpilot-star.svg'
import wholePlan from './images/icons/whole-plan.svg'
import mixPlan from './images/icons/mix-plan.svg'
import bannerBestValue from './images/icons/banner-best-value.svg'
import baHat from './images/header/ba-hat.svg'
import checkmarkYellow from './images/icons/checkmark--yellow.svg'
import closeIcon from './images/icons/close.svg'
import betterDigestion from './images/modals/better-digestion.svg'
import deliveryVan from './images/modals/delivery-van.svg'
import deliveryVanWhite from './images/icons/delivery-van--white.svg'
import flexiblePlan from './images/modals/flexible-plan.svg'
import moreEnergy from './images/modals/more-energy.svg'
import ongoingBox from './images/modals/ongoing-box.svg'
import shinyCoats from './images/modals/shiny-coats.svg'
import trialBox from './images/modals/trial-box.svg'
import vetLine from './images/modals/vet-line.svg'
import questionHeader from './images/questions/question-header.svg'
import walkingDog from './images/questions/walking-dog.svg'
import skinny from './images/questions/body-condition/skinny.svg'
import skinnySelected from './images/questions/body-condition/skinny--selected.svg'
import justRight from './images/questions/body-condition/just-right.svg'
import justRightSelected from './images/questions/body-condition/just-right--selected.svg'
import chubby from './images/questions/body-condition/chubby.svg'
import chubbySelected from './images/questions/body-condition/chubby--selected.svg'
import bundleOfEnergy from './images/questions/activity-level/bundle-of-energy.svg'
import bundleOfEnergySelected from './images/questions/activity-level/bundle-of-energy--selected.svg'
import fairlyActive from './images/questions/activity-level/fairly-active.svg'
import fairlyActiveSelected from './images/questions/activity-level/fairly-active--selected.svg'
import lazyBones from './images/questions/activity-level/lazy-bones.svg'
import lazyBonesSelected from './images/questions/activity-level/lazy-bones--selected.svg'
import hyperactive from './images/questions/activity-level/hyperactive.svg'
import hyperactiveSelected from './images/questions/activity-level/hyperactive--selected.svg'
import leisurely from './images/questions/activity-level/leisurely.svg'
import leisurelySelected from './images/questions/activity-level/leisurely--selected.svg'
import noTreats from './images/questions/snacks/no-treats.svg'
import noTreatsSelected from './images/questions/snacks/no-treats--selected.svg'
import someTreats from './images/questions/snacks/some-treats.svg'
import someTreatsSelected from './images/questions/snacks/some-treats--selected.svg'
import lotsOfTreats from './images/questions/snacks/lots-of-treats.svg'
import lotsOfTreatsSelected from './images/questions/snacks/lots-of-treats--selected.svg'
import paw from './images/questions/lead-capture/paw.svg'
import pencil from './images/icons/pencil.svg'
import warningDog from './images/icons/warning-dog.svg'

import cockapoo from './images/questions/dog-weight/cockapoo.svg'
import dalmatian from './images/questions/dog-weight/dalmatian.svg'
import labrador from './images/questions/dog-weight/labrador.svg'
import schaeferhund from './images/questions/dog-weight/schaeferhund.svg'
import smallChihuahua from './images/questions/dog-weight/small-chihuahua.svg'
import springerSpaniel from './images/questions/dog-weight/springer-spaniel.svg'
import greatDane from './images/questions/dog-weight/great-dane.svg'

import draggableThumb from './images/questions/dog-weight/draggable-thumb.svg'
import bernhardiner from './images/how-many-dogs/Bernhardiner.svg'
import bloodhound from './images/how-many-dogs/Bloodhound.svg'
import bulldog from './images/how-many-dogs/Bulldog.svg'
import chihuahua from './images/how-many-dogs/Chihuahua.svg'
import walkingDachshund from './images/how-many-dogs/Dachshund.svg'
import goldenRetriever from './images/how-many-dogs/Golden-Retriever.svg'
import deliveredToYourDoor from './images/icons/delivered-to-your-door.svg'
import gentlyCooked from './images/icons/gently-cooked.svg'
import freshIngredients from './images/icons/fresh-ingredients.svg'
import orderSuccess from './images/order-confirmation/order--success.svg'
import orderPending from './images/order-confirmation/order--pending.svg'
import salesHistory from './images/icons/sales-history.svg'
import calculatePlanDogBlob from './images/calculate-plan/dog-blob.svg'
import calculatePlanDogSparks from './images/calculate-plan/dog-sparks.svg'
import calculatePlanDogStar from './images/calculate-plan/dog-star.svg'
import calculatePlanDogTail from './images/calculate-plan/dog-tail.svg'
import calculatePlanDog from './images/calculate-plan/dog.svg'
import calculatePlanLine from './images/calculate-plan/line.svg'



const assetPaths = {
  butternutLogo,
  closedBox,
  toolTipQuestion,
  poweredByStripe,
  trustPilotRating,
  sprinkle,
  checkmarkActive,
  checkmark,
  information,
  restart,
  logOut,
  angleDown,
  angleLeft,
  angleRight,
  chicken,
  beef,
  turkey,
  lamb,
  whiteFish,
  pork,
  duckAndChicken,
  fresh,
  noGrains,
  noPreservatives,
  trustpilotLogoBlack,
  trustpilotLogo,
  trustpilotRating,
  trustpilotStar,
  wholePlan,
  mixPlan,
  bannerBestValue,
  baHat,
  checkmarkYellow,
  closeIcon,
  betterDigestion,
  deliveryVan,
  deliveryVanWhite,
  flexiblePlan,
  moreEnergy,
  ongoingBox,
  shinyCoats,
  trialBox,
  vetLine,
  questionHeader,
  walkingDog,
  skinny,
  skinnySelected,
  justRight,
  justRightSelected,
  chubby,
  chubbySelected,
  labrador,
  cockapoo,
  dalmatian,
  schaeferhund,
  smallChihuahua,
  springerSpaniel,
  greatDane,
  greatDane,
  bundleOfEnergy,
  bundleOfEnergySelected,
  fairlyActive,
  fairlyActiveSelected,
  lazyBones,
  lazyBonesSelected,
  hyperactive,
  hyperactiveSelected,
  leisurely,
  leisurelySelected,
  noTreats,
  noTreatsSelected,
  someTreats,
  someTreatsSelected,
  lotsOfTreats,
  lotsOfTreatsSelected,
  paw,
  pencil,
  warningDog,
  draggableThumb,
  bernhardiner,
  bloodhound,
  bulldog,
  chihuahua,
  walkingDachshund,
  goldenRetriever,
  deliveredToYourDoor,
  gentlyCooked,
  freshIngredients,
  orderSuccess,
  orderPending,
  salesHistory,
  calculatePlanDogBlob,
  calculatePlanDogSparks,
  calculatePlanDogStar,
  calculatePlanDogTail,
  calculatePlanDog,
  calculatePlanLine
}

export default assetPaths
