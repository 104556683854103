/* globals fetch */
import env from './env'

export function send (payload) {
  const uri = `${env.railsURI}/point-of-sale/v1/monitoring/${payload.event}/${payload.delta}`

  fetch(uri, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${payload.apiToken}`
    }
  }).catch(console.error)
}
