/* globals fetch */
import idb from 'idb'

import * as monitoring from './monitoring'
import env from './env'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: env.sentryDsn,
    release: env.version
  })
}

class NetworkError extends Error {
  constructor (message) {
    super(message)
    this.name = 'NetworkError'
  }
}

class UnrecoverableStripeError extends Error {
  constructor (message) {
    super(message)
    this.name = 'UnrecoverableStripeError'
  }
}

class UnauthorisedRailsRequestErorr extends Error {
  constructor (message) {
    super(message)
    this.name = 'UnauthorisedRailsRequestErorr'
  }
}

class UnrecoverableRailsError extends Error {
  constructor (message) {
    super(message)
    this.name = 'UnrecoverableRailsError'
  }
}

const stripePublishableKey = process.env.ELM_APP_STRIPE_PUBLISHABLE_KEY
const butternutBoxAppUrl = process.env.ELM_APP_RAILS_URI
const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'point-of-sales-auth-persistence-v0'

const dbPromise = idb.open('signups', 1, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore('complete', { autoIncrement: true, keyPath: 'id' })
      upgradeDb.createObjectStore('pending', { autoIncrement: true, keyPath: 'id' })
      upgradeDb.createObjectStore('failed', { autoIncrement: true, keyPath: 'id' })
  }
})

async function networkRequest (uri, options) {
  try {
    return await fetch(uri, options)
  } catch (e) {
    throw new NetworkError(`A NetworkError occurred while making a request to ${uri}`)
  }
}

async function addSignUp (objectStoreName, signup) {
  const db = await dbPromise
  const tx = db.transaction(objectStoreName, 'readwrite')
  const store = tx.objectStore(objectStoreName)

  store.add(signup)
  await tx.complete
  return getAll()
}

async function createStripeToken (cardDetails) {
  const authHeader = `Bearer ${stripePublishableKey}`
  const headers = {
    'Accept': 'application/json',
    'Authorization': authHeader,
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const stripeParams = {
    'card[number]': cardDetails.number,
    'card[exp_month]': cardDetails.expiryMonth,
    'card[exp_year]': cardDetails.expiryYear,
    'card[cvc]': cardDetails.cvc
  }

  const body = Object.keys(stripeParams).map(key => {
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(stripeParams[key])
    return `${encodedKey}=${encodedValue}`
  }).join('&')

  const response = await networkRequest('https://api.stripe.com/v1/tokens', {
    method: 'POST',
    headers,
    body
  })

  const token = await response.json()
  if (response.ok && token.id) {
    return token.id
  } else {
    const message = `An unrecoverable error occurred making a request to Stripe. Status code: ${response.status}`
    throw new UnrecoverableStripeError(message)
  }
}

async function createSubscription (authToken, stripeToken, payloadAwaitingStripeToken) {
  const body = {
    ...payloadAwaitingStripeToken,
    stripe_token: stripeToken
  }

  const response = await networkRequest(`${butternutBoxAppUrl}/point-of-sale/v1/checkout/offline`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })

  if (!response.ok) {
    const error = categoriseRailsRequestError(response.status)
    throw error
  }
}

function categoriseRailsRequestError (statusCode) {
  if (statusCode === 401) {
    return new UnauthorisedRailsRequestErorr('Authentication failed when making a request to the Rails app')
  } else {
    return new UnrecoverableRailsError('An unrecoverable error occurred when making a request to the Rails app')
  }
}

export function setAuthToken (token) {
  const key = LOCAL_STORAGE_AUTH_TOKEN_KEY
  window.localStorage.setItem(key, token)
}

export function getAuthToken () {
  return window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
}

export const retryNow = (() => {
  const doRetry = async (id) => {
    const authToken = getAuthToken()
    if (!authToken) return
    const db = await dbPromise
    const pendingTx = db.transaction('pending', 'readonly')
    const store = pendingTx.objectStore('pending')
    const signUp = await store.get(id)

    if (!signUp) return

    try {
      const { card, payloadAwaitingStripeToken } = signUp
      const stripeToken = await createStripeToken(card)

      monitoring.send({ apiToken: authToken, event: 'SignUp-Offline-Attempt', delta: '1' })
      await createSubscription(authToken, stripeToken, payloadAwaitingStripeToken)

      const successTx = db.transaction(
        ['pending', 'complete']
        , 'readwrite'
      )
      const pending = successTx.objectStore('pending')
      const success = successTx.objectStore('complete')
      pending.delete(id)
      const { id: dontCare, ...rest } = minimiseSignUpData(signUp)
      const completedAt = (new Date()).getTime()
      success.add({ ...rest, completedAt })
      await successTx.complete
      monitoring.send({ apiToken: authToken, event: 'SignUp-Offline-Success', delta: '1' })
    } catch (e) {
      if (process.env.NODE_ENV === 'PRODUCTION') {
        Sentry.captureException(e)
      }

      if (e instanceof UnauthorisedRailsRequestErorr) {
        // leave them in the pending queue
        monitoring.send({ apiToken: authToken, event: 'SignUp-Offline-Failure-Rails', delta: '1' })
        return
      } else if (e instanceof NetworkError) {
        // leave them in the pending queue
        monitoring.send({ apiToken: authToken, event: 'SignUp-Offline-Failure-Unreachable', delta: '1' })
        return
      }

      const failedTx = db.transaction(
        ['pending', 'failed']
        , 'readwrite'
      )
      const pending = failedTx.objectStore('pending')
      const failed = failedTx.objectStore('failed')
      pending.delete(id)
      const { id: dontCare, ...rest } = minimiseSignUpData(signUp)
      failed.add(rest)
      await failedTx.complete
    }
  }

  let retryLock = false
  return async (id) => {
    if (retryLock || !window.navigator.onLine) return getAll()
    try {
      retryLock = true
      await doRetry(id)
    } catch (e) {
      throw e
    } finally {
      retryLock = false
    }

    return getAll()
  }
})()

export const retryAll = (() => {
  let retryLock = false
  return async () => {
    if (retryLock) return
    retryLock = true

    try {
      const db = await dbPromise
      const tx = db.transaction(['pending'], 'readonly')
      const ids = await tx.objectStore('pending').getAllKeys()
      for (const id of ids) {
        await retryNow(id)
      }
    } catch (e) {
      // TODO: what should we do here?
    } finally {
      retryLock = false
    }
  }
})()

export async function getAll () {
  const db = await dbPromise
  const tx = db.transaction(
    ['complete', 'pending', 'failed'],
    'readonly'
  )
  const [
    complete,
    pending,
    failed
  ] = await Promise.all([
    tx.objectStore('complete').getAll(),
    tx.objectStore('pending').getAll(),
    tx.objectStore('failed').getAll()
  ])

  const minimisedPending = pending.map(minimiseSignUpData)

  return {
    complete,
    pending: minimisedPending,
    failed
  }
}

export async function persistSuccessfulSignUp (signUp) {
  return addSignUp('complete', signUp)
}

export function attemptLater (signUp) {
  return addSignUp('pending', signUp)
}

function minimiseSignUpData (pendingSignup) {
  const { user } = pendingSignup.payloadAwaitingStripeToken
  return {
    id: pendingSignup.id,
    name: `${user.first_name} ${user.last_name}`,
    dogNames: user.dogs.map(dog => dog.name),
    firstAttemptedAt: pendingSignup.firstAttemptedAt,
    completedAt: 0
  }
}
